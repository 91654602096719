export const sammanvagningCardOptions = () => {
  return {
    w: 2.83,
    h: 4,
    x: 9.77,
    y: 2,
    shadow: {
      type: 'outer',
      color: '000000',
      blur: 5,
      angle: 2,
      offset: 1.0,
      opacity: 0.4,
    },
    fill: { color: 'ffffff' },
  } as any;
};

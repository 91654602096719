import { Params } from '@angular/router';
import Query from '@arcgis/core/rest/support/Query';

export const ingaendeOmradenQuery = (params: Params): Query => {
    const { landsdel, lan } = params;
    const query = new Query();
    query.returnGeometry = true;
    query.outFields = ['*'];
    if (landsdel === 'alla') {
        // Hela landet
        query.where = '1=1';
        return query;
    } else if (isNumeric(landsdel) && isNumeric(lan)) {
        // Länsnivå
        query.where = `LandsdelKod=${landsdel} and LanKod='${lan}'`;
        return query;
    } else if (isNumeric(landsdel)) {
        // Landsdelsnivå
        query.where = `LandsdelKod=${landsdel}`;
        return query;
    }
};

const isNumeric = (value: any): boolean => {
    return !isNaN(value - parseFloat(value));
};

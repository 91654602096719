import { StoreModule } from '@ngrx/store';
import { reducers } from './reducers';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { environment } from '../../environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { AbinEffects } from './effects/abin.effects';
import { UppfoljniningsRapportEffects } from './effects/uppfoljningsrapport.effects';

const useDevTools = true;

const imports: any[] = [
  HttpClientModule,
  StoreModule.forRoot(reducers, {
    runtimeChecks: {
      strictStateImmutability: true,
      strictActionImmutability: true,
    },
  }),
  EffectsModule.forRoot([AbinEffects, UppfoljniningsRapportEffects]),
  !environment.production && useDevTools
    ? StoreDevtoolsModule.instrument({ maxAge: 50 , connectInZone: true})
    : [],
];

@NgModule({
  imports,
  providers: [],
})
export class InGeApiStoreModule {
  public static forRoot(): ModuleWithProviders<InGeApiStoreModule> {
    return {
      ngModule: InGeApiStoreModule,
      providers: [
        /* TODO */
      ],
    };
  }
}

<!-- <app-ui-info-box ui-info-box-light ui-info-box-centered>
  <ui-info-box-header>Andel ytor med förekomst av RASE, sammanvägning för de tre senaste inventeringarna
  </ui-info-box-header>
  <ui-info-box-content>
    <h3>{{(inventering$|async)?.raseAndelForekomst3InvMedel|percent:'1.0-1':'sv'}}</h3>
  </ui-info-box-content>
</app-ui-info-box> -->

<app-summary-card>
  <app-summary-card-icon>
    <span class="abinicon-tree-chart"></span>
  </app-summary-card-icon>
  <app-summary-card-header>
    {{
      (inventering$ | async)?.raseAndelForekomst3InvMedel | percent: "1.0":"sv"
    }}
  </app-summary-card-header>
  <app-summary-card-content>
    Andel ytor med förekomst av RASE, sammanvägning för de tre senaste
    inventeringarna
  </app-summary-card-content>
</app-summary-card>

import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { AbinResultDto } from 'src/app/inge-api/client/models';
import { Fordjupning, InGeApiState, InventeringsNiva } from 'src/app/store/state-model/inge-api.model';
import { ButtonGroupItemData } from './button-group-item.model';

@Component({
    selector: 'app-fordjupning-button-group',
    templateUrl: './fordjupning-button-group.component.html',
    styleUrls: ['./fordjupning-button-group.component.scss']
})
export class FordjupningButtonGroupComponent implements OnInit, AfterViewInit {
    fordjupningData$: Observable<Fordjupning>;
    inventeringar$: Observable<AbinResultDto[]>;
    @Output() selectedOmradeEvent = new EventEmitter<ButtonGroupItemData>();
    buttonGroupData: ButtonGroupItemData[];
    constructor(private store: Store<InGeApiState>, private cd: ChangeDetectorRef) {
        this.fordjupningData$ = this.store.pipe(
            select((x) => {
                if (x?.uppfoljningsrapport?.fordjupning) return x.uppfoljningsrapport.fordjupning;
            })
        );
        this.inventeringar$ = this.store.pipe(
            select((x) => {
                if (x?.uppfoljningsrapport?.inventeringar?.length > 0) {
                    return x?.uppfoljningsrapport?.inventeringar;
                }
            })
        );
    }
    ngAfterViewInit(): void {
        combineLatest([this.fordjupningData$, this.inventeringar$]).subscribe(([fordjupningsData, inventeringar]) => {
            if (fordjupningsData && inventeringar) {
                const buttonGroupData: ButtonGroupItemData[] = [
                    {
                        displayName: 'Alla',
                        omradeName: 'alla',
                        niva: fordjupningsData.fordjupningsNiva,
                        inventeringar: inventeringar
                    }
                ];
                fordjupningsData.inventeringar.forEach((inv) => {
                    if (inv.length > 0) {
                        if (fordjupningsData.fordjupningsNiva === InventeringsNiva.LANDSDEL) {
                            const displayName = inv[0].landsdelNamn;
                            const omradeName = displayName;
                            buttonGroupData.push({
                                omradeName,
                                niva: fordjupningsData.fordjupningsNiva,
                                displayName,
                                inventeringar: inv
                            });
                        }
                        if (fordjupningsData.fordjupningsNiva === InventeringsNiva.LAN) {
                            const displayName = inv[0].lanNamn;
                            const omradeName = displayName;
                            buttonGroupData.push({
                                omradeName,
                                niva: fordjupningsData.fordjupningsNiva,
                                displayName,
                                inventeringar: inv
                            });
                        }
                        if (fordjupningsData.fordjupningsNiva === InventeringsNiva.AFO) {
                            let displayName = `ÄFO ${inv[0].afoNr}`;
                            const omradeName = inv[0].afoNamn;
                            buttonGroupData.push({
                                omradeName,
                                niva: fordjupningsData.fordjupningsNiva,
                                displayName,
                                inventeringar: inv
                            });
                        }
                    }
                });

                // Sortering av knappordning
                if (fordjupningsData.fordjupningsNiva === InventeringsNiva.LANDSDEL) {
                    const order = ['norra norrland', 'södra norrland', 'svealand', 'götaland'];

                    this.buttonGroupData = buttonGroupData.sort((a, b) => {
                        const ainv = order.indexOf(a.inventeringar[0].landsdelNamn?.toLowerCase()) ?? 5;
                        const binv = order.indexOf(b.inventeringar[0].landsdelNamn?.toLowerCase()) ?? 4;
                        return ainv - binv;
                    });
                } else if (fordjupningsData.fordjupningsNiva === InventeringsNiva.AFO) {
                    this.buttonGroupData = buttonGroupData.sort((a, b) => {
                        const ainv = a.inventeringar[0].afoNr;
                        const binv = b.inventeringar[0].afoNr;
                        return ainv - binv;
                    });
                } else {
                    this.buttonGroupData = buttonGroupData;
                }
                this.cd.detectChanges();
            }
        });
    }

    onOmradeSelect(omrade: ButtonGroupItemData) {
        if (omrade) {
            this.selectedOmradeEvent.emit(omrade);
        }
    }

    ngOnInit(): void {}
}

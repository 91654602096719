import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiComponentsModule } from '../ui-components/ui-components.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from '../app-routing.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedComponentsModule } from '../shared-components/shared-components.module';

import { GoogleChartsBaseModule } from '../google-charts/google-charts-base-components/google-charts-base.module';

import { RapportKomboMainComponent } from './rapport-kombo-main/rapport-kombo-main.component';
import { AbinResultatRapportModule } from '../abin-resultat-rapport/abin-resultat-rapport.module';
import { SharedReportComponentsModule } from '../shared-report-components/shared-report-components.module';
import { KomboMarkslagsfordelningComponent } from './rapport-kombo-main/components/markslagsfordelning/kombo-markslagsfordelning.component';
import { KomboAgoslagsfordelningComponent } from './rapport-kombo-main/components/agoslagsfordelning/kombo-agoslagsfordelning.component';
import { KomboFoderprognosComponent } from './rapport-kombo-main/components/foderprognos/kombo-foderprognos.component';
import { KomboSkogensSammansattningComponent } from './rapport-kombo-main/components/skogenssammansattning/kombo-skogenssammansattning.component';
import { KomboTradslagensSammansattningComponent } from './rapport-kombo-main/components/tradslagsammansattning/kombo-tradslagsammansattning.component';
import { KomboOvrigaBestandsuppgifterComponent } from './rapport-kombo-main/components/ovrigabestandsuppg/kombo-ovrigabestandsuppg.component';
import { KomboMagermarkComponent } from './rapport-kombo-main/components/magermark/kombo-magermark.component';
import { KomboMellanmarkComponent } from './rapport-kombo-main/components/mellanmark/kombo-mellanmark.component';
import { KomboBordigmarkComponent } from './rapport-kombo-main/components/bordigmark/kombo-bordigmark.component';

@NgModule({
    declarations: [
        RapportKomboMainComponent,
        KomboMarkslagsfordelningComponent,
        KomboAgoslagsfordelningComponent,
        KomboFoderprognosComponent,
        KomboSkogensSammansattningComponent,
        KomboTradslagensSammansattningComponent,
        KomboOvrigaBestandsuppgifterComponent,
        KomboMagermarkComponent,
        KomboMellanmarkComponent,
        KomboBordigmarkComponent
    ],
    imports: [
        CommonModule,
        UiComponentsModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        SharedComponentsModule,
        GoogleChartsBaseModule,
        AbinResultatRapportModule,
        SharedReportComponentsModule
    ],
    exports: [RapportKomboMainComponent]
})
export class AbinKomboRapportModule {}

<app-ui-toast-message
  uiToastInfo
  *ngIf="_config?.render"
  [message]="_config?.message"
  [header]="_config?.header"
>
  <span uiToastContent>
    <ng-content select="[warningContent]"></ng-content>
  </span>
</app-ui-toast-message>

import {
  Component,
  ContentChildren,
  EventEmitter,
  HostBinding,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ToggleBtnService } from '../toggle-btn.service';
import { UiToggleButtonComponent } from '../ui-toggle-button/ui-toggle-button.component';

@Component({
  selector: 'app-ui-toggle-button-group',
  templateUrl: './ui-toggle-button-group.component.html',
  styleUrls: ['./ui-toggle-button-group.component.scss'],
  providers: [ToggleBtnService],
})
export class UiToggleButtonGroupComponent implements OnInit, OnDestroy {
  @HostBinding('class')
  elementClass = 'ui-toggle-button-group';
  @ContentChildren(UiToggleButtonComponent, { descendants: true })
  children: QueryList<UiToggleButtonComponent>;
  private sub: Subscription;
  public toggleButtons: UiToggleButtonComponent[] = [];
  @Output() valueChange = new EventEmitter<any>();
  constructor(private service: ToggleBtnService) {}

  ngOnInit(): void {
    this.sub = this.service.activeToggle.subscribe((x) => {
      if (!x) {
        return;
      }

      this.valueChange.emit(x.value);
      this.children.toArray().forEach((c) => {
        if (x.id === c._id) {
          c.active = true;
        } else {
          c.active = false;
        }
      });
    });
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}

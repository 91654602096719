<app-summary-card>
  <app-summary-card-icon>
    <span class="abinicon-tall_bordig_mark"></span>
  </app-summary-card-icon>
  <app-summary-card-header>
    {{
      ((inventeringar$ | async)
        ?.standortsanpassningAndelBordigaMarkerMedTall3InvMedel
        | percent : "1.0" : "sv") || 0 + " %"
    }}
  </app-summary-card-header>
  <app-summary-card-content>
    Andel av de bördiga markerna som föryngras med tall, sammanvägning för de
    tre senaste inventeringarna.
  </app-summary-card-content>
</app-summary-card>

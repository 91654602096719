import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[ui-select]',
})
export class UiSelectDirective {
  constructor() {}

  @HostBinding('class') elClass = 'ui-select';
}

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AbinResultDto } from '../../../../inge-api/client/models';
import { ButtonGroupItemData } from '../../../../shared-report-components/base-components/fordjupning-button-group/button-group-item.model';
import { ChartCategory, ColorMapService } from '../../../../common/service/color-mapper.service';
import { BehaviorSubject } from 'rxjs';
import { UiTableConfig } from '../../../../ui-components/ui-table/ui-table/models/tableConfig';
import { formatNumber, formatPercent } from '@angular/common';
import { round } from 'lodash-es';

@Component({
    selector: 'app-kombo-ovrigabestandsuppg',
    templateUrl: './kombo-ovrigabestandsuppg.component.html',
    styleUrl: './kombo-ovrigabestandsuppg.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class KomboOvrigaBestandsuppgifterComponent implements OnInit {
    tableConfig: UiTableConfig = {
        columns: [],
        rowActions: []
    };

    tableCaption = '';

    tableContent = [];

    options: any = {
        chartArea: { width: '90%', height: '90%' },
        height: 230,
        pieSliceText: 'none',
        tooltip: {
            trigger: 'none'
        },
        legend: {
            position: 'none'
        }
    };
    inventeringsAr: number;
    data: any[][];
    legendData: any[];

    private _omradeNamn = new BehaviorSubject<string>(null);
    omradeNamn$ = this._omradeNamn.asObservable();

    constructor(public colormapService: ColorMapService) {}

    ngOnInit(): void {}

    onOmradeSelect(omrade: ButtonGroupItemData) {
        if (omrade && omrade.inventeringar && omrade.inventeringar.length > 0) {
            this._omradeNamn.next(omrade.omradeName);
            this.generateTableData(omrade.inventeringar[0]);
            this.generateChartData(omrade.inventeringar[0]);
        }
    }

    generateTableData(senasteInv: AbinResultDto) {
        this.tableConfig = {
            columns: [
                { title: 'Beståndsuppgift', dataProperty: 'titel' },
                { title: `${senasteInv.invAr}`, dataProperty: 'innevarandeAr' },
                {
                    title: 'Senaste tre inventeringar',
                    dataProperty: 'senasteTreInv'
                }
            ],
            rowActions: []
        };

        this.tableContent = [
            {
                titel: 'Beståndsmedelhöjd',
                innevarandeAr: formatNumber(senasteInv.medelbestandshojd / 10, 'sv', '1.0-1') + ' m',
                senasteTreInv: formatNumber(senasteInv.medelbestandshojd3InvMedel / 10, 'sv', '1.0-1') + ' m'
            },
            {
                titel: 'Andel provytor som röjts',
                innevarandeAr: formatPercent(senasteInv.andelRojt, 'sv', '1.0-0'),
                senasteTreInv: formatPercent(senasteInv.andelRojt3InvMedel, 'sv', '1.0-0')
            }
        ];
    }

    generateChartData(senasteInventering: AbinResultDto) {
        const data: any[][] = [['Träslag', 'Procent']];
        const colors = [];
        const legendData = [];
        this.inventeringsAr = senasteInventering.invAr;
        if (senasteInventering.andelRojt3InvMedel) {
            const rojdaName = 'Röjda';
            const rojdaValue = Math.round(senasteInventering.andelRojt3InvMedel * 100);
            const rojdaColor = this.colormapService.getColor(ChartCategory.TALL);
            data.push([rojdaName, rojdaValue]);
            colors.push(rojdaColor);
            legendData.push({
                name: rojdaName,
                value: rojdaValue,
                color: rojdaColor
            });

            const inteRojdaName = 'Inte röjda';
            const inteRojdaValue = Math.round(100 - senasteInventering.andelRojt3InvMedel * 100);
            const inteRojdaColor = this.colormapService.getColor(ChartCategory.INGENATGARD);
            data.push([inteRojdaName, inteRojdaValue]);
            colors.push(inteRojdaColor);
            legendData.push({
                name: inteRojdaName,
                value: inteRojdaValue,
                color: inteRojdaColor
            });
        }
        this.data = data;
        this.options = { ...this.options, colors };
        this.legendData = legendData;
    }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ChartCategory } from 'src/app/common/service';
import { AbinResultDto } from 'src/app/inge-api/client/models';

import { FordelningPiechartBaseComponent } from '../../base-components';
import { round } from 'lodash-es';

@Component({
    selector: 'app-foryngring-magermark-traslag-chart',
    templateUrl: './foryngring-magermark-traslag-chart.component.html',
    styleUrls: ['./foryngring-magermark-traslag-chart.component.scss']
})
export class ForyngringMagermarkTraslagChartComponent extends FordelningPiechartBaseComponent implements OnInit, OnDestroy {
    private subs: Subscription[];

    private chartEntries = [
        {
            name: 'Tall',
            key: 'standortsanpassningAndelMagraMarkerMedTall3InvMedel',
            color: ChartCategory.TALL
        },
        {
            name: 'Gran',
            key: 'standortsanpassningAndelMagraMarkerMedGran3InvMedel',
            color: ChartCategory.GRAN
        },
        {
            name: 'Björk',
            key: 'standortsanpassningAndelMagraMarkerMedBjork3InvMedel',
            color: ChartCategory.BJORK
        },
        {
            name: 'Lärk',
            key: 'standortsanpassningAndelMagraMarkerMedLark3InvMedel',
            color: ChartCategory.LARK
        },
        {
            name: 'Contorta',
            key: 'standortsanpassningAndelMagraMarkerMedContorta3InvMedel',
            color: ChartCategory.CONTORTA
        },
        {
            name: 'Övrigt',
            key: 'standortsanpassningAndelMagraMarkerMedOvrigt3InvMedel',
            color: ChartCategory.OVRIG
        },
        {
            name: 'Uppgift saknas',
            key: 'standortsanpassningAndelMagraMarkerMedIngenAtgard3InvMedel',
            color: ChartCategory.INGENATGARD
        }
    ];
    data: any[][];
    legendData: any[];
    public andelMagraMarker: number = 0;

    ngOnInit(): void {
        this.subs = [];
        this.subs.push(
            this.inventering$.subscribe((x) => {
                if (x) {
                    this.generateChartData(x);
                }
            })
        );
    }

    generateChartData(senasteInventering: AbinResultDto) {
        const data: any[][] = [['Markslag', 'Procent']];
        const colors = [];
        const legendData = [];
        for (const entry of this.chartEntries) {
            if (senasteInventering[entry.key]) {
                const name = entry.name;
                const value = Math.round(senasteInventering[entry.key] * 100);
                const color = this.colormapService.getColor(entry.color);
                data.push([name, value]);
                colors.push(color);
                legendData.push({ name, value, color });
            }
        }
        // Uppdatera värdet för mager mark om det finns för inventeringen
        if (senasteInventering.andelMagraMarker3InvMedel && senasteInventering.andelMagraMarker3InvMedel > 0) {
            this.andelMagraMarker = round(senasteInventering.andelMagraMarker3InvMedel * 100, 1);
        }
        this.data = data;
        this.options = { ...this.options, colors };
        this.legendData = legendData;
    }

    ngOnDestroy(): void {
        this.subs.forEach((x) => x.unsubscribe());
    }
}

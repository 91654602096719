import { NgModule } from '@angular/core';
import { GoogleBarchartComponent } from './google-barchart/google-barchart.component';
import { GoogleLineChartBaseComponent } from './google-line-chart/google-line-chart.component';
import { GooglePieChartBaseComponent } from './google-pie-chart/google-pie-chart.component';
import { GoogleCombochartComponent } from './google-combochart/google-combochart.component';

@NgModule({
    imports: [],
    exports: [GoogleBarchartComponent, GoogleCombochartComponent, GoogleLineChartBaseComponent, GooglePieChartBaseComponent],
    declarations: [GoogleBarchartComponent, GoogleCombochartComponent, GoogleLineChartBaseComponent, GooglePieChartBaseComponent],
    providers: []
})
export class GoogleChartsBaseModule {}

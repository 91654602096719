import { formatPercent } from '@angular/common';
import pptxgen from 'pptxgenjs';
import { AbinResultDto } from 'src/app/inge-api/client/models';
import addBanner from '../slide-components/_banner';
export default (slide: pptxgen.Slide, senasteInventering: AbinResultDto) => {
    addBanner(slide, {
        header: 'Sammanfattning',
        text: 'I sammanfattningen redovisas resultat utifrån den målsättning som är antagen. De redovisade resultaten är en sammanvägning av de tre senaste inventeringarna.'
    });

    const slutsatser = getSlutsatser(senasteInventering);
    slutsatser.forEach((slutsats, index) => {
        const w = 2.2;
        const h = 2.4;
        let x = 3 + (w + 0.3) * index;
        let y = 1.4;
        if (index > 2) {
            y = 4;
            x = 3 + (w + 0.3) * (index - 3);
        }
        slide
            .addShape('rect', {
                w,
                h,
                x,
                y,
                shadow: {
                    type: 'outer',
                    color: '000000',
                    blur: 5,
                    angle: 2,
                    offset: 1.0,
                    opacity: 0.4
                },
                fill: { color: 'ffffff' }
            })
            .addImage({
                path: slutsats.icon,
                // sizing: { type: 'contain', w: 0.63 * slutsats.iconRation, h: 0.63 },
                w: slutsats.w,
                h: slutsats.h,
                x: x + w / 2 - slutsats.w / 2,
                y: y + 0.2
            })
            .addText(slutsats.header, {
                h: 0.3,
                w: w,
                x,
                y: y + 0.63 + 0.2,
                fontSize: 12,
                bold: true,
                align: 'center'
            })
            .addText(slutsats.text, {
                w: w,
                x,
                h: 1.31,
                y: y + 0.63 + 0.3 + 0.15,
                fontSize: 9,
                bold: false,
                align: 'center',
                valign: 'middle'
            });
    });
};

const getSlutsatser = (senasteInventering: AbinResultDto) => {
    const basePath = 'assets/abin-icons-png';
    const arsskadadTallIcon =
        senasteInventering.slutsatsArsskadaTallInt > 0 ? `${basePath}/Arsskada_tall_uppfyllt-2022.svg` : `${basePath}/Arsskada_tall_ej_uppfyllt-2022.svg`;
    const oskadadTallIcon =
        senasteInventering.slutsatsOskadadTallInt > 0 ? `${basePath}/Oskadad_tall_uppfyllt-2022.svg` : `${basePath}/Oskadad_tall_ej_uppfyllt-2022.svg`;
    const arsskadadGranIcon =
        senasteInventering.slutsatsArsskadaGranInt > 0 ? `${basePath}/Arsskada_gran_uppfyllt-2022.svg` : `${basePath}/Arsskada_gran_ej_uppfyllt-2022.svg`;
    const tallPaMagraMarkerIcon =
        senasteInventering.slutsatsTallpaMagraMarkerInt > 0
            ? `${basePath}/Tall_mager_mark_uppfyllt-2022.svg`
            : `${basePath}/Tall_mager_mark_ej_uppfyllt-2022.svg`;
    const tallPaMellanMarkerIcon =
        senasteInventering.slutsatsTallpaMellanMarkerInt > 0
            ? `${basePath}/Tall_mellanmark_uppfyllt-2022.svg`
            : `${basePath}/Tall_mellanmark_ej_uppfyllt-2022.svg`;
    const konkurrensstatusRaseIcon =
        senasteInventering.slutsatsRASEGynInt > 0
            ? `${basePath}/Rase_gynnsam_status_uppfylld-2022.svg`
            : `${basePath}/Rase_gynnsam_status_ej_uppfylld-2022.svg`;
    return [
        {
            icon: arsskadadTallIcon,
            iconRatio: 241 / 261,
            h: 0.63,
            w: 0.73,
            header: `Årsskadad tall, ${formatPercent(senasteInventering.arsskadaTallAndel3InvMedel, 'sv-SE', '1.0')}`,
            text: `${senasteInventering.slutsatsArsskadaTallText}`
        },
        {
            icon: oskadadTallIcon,
            iconRatio: 241 / 261,
            h: 0.63,
            w: 0.73,
            header: `Oskadad tall, ${formatPercent(senasteInventering.oskadadTallAndel3InvMedel, 'sv-SE', '1.0')}`,
            text: `${senasteInventering.slutsatsOskadadTallText}`
        },
        {
            icon: arsskadadGranIcon,
            iconRatio: 242 / 208,
            h: 0.63,
            w: 0.73,
            header: `Årsskadad gran, ${formatPercent(senasteInventering.arsskadaGranAndel3InvMedel, 'sv-SE', '1.1-1')}`,
            text: `${senasteInventering.slutsatsArsskadaGranText}`
        },
        {
            icon: tallPaMagraMarkerIcon,
            iconRatio: 241 / 261,
            h: 0.63,
            w: 0.73,
            header: `Tall på magra marker, ${formatPercent(senasteInventering.standortsanpassningAndelMagraMarkerMedTall3InvMedel, 'sv-SE', '1.0')}`,
            text: `${senasteInventering.slutsatsTallpaMagraMarkerText}`
        },
        {
            icon: tallPaMellanMarkerIcon,
            iconRatio: 241 / 261,
            h: 0.63,
            w: 0.73,
            header: `Tall på mellanmarker, ${formatPercent(senasteInventering.standortsanpassningAndelMellanMarkerMedTall3InvMedel, 'sv-SE', '1.0')}`,
            text: `${senasteInventering.slutsatsTallpaMellanMarkerText}`
        },
        {
            icon: konkurrensstatusRaseIcon,
            iconRatio: 242 / 208,
            h: 0.66,
            w: 0.73,
            header: `Konkurrensstatus RASE, ${formatPercent(senasteInventering.raseAndelGynnsam3InvMedel, 'sv-SE', '1.0')}`,
            text: `${senasteInventering.slutsatsRASEGynText}`
        }
    ];
};

<app-fordjupning-karta-base
  [layerConfig]="layerConfig$ | async"
  (mapClicked)="onMapClicked($event)"
>
  <app-fordjupning-karta-popup
    [isLoading]="isLoadingPopupData"
    [popupContent]="popupData$ | async"
  >
    <ng-container
      *ngIf="(popupData$ | async)?.thumbsUp; else elseTemplate"
      popupIcon
    >
      <span class="abinicon-arsskada_gran_uppfyllt">
        <span class="path1"></span>
        <span class="path2"></span>
      </span>
    </ng-container>
    <ng-template #elseTemplate popupIcon>
      <span class="abinicon-arsskada_gran_ej_uppfyllt">
        <span class="path1"></span>
        <span class="path2"></span>
      </span>
    </ng-template>
  </app-fordjupning-karta-popup>
</app-fordjupning-karta-base>




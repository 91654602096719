import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiButtonAccentDirective } from './directives/ui-button-accent.directive';
import { UiButtonDarkDirective } from './directives/ui-button-dark.directive';
import { UiButtonIconDirective } from './directives/ui-button-icon.directive';
import { UiButtonLightDirective } from './directives/ui-button-light.directive';
import { UiButtonOutlinedDirective } from './directives/ui-button-outlined.directive';
import { UiButtonRoundDirective } from './directives/ui-button-round.directive';
import { UiButtonComponent } from './ui-button/ui-button.component';
@NgModule({
  declarations: [
    UiButtonComponent,
    UiButtonRoundDirective,
    UiButtonOutlinedDirective,
    UiButtonLightDirective,
    UiButtonDarkDirective,
    UiButtonAccentDirective,
    UiButtonIconDirective,
  ],
  imports: [CommonModule],
  exports: [
    UiButtonComponent,
    UiButtonRoundDirective,
    UiButtonOutlinedDirective,
    UiButtonLightDirective,
    UiButtonDarkDirective,
    UiButtonAccentDirective,
    UiButtonIconDirective,
  ],
})
export class UiButtonModule {}

import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { InGeApiState } from '../../../store/state-model/inge-api.model';
import { Observable } from 'rxjs';
import { selectInvNiva } from '../../../store/selectors/inventering.selector';
@Component({
    selector: 'app-abin-rapport-container',
    templateUrl: './rapport-container.component.html',
    styleUrls: ['./rapport-container.component.scss']
})
export class RapportContainerComponent implements OnInit {
    visaStandardRapport$: Observable<boolean>;

    constructor(private store: Store<InGeApiState>) {
        this.visaStandardRapport$ = this.store.select(selectInvNiva);
    }
    ngOnInit(): void {}
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiLegendComponent } from './ui-legend/ui-legend.component';
import { UiLegendItemComponent } from './ui-legend-item/ui-legend-item.component';
import { UiLegendVerticalDirective } from './directives/ui-legend-vertical.directive';
@NgModule({
  declarations: [
    UiLegendComponent,
    UiLegendItemComponent,
    UiLegendVerticalDirective,
  ],
  imports: [CommonModule],
  exports: [
    UiLegendComponent,
    UiLegendItemComponent,
    UiLegendVerticalDirective,
  ],
})
export class UiLegendModule {}

import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-ui-toast-message',
  templateUrl: './ui-toast-message.component.html',
  styleUrls: ['./ui-toast-message.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiToastMessageComponent implements OnInit {
  @HostBinding('class') elClass = 'ui-toast-message';
  constructor() {}
  @Input() header: string;
  @Input() message: string;
  ngOnInit(): void {

  }
}

import { Injectable } from '@angular/core';
import * as colors from '../../ui-components/colors';

@Injectable({
    providedIn: 'root'
})
export class ColorMapService {
    private colorMap = new Map<ChartCategory, string>([
        [ChartCategory.TALL, colors.lightOliveGreen],
        [ChartCategory.GRAN, colors.sapGreen],
        [ChartCategory.BARRBLAND, colors.lightForestGreen],
        [ChartCategory.BJORK, colors.birchYellow],
        [ChartCategory.CONTORTA, colors.petrol],
        [ChartCategory.LARK, colors.milkChocolate],
        [ChartCategory.OVRIG, colors.brick],
        [ChartCategory.VATTEN, colors.oceanBlue],
        [ChartCategory.SKOGSMARK, colors.spruce],
        [ChartCategory.INGENATGARD, colors.veryLightGrey],
        [ChartCategory.RASE, colors.blueGreen],
        [ChartCategory.MYRMARK, colors.brownishOrange],
        [ChartCategory.BORDIGAMARKER, colors.green],
        [ChartCategory.MELLANMARKER, colors.lightOliveGreen],
        [ChartCategory.MAGRAMARKER, colors.veryLightOliveGreen],
        [ChartCategory.BARCHARTDEFAULT, colors.veryLightOliveGreen],
        [ChartCategory.PIECHARTDEFAULT, colors.veryLightOliveGreen],
        [ChartCategory.TATORTSMARK, colors.milkChocolate],
        [ChartCategory.MEDEL3AR, colors.darkBlue]
    ]);

    getColor(category: ChartCategory): string {
        return this.colorMap.get(category);
    }
}

export enum ChartCategory {
    TALL,
    GRAN,
    BARRBLAND,
    BJORK,
    CONTORTA,
    LARK,
    OVRIG,
    VATTEN,
    SKOGSMARK,
    INGENATGARD,
    RASE,
    MYRMARK,
    TATORTSMARK,
    BORDIGAMARKER,
    MELLANMARKER,
    MAGRAMARKER,
    BARCHARTDEFAULT,
    PIECHARTDEFAULT,
    SENASTEINVENTERINGSRUTOR,
    FOREGAENDEINVENTERINGSRUTOR,
    MEDEL3AR
}

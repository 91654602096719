import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-ui-section-header',
  templateUrl: './ui-section-header.component.html',
  styleUrls: ['./ui-section-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiSectionHeaderComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { combineLatest } from 'rxjs';
import { getPackageForChart, GoogleChartType } from 'src/app/google-charts';
import { GoogleChartBaseComponent } from '../chart-base.component';
declare const google: any;
@Component({
  selector: 'app-google-barchart',
  templateUrl: './google-barchart.component.html',
  styleUrls: ['./google-barchart.component.scss'],
})
export class GoogleBarchartComponent
  extends GoogleChartBaseComponent
  implements OnInit
{
  @ViewChild('chartContainer', { read: ElementRef })
  private containerEl: ElementRef<HTMLElement>;
  chartType = GoogleChartType.ColumnChart;

  @Input()
  set data(val: Array<Array<any>>) {
    if (val && val.length > 0) {
      this.chartData.next(val);
    }
  }

  @Input()
  set options(val: Record<string, any>) {
    if (val) {
      this.chartOptions.next(val);
    }
  }

  ngOnInit(): void {
    const chartPackage = getPackageForChart(this.chartType);

    combineLatest([
      this.chartData$,
      this.chartOptions$,
      this.loadChartPackage(chartPackage),
    ]).subscribe(([data, options, _null]) => {
      if (data && options) {
        this.chart = new google.visualization.ColumnChart(
          this.containerEl.nativeElement
        );
        this.drawChart(data, options);
      }
    });
  }
}

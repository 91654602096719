import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ExportReportModalService {
  private visibleSource = new BehaviorSubject<boolean>(false);
  public visible$ = this.visibleSource.asObservable();
  constructor() {}

  setVisible(val: boolean) {
    this.visibleSource.next(val);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiTableComponent } from './ui-table/ui-table.component';
import { UiButtonModule } from '../ui-button/ui-button.module';
import { BorderTopDirective } from './border-top.directive';
import { UiTableRightAlignedDirective } from './ui-table/ui-table-right-aligned.directive';
@NgModule({
  declarations: [
    UiTableComponent,
    BorderTopDirective,
    UiTableRightAlignedDirective,
  ],
  imports: [CommonModule, UiButtonModule],
  exports: [UiTableComponent, BorderTopDirective, UiTableRightAlignedDirective],
})
export class UiTableModule {}

import { Component, OnInit, Input, ViewChild, ElementRef, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { FordjupningKartaPopupContent } from './fordjupning-karta-popup.model';
@Component({
    selector: 'app-fordjupning-karta-popup',
    templateUrl: './fordjupning-karta-popup.component.html',
    styleUrls: ['./fordjupning-karta-popup.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FordjupningKartaPopupComponent implements OnInit {
    private _show: boolean = false;

    @Input()
    public get show() {
        return this._show;
    }
    public set show(value: boolean) {
        this._show = value;
        this.cdr.markForCheck();
    }
    @Input() isLoading: boolean;
    @Input() popupContent: FordjupningKartaPopupContent;

    @ViewChild('popupContainer') popup: ElementRef;
    constructor(private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {}
}

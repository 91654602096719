import pptxgen from 'pptxgenjs';
import { AbinResultDto } from 'src/app/inge-api/client/models';
import { InventeringsNiva } from 'src/app/store/state-model/inge-api.model';
import * as uiColors from 'src/app/ui-components/colors';
import _arsskadorGran from './slides/_arsskadorGran';
import _arsskadorTall from './slides/_arsskadorTall';
import _arsskadorTallGeografiskFordelning from './slides/_arsskadorTallGeografiskFordelning';
import _firstPage from './slides/_firstPage';
import _foderproducerandeUngskog from './slides/_foderproducerandeUngskog';
import _introduktion from './slides/_introduktion';
import _oskadadTall from './slides/_oskadadTall';
import _raseForekomst from './slides/_raseForekomst';
import _raseKonkurrensStatus from './slides/_raseKonkurrensStatus';
import _sammanfattning from './slides/_sammanfattning';
import _skogligBetesinventering from './slides/_skogligBetesinventering';
import _tallPaBordigMark from './slides/_tallPaBordigMark';
import _tallPaMagraMarker from './slides/_tallPaMagraMarker';
import _tallPaMellanmark from './slides/_tallPaMellanmark';
export class PowerPoint {
    private assetsFolder: string;
    private document: pptxgen;
    private fileName: string;
    private templateName: string;

    constructor() {
        this.assetsFolder = 'assets';
        this.fileName = 'ÄbinRapport.pptx';
        this.templateName = 'MASTER';
    }
    generatePowerPoint(
        inventeringar: AbinResultDto[],
        inventeringsrutorKarta: __esri.Screenshot,
        arsskadorKarta: __esri.Screenshot,
        omradesNamn: string,
        inventeringsNiva: InventeringsNiva
    ): Promise<string> {
        this.document = this.createMasterTemplate();
        const senasteInventering = inventeringar[0];
        const inventeringsAr = senasteInventering.invAr;

        _firstPage(this.getSlide(omradesNamn), inventeringsAr, omradesNamn);
        _introduktion(this.getSlide(omradesNamn), inventeringsrutorKarta?.dataUrl, senasteInventering, inventeringsNiva);
        _sammanfattning(this.getSlide(omradesNamn), senasteInventering);
        _arsskadorTall(this.getSlide(omradesNamn), inventeringar);
        _arsskadorTallGeografiskFordelning(this.getSlide(omradesNamn), arsskadorKarta?.dataUrl);
        _oskadadTall(this.getSlide(omradesNamn), inventeringar);
        _arsskadorGran(this.getSlide(omradesNamn), inventeringar);
        _raseForekomst(this.getSlide(omradesNamn), inventeringar);
        _raseKonkurrensStatus(this.getSlide(omradesNamn), inventeringar);
        _tallPaMagraMarker(this.getSlide(omradesNamn), inventeringar);
        _tallPaMellanmark(this.getSlide(omradesNamn), inventeringar);
        _tallPaBordigMark(this.getSlide(omradesNamn), inventeringar);
        _foderproducerandeUngskog(this.getSlide(omradesNamn), inventeringar);
        _skogligBetesinventering(this.getSlide(omradesNamn));
        return this.write(this.fileName);
    }
    private getSlide(omradesNamn?: string): pptxgen.Slide {
        let slide = this.document.addSlide({ masterName: this.templateName });
        if (omradesNamn) {
            slide.addText(omradesNamn, {
                x: 4.31,
                y: 6.88,
                fontSize: 16,
                w: 7.32,
                h: 0.34,
                color: uiColors.greyishBrown
            });
        }
        return slide;
    }
    private createMasterTemplate = (): pptxgen => {
        const logo = `${this.assetsFolder}/Skogsstyrelsen_logo_1rad.png`;
        const document = new pptxgen();
        document.layout = 'LAYOUT_WIDE';

        document.defineSlideMaster({
            title: this.templateName,
            background: { color: 'FFFFFF' },
            objects: [
                {
                    line: {
                        x: 1,
                        y: 6.6,
                        w: 11.4,
                        h: 0,
                        align: 'center',
                        line: { color: '000000', width: 1 }
                    }
                },
                {
                    image: {
                        x: 1,
                        y: 6.61,
                        w: 2.71,
                        h: 0.79,
                        path: logo
                    }
                }
            ],
            slideNumber: {
                x: '90%',
                y: 6.88,
                fontSize: 12,
                color: uiColors.greyishBrown
            }
        });
        return document;
    };
    private write(fileName: string): Promise<string> {
        return this.document.writeFile({ fileName });
    }
}

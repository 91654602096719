import { max } from 'lodash-es';

export const getYAxisMaxValue = (data: any[][]) => {
    if (!data || !data.length) return;
    const values = data.slice(1).map((x) => x[1]);
    const maxValue = max(values);

    if (between(maxValue, 0, 1)) {
        return 1.5;
    }
    if (between(maxValue, 1, 2)) {
        return 2.5;
    }
    if (between(maxValue, 2, 3)) {
        return 3.5;
    }
    if (between(maxValue, 3, 4)) {
        return 4.5;
    }
    if (between(maxValue, 4, 5)) {
        return 5.5;
    }
    if (between(maxValue, 5, 6)) {
        return 6.5;
    }
    if (between(maxValue, 6, 7)) {
        return 7.5;
    }
    if (between(maxValue, 7, 8)) {
        return 8.5;
    }
    if (between(maxValue, 8, 9)) {
        return 10;
    }
    if (between(maxValue, 9, 10)) {
        return 12;
    }
    if (between(maxValue, 10, 15)) {
        return 17;
    }
    if (between(maxValue, 15, 20)) {
        return 25;
    }
    if (between(maxValue, 20, 30)) {
        return 40;
    }
    if (between(maxValue, 30, 40)) {
        return 50;
    }
    if (between(maxValue, 40, 50)) {
        return 60;
    }
    if (between(maxValue, 50, 60)) {
        return 70;
    }
    if (between(maxValue, 60, 70)) {
        return 80;
    }
    if (between(maxValue, 70, 80)) {
        return 90;
    }
    if (between(maxValue, 80, 90)) {
        return 100;
    }
    if (between(maxValue, 90, 100)) {
        return 110;
    }
};

export const getYAxisMaxValueFromList = (data: any[]) => {
    if (!data || !data.length) return;

    const maxValue = max(data);

    if (between(maxValue, 0, 1)) {
        return 1.5;
    }
    if (between(maxValue, 1, 2)) {
        return 2.5;
    }
    if (between(maxValue, 2, 3)) {
        return 3.5;
    }
    if (between(maxValue, 3, 4)) {
        return 4.5;
    }
    if (between(maxValue, 4, 5)) {
        return 5.5;
    }
    if (between(maxValue, 5, 6)) {
        return 6.5;
    }
    if (between(maxValue, 6, 7)) {
        return 7.5;
    }
    if (between(maxValue, 7, 8)) {
        return 8.5;
    }
    if (between(maxValue, 8, 9)) {
        return 10;
    }
    if (between(maxValue, 9, 10)) {
        return 12;
    }
    if (between(maxValue, 10, 15)) {
        return 17;
    }
    if (between(maxValue, 15, 20)) {
        return 25;
    }
    if (between(maxValue, 20, 30)) {
        return 40;
    }
    if (between(maxValue, 30, 40)) {
        return 50;
    }
    if (between(maxValue, 40, 50)) {
        return 60;
    }
    if (between(maxValue, 50, 60)) {
        return 70;
    }
    if (between(maxValue, 60, 70)) {
        return 80;
    }
    if (between(maxValue, 70, 80)) {
        return 90;
    }
    if (between(maxValue, 80, 90)) {
        return 100;
    }
    if (between(maxValue, 90, 100)) {
        return 110;
    }
};

const between = (val: number, start: number, end: number) => {
    return val >= start && val < end;
};

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AbinResultDto } from '../../../../inge-api/client/models';
import { ButtonGroupItemData } from '../../../../shared-report-components/base-components/fordjupning-button-group/button-group-item.model';
import { ChartCategory } from '../../../../common/service/color-mapper.service';
import { BehaviorSubject } from 'rxjs';
import round from 'lodash-es/round';
import { AndelCombochartBaseComponent } from '../../../../abin-resultat-rapport/base-components/andel-combochart-base/andel-combochart-base.component';

@Component({
    selector: 'app-kombo-magermark',
    templateUrl: './kombo-magermark.component.html',
    styleUrl: './kombo-magermark.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class KomboMagermarkComponent extends AndelCombochartBaseComponent implements OnInit {
    // Magra marker diagram
    public dataBarchart: any[][];
    public andelMagraMarker: number = 0;

    // Föryngrade trädslag chart
    private chartEntriesTradslag = [
        {
            name: 'Tall',
            key: 'standortsanpassningAndelMagraMarkerMedTall3InvMedel',
            color: ChartCategory.TALL
        },
        {
            name: 'Gran',
            key: 'standortsanpassningAndelMagraMarkerMedGran3InvMedel',
            color: ChartCategory.GRAN
        },
        {
            name: 'Björk',
            key: 'standortsanpassningAndelMagraMarkerMedBjork3InvMedel',
            color: ChartCategory.BJORK
        },
        {
            name: 'Lärk',
            key: 'standortsanpassningAndelMagraMarkerMedLark3InvMedel',
            color: ChartCategory.LARK
        },
        {
            name: 'Contorta',
            key: 'standortsanpassningAndelMagraMarkerMedContorta3InvMedel',
            color: ChartCategory.CONTORTA
        },
        {
            name: 'Övrigt',
            key: 'standortsanpassningAndelMagraMarkerMedOvrigt3InvMedel',
            color: ChartCategory.OVRIG
        },
        {
            name: 'Uppgift saknas',
            key: 'standortsanpassningAndelMagraMarkerMedIngenAtgard3InvMedel',
            color: ChartCategory.INGENATGARD
        }
    ];
    optionsTradslag: any = {
        chartArea: { width: '90%', height: '90%' },
        height: 230,
        pieSliceText: 'none',
        tooltip: {
            trigger: 'none'
        },
        legend: {
            position: 'none'
        }
    };
    dataTradslag: any[][];
    legendDataTradslag: any[];

    private _omradeNamn = new BehaviorSubject<string>(null);
    omradeNamn$ = this._omradeNamn.asObservable();
    private _senasteInventering = new BehaviorSubject<AbinResultDto>(null);
    senasteInventering$ = this._senasteInventering.asObservable();

    ngOnInit(): void {}

    onOmradeSelect(omrade: ButtonGroupItemData) {
        if (omrade && omrade.inventeringar && omrade.inventeringar.length > 0) {
            const inventeringarSorted = omrade.inventeringar.map((a) => a).sort((a, b) => a.invAr - b.invAr);
            this._omradeNamn.next(omrade.omradeName);
            this.generateChartData(inventeringarSorted);
            this.generateChartDataTradslag(omrade.inventeringar[0]);
            this._senasteInventering.next(omrade.inventeringar[0]);
        }
    }

    generateChartData(inventeringar: AbinResultDto[]) {
        const data: any[][] = [
            [
                'År',
                { id: 'Arsskada_1ar', type: 'number', label: 'Årsvärde' },
                { id: 'tool_tip_1ar', type: 'string', role: 'tooltip', p: { html: true } },
                { id: 'Medel_3ar', type: 'number', label: 'Sammanvägning för de tre senaste inventeringarna' },
                { id: 'tool_tip_3ar', type: 'string', role: 'tooltip', p: { html: true } }
            ]
        ];

        inventeringar.forEach((inv) => {
            if (inv.standortsanpassningAndelMagraMarkerMedTall || inv.standortsanpassningAndelMagraMarkerMedTall === 0) {
                // Uppdatera värdet för mager mark om det finns för inventeringen
                if (inv.andelMagraMarker3InvMedel && inv.andelMagraMarker3InvMedel > 0) {
                    this.andelMagraMarker = round(inv.andelMagraMarker3InvMedel * 100, 1);
                }

                // Årsskada
                const arsVarde = round(inv.standortsanpassningAndelMagraMarkerMedTall * 100, 1);

                // 3års medel
                const trearsMedel = round(inv.standortsanpassningAndelMagraMarkerMedTall3InvMedel * 100, 1);

                data.push([
                    `${inv.invAr}`,
                    arsVarde > 0 ? arsVarde : null,
                    this.getTooltipNoFelmarginal(false, arsVarde),
                    trearsMedel,
                    this.getTooltipNoFelmarginal(true, trearsMedel)
                ]);
            }
        });

        this.options = {
            ...this.options,
            tooltip: { isHtml: true },
            height: 315,
            chartArea: { width: '100%', height: '100%', top: 25, left: 50, right: 0, bottom: 50 },
            vAxis: {
                ...this.options.vAxis,
                maxValue: this.getMaxValue(data)
            }
        };

        this.dataBarchart = data;
    }

    generateChartDataTradslag(senasteInventering: AbinResultDto) {
        const data: any[][] = [['Markslag', 'Procent']];
        const colors = [];
        const legendData = [];
        for (const entry of this.chartEntriesTradslag) {
            if (senasteInventering[entry.key]) {
                const name = entry.name;
                const value = Math.round(senasteInventering[entry.key] * 100);
                const color = this.colormapService.getColor(entry.color);
                data.push([name, value]);
                colors.push(color);
                legendData.push({ name, value, color });
            }
        }

        this.dataTradslag = data;
        this.optionsTradslag = { ...this.optionsTradslag, colors };
        this.legendDataTradslag = legendData;
    }
}

import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SpatialReference } from '@arcgis/core/geometry';
import WMSLayer from '@arcgis/core/layers/WMSLayer';
import Map from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-resultat-karta-base',
    templateUrl: './resultat-karta-base.component.html',
    styleUrls: ['./resultat-karta-base.component.scss']
})
export class ResultatKartaBaseComponent implements OnInit, OnDestroy {
    @ViewChild('mapViewNode', { static: true }) private mapViewEl: ElementRef;
    view: MapView;
    // isLoading = true;
    private loadingBaseMapSubject = new BehaviorSubject<boolean>(true);
    loading$ = this.loadingBaseMapSubject.asObservable();
    lmtopowebb: WMSLayer;
    constructor() {}

    ngOnInit(): void {
        this.initializeMap();
    }

    initializeMap(): MapView {
        try {
            const lmtopowebb = new WMSLayer({
                url: environment.kartor.LMTopoWebb,
                sublayers: [
                    {
                        name: 'topowebbkartan_nedtonad'
                    }
                ]
            });

            this.lmtopowebb = lmtopowebb;

            const mapProperties = {
                layers: [lmtopowebb]
            };
            const map = new Map(mapProperties);
            const mapViewProperties = {
                container: this.mapViewEl.nativeElement,
                spatialReference: new SpatialReference({ wkid: 3006 }),
                map,
                maxZoom: 5,
                navigation: {
                    mouseWheelZoomEnabled: false,
                    browserTouchPanEnabled: false
                },
                constraints: {
                    maxScale: 150000,
                    minScale: 22000000
                }
            };

            this.view = new MapView(mapViewProperties);
            // this.view.constraints = {
            //     maxScale: 80000,
            //     minScale: 22000000
            // };
            this.view.watch('updating', (status) => {
                this.loadingBaseMapSubject.next(status);
            });

            return this.view;
        } catch (error) {
            this.loadingBaseMapSubject.next(false);
        }
    }

    ngOnDestroy(): void {
        if (this.view) {
            // destroy the map view
            this.view.container = null;
        }
    }
}

import { CommonModule, formatNumber } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { cloneDeep, round } from 'lodash-es';
import { BehaviorSubject, Observable, Subscription, combineLatest, map } from 'rxjs';
import { ColorMapService, ChartCategory } from '../../../common/service';
import { getYAxisMaxValue } from '../../../google-charts/google-chart-utils';
import { AbinResultDto } from '../../../inge-api/client/models';
import { InGeApiState } from '../../../store/state-model/inge-api.model';
import { GoogleCombochartComponent } from '../../../google-charts/google-charts-base-components/google-combochart/google-combochart.component';

@Component({
    selector: 'app-arsskada-combochart',
    templateUrl: './arsskada-combochart.component.html',
    styleUrl: './arsskada-combochart.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArsskadaCombochartComponent {
    @ViewChild('chartContainer') chartContainer: ElementRef;
    @ViewChild(GoogleCombochartComponent, { static: true })
    googleCombochart: GoogleCombochartComponent;
    data: any[][];
    showFelmarginalText: boolean = false;

    private subs: Subscription[];

    private _inventeringar = new BehaviorSubject<AbinResultDto[]>([]);
    inventeringar$ = this._inventeringar.asObservable();

    private _vardeVariabel = new BehaviorSubject<string[]>([]);
    vardeVariabel$ = this._vardeVariabel.asObservable();

    private _diagramBeskrivning = new BehaviorSubject<string>(null);
    diagramBeskrivning$ = this._diagramBeskrivning.asObservable();

    private _decimals: number;
    private _vAxisGridlinesMultiple: number;

    @Input()
    set inventeringar(val: AbinResultDto[]) {
        if (val) this._inventeringar.next(val);
    }

    @Input()
    set vardeVariabler(val: string[]) {
        if (val) this._vardeVariabel.next(val);
    }

    @Input()
    set diagramBeskrivning(val: string) {
        if (val) this._diagramBeskrivning.next(val);
    }

    @Input()
    set decimals(val: number) {
        this._decimals = val;
    }

    @Input()
    set vAxisGridlinesMultiple(val: number) {
        this._vAxisGridlinesMultiple = val;
    }

    constructor(private store: Store<InGeApiState>, private colormapService: ColorMapService) {}

    ngOnInit(): void {
        this.options.colors = [this.colormapService.getColor(ChartCategory.BARCHARTDEFAULT), this.colormapService.getColor(ChartCategory.MEDEL3AR)];

        this.subs = [];

        this.subs.push(
            combineLatest([this.inventeringar$, this.vardeVariabel$]).subscribe(([inventeringar, vardeVariabel]) => {
                if (inventeringar && inventeringar.length > 0 && vardeVariabel && vardeVariabel.length > 0) {
                    const sorted = cloneDeep(inventeringar).sort((a, b) => a.invAr - b.invAr);
                    if (vardeVariabel.length == 2) {
                        this.generateCombochartData(sorted, vardeVariabel);
                        this.showFelmarginalText = false;
                    } else if (vardeVariabel.length == 4) {
                        this.generateCombochartDataWithFelmarginaler(sorted, vardeVariabel);
                        this.showFelmarginalText = true;
                    }
                }
            })
        );
    }

    ngOnDestroy(): void {
        this.subs.forEach((x) => x.unsubscribe());
    }

    generateCombochartDataWithFelmarginaler(inventeringar: AbinResultDto[], vardeVariabler: string[]) {
        if (inventeringar.length === 0) {
            console.info('Inventeringar saknas, kunde inte skapa combo diagram');
            return;
        }
        if (vardeVariabler.length != 4) {
            console.info('Fel antal värdevariabler, kunde inte skapa combo diagram. Antal variabler var: ', vardeVariabler.length);
            return;
        }

        vardeVariabler.forEach((variabel) => {
            if (!inventeringar.every((x) => x.hasOwnProperty(variabel))) {
                console.trace(`Felaktig värdevarabel ${variabel}. Kunde inte skapa combodiagram`);
                return;
            }
        });

        if (inventeringar && inventeringar.length > 0) {
            const data: any[][] = [
                [
                    'År',
                    { id: 'Arsskada_1ar', type: 'number', label: 'Årsvärde' },
                    { id: 'Medelfel_low', type: 'number', role: 'interval' },
                    { id: 'Medelfel_high', type: 'number', role: 'interval' },
                    { id: 'tool_tip_1ar', type: 'string', role: 'tooltip', p: { html: true } },
                    { id: 'Medel_3ar', type: 'number', label: 'Sammanvägning för de tre senaste inventeringarna' },
                    { id: 'Medelfel_3ar_low', type: 'number', role: 'interval' },
                    { id: 'Medelfel_3ar_high', type: 'number', role: 'interval' },
                    { id: 'tool_tip_3ar', type: 'string', role: 'tooltip', p: { html: true } }
                ]
            ];
            inventeringar.forEach((inv) => {
                if (inv[vardeVariabler[0]] || inv[vardeVariabler[0]] === 0) {
                    // Årsskada
                    const arsskadaAndel = round(inv[vardeVariabler[0]] * 100, this.decimals ?? 1);
                    const mfelArsskada = inv[vardeVariabler[1]] * 100 * 2;
                    const mfel_Arsskada_low = arsskadaAndel > 0 ? round(arsskadaAndel - mfelArsskada, 2) : null;
                    const mfel_Arsskada_high = arsskadaAndel > 0 ? round(arsskadaAndel + mfelArsskada, 2) : null;

                    // 3års medel
                    const arsskadaAndel3InvMedel = round(inv[vardeVariabler[2]] * 100, this.decimals ?? 1);
                    const mfel3arArsskada95procent = inv[vardeVariabler[3]] * 2 * 100;
                    const mfel3arArsskada_low = arsskadaAndel3InvMedel - mfel3arArsskada95procent;
                    const mfel3arArsskada_high = arsskadaAndel3InvMedel + mfel3arArsskada95procent;

                    const decimalFormatString = this._decimals ? '1.' + this._decimals + '-' + this._decimals : '1.0-0';

                    data.push([
                        `${inv.invAr}`,
                        arsskadaAndel > 0 ? arsskadaAndel : null,
                        mfel_Arsskada_low > 0 ? mfel_Arsskada_low : 0,
                        mfel_Arsskada_high,
                        this._decimals
                            ? this.getTooltip(false, arsskadaAndel, mfelArsskada, decimalFormatString)
                            : this.getTooltip(false, arsskadaAndel, mfelArsskada),
                        arsskadaAndel3InvMedel,
                        mfel3arArsskada_low > 0 ? mfel3arArsskada_low : 0,
                        mfel3arArsskada_high,
                        this._decimals
                            ? this.getTooltip(true, arsskadaAndel3InvMedel, mfel3arArsskada95procent, decimalFormatString)
                            : this.getTooltip(true, arsskadaAndel3InvMedel, mfel3arArsskada95procent)
                    ]);
                }
            });
            this.options = {
                ...this.options,
                tooltip: { isHtml: true },
                interval: {
                    Medelfel_low: { style: 'bars', lineWidth: 1 },
                    Medelfel_high: { style: 'bars', lineWidth: 1 },
                    Medelfel_3ar_low: { style: 'area', curveType: 'none', fillOpacity: 0.3 },
                    Medelfel_3ar_high: { style: 'area', curveType: 'none', fillOpacity: 0.3 }
                },
                height: 250,
                vAxis: {
                    ...this.options.vAxis,
                    maxValue: this.getMaxValue(data)
                }
            };

            this.data = data;
        }
    }

    generateCombochartData(inventeringar: AbinResultDto[], vardeVariabler: string[]) {
        if (inventeringar.length === 0) {
            console.info('Inventeringar saknas, kunde inte skapa combo diagram');
            return;
        }
        if (vardeVariabler.length != 2) {
            console.info('Fel antal värdevariabler, kunde inte skapa combo diagram. Antal variabler var: ', vardeVariabler.length);
            return;
        }

        vardeVariabler.forEach((variabel) => {
            if (!inventeringar.every((x) => x.hasOwnProperty(variabel))) {
                console.trace(`Felaktig värdevarabel ${variabel}. Kunde inte skapa combodiagram`);
                return;
            }
        });

        if (inventeringar && inventeringar.length > 0) {
            const data: any[][] = [
                [
                    'År',
                    { id: 'Arsskada_1ar', type: 'number', label: 'Årsvärde' },
                    { id: 'tool_tip_1ar', type: 'string', role: 'tooltip', p: { html: true } },
                    { id: 'Medel_3ar', type: 'number', label: 'Sammanvägning för de tre senaste inventeringarna' },
                    { id: 'tool_tip_3ar', type: 'string', role: 'tooltip', p: { html: true } }
                ]
            ];
            inventeringar.forEach((inv) => {
                if (inv[vardeVariabler[0]] || inv[vardeVariabler[0]] === 0) {
                    // Årsskada
                    const arsskadaAndel = round(inv[vardeVariabler[0]] * 100, this.decimals ?? 1);

                    // 3års medel
                    const arsskadaAndel3InvMedel = round(inv[vardeVariabler[1]] * 100, this.decimals ?? 1);

                    const decimalFormatString = this._decimals ? '1.' + this._decimals + '-' + this._decimals : '1.0-0';

                    data.push([
                        `${inv.invAr}`,
                        arsskadaAndel > 0 ? arsskadaAndel : null,
                        this._decimals
                            ? this.getTooltipNoFelmarginal(false, arsskadaAndel, decimalFormatString)
                            : this.getTooltipNoFelmarginal(false, arsskadaAndel),
                        arsskadaAndel3InvMedel,
                        this._decimals
                            ? this.getTooltipNoFelmarginal(true, arsskadaAndel3InvMedel, decimalFormatString)
                            : this.getTooltipNoFelmarginal(true, arsskadaAndel3InvMedel)
                    ]);
                }
            });
            this.options = {
                ...this.options,
                tooltip: { isHtml: true },
                height: 315,
                vAxis: {
                    ...this.options.vAxis,
                    maxValue: this.getMaxValue(data)
                }
            };

            this.data = data;
        }
    }

    public options: any = {
        chartArea: { width: '100%', height: '100%', top: 10, left: 50, right: 0, bottom: 50 },
        hAxis: {
            maxAlternation: 6,
            formatOptions: { decimalSymbol: ',', groupingSymbol: '.' }
        },
        vAxis: {
            title: 'Procent %',
            minValue: 0,
            baseline: 0,
            baselineColor: '#ccc',
            gridlines: { multiple: 1 },
            minorGridlines: { count: 0 },
            titleTextStyle: {
                italic: false,
                fontSize: 16
            },
            formatOptions: { decimalSymbol: ',', groupingSymbol: '.' }
        },
        seriesType: 'bars',
        series: { 1: { type: 'line' } },
        intervals: { style: 'bars' },
        annotations: {
            alwaysOutside: true,
            textStyle: {
                color: 'black'
            },
            style: 'point',
            stem: {
                length: 2
            }
        },
        legend: { position: 'bottom' },
        fontName: 'Roboto',
        bar: { groupWidth: '40%' },
        animation: {
            startup: true,
            duration: 100
        },
        colors: []
    };

    getMaxValue(data: any[][]) {
        if (!data || !data.length) return;
        return getYAxisMaxValue(data);
    }

    protected getTooltip(isTreArsMedel: boolean, skadaAndel: number, konfidensProcent: number, decimalFormat: string = '1.0-0'): string {
        const felmarginalStr = 'Felmarginal +/- ' + formatNumber(konfidensProcent, 'sv-SE', decimalFormat) + '%)';
        return (
            '<div class="tooltip_layout">' +
            '<table>' +
            '<tr>' +
            '<td>' +
            (isTreArsMedel ? 'Förekomst senaste 3 inv' : 'Förekomst 1 år') +
            '</td>' +
            '</tr>' +
            '<tr>' +
            '<td><b>' +
            formatNumber(skadaAndel, 'sv-SE', decimalFormat) +
            '</b>% ' +
            (konfidensProcent ? felmarginalStr : '') +
            '</td>' +
            '</tr>' +
            '</table>' +
            '</div>'
        );
    }

    protected getTooltipNoFelmarginal(
        isTreArsMedel: boolean,
        skadaAndel: number,
        text1Ar: string = 'Förekomst 1 år',
        text3Ar: string = 'Förekomst senaste 3 inv',
        decimalFormat: string = '1.0-0'
    ): string {
        return (
            '<div class="tooltip_layout">' +
            '<table>' +
            '<tr>' +
            '<td>' +
            (isTreArsMedel ? text3Ar : text1Ar) +
            '</td>' +
            // '</tr>' +
            // '<tr>' +
            '<td> <b>' +
            formatNumber(skadaAndel, 'sv-SE', decimalFormat) +
            '</b>%</td>' +
            '</tr>' +
            '</table>' +
            '</div>'
        );
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiSectionHeaderComponent } from './ui-section-header/ui-section-header.component';
import { UiSectionTitleDirective } from './directives/ui-section-title.directive';
import { UiSectionTextDirective } from './directives/ui-section-text.directive';

@NgModule({
  declarations: [
    UiSectionHeaderComponent,
    UiSectionTitleDirective,
    UiSectionTextDirective,
  ],
  imports: [CommonModule],
  exports: [
    UiSectionHeaderComponent,
    UiSectionTitleDirective,
    UiSectionTextDirective,
  ],
})
export class UiSectionHeaderModule {}

<div class="fordjupning-karta__popup-container" #popupContainer [class.active]="show">
    <div class="loading-mask" *ngIf="isLoading">
        <app-loader class="karta-loader"></app-loader>
    </div>
    <!-- <app-summary-card>
    <app-summary-card-icon>
      <ng-content select="[popupIcon]"></ng-content>
    </app-summary-card-icon>
    <app-summary-card-header>
      {{ popupContent?.omradeVarde }}
    </app-summary-card-header>
    <app-summary-card-content>
      {{ popupContent?.omradeNamn }}
      <br />
      {{ popupContent?.contentText }}
    </app-summary-card-content>
  </app-summary-card> -->

    <app-ui-info-box ui-info-box-light ui-info-box-centered ui-info-box-no-border ui-info-box-shadow>
        <div class="fordjupning-karta-popup__omrade-namn">
            <span>{{ popupContent?.omradeNamn }}</span>
        </div>
        <hr />
        <ui-info-box-header>
            <ng-content select="[popupIcon]"></ng-content>
        </ui-info-box-header>
        <ui-info-box-content>
            <p class="content-header">
                {{ popupContent?.omradeVarde }}
            </p>
            <p>
                {{ popupContent?.contentText }}
            </p>
        </ui-info-box-content>
    </app-ui-info-box>
</div>

import pptxgen from 'pptxgenjs';
import * as uiColors from 'src/app/ui-components/colors';
import _banner from '../slide-components/_banner';
export default (slide: pptxgen.Slide, kartaDataUrl: string) => {
    _banner(slide, {
        header: 'Geografisk fördelning av årsskada på tall'
        // text: 'Målet är maximalt fem procent årliga skador på tallungskog',
    });
    if (kartaDataUrl) {
        slide.addImage({
            data: kartaDataUrl,
            x: 3.88,
            y: 1.3,
            w: 5.67,
            h: 3.9
        });
        slide.addText(
            'Kartan visar hur skadorna varierar inom området och baseras på de tre senaste inventeringarna. Mörkare färg innebär att en högre andel av tallstammarna är skadade än i de områden på kartan som har ljusare nyans.',
            { fontSize: 12, h: 1, w: 5.67, x: 3.88, y: 5.24 }
        );
    } else {
        slide
            .addText('Ett fel uppstod när kartan skulle laddas', {
                shape: 'rect',
                x: 3.88,
                y: 1.3,
                w: 5.67,
                h: 3.9,
                fontSize: 12,
                align: 'center',
                line: {
                    width: 1,
                    color: uiColors.veryLightGrey
                }
            })
            .addImage({
                path: 'assets/abin-icons-png/error.png',
                x: 4.88,
                y: 3.1,
                w: 0.3,
                h: 0.3
            });
    }
};

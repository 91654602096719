import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ChartCategory } from 'src/app/common/service';
import { AbinResultDto } from 'src/app/inge-api/client/models';
import { FordelningPiechartBaseComponent } from '../../base-components';

@Component({
    selector: 'app-foder-unskogenssammansattning-chart',
    templateUrl: './foder-unskogenssammansattning-chart.component.html',
    styleUrls: ['./foder-unskogenssammansattning-chart.component.scss']
})
export class FoderUnskogenssammansattningChartComponent extends FordelningPiechartBaseComponent implements OnInit, OnDestroy {
    private subs: Subscription[];
    private chartEntries = [
        {
            name: 'Tallskog',
            key: 'tallskogHaABIN3InvMedel',
            color: ChartCategory.TALL
        },
        {
            name: 'Granskog',
            key: 'granskogHaABIN3InvMedel',
            color: ChartCategory.GRAN
        },
        {
            name: 'Barrblandskog',
            key: 'bblandskogHaABIN3InvMedel',
            color: ChartCategory.BARRBLAND
        },
        {
            name: 'Övrig skog',
            key: 'ovrigskogHaABIN3InvMedel',
            color: ChartCategory.OVRIG
        }
    ];
    inventeringsAr: number;
    data: any[][];
    legendData: any[];

    ngOnInit(): void {
        this.subs = [];
        this.subs.push(
            this.inventering$.subscribe((x) => {
                if (x) {
                    this.generateChartData(x);
                }
            })
        );
    }

    generateChartData(senasteInventering: AbinResultDto) {
        const data: any[][] = [['Träslag', 'Procent']];
        const colors = [];
        const legendData = [];
        this.inventeringsAr = senasteInventering.invAr;
        const total = this.getTotalSkogHaAbin(senasteInventering);
        for (const entry of this.chartEntries) {
            if (senasteInventering[entry.key]) {
                const name = entry.name;
                const value = Math.round((senasteInventering[entry.key] / total) * 100);
                const color = this.colormapService.getColor(entry.color);
                data.push([name, value]);
                colors.push(color);
                legendData.push({ name, value, color });
            }
        }
        this.data = data;
        this.options = { ...this.options, colors };
        this.legendData = legendData;
    }

    getTotalSkogHaAbin(inv: AbinResultDto): number {
        let sumTotal = 0;
        for (const entry of this.chartEntries) {
            const value = inv[entry.key];
            sumTotal += value || 0;
        }
        return Math.round(sumTotal);
    }

    ngOnDestroy(): void {
        this.subs.forEach((x) => x.unsubscribe());
    }
}

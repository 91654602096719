import pptxgen from 'pptxgenjs';
import * as uiColors from 'src/app/ui-components/colors';
import addBanner from '../slide-components/_banner';
export default (slide: pptxgen.Slide) => {
    addBanner(slide, {
        header: 'Skoglig betesinventering',
        text: 'I en kunskapsbaserad älgförvaltning vilar beslut på ett relevant, kvalitetssäkrat och vetenskapligt framtaget underlag. Att förvalta älg och andra hjortdjur kompliceras av att hänsyn måste tas till fler aspekter än bara djuren. Det gäller till exempel skador på skog och gröda samt viltolyckor i trafiken.'
    })
        .addShape('rect', {
            fill: { color: uiColors.veryLightOliveGreen },
            x: 0,
            y: 0,
            h: 2.1,
            w: '100%'
        })
        .addText('Skoglig betesinventering', {
            fontSize: 25,
            bold: true,
            x: 1,
            y: 0,
            h: 0.59,
            w: 12.1,
            align: 'left'
        });
    const lastPageBannerText = [
        {
            text: 'I en kunskapsbaserad älgförvaltning vilar beslut på ett relevant, kvalitetssäkrat och vetenskapligt framtaget underlag. Att förvalta älg och andra hjortdjur kompliceras av att hänsyn måste tas till fler aspekter än bara djuren. Det gäller till exempel skador på skog och gröda samt viltolyckor i trafiken.',
            options: { breakLine: true }
        },
        {
            text: '',
            options: { breakLine: true }
        },
        {
            text: 'I en modern förvaltning av älg och andra hjortdjur beaktas olika intressen på ett väl avvägt sätt. Målet är att de positiva värdena som sammankopplas med våra hjortdjur i landskapet ska överskugga de negativa konsekvenserna.',
            options: { breakLine: true }
        }
    ];
    slide.addText(lastPageBannerText, {
        fontSize: 14,
        bold: false,
        x: 1,
        y: 0.49,
        h: 1.47,
        w: 12.1,
        align: 'left'
    });

    const page14LeftSideText = [
        {
            text: 'Skoglig betesinventering består av underlag från olika datakällor (Äbin, foderprognoser, Riksskogstaxeringen med flera) som tillsammans syftar till att ge en sammanhållen beskrivning av tillståndet i skogen för de som förvaltar hjortdjur eller intresserar sig för viltanpassad skogsskötsel.',
            options: { breakLine: true, fontSize: 12 }
        },
        { text: '', options: { breakLine: true, fontSize: 12 } },
        {
            text: 'Älgbetesinventeringen (Äbin) är en basmetod som används inom älgförvaltningen. I Äbin tas uppgifter fram som visar skogstillståndet med fokus på skador från hjortdjur. Inventeringen baseras på en kvalitetssäkrad statistisk metod som beräknar hur många av träden inom ett inventeringsområde som skadats av hjortdjur. Äbin tar även fram uppgifter om trädslag som är särskilt begärliga för viltet; rönn, asp, sälg och ek. Dessutom ger inventeringen information om vilka trädslag som använts då ungskogen anlades. Uppgifterna från Äbin säger alltså inte bara något om de skogsskador som orsakas av älg och andra hjortdjur. Här finns även relevant information om tillgång till och utnyttjande av kvistfoder samt ungskogens sammansättning av trädslag. Fältarbetet utförs på våren av professionella entreprenörer. Skogsstyrelsen ansvarar för metoden, analyserar och sammanställer inventeringsresultaten samt levererar resultat som framför allt används av älgförvaltningsgrupperna.',
            options: { breakLine: true, fontSize: 12 }
        }
    ];
    slide.addText(page14LeftSideText, { h: 3.8, w: 5.67, x: 1, y: 2.3 });

    const page14RightSideText = [
        {
            text: 'Trots namnet skiljer inte Äbin på skador som orsakats av andra vilda hjortdjur än älg.',
            options: { breakLine: true, fontSize: 12 }
        },
        { text: '', options: { breakLine: true, fontSize: 12 } },
        {
            text: 'Foderprognoser visar hur mycket ungskog som finns inom ett specifikt åldersintervall då kvistfoder är tillgängligt för älg och andra hjortdjur. Skogsstyrelsen beräknar årligen den foderproducerande ungskogsarealen utifrån en satellitbildsanalys. Därefter används en matematisk modell för att beräkna inom vilka åldersintervall som ungskogen befinner sig i ett höjdintervall som ger stor tillgång till tillgänglig kvist för i första hand älg. Sådan skog har en medelhöjd mellan 1 och 6 meter.',
            options: { breakLine: true, fontSize: 12 }
        },
        { text: '', options: { breakLine: true, fontSize: 12 } },
        {
            text: 'Riksskogstaxeringens statistik är en del av Sveriges officiella statistik. Som underlag för statistikproduktionen utför Riksskogstaxeringen en årlig stickprovsinventering i fält av Sveriges landareal, exklusive kalfjäll och bebyggd mark. Riksskogstaxeringen drivs av Institutionen för skoglig resurshushållning vid Sveriges lantbruksuniversitet (SLU) i Umeå. I denna rapport redovisas skogens sammansättning samt ståndortsindex, som är ett mått på markens produktionsförmåga.',
            options: { breakLine: true, fontSize: 12 }
        }
    ];
    slide.addText(page14RightSideText, { h: 3.8, w: 5.67, x: 6.67, y: 2.3 });
    return slide;
};

import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AbinResultDto } from 'src/app/inge-api/client/models';
import { Store, select } from '@ngrx/store';
import { InGeApiState } from 'src/app/store/state-model/inge-api.model';

@Component({
  selector: 'app-foryngring-mellanmark-sammanvagning',
  templateUrl: './foryngring-mellanmark-sammanvagning.component.html',
  styleUrls: ['./foryngring-mellanmark-sammanvagning.component.scss'],
})
export class ForyngringMellanmarkSammanvagningComponent implements OnInit {
  inventering$: Observable<AbinResultDto>;
  inventeringarLoading$: Observable<boolean>;
  inventeringarError$: Observable<boolean>;
  private subs: Subscription[];
  constructor(private store: Store<InGeApiState>) {
    this.inventering$ = this.store.pipe(
      select((x) => {
        if (x && x.abin.inventeringar && x.abin.inventeringar.length > 0) {
          return x.abin.inventeringar[0];
        }
      })
    );
    this.inventeringarLoading$ = store.pipe(
      select((x) => x.abin.loadingInventeringar)
    );
    this.inventeringarError$ = store.pipe(
      select((x) => x.abin.inventeringarError)
    );
  }

  ngOnInit(): void {}
}

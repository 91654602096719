import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[ui-info-box-light]',
})
export class UiInfoBoxLightDirective {
  constructor() {}

  @HostBinding('class')
  elementClass = 'ui-info-box-light';
}

import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'ui-error-margin',
    template: `<div class="ui-error-margin">
        Felmarginal visas med felstaplar samt en ljusblå zon. Definition för felmarginal finns längst ner i rapporten.
    </div>`,
    styleUrl: './ui-error-margin.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiErrorMarginComponent {}

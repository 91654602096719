<!-- <div class="row">
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <ui-figure-caption>
          <ui-figure-text>
            <h4>Inventeringens omfattning</h4>
          </ui-figure-text>
        </ui-figure-caption>
      </div>
    </div>
    <div class="row" *ngFor="let item of tableContent" style="border-bottom:1px solid grey;">
      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
        {{item.titel}}
      </div>
      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6" style="text-align: right;">
        {{item.varde}}
      </div>
    </div>
  </div>
</div> -->
<div class="row">
    <div class="col-xs-12">
        <ui-figure-caption>
            <ui-figure-text>
                <h4>Inventeringens omfattning innevarande år</h4>
            </ui-figure-text>
        </ui-figure-caption>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <app-ui-info-box ui-info-box-light ui-info-box-no-border ui-info-box-shadow class="omrade__info-ruta">
            <ui-info-box-content>
                <div class="row bottom-xs" *ngFor="let item of tableContent">
                    <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7 info-col">
                        <p class="p-bold">{{ item.titel }}:</p>
                    </div>
                    <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5 info-col varde-col">
                        <p>{{ item.varde }}</p>
                    </div>
                </div>
                <hr />
            </ui-info-box-content>
        </app-ui-info-box>
    </div>
</div>

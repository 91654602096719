<app-ui-info-box ui-info-box-light ui-info-box-no-border ui-info-box-shadow class="foder__info-ruta">
  <ui-info-box-header>
    <h4>Kategorier</h4>
  </ui-info-box-header>
  <ui-info-box-content>

    <div class="row">
      <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5 info-col">
        <p class="p-bold">Tallskog:</p>
      </div>
      <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7 info-col">
        <p>>= 65 % tall</p>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5 info-col">
        <p class="p-bold">Granskog:</p>
      </div>
      <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7 info-col">
        <p>>= 65 % gran</p>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5 info-col">
        <p class="p-bold">Barrblandskog:</p>
      </div>
      <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7 info-col">
        <p>>= 65 % tall & gran</p>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5 info-col">
        <p class="p-bold">Övrig skog:</p>
      </div>
      <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7 info-col">
        <p>Lövskog, lövbarrblandskog, lärkskog, etc.</p>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-xs-12">
        <p class="p-italic">I Äbin hamnar trädslag som concortatall, lärk, hybridasp, m.fl. i kategori övrigt</p>
      </div>
    </div>
  </ui-info-box-content>
</app-ui-info-box>

<div class="ui-toast-icon-container">
  <span class="material-icons"> </span>
</div>

<div class="ui-toast-text-container">
  <span class="ui-toast-header">{{ header }}</span>
  <span class="ui-toast-text">{{ message }}</span>
  <span class="ui-toast-text">
    <ng-content select="[uiToastContent]"></ng-content>
  </span>
</div>

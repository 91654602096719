import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ExportReportModalService } from 'src/app/shared-components/export-report-modal/export-report-modal.service';
import {
  InGeApiState,
  InventeringsNiva,
} from 'src/app/store/state-model/inge-api.model';

@Component({
  selector: 'app-rapport-footer',
  templateUrl: './rapport-footer.component.html',
  styleUrls: ['./rapport-footer.component.scss'],
})
export class RapportFooterComponent implements OnInit {
  inventeringsNamn$: Observable<string>;
  constructor(
    private store: Store<InGeApiState>,
    private exportModalService: ExportReportModalService
  ) {
    this.inventeringsNamn$ = this.store.pipe(
      select((x) => {
        if (x && x.abin?.inventeringar && x.abin.inventeringar.length > 0) {
          const senaste = x.abin.inventeringar[0];
          const niva = x.abin.inventeringsNiva;
          switch (niva) {
            case InventeringsNiva.LAND:
              return 'Område: Hela landet';
            case InventeringsNiva.LANDSDEL:
              return `Område: ${senaste.landsdelNamn}`;
            case InventeringsNiva.LAN:
              return `Område: ${senaste.lanNamn}`;
            case InventeringsNiva.AFO:
              return `Område: ${senaste.afoNamn}, ${senaste.lanNamn} `;
            case InventeringsNiva.STRATUM:
              return `Område: Delområde ${senaste.delomrNamn} i ${senaste.afoNamn}, ${senaste.lanNamn}`;

            default:
              return '';
          }
        }
      })
    );
  }

  ngOnInit(): void {}

  exportReport() {
    this.exportModalService.setVisible(true);
  }
}

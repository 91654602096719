<!-- <app-ui-info-box ui-info-box-light ui-info-box-centered>
  <ui-info-box-header>Andel oskadade tall, sammanvägning för de tre senaste
    inventeringarna</ui-info-box-header>
  <ui-info-box-content>
    <h3>{{(inventeringar$|async)?.oskadadTallAndel3InvMedel|percent:'1.0':'sv'}}</h3>
  </ui-info-box-content>
</app-ui-info-box> -->

<app-summary-card>
  <app-summary-card-icon>
    <ng-container
      *ngIf="
        (inventering$ | async)?.slutsatsOskadadTallInt > 0;
        else elseTemplate2
      "
    >
      <span class="abinicon-oskadad_tall_uppfyllt"></span>
    </ng-container>
    <ng-template #elseTemplate2>
      <span class="abinicon-oskadad_tall_ej_uppfyllt"></span>
    </ng-template>
  </app-summary-card-icon>
  <app-summary-card-header>
    {{
      (inventering$ | async)?.oskadadTallAndel3InvMedel | percent : "1.0" : "sv"
    }}
  </app-summary-card-header>
  <app-summary-card-content>
    Andel oskadad tall, sammanvägning för de tre senaste inventeringarna
  </app-summary-card-content>
</app-summary-card>

import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ArsskadorKartaService } from 'src/app/abin-resultat-rapport/rapport-components/arsskador-karta/arsskador-karta.service';
import { KartaInventeringsrutorService } from 'src/app/abin-resultat-rapport/rapport-components/karta-inventeringsrutor/karta-inventeringsrutor.service';
import { AbinResultDto } from 'src/app/inge-api/client/models';
import {
  InGeApiState,
  InventeringsNiva,
} from 'src/app/store/state-model/inge-api.model';
import { PowerPoint } from './powerpoint';

@Injectable({ providedIn: 'root' })
export class PowerPointGeneratorService {
  private inventeringsNiva$: Observable<InventeringsNiva>;
  private inventeringar$: Observable<AbinResultDto[]>;

  constructor(
    private store: Store<InGeApiState>,
    private kartaInventeringsrutorService: KartaInventeringsrutorService,
    private arsskadorKartaService: ArsskadorKartaService
  ) {
    this.inventeringar$ = this.store.pipe(select((x) => x.abin.inventeringar));
    this.inventeringsNiva$ = this.store.pipe(
      select((x) => x.abin.inventeringsNiva)
    );
  }

  generateReport() {
    return combineLatest([
      this.inventeringar$,
      this.inventeringsNiva$,
      this.kartaInventeringsrutorService.getScreenshot(600 * 1.45, 600),
      this.arsskadorKartaService.getScreenshot(600 * 1.45, 600),
    ]).pipe(
      switchMap(
        ([
          inventeringar,
          inventeringsNiva,
          inventeringsrutorKarta,
          arsskadorKarta,
        ]) => {
          return this.exportPowerpoint(
            inventeringar,
            inventeringsNiva,
            inventeringsrutorKarta,
            arsskadorKarta
          );
        }
      )
    );
  }

  private exportPowerpoint(
    inventeringar: AbinResultDto[],
    inventeringsNiva: InventeringsNiva,
    inventeringsrutorKarta: __esri.Screenshot,
    arsskadorKarta: __esri.Screenshot
  ) {
    const omradesNamn = this.getInventeringsOmrade(
      inventeringar,
      inventeringsNiva
    );
    const powerpointGenerator = new PowerPoint();
    return from(
      powerpointGenerator.generatePowerPoint(
        inventeringar,
        inventeringsrutorKarta,
        arsskadorKarta,
        omradesNamn,
        inventeringsNiva
      )
    );
  }

  private getInventeringsOmrade(
    inventeringar: AbinResultDto[],
    inventeringsNiva: InventeringsNiva
  ) {
    const senaste = inventeringar[0];
    const niva = inventeringsNiva;
    switch (niva) {
      case InventeringsNiva.LAND:
        return 'Område: Hela landet';
      case InventeringsNiva.LANDSDEL:
        return `Område: ${senaste.landsdelNamn}`;
      case InventeringsNiva.LAN:
        return `Område: ${senaste.lanNamn}`;
      case InventeringsNiva.AFO:
        return `Område: ${senaste.afoNamn}, ${senaste.lanNamn} `;
      case InventeringsNiva.STRATUM:
        return `Område: Delområde ${senaste.delomrNamn} i ${senaste.afoNamn}, ${senaste.lanNamn}`;

      default:
        return '';
    }
  }
}

import { Injectable } from '@angular/core';
import { EsriJsonGeometry } from './esri-json';
import * as ArcGisParser from '@terraformer/arcgis';
import * as WktParser from '@terraformer/wkt';
@Injectable({
  providedIn: 'root',
})
export class GeometryTransformService {
  constructor() {}

  wktToEsri(wkt: string, wkid?: number) {
    const geojson = WktParser.wktToGeoJSON(wkt);
    const arcgis = ArcGisParser.geojsonToArcGIS(geojson);
    if (wkid && wkid > 1000) {
      arcgis.spatialReference = { wkid };
    }

    return arcgis as EsriJsonGeometry;
  }

  wktListToEsri(wkt: string[], wkid?: number): EsriJsonGeometry[] {
    try {
      return wkt.map((x) => {
        return this.wktToEsri(x, wkid);
      });
    } catch (error) {
      return [];
    }
  }

  geojsonToEsri(geojsonString: string, wkid?: number): EsriJsonGeometry {
    const geojson = JSON.parse(geojsonString);
    const arcgis = ArcGisParser.geojsonToArcGIS(geojson);
    if (wkid && wkid > 100) {
      arcgis.spatialReference = { wkid };
    }
    return arcgis;
  }

  geojsonListToEsri(geojson: string[], wkid?: number) {
    return geojson.map((x) => {
      return this.geojsonToEsri(x, wkid);
    });
  }

  esriToWkt(esriJson: string) {
    const geojson = this.esriToGeojson(esriJson);
    return WktParser.geojsonToWKT(geojson);
  }

  geojsonToWkt(geoJsonString: string) {
    const geojson = JSON.parse(geoJsonString);
    return WktParser.geojsonToWKT(geojson);
  }

  esriToGeojson(esriJson: string) {
    const esri = JSON.parse(esriJson);
    return ArcGisParser.arcgisToGeoJSON(esri);
  }

  wktToGeojson(wkt: string) {
    return WktParser.wktToGeoJSON(wkt);
  }
}

import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[uiToastInfo]',
})
export class UiToastInfoDirective {
  constructor() {}

  @HostBinding('class') elClass = 'info';
}

import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ColorMapService } from 'src/app/common/service';
import { AbinResultDto } from 'src/app/inge-api/client/models';
import { InGeApiState } from 'src/app/store/state-model/inge-api.model';

@Component({
  selector: 'app-fordelning-piechart-base',
  template: ``,
})
export class FordelningPiechartBaseComponent implements OnInit {
  inventering$: Observable<AbinResultDto>;
  options: any = {
    chartArea: { width: '90%', height: '90%' },
    height: 230,
    pieSliceText: 'none',
    tooltip: {
      trigger: 'none',
    },
    legend: {
      position: 'none',
    },
  };
  constructor(
    private store: Store<InGeApiState>,
    public colormapService: ColorMapService
  ) {
    this.inventering$ = this.store.pipe(
      select((x) => x.abin.inventeringar),
      map((x) => {
        const sorted = x && cloneDeep(x).sort((a, b) => b.invAr - a.invAr);
        return sorted && sorted[0];
      })
    );
  }

  ngOnInit(): void {}
}

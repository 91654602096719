<div class="row">
    <div class="col-xs-12">Välj område med knapparna för att visa utvecklingen över tid och vilka år som inventerats.</div>

    <div class="col-xs-12">
        <app-fordjupning-button-group (selectedOmradeEvent)="onOmradeSelect($event)"></app-fordjupning-button-group>
    </div>
    <div class="col-xs-12">
        <p><b>Valt område</b>: {{ omradeNamn$ | async }}</p>
    </div>
</div>

<div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7 mt-0 pl-0">
        <div class="col-xs-12">
            <ui-figure-caption>
                <ui-figure-text>
                    <h4 class="mt-0">Föryngrat trädslag i ungskog på mellanmarker, de senaste tre inventeringarna.</h4>
                </ui-figure-text>
                <ui-figure-sub-text> Andel mellanmark: {{ andelMellanMarker }}% för de tre senaste inventeringarna </ui-figure-sub-text>
            </ui-figure-caption>
        </div>
        <div class="row middle-xs center-xs">
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <app-google-pie-chart [data]="dataTradslag" [options]="optionsTradslag"></app-google-pie-chart>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <ui-legend ui-legend-vertical>
                    <ui-legend-item *ngFor="let item of legendDataTradslag; let i = index" [color]="item.color">
                        {{ item.name }}: {{ item.value | number }}%
                    </ui-legend-item>
                </ui-legend>
            </div>
        </div>
    </div>

    <!-- Tom kolumn -->
    <div class="col-xs-0 col-lg-1"></div>

    <div class="col-md-4 col-xs-12 col-lg-4">
        <ng-container *ngIf="senasteInventering$ | async as senasteInventering">
            <app-sammanvagning-card
                [inventering]="senasteInventering"
                vardeVariabel="standortsanpassningAndelMellanMarkerMedTall3InvMedel"
                cardBeskrivning="Andel av mellanmarkerna som föryngras med tall, sammanvägning för de tre senaste inventeringarna."
            >
                <ng-container sammanvagningCardIcon *ngIf="senasteInventering?.slutsatsTallpaMellanMarkerInt > 0; else elseTemplate">
                    <span class="abinicon-tall_mellanmark_uppfyllt"></span>
                </ng-container>
                <ng-template sammanvagningCardIcon #elseTemplate>
                    <span class="abinicon-tall_mellanmark_ej_uppfyllt"></span>
                </ng-template>
            </app-sammanvagning-card>
        </ng-container>
    </div>
</div>
<br />
<div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div #chartContainer>
            <ui-figure-caption>
                <ui-figure-text>
                    <h4>Andel ungskog på mellanmark som är föryngrad med tall</h4>
                </ui-figure-text>
                <ui-figure-sub-text> Andel mellanmark: {{ andelMellanMarker }}% för de tre senaste inventeringarna </ui-figure-sub-text>
            </ui-figure-caption>

            <app-google-barchart [data]="dataBarchart" [options]="options"></app-google-barchart>
        </div>
    </div>
</div>

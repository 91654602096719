<app-summary-card>
  <app-summary-card-icon>
    <ng-container
      *ngIf="
        (inventering$ | async)?.slutsatsArsskadaTallInt > 0;
        else elseTemplate
      "
    >
      <span class="abinicon-arsskada_tall_uppfyllt"></span>
    </ng-container>
    <ng-template #elseTemplate>
      <span class="abinicon-arsskada_tall_ej_uppfyllt"></span>
    </ng-template>
  </app-summary-card-icon>
  <app-summary-card-header>
    {{
      (inventering$ | async)?.arsskadaTallAndel3InvMedel | percent: "1.0":"sv"
    }}
  </app-summary-card-header>
  <app-summary-card-content>
    Andel årskador på tall, sammanvägning för de tre senaste inventeringarna
  </app-summary-card-content>
</app-summary-card>

import { formatPercent } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { flatten } from 'lodash-es';
import { BehaviorSubject, Observable } from 'rxjs';
import { InventeringsNiva, Fordjupning, InGeApiState } from 'src/app/store/state-model/inge-api.model';
import { environment } from 'src/environments/environment';
import { SublayerConfiguration } from '../../base-components/fordjupning-karta-base/fordjupning-karta.model';
import { FordjupningKartaPopupContent } from '../../base-components/fordjupning-karta-popup/fordjupning-karta-popup.model';

@Component({
    selector: 'app-uppfolj-konkurrens-rase-karta',
    templateUrl: './uppfolj-konkurrens-rase-karta.component.html',
    styleUrls: ['./uppfolj-konkurrens-rase-karta.component.scss']
})
export class UppfoljKonkurrensRaseKartaComponent implements OnInit {
    private _layerConfig = new BehaviorSubject<SublayerConfiguration>(null);
    layerConfig$ = this._layerConfig.asObservable();
    inventeringsNiva$: Observable<InventeringsNiva>;
    inventeringsNiva: InventeringsNiva;
    fordjupningsData$: Observable<Fordjupning>;
    fordjupningsData: Fordjupning;
    private popupData = new BehaviorSubject<FordjupningKartaPopupContent>(null);
    popupData$ = this.popupData.asObservable();
    isLoadingPopupData: boolean;

    constructor(private store: Store<InGeApiState>) {
        this.inventeringsNiva$ = this.store.pipe(select((x) => x.uppfoljningsrapport.inventeringsNiva));
        this.store.pipe(select((x) => x.uppfoljningsrapport.fordjupning)).subscribe((x) => (this.fordjupningsData = x));
    }
    ngOnDestroy(): void {}
    ngOnInit(): void {
        this.inventeringsNiva$.subscribe((x) => {
            if (x) {
                this.inventeringsNiva = x;
                const mapserviceConfig = environment.kartor.geodataportalVisaAbinrapport_3_0;
                if (x === InventeringsNiva.LAND) {
                    this._layerConfig.next({
                        layerId: mapserviceConfig.sublayers.konkurrensstatusRASELandsdel.id, // ÄNDRA
                        parentId: mapserviceConfig.sublayers.konkurrensstatusRASELandsdel.parentId
                    });
                }
                if (x === InventeringsNiva.LANDSDEL) {
                    this._layerConfig.next({
                        layerId: mapserviceConfig.sublayers.konkurrensstatusRASELan.id,
                        parentId: mapserviceConfig.sublayers.konkurrensstatusRASELan.parentId
                    });
                }
                if (x === InventeringsNiva.LAN) {
                    this._layerConfig.next({
                        layerId: mapserviceConfig.sublayers.konkurrensstatusRASEAfo.id,
                        parentId: mapserviceConfig.sublayers.konkurrensstatusRASEAfo.parentId
                    });
                }
            }
        });
    }

    onMapClicked(graphic: __esri.Graphic) {
        this.isLoadingPopupData = true;
        if (!graphic || !this.fordjupningsData || !this.fordjupningsData.inventeringar) {
            return;
        }
        const inventeringar = flatten(this.fordjupningsData.inventeringar);
        let omradeNamn: string;
        let omradeVardePercent: string;
        const contentText = 'Sammanvägning för de tre senaste inventeringarna';
        let thumbsUp = false;
        if (this.fordjupningsData.fordjupningsNiva === InventeringsNiva.LANDSDEL) {
            const landsdelKod = graphic.attributes.LandsdelKod.toString();
            const inventering = inventeringar.filter((x) => x.landsdelKod.toString() === landsdelKod)[0];
            omradeNamn = inventering.landsdelNamn;
            omradeVardePercent = formatPercent(
                inventering.raseAndelGynnsam3InvMedel, //ÄNDRA
                'sv',
                '1.0'
            );
            thumbsUp = inventering.slutsatsRASEGynInt > 0; //ÄNDRA
        }
        if (this.fordjupningsData.fordjupningsNiva === InventeringsNiva.LAN) {
            const lanKod = graphic.attributes.LanKod.toString();
            const inventering = inventeringar.filter((x) => x.lanKod === lanKod)[0];
            omradeNamn = `${inventering.landsdelNamn} - ${inventering.lanNamn}`;
            omradeVardePercent = formatPercent(inventering.raseAndelGynnsam3InvMedel, 'sv', '1.0');
            thumbsUp = inventering.slutsatsRASEGynInt > 0;
        }
        if (this.fordjupningsData.fordjupningsNiva === InventeringsNiva.AFO) {
            const AFONr = graphic.attributes.AFONr.toString();
            const inventering = inventeringar.filter((x) => x.afoNr.toString() === AFONr)[0];
            omradeNamn = `${inventering.lanNamn} - ${inventering.afoNamn} (ÄFO nr ${inventering.afoNr})`;
            omradeVardePercent = formatPercent(
                inventering.raseAndelGynnsam3InvMedel, //ÄNDRA
                'sv',
                '1.0'
            );
            thumbsUp = inventering.slutsatsRASEGynInt > 0;
        }

        this.popupData.next({
            omradeNamn,
            omradeVarde: `Konkurrensstatus RASE, ${omradeVardePercent}`, // ÄNDRA
            contentText,
            thumbsUp
        });
        this.isLoadingPopupData = false;
    }
}

<div #chartContainer>
    <ui-figure-caption>
        <!-- <ui-figure-number>Figur 4.</ui-figure-number> -->
        <ui-figure-text>
            <h4>Andel oskadade tallstammar i ungskog inklusive felmarginal</h4>
        </ui-figure-text>
    </ui-figure-caption>
    <app-google-combochart [data]="data" [options]="options"></app-google-combochart>
    <ui-error-margin />
</div>

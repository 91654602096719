import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ChartCategory } from 'src/app/common/service';
import { AbinResultDto } from 'src/app/inge-api/client/models';
import { FordelningPiechartBaseComponent } from '../../base-components';

@Component({
    selector: 'app-foryngrade-traslag-chart',
    templateUrl: './foryngrade-traslag-chart.component.html',
    styleUrls: ['./foryngrade-traslag-chart.component.scss']
})
export class ForyngradeTraslagChartComponent extends FordelningPiechartBaseComponent implements OnInit, OnDestroy {
    private subs: Subscription[];
    data: any[][];
    legendData: any[];

    ngOnInit(): void {
        this.subs = [];
        this.subs.push(
            this.inventering$.subscribe((senasteInventering) => {
                if (senasteInventering) {
                    this.generateChartData(senasteInventering);
                }
            })
        );
    }

    generateChartData(senasteInventering: AbinResultDto) {
        const total = this.getTotal(senasteInventering);
        const data: any[][] = [['Träslag', 'Procent']];
        const colors = [];
        const legendData = [];
        if (senasteInventering.foryngTallHa3InvMedel) {
            const name = 'Tall';
            const value = Math.round((senasteInventering.foryngTallHa3InvMedel / total) * 100);
            const color = this.colormapService.getColor(ChartCategory.TALL);
            data.push([name, value]);
            colors.push(color);
            legendData.push({ name, value, color });
        }

        if (senasteInventering.foryngGranHa3InvMedel) {
            const name = 'Gran';
            const value = Math.round((senasteInventering.foryngGranHa3InvMedel / total) * 100);
            const color = this.colormapService.getColor(ChartCategory.GRAN);
            data.push([name, value]);
            colors.push(color);
            legendData.push({ name, value, color });
        }

        if (senasteInventering.foryngBjorkHa3InvMedel) {
            const name = 'Björk';
            const value = Math.round((senasteInventering.foryngBjorkHa3InvMedel / total) * 100);
            const color = this.colormapService.getColor(ChartCategory.BJORK);
            data.push([name, value]);
            colors.push(color);
            legendData.push({ name, value, color });
        }
        if (senasteInventering.foryngContortaHa3InvMedel) {
            const name = 'Contorta';
            const value = Math.round((senasteInventering.foryngContortaHa3InvMedel / total) * 100);
            const color = this.colormapService.getColor(ChartCategory.CONTORTA);
            data.push([name, value]);
            colors.push(color);
            legendData.push({ name, value, color });
        }
        if (senasteInventering.foryngLarkHa3InvMedel) {
            const name = 'Lärk';
            const value = Math.round((senasteInventering.foryngLarkHa3InvMedel / total) * 100);
            const color = this.colormapService.getColor(ChartCategory.LARK);
            data.push([name, value]);
            colors.push(color);
            legendData.push({ name, value, color });
        }
        if (senasteInventering.foryngOvrigtHa3InvMedel) {
            const name = 'Övrigt';
            const value = Math.round((senasteInventering.foryngOvrigtHa3InvMedel / total) * 100);
            const color = this.colormapService.getColor(ChartCategory.OVRIG);
            data.push([name, value]);
            colors.push(color);
            legendData.push({ name, value, color });
        }
        if (senasteInventering.foryngIngenHa3InvMedel) {
            const name = 'Okänt';
            const value = Math.round((senasteInventering.foryngIngenHa3InvMedel / total) * 100);
            const color = this.colormapService.getColor(ChartCategory.INGENATGARD);
            data.push([name, value]);
            colors.push(color);
            legendData.push({ name, value, color });
        }
        this.data = data;
        this.options = { ...this.options, colors };
        this.legendData = legendData;
    }

    getTotal(inv: AbinResultDto): number {
        return (
            (inv.foryngTallHa3InvMedel || 0) +
            (inv.foryngBjorkHa3InvMedel || 0) +
            (inv.foryngContortaHa3InvMedel || 0) +
            (inv.foryngGranHa3InvMedel || 0) +
            (inv.foryngIngenHa3InvMedel || 0) +
            (inv.foryngLarkHa3InvMedel || 0) +
            (inv.foryngOvrigtHa3InvMedel || 0)
        );
    }

    ngOnDestroy(): void {
        this.subs.forEach((x) => x.unsubscribe());
    }
}

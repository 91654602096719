import { formatNumber } from '@angular/common';
import pptxgen from 'pptxgenjs';
import { AbinResultDto } from 'src/app/inge-api/client/models';
import { InventeringsNiva } from 'src/app/store/state-model/inge-api.model';
import * as uiColors from 'src/app/ui-components/colors';
import addBanner from '../slide-components/_banner';
import { sammanvagningCardOptions } from '../slide-components/_sammanvagningCard';
export default (slide: pptxgen.Slide, kartaDataUrl: string, senasteInventering: AbinResultDto, inventeringsNiva: InventeringsNiva) => {
    addBanner(slide, {
        header: 'Introduktion',
        text: 'Utgångspunkten i den svenska förvaltningen av hjortdjur är att den ska vila på bra beslutsunderlag, så att tillräcklig hänsyn kan tas till de oönskade effekter som närvaron av hjortdjur för med sig.'
    });
    if (kartaDataUrl) {
        slide.addImage({
            data: kartaDataUrl,
            x: 1,
            y: 1.3,
            w: 5.67,
            h: 3.9
        });
        slide.addText(getKartText(inventeringsNiva), {
            fontSize: 12,
            h: 1,
            w: 5.67,
            x: 1,
            y: 5.33
        });
    } else {
        slide
            .addText('Ett fel uppstod när kartan skulle laddas', {
                shape: 'rect',
                x: 1,
                y: 1.3,
                w: 5.67,
                h: 3.9,
                fontSize: 12,
                align: 'center',
                line: {
                    width: 1,
                    color: uiColors.veryLightGrey
                }
            })
            .addImage({
                path: 'assets/abin-icons-png/error.png',
                x: 2.07,
                y: 3.1,
                w: 0.3,
                h: 0.3
            });
    }

    const tableCardOptions = {
        ...sammanvagningCardOptions(),
        x: 7.23,
        y: 1.3,
        h: 3.9,
        w: 4.4
    };
    slide.addShape('rect', tableCardOptions);

    slide.addText('Inventeringens omfattning innevarande år', {
        w: 3.4,
        x: 7.75,
        y: 1.75,
        fontSize: 18,
        bold: true,
        align: 'center'
    });

    const rows: any[] = [
        [
            {
                text: 'Antal km-rutor i stickprovet inkl. rutor som saknar ungskog',
                options: { align: 'left', bold: true }
            },
            {
                text: formatNumber(senasteInventering.antalRutor, 'sv'),
                options: {
                    align: 'right',
                    bold: false
                }
            }
        ],
        [
            {
                text: 'Antal inventerade ungskogar',
                options: { align: 'left', bold: true }
            },
            {
                text: formatNumber(senasteInventering.antalBestand, 'sv'),
                options: { align: 'right', bold: false }
            }
        ],
        [
            {
                text: 'Antal inventerade provytor',
                options: { align: 'left', bold: true }
            },
            {
                text: formatNumber(senasteInventering.antalYtorIngar, 'sv'),
                options: {
                    align: 'right',
                    bold: false
                }
            }
        ],
        [
            {
                text: 'Landareal, ha',
                options: {
                    align: 'left',
                    bold: true,
                    border: [{ pt: 0 }, { pt: 0 }, { pt: 1, color: '999999' }, { pt: 0 }]
                }
            },
            {
                text: senasteInventering.arealLandHa ? formatNumber(senasteInventering.arealLandHa, 'sv') : '',
                options: {
                    align: 'right',
                    bold: false,
                    border: [{ pt: 0 }, { pt: 0 }, { pt: 1, color: '999999' }, { pt: 0 }]
                }
            }
        ]
    ];
    slide.addTable(rows, { x: 7.63, y: 2.47, w: 3.61, fontSize: 14 });
};

const getKartText = (inventeringsNiva: InventeringsNiva) => {
    if (inventeringsNiva === InventeringsNiva.LAND || inventeringsNiva === InventeringsNiva.LANDSDEL) {
        return 'Kartan visar den geografiska avgränsning som rapporten gäller för. Det stickprov av slumpmässigt placerade kilometerrutor som ingår i inventeringen visas endast på läns- och ÄFO-nivå.';
    }
    return 'Kartan visar den geografiska avgränsning som rapporten gäller för och det stickprov av slumpmässigt placerade kilometerrutor som ingår i inventeringen. Röda rutor ingick i den senaste inventeringen medan de grå rutorna visar tidigare två inventeringar.';
};

import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { round } from 'lodash-es';
import { Subscription } from 'rxjs';
import { AndelCombochartBaseComponent } from '../../base-components';

@Component({
    selector: 'app-rase-konkurrens-chart',
    templateUrl: './rase-konkurrens-chart.component.html',
    styleUrls: ['./rase-konkurrens-chart.component.scss']
})
export class RaseKonkurrensChartComponent extends AndelCombochartBaseComponent implements OnInit, OnDestroy {
    @ViewChild('chartContainer') chartContainer: ElementRef;

    private subs: Subscription[];
    data: any[][];

    ngOnInit(): void {
        this.subs = [];
        this.subs.push(
            this.inventeringar$.subscribe((x) => {
                if (x && x.length > 0) {
                    const data: any[][] = [
                        [
                            'År',
                            { id: 'Arsskada_1ar', type: 'number', label: 'Årsvärde' },
                            { id: 'tool_tip_1ar', type: 'string', role: 'tooltip', p: { html: true } },
                            { id: 'Medel_3ar', type: 'number', label: 'Sammanvägning för de tre senaste inventeringarna' },
                            { id: 'tool_tip_3ar', type: 'string', role: 'tooltip', p: { html: true } }
                        ]
                    ];

                    x.forEach((inv) => {
                        if (inv.raseAndelGynnsam || inv.raseAndelGynnsam === 0) {
                            // Årsskada
                            const arsskadaAndel = round(inv.raseAndelGynnsam * 100, 1);

                            // 3års medel
                            const arsskadaAndel3InvMedel = round(inv.raseAndelGynnsam3InvMedel * 100, 1);

                            data.push([
                                `${inv.invAr}`,
                                arsskadaAndel > 0 ? arsskadaAndel : null,
                                this.getTooltipNoFelmarginal(false, arsskadaAndel, 'Gynnsam 1 år', 'Gynnsam senaste 3 inv'),
                                arsskadaAndel3InvMedel,
                                this.getTooltipNoFelmarginal(true, arsskadaAndel3InvMedel, 'Gynnsam 1 år', 'Gynnsam senaste 3 inv')
                            ]);
                        }
                    });
                    this.options = {
                        ...this.options,
                        tooltip: { isHtml: true },
                        height: 250,
                        vAxis: {
                            ...this.options.vAxis,
                            maxValue: this.getMaxValue(data)
                        }
                    };

                    this.data = data;
                }
            })
        );
    }

    ngOnDestroy(): void {
        this.subs.forEach((x) => x.unsubscribe());
    }
}

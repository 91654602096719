import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from '../app-routing.module';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { UiComponentsModule } from '../ui-components/ui-components.module';
import { ContainerComponent } from './container/container/container.component';
import { RapportSelectComponent } from './rapport-select/rapport-select.component';
import { ResultatRapportSelectComponent } from './rapport-select/resultat-rapport-select/resultat-rapport-select.component';
import { UppfoljRapportSelectComponent } from './rapport-select/uppfolj-rapport-select/uppfolj-rapport-select.component';
import { RapportContainerComponent } from './container/rapport-container/rapport-container.component';
import { AbinKomboRapportModule } from '../abin-kombo-rapport/abin-kombo-rapport.module';
import { AbinResultatRapportModule } from '../abin-resultat-rapport/abin-resultat-rapport.module';

@NgModule({
    declarations: [ContainerComponent, RapportSelectComponent, ResultatRapportSelectComponent, UppfoljRapportSelectComponent, RapportContainerComponent],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        SharedComponentsModule,
        UiComponentsModule,
        AbinResultatRapportModule,
        AbinKomboRapportModule
    ],
    exports: [ContainerComponent, RapportContainerComponent]
})
export class AbinRapportSelectModule {}

<app-summary-card class="uppfolj-sammanvagning-card">
  <app-summary-card-icon>
    <ng-content select="[sammanvagningCardIcon]"></ng-content>
  </app-summary-card-icon>
  <app-summary-card-header>
    {{ percentValue$ | async }}
  </app-summary-card-header>
  <app-summary-card-content>
    {{ cardBeskrivning$ |async }}
  </app-summary-card-content>
</app-summary-card>

import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UiComponentsModule } from './ui-components/ui-components.module';
import { AbinRapportSelectModule } from './abin-rapport-select/abin-rapport-select.module';
import { InGeApiStoreModule } from './store/store.module';
import { SharedComponentsModule } from './shared-components/shared-components.module';
import { registerLocaleData } from '@angular/common';
import locale from '@angular/common/locales/sv';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth/auth.interceptor';
registerLocaleData(locale);
@NgModule({
    declarations: [AppComponent],
    imports: [InGeApiStoreModule.forRoot(), BrowserModule, AppRoutingModule, UiComponentsModule, AbinRapportSelectModule, SharedComponentsModule],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        {
            provide: LOCALE_ID,
            useValue: 'sv_SE'
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}

<!-- <div #mapViewNode class="inventeringsrutor__esri-map">
  <div class="loading-mask" *ngIf="isLoading">
    <app-loader class="karta-loader"></app-loader>
  </div>
</div> -->
<app-resultat-karta-base #mapComponent></app-resultat-karta-base>
<br />
<ui-figure-caption>
  <ui-figure-text ui-figure-text-bold>
    <ng-container
      *ngIf="
        (inventeringsNiva$ | async) === 'HelaLandet' ||
          (inventeringsNiva$ | async) === 'Landsdel';
        else longTextTemplate
      "
    >
      Kartan visar den geografiska avgränsning som rapporten gäller för. Det
      stickprov av slumpmässigt placerade kilometerrutor som ingår i
      inventeringen visas endast på läns- och ÄFO-nivå. Bakgrundskarta © Esri
    </ng-container>

    <ng-template #longTextTemplate>
      Kartan visar den geografiska avgränsning som rapporten gäller för och det
      stickprov av slumpmässigt placerade kilometerrutor som ingår i
      inventeringen. Röda rutor ingick i den senaste inventeringen medan de grå
      rutorna visar tidigare två inventeringar. Du kan zooma in och panorera för
      att studera enskilda rutor. Bakgrundskarta © Lantmäteriet
    </ng-template>
  </ui-figure-text>
</ui-figure-caption>

import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AbinMetadataDto } from 'src/app/inge-api/client/models';
import { InGeApiState } from 'src/app/store/state-model/inge-api.model';

@Component({
    selector: 'app-rapport-select',
    templateUrl: './rapport-select.component.html',
    styleUrls: ['./rapport-select.component.scss']
})
export class RapportSelectComponent implements OnInit {
    delomraden$: Observable<AbinMetadataDto[]>;
    loadingDelomraden$: Observable<boolean>;
    afo$: Observable<AbinMetadataDto[]>;
    loadingAfo$: Observable<boolean>;
    lan$: Observable<AbinMetadataDto[]>;
    loadingLan$: Observable<boolean>;
    landsdelar$: Observable<AbinMetadataDto[]>;
    loadingLandsdelar$: Observable<boolean>;
    requestError$: Observable<boolean>;

    public reportToShow: 'resultat' | 'mal' | null;

    subs: Subscription[] = [];

    toggleDisplayMainReportDiv(rapporttyp: 'resultat' | 'mal') {
        this.reportToShow = rapporttyp;
    }

    constructor(private store: Store<InGeApiState>) {
        this.requestError$ = combineLatest([
            this.store.pipe(select((x) => x.abin.landsdelarError)),
            this.store.pipe(select((x) => x.abin.lanError)),
            this.store.pipe(select((x) => x.abin.afoError)),
            this.store.pipe(select((x) => x.abin.delomradenError))
        ]).pipe(
            map((x) => {
                return x.includes(true);
            })
        );
    }

    ngOnInit(): void {}
}

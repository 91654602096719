import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription, forkJoin, zip } from 'rxjs';
import { AbinState, InventeringsNiva, InGeApiState } from 'src/app/store/state-model/inge-api.model';
import { select, Store } from '@ngrx/store';
import { AbinResultDto } from 'src/app/inge-api/client/models';
import { UiTableConfig } from 'src/app/ui-components/ui-table/ui-table/models/tableConfig';
import { formatNumber } from '@angular/common';

@Component({
    selector: 'app-omrade-table',
    templateUrl: './omrade-table.component.html',
    styleUrls: ['./omrade-table.component.scss']
})
export class OmradeTableComponent implements OnInit, OnDestroy {
    inventeringar$: Observable<AbinResultDto[]>;
    inventeringarLoading$: Observable<boolean>;
    inventeringarError$: Observable<boolean>;
    InventeringsNiva$: Observable<InventeringsNiva>;
    tableConfig: UiTableConfig = {
        columns: [
            { title: '', dataProperty: 'titel' },
            { title: '', dataProperty: 'varde' }
        ],
        rowActions: []
    };

    tableContent = [];
    private subs: Subscription[] = [];
    constructor(private store: Store<InGeApiState>) {
        this.inventeringar$ = this.store.pipe(select((x) => x.abin.inventeringar));
        this.inventeringarLoading$ = store.pipe(select((x) => x.abin.loadingInventeringar));
        this.inventeringarError$ = store.pipe(select((x) => x.abin.inventeringarError));
        this.InventeringsNiva$ = store.pipe(select((x) => x.abin.inventeringsNiva));
    }

    ngOnInit(): void {
        this.subs.push(
            zip(this.InventeringsNiva$, this.inventeringar$).subscribe((x) => {
                if (x && x[0] && x[1]) {
                    const inventering = x[1][0];
                    if (inventering) {
                        const niva = x[0];
                        let omrade = '';
                        if (niva === InventeringsNiva.LAND) {
                            omrade = 'Hela landet';
                        }
                        if (niva === InventeringsNiva.LANDSDEL) {
                            omrade = `${inventering.landsdelNamn}`;
                        }
                        if (niva === InventeringsNiva.LAN) {
                            omrade = `Län ${inventering.lanNamn}`;
                        }
                        if (niva === InventeringsNiva.AFO) {
                            omrade = `ÄFO ${inventering.afoNamn}`;
                        }
                        if (niva === InventeringsNiva.STRATUM) {
                            omrade = `Delområde ${inventering.delomrNamn}`;
                        }
                        this.tableContent = [
                            {
                                titel: 'Antal km-rutor i stickprovet inkl. rutor som saknar ungskog',
                                varde: formatNumber(inventering.antalRutor, 'sv')
                            },
                            {
                                titel: 'Antal inventerade ungskogar',
                                varde: formatNumber(inventering.antalBestand, 'sv')
                            },
                            {
                                titel: 'Antal inventerade provytor',
                                varde: formatNumber(inventering.antalYtorIngar, 'sv')
                            },
                            {
                                titel: 'Landareal, ha',
                                varde: inventering.arealLandHa ? formatNumber(inventering.arealLandHa, 'sv') : ''
                            }
                        ];
                    }
                }
            })
        );
    }

    ngOnDestroy(): void {
        this.subs.forEach((x) => x.unsubscribe());
    }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiComponentsModule } from '../ui-components/ui-components.module';
import { BegreppForklaringarTableComponent } from './begrepp-forklaringar-table/begrepp-forklaringar-table.component';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { ExportReportModalComponent } from './export-report-modal/export-report-modal.component';
import { LitteraturforteckningComponent } from './litteraturforteckning/litteraturforteckning.component';
import { LoaderComponent } from './loader/loader.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { RapportRenderingFilterComponent } from './rapport-rendering-filter/rapport-rendering-filter.component';
import { RapportToolbarComponent } from './rapport-toolbar/rapport-toolbar.component';
import { RapportWarningComponent } from './rapport-warning/rapport-warning.component';
import { SiteHeaderComponent } from './site-header/site-header.component';
import {
  SummaryCardComponent,
  SummaryCardContentDirective,
  SummaryCardHeaderDirective,
  SummaryCardIconDirective,
} from './summary-card';

@NgModule({
  declarations: [
    LoaderComponent,
    ErrorMessageComponent,
    SiteHeaderComponent,
    ProgressBarComponent,
    RapportRenderingFilterComponent,
    SummaryCardComponent,
    SummaryCardContentDirective,
    SummaryCardHeaderDirective,
    SummaryCardIconDirective,
    BegreppForklaringarTableComponent,
    LitteraturforteckningComponent,
    RapportWarningComponent,
    RapportToolbarComponent,
    ExportReportModalComponent,
  ],
  imports: [CommonModule, UiComponentsModule],
  exports: [
    LoaderComponent,
    ErrorMessageComponent,
    SiteHeaderComponent,
    ProgressBarComponent,
    RapportRenderingFilterComponent,
    SummaryCardComponent,
    SummaryCardContentDirective,
    SummaryCardHeaderDirective,
    SummaryCardIconDirective,
    BegreppForklaringarTableComponent,
    LitteraturforteckningComponent,
    RapportWarningComponent,
    RapportToolbarComponent,
    ExportReportModalComponent,
  ],
})
export class SharedComponentsModule {}

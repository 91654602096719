import pptxgen from 'pptxgenjs';
import * as uiColors from 'src/app/ui-components/colors';
export default (
  slide: pptxgen.Slide,
  banner: { header?: string; text?: string }
) => {
  slide.addShape('rect', {
    fill: { color: uiColors.veryLightOliveGreen },
    x: 0,
    y: 0,
    h: 1.2,
    w: '100%',
  });
  if (banner.header) {
    slide.addText(banner.header, {
      fontSize: 25,
      bold: true,
      x: 1,
      y: 0,
      h: 0.59,
      w: 12.1,
      align: 'left',
    });
  }
  if (banner.text) {
    slide.addText(banner.text, {
      fontSize: 14,
      bold: false,
      x: 1,
      y: 0.49,
      h: 0.6,
      w: 12.1,
      align: 'left',
    });
  }
  return slide;
};

import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Subject } from 'rxjs';
import { within } from '../helpers';

@Injectable()
export class KartaClickService {
    private highlighted: __esri.Handle;
    private mapViewRef: __esri.MapView = {} as __esri.MapView;
    private ingaendeOmraden: __esri.Graphic[];
    private kartanClicked = new Subject<boolean[]>();

    clicked$ = this.kartanClicked.asObservable();

    public activate(mapView: __esri.MapView, ingOmraden: __esri.Graphic[]): void {
        this.mapViewRef = mapView;
        this.ingaendeOmraden = ingOmraden;
        this.mapViewRef.when(() => {
            this.addEventListener();
        });
    }

    private addEventListener(): void {
        this.mapViewRef.on('click', async (clickEv: any) => {
            const mapPoint = clickEv.mapPoint;
            const result = await Promise.all(this.ingaendeOmraden.map((x) => within(mapPoint, x.geometry)));
            this.kartanClicked.next(result);
        });
    }
}

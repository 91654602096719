<app-ui-modal [visible]="visible$ | async">
  <div class="export-report-modal">
    <div class="export-report-modal__loader-container" *ngIf="exportLoading">
      <app-loader></app-loader>
    </div>
    <button class="export-report-modal__close-icon">
      <span class="material-icons-outlined" (click)="close()"> close </span>
    </button>

    <div class="export-report-modal__content">
      <div class="row">
        <div class="col-xs-12 export-report-modal__header-column">
          <h3>Exportera rapport</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <form>
            <label>Välj format:</label>
            <select ui-select>
              <option value="pptx">Powerpoint</option>
              <!-- <option value="pdf">Pdf</option> -->
            </select>
          </form>
        </div>
      </div>
    </div>
    <div class="export-report-modal__footer">
      <div class="row">
        <div class="col-xs-12">
          <button
            [disabled]="reportStillLoading$ | async"
            class="export-report-modal__download-btn"
            (click)="exportReport()"
            [disabled]="exportLoading"
          >
            Exportera
          </button>
        </div>
        <div class="col-xs-12">
          <app-ui-toast-message
            *ngIf="reportStillLoading$ | async"
            message="Visas komponenter i rapporten laddas fortfarande, var vänlig vänta tills laddningen är färdig"
            uiToastInfo
          ></app-ui-toast-message>
        </div>
        <div class="col-xs-12">
          <app-ui-toast-message
            *ngIf="exportSuccessMessage"
            [message]="exportSuccessMessage"
            uiToastSuccess
          ></app-ui-toast-message>
          <app-ui-toast-message
            *ngIf="exportErrorMessage"
            [message]="exportErrorMessage"
            uiToastError
          ></app-ui-toast-message>
        </div>
      </div>
    </div>
  </div>
</app-ui-modal>

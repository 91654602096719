<!-- <app-ui-info-box ui-info-box-light ui-info-box-centered>
  <ui-info-box-header>Andel ytor med gynnsam konkurrensstatus för RASE, sammanvägning för de tre senaste inventeringarna
  </ui-info-box-header>
  <ui-info-box-content>
    <h3>{{(inventering$|async)?.raseAndelGynnsam3InvMedel|percent:'1.0-1':'sv'}}</h3>
  </ui-info-box-content>
</app-ui-info-box> -->

<app-summary-card>
  <app-summary-card-icon>
    <ng-container
      *ngIf="(inventering$ | async)?.slutsatsRASEGynInt > 0; else elseTemplate6"
    >
      <span class="abinicon-rase_gynnsam_status_uppfylld"></span>
    </ng-container>
    <ng-template #elseTemplate6>
      <span class="abinicon-rase_gynnsam_status_ej_uppfylld"></span>
    </ng-template>
  </app-summary-card-icon>
  <app-summary-card-header>
    {{
      (inventering$ | async)?.raseAndelGynnsam3InvMedel | percent: "1.0":"sv"
    }}
  </app-summary-card-header>
  <app-summary-card-content>
    Andel ytor med gynnsam konkurrensstatus för RASE, sammanvägning för de tre
    senaste inventeringarna
  </app-summary-card-content>
</app-summary-card>

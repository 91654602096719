import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiToggleButtonGroupComponent } from './ui-toggle-button-group/ui-toggle-button-group.component';
import { UiToggleButtonComponent } from './ui-toggle-button/ui-toggle-button.component';
import { UiButtonModule } from '../ui-button/ui-button.module';

@NgModule({
  declarations: [UiToggleButtonGroupComponent, UiToggleButtonComponent],
  imports: [CommonModule, UiButtonModule],
  exports: [UiToggleButtonComponent, UiToggleButtonGroupComponent],
})
export class UiToggleButtonsModule {}

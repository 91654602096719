import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-ui-modal',
  templateUrl: './ui-modal.component.html',
  styleUrls: ['./ui-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiModalComponent implements OnInit {
  visibleSource = new BehaviorSubject<boolean>(false);
  visible$ = this.visibleSource.asObservable();
  constructor(private ref: ChangeDetectorRef) {}

  @Input()
  set visible(val: boolean) {
    this.visibleSource.next(val);
  }

  ngOnInit(): void {}
}

<div class="row">
    <div class="col-xs-12">
        <h4 class="mt-0">Fördelning av ägoslag inom områdets landareal.</h4>
        <div>Välj område med knapparna.</div>
    </div>

    <div class="col-xs-12">
        <app-fordjupning-button-group (selectedOmradeEvent)="onOmradeSelect($event)"></app-fordjupning-button-group>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <span><b>Valt område</b>: {{ omradeNamn$ | async }}</span>
    </div>
</div>

<div class="row middle-xs center-xs">
    <ng-container *ngIf="data && data.length > 0; else elseTemplate">
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
            <ui-figure-caption>
                <ui-figure-text>
                    <h4>Fördelning av ägoslag inom områdets landareal</h4>
                </ui-figure-text>
            </ui-figure-caption>
            <app-google-pie-chart [data]="data" [options]="options"></app-google-pie-chart>
            <ui-figure-caption>
                <ui-figure-text>
                    <p>Källa: Lantmäteriet, GSD-Översiktskartan samt Äbin</p>
                </ui-figure-text>
            </ui-figure-caption>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
            <ui-legend ui-legend-vertical>
                <ui-legend-item *ngFor="let item of legendData; let i = index" [color]="item.color">
                    {{ item.name }}: {{ item.value | number }}%
                </ui-legend-item>
            </ui-legend>
        </div>
    </ng-container>
    <ng-template #elseTemplate>
        <div class="col-xs-6">Kunde inte ladda ägoslag för detta område</div>
    </ng-template>
</div>

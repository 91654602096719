<div #rapportComponent>
  <ui-figure-caption>
    <ui-figure-text>
      <h4>Utveckling av foderproducerande ungskogsareal över tid</h4>
    </ui-figure-text>
  </ui-figure-caption>
  <app-google-line-chart
    [data]="data"
    [options]="options"
  ></app-google-line-chart>
</div>

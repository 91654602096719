import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiFigureCaptionComponent } from './ui-figure-caption/ui-figure-caption.component';
import { UiFigureNumberDirective } from './directives/ui-figure-number.directive';
import { UiFigureTextDirective } from './directives/ui-figure-text.directive';
import { UiFigureTextBoldDirective } from './directives/ui-figure-text-bold.directive';
import { UiFigureTextCenteredDirective } from './directives/ui-figure-text-center.directive';
import { UiFigureSubTextDirective } from './directives/ui-figure-sub-text.directive';
@NgModule({
    declarations: [
        UiFigureCaptionComponent,
        UiFigureNumberDirective,
        UiFigureSubTextDirective,
        UiFigureTextDirective,
        UiFigureTextBoldDirective,
        UiFigureTextCenteredDirective
    ],
    imports: [CommonModule],
    exports: [
        UiFigureCaptionComponent,
        UiFigureNumberDirective,
        UiFigureSubTextDirective,
        UiFigureTextDirective,
        UiFigureTextBoldDirective,
        UiFigureTextCenteredDirective
    ]
})
export class UiFigureCaptionModule {}

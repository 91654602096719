import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: 'ui-info-box-header',
})
export class UiInfoBoxHeaderDirective {
  constructor() {}

  @HostBinding('class') elementClass = 'ui-info-box-header';
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UiTableConfig } from 'src/app/ui-components/ui-table/ui-table/models/tableConfig';

@Component({
    selector: 'app-begrepp-forklaringar-table',
    templateUrl: './begrepp-forklaringar-table.component.html',
    styleUrls: ['./begrepp-forklaringar-table.component.scss']
})
export class BegreppForklaringarTableComponent implements OnInit {
    tableConfig: UiTableConfig = {
        columns: [
            { title: 'Begrepp', dataProperty: 'title' },
            { title: 'Förklaring', dataProperty: 'value' }
        ],
        rowActions: []
    };

    tableContent = [];
    constructor(private router: Router) {}

    ngOnInit(): void {
        this.tableContent = [
            {
                title: '1 års-värde',
                value: 'Resultat från ett enstaka år.'
            },
            {
                title: 'Sammanvägning för de tre senaste inventeringarna (ibland även kallad treårsmedel)',
                value: 'Flerårsmedelvärde beräknas utifrån en sammanvägning av grunddata för de tre senaste inventeringarna. Det är inte ett enkelt medeltal av resultaten.'
            },
            {
                title: 'Felmarginal',
                value: 'I en del av figurerna i rapporten visas en felmarginal. Detta är ett uttryck för osäkerhet i datat. I rapporten är felmarginalen samma som ett 95 % konfidensintervall.'
            }
        ];
    }
}

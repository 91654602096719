<div class="row middle-xs center-xs">
    <div class="col-xs-12">
        <ui-figure-caption>
            <ui-figure-text>
                <h4>Föryngrat trädslag i ungskog på bördiga marker för de tre senaste inventeringarna</h4>
            </ui-figure-text>
            <ui-figure-sub-text> Andel bördig mark: {{ andelBordigMark }}% för de tre senaste inventeringarna </ui-figure-sub-text>
        </ui-figure-caption>
    </div>
    <ng-container *ngIf="data && data.length; else messageTemplate">
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
            <app-google-pie-chart [data]="data" [options]="options"></app-google-pie-chart>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
            <ui-legend ui-legend-vertical>
                <ui-legend-item *ngFor="let item of legendData; let i = index" [color]="item.color">
                    {{ item.name }}: {{ item.value | number }}%
                </ui-legend-item>
            </ui-legend>
        </div>
    </ng-container>

    <ng-template #messageTemplate>
        <app-ui-toast-message uiToastInfo [message]="'Diagram visas ej då ingen bördig mark registrerades under ' + inventeringsAr"></app-ui-toast-message>
    </ng-template>
</div>

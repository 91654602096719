import { Component, OnInit, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { InGeApiState } from 'src/app/store/state-model/inge-api.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-rapport-rendering-filter',
  templateUrl: './rapport-rendering-filter.component.html',
  styleUrls: ['./rapport-rendering-filter.component.scss'],
})
export class RapportRenderingFilterComponent implements OnInit {
  loadingInventeringar$: Observable<boolean>;
  inventeringarError$: Observable<boolean>;
  constructor(private store: Store<InGeApiState>) {
    this.loadingInventeringar$ = this.store.pipe(
      select((x) => x.abin.loadingInventeringar)
    );
    this.inventeringarError$ = this.store.pipe(
      select((x) => x.abin.inventeringarError)
    );
  }

  @Input() displayError = true;
  @Input() displayLoader = true;

  ngOnInit(): void {}
}

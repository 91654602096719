<div class="row">
  <div class="col-xs-12">
    <footer>
      {{ inventeringsNamn$ | async }}
      &nbsp; | &nbsp;
      <a
        [routerLink]="[]"
        queryParamsHandling="merge"
        fragment="rapport-toc-section"
        >Gå till toppen av sidan
      </a>
      &nbsp; | &nbsp;
      <button class="rapport-footer__button" (click)="exportReport()">
        <span class="material-icons-outlined"> file_download </span>Ladda ned
        rapport
      </button>
    </footer>
  </div>
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiButtonModule } from './ui-button/ui-button.module';
import { UiFigureCaptionModule } from './ui-figure-caption/ui-figure-caption.module';
import { UiImageModule } from './ui-image/ui-image.module';
import { UiInfoBoxModule } from './ui-info-box/ui-info-box.module';
import { UiLegendModule } from './ui-legend/ui-legend.module';
import { UiListModule } from './ui-list/ui-list.module';
import { UiModalModule } from './ui-modal/ui-modal.module';
import { UiSectionHeaderModule } from './ui-section-header/ui-section-header.module';
import { UiSelectModule } from './ui-select/ui-select.module';
import { UiTableModule } from './ui-table/ui-table.module';
import { UiToastMessageModule } from './ui-toast-message/ui-toast-message.module';
import { UiToggleButtonsModule } from './ui-toggle-buttons/ui-toggle-buttons.module';
import { UiErrorMarginModule } from './ui-error-margin/ui-error-margin.module';
@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        UiErrorMarginModule,
        UiInfoBoxModule,
        UiToggleButtonsModule,
        UiTableModule,
        UiButtonModule,
        UiListModule,
        UiImageModule,
        UiSelectModule,
        UiSectionHeaderModule,
        UiLegendModule,
        UiFigureCaptionModule,
        UiToastMessageModule,
        UiModalModule
    ],
    exports: [
        UiInfoBoxModule,
        UiErrorMarginModule,
        UiToggleButtonsModule,
        UiTableModule,
        UiButtonModule,
        UiListModule,
        UiImageModule,
        UiSelectModule,
        UiSectionHeaderModule,
        UiLegendModule,
        UiFigureCaptionModule,
        UiToastMessageModule,
        UiModalModule
    ]
})
export class UiComponentsModule {}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ChartCategory } from 'src/app/common/service';
import { AbinResultDto } from 'src/app/inge-api/client/models';
import { FordelningPiechartBaseComponent } from '../../base-components';

@Component({
    selector: 'app-andel-markslag-chart',
    templateUrl: './andel-markslag-chart.component.html',
    styleUrls: ['./andel-markslag-chart.component.scss']
})
export class AndelMarkslagChartComponent extends FordelningPiechartBaseComponent implements OnInit, OnDestroy {
    private subs: Subscription[];
    data: any[][];
    legendData: any[];

    ngOnInit(): void {
        this.subs = [];
        this.subs.push(
            this.inventering$.subscribe((senasteInventering) => {
                if (senasteInventering) {
                    this.generateChartData(senasteInventering);
                }
            })
        );
    }

    generateChartData(senasteInventering: AbinResultDto) {
        const data: any[][] = [['Markslag', 'Procent']];
        const colors = [];
        const legendData = [];
        if (senasteInventering.andelMagraMarker3InvMedel) {
            const name = 'Magra marker';
            const value = Math.round(senasteInventering.andelMagraMarker3InvMedel * 100);
            const color = this.colormapService.getColor(ChartCategory.MAGRAMARKER);
            data.push([name, value]);
            colors.push(color);
            legendData.push({ name, value, color });
        }
        if (senasteInventering.andelMellanMarker3InvMedel) {
            const name = 'Mellanmarker';
            const value = Math.round(senasteInventering.andelMellanMarker3InvMedel * 100);
            const color = this.colormapService.getColor(ChartCategory.MELLANMARKER);
            data.push([name, value]);
            colors.push(color);
            legendData.push({ name, value, color });
        }
        if (senasteInventering.andelBordigaMarker3InvMedel) {
            const name = 'Bördiga marker';
            const value = Math.round(senasteInventering.andelBordigaMarker3InvMedel * 100);
            const color = this.colormapService.getColor(ChartCategory.BORDIGAMARKER);
            data.push([name, value]);
            colors.push(color);
            legendData.push({ name, value, color });
        }

        this.data = data;
        this.options = { ...this.options, colors };
        this.legendData = legendData;
    }

    ngOnDestroy(): void {
        this.subs.forEach((x) => x.unsubscribe());
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from '../app-routing.module';
import { GoogleChartsBaseModule } from '../google-charts/google-charts-base-components/google-charts-base.module';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { UiComponentsModule } from '../ui-components/ui-components.module';
import { UppfoljRapportComponent } from './uppfolj-rapport.component';
import { SharedReportComponentsModule } from '../shared-report-components/shared-report-components.module';

@NgModule({
    imports: [
        CommonModule,
        UiComponentsModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        SharedComponentsModule,
        GoogleChartsBaseModule,
        SharedReportComponentsModule
    ],
    exports: [UppfoljRapportComponent],
    declarations: [UppfoljRapportComponent],
    providers: []
})
export class AbinUppfoljRapportModule {}

import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core';
import {
  UiTableConfig,
  UiTableRowAction,
  UiTableAction,
} from './models/tableConfig';

@Component({
  selector: 'app-ui-table',
  templateUrl: './ui-table.component.html',
  styleUrls: ['./ui-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiTableComponent implements OnInit {
  _tableContent: any[];
  _tableConfig: UiTableConfig;
  @Input()
  public set tableConfig(value: UiTableConfig) {
    if (value) {
      this._tableConfig = {
        columns: value.columns.map((x) => ({ ...x })),
        rowActions: value.rowActions.map((x) => ({ ...x })),
      };
    }
  }

  @Input()
  public caption: string;

  @Input()
  public set tableContent(value: any[]) {
    this._tableContent = value.map((x) => ({ ...x }));
  }

  @Input()
  public selectable: boolean;

  @Output()
  public rowClicked = new EventEmitter<UiTableAction>();

  constructor() {}

  ngOnInit(): void {}

  public onRowActionClicked(actionType: string, rowData: any): void {
    const userAction: UiTableAction = {
      actionId: actionType,
      rowData,
    };

    this.rowClicked.emit(userAction);
  }
}

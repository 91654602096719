import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiImageComponent } from './ui-image.component';
import { UiImageBorderedDirective } from './image-bordered.directive';

@NgModule({
  declarations: [UiImageComponent, UiImageBorderedDirective],
  imports: [CommonModule],
  exports: [UiImageComponent, UiImageBorderedDirective],
})
export class UiImageModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiToastMessageComponent } from './ui-toast-message/ui-toast-message.component';
import { UiToastWarningDirective } from './directives/ui-toast-warning.directive';
import { UiToastErrorDirective } from './directives/ui-toast-error.directive';
import { UiToastSuccessDirective } from './directives/ui-toast-success.directive';
import { UiToastInfoDirective } from './directives/ui-toast-info.directive';

@NgModule({
  declarations: [
    UiToastMessageComponent,
    UiToastWarningDirective,
    UiToastErrorDirective,
    UiToastSuccessDirective,
    UiToastInfoDirective,
  ],
  imports: [CommonModule],
  exports: [
    UiToastMessageComponent,
    UiToastWarningDirective,
    UiToastErrorDirective,
    UiToastSuccessDirective,
    UiToastInfoDirective,
  ],
})
export class UiToastMessageModule {}

<form [formGroup]="selectForm" aria-labelledby="selectRegionHeader">
    <div class="row">
        <div class="col-xs-12">
            <h3>Resultatrapport</h3>
            <p class="p-bold" id="selectRegionHeader">
                Välj önskad geografisk avgränsning med menyerna nedan och tryck sedan på Visa Resultatrapport för att ladda rapporten.
            </p>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="label-container">
                        <label for="landsdel-select">Landsdel</label>
                        <app-progress-bar *ngIf="loadingLandsdelar$ | async"></app-progress-bar>
                    </div>
                    <select ui-select formControlName="landsdel" [compareWith]="compareFn" id="landsdel-select">
                        <option [ngValue]="{ namn: 'Alla', kod: 'alla' }">Alla</option>
                        <option *ngFor="let landsdel of landsdelar$ | async" [ngValue]="landsdel">
                            {{ landsdel.namn }}
                        </option>
                    </select>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="label-container">
                        <label for="lan-select">Län</label>
                        <app-progress-bar *ngIf="loadingLan$ | async"></app-progress-bar>
                    </div>
                    <select ui-select formControlName="lan" [compareWith]="compareFn" id="lan-select">
                        <option [ngValue]="{ namn: 'Alla', kod: 'alla' }">Alla</option>
                        <option *ngFor="let lan of lan$ | async" [ngValue]="lan">
                            {{ lan.namn }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="label-container">
                        <label for="afo-select">Älgförvaltningsområde</label>
                        <app-progress-bar *ngIf="loadingAfo$ | async"></app-progress-bar>
                    </div>
                    <select ui-select formControlName="afo" [compareWith]="compareFn" id="afo-select">
                        <!-- Specialhantering för GOTLAND (09) som bara har ett ÄFO, ska inte visa något alternativ för alla -->
                        <option *ngIf="selectForm?.get('lan')?.value?.kod !== '09'" [ngValue]="{ namn: 'Alla', kod: 'alla' }">Alla</option>
                        <option *ngFor="let afo of afo$ | async" [ngValue]="afo">
                            {{ afo.namn }}
                        </option>
                    </select>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="label-container">
                        <label for="delomrade-select">Delområde</label>
                        <app-progress-bar *ngIf="loadingDelomraden$ | async"></app-progress-bar>
                    </div>
                    <select ui-select formControlName="delomrade" [compareWith]="compareFn" id="delomrade-select">
                        <option [ngValue]="{ namn: 'Alla', kod: 'alla' }">Alla</option>
                        <option *ngFor="let delomrade of delomraden$ | async" [ngValue]="delomrade">
                            {{ delomrade.namn }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>
    <br />
    <br />
    <div class="row">
        <div class="col-xs-12">
            <button ui-button ui-button-outlined ui-button-round (click)="laddaRapport($event)">Visa Resultatrapport</button>
        </div>
    </div>
</form>

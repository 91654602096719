export const disableNavigation = (view: __esri.MapView) => {
  view.on('key-down', (event) => {
    const prohibitedKeys = ['+', '-', 'Shift', '_', '='];
    const keyPressed = event.key;
    if (prohibitedKeys.indexOf(keyPressed) !== -1) {
      event.stopPropagation();
    }
  });
  view.on('double-click', (event) => {
    event.stopPropagation();
  });

  view.on('double-click', ['Control'], (event) => {
    event.stopPropagation();
  });
  view.on('drag', (event) => {
    event.stopPropagation();
  });
  view.on('drag', ['Shift'], (event) => {
    event.stopPropagation();
  });

  view.on('drag', ['Shift', 'Control'], (event) => {
    event.stopPropagation();
  });
};

export const zoomToGraphics = () => {};

/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { INGEApiBaseService } from '../inge-api-base-service';
import { INGEApiConfiguration } from '../inge-api-configuration';
import { INGEAPIResponse } from '../ingeapi-response';
import { INGEApiRequestBuilder } from '../inge-api-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AbinGeometriDto } from '../models/abin-geometri-dto';
import { AbinMetadataDto } from '../models/abin-metadata-dto';
import { AbinResultDto } from '../models/abin-result-dto';

@Injectable({
  providedIn: 'root',
})
export class AbinService extends INGEApiBaseService {
  constructor(
    config: INGEApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation abinSammanfattningHelaLandet
   */
  static readonly AbinSammanfattningHelaLandetPath = '/v1/Abin/HelaLandet';

  /**
   * Inventeringsresultatet för Skoglig betesinventering för hela landet.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `abinSammanfattningHelaLandet()` instead.
   *
   * This method doesn't expect any request body.
   */
  abinSammanfattningHelaLandet$Response(params?: {
  }): Observable<INGEAPIResponse<Array<AbinResultDto>>> {

    const rb = new INGEApiRequestBuilder(this.rootUrl, AbinService.AbinSammanfattningHelaLandetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as INGEAPIResponse<Array<AbinResultDto>>;
      })
    );
  }

  /**
   * Inventeringsresultatet för Skoglig betesinventering för hela landet.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `abinSammanfattningHelaLandet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  abinSammanfattningHelaLandet(params?: {
  }): Observable<Array<AbinResultDto>> {

    return this.abinSammanfattningHelaLandet$Response(params).pipe(
      map((r: INGEAPIResponse<Array<AbinResultDto>>) => r.body as Array<AbinResultDto>)
    );
  }

  /**
   * Path part for operation abinSammanfattningLandsel
   */
  static readonly AbinSammanfattningLandselPath = '/v1/Abin/Landsdel/{landsdelkod}';

  /**
   * Inventeringsresultatet för Skoglig betesinventering för angiven landsdel.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `abinSammanfattningLandsel()` instead.
   *
   * This method doesn't expect any request body.
   */
  abinSammanfattningLandsel$Response(params: {

    /**
     * Landsdelskod - För att se tillgängliga landsdelskoder se /Abin/Landsdel/Metadata
     */
    landsdelkod: number;
  }): Observable<INGEAPIResponse<Array<AbinResultDto>>> {

    const rb = new INGEApiRequestBuilder(this.rootUrl, AbinService.AbinSammanfattningLandselPath, 'get');
    if (params) {
      rb.path('landsdelkod', params.landsdelkod, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as INGEAPIResponse<Array<AbinResultDto>>;
      })
    );
  }

  /**
   * Inventeringsresultatet för Skoglig betesinventering för angiven landsdel.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `abinSammanfattningLandsel$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  abinSammanfattningLandsel(params: {

    /**
     * Landsdelskod - För att se tillgängliga landsdelskoder se /Abin/Landsdel/Metadata
     */
    landsdelkod: number;
  }): Observable<Array<AbinResultDto>> {

    return this.abinSammanfattningLandsel$Response(params).pipe(
      map((r: INGEAPIResponse<Array<AbinResultDto>>) => r.body as Array<AbinResultDto>)
    );
  }

  /**
   * Path part for operation abinSammanfattningLan
   */
  static readonly AbinSammanfattningLanPath = '/v1/Abin/Lan/{lankod}';

  /**
   * Inventeringsresultatet för Skoglig betesinventering för angivet län.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `abinSammanfattningLan()` instead.
   *
   * This method doesn't expect any request body.
   */
  abinSammanfattningLan$Response(params: {

    /**
     * Länkod - För att se tillgängliga länkoder se /Abin/Landsdel/{landsdelkod}/Lan/Metadata
     */
    lankod: string;
  }): Observable<INGEAPIResponse<Array<AbinResultDto>>> {

    const rb = new INGEApiRequestBuilder(this.rootUrl, AbinService.AbinSammanfattningLanPath, 'get');
    if (params) {
      rb.path('lankod', params.lankod, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as INGEAPIResponse<Array<AbinResultDto>>;
      })
    );
  }

  /**
   * Inventeringsresultatet för Skoglig betesinventering för angivet län.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `abinSammanfattningLan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  abinSammanfattningLan(params: {

    /**
     * Länkod - För att se tillgängliga länkoder se /Abin/Landsdel/{landsdelkod}/Lan/Metadata
     */
    lankod: string;
  }): Observable<Array<AbinResultDto>> {

    return this.abinSammanfattningLan$Response(params).pipe(
      map((r: INGEAPIResponse<Array<AbinResultDto>>) => r.body as Array<AbinResultDto>)
    );
  }

  /**
   * Path part for operation abinSammanfattningLanAfo
   */
  static readonly AbinSammanfattningLanAfoPath = '/v1/Abin/Lan/{lankod}/Afo/{afonr}';

  /**
   * Inventeringsresultatet för Skoglig betesinventering för angivet älgförvaltningsområde.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `abinSammanfattningLanAfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  abinSammanfattningLanAfo$Response(params: {

    /**
     * Länkod - För att se tillgängliga länkoder se /Abin/Landsdel/{landsdelkod}/Lan/Metadata
     */
    lankod: string;

    /**
     * Äfonummer - För att se tillgänglia äfonummer se /Abin/Landsdel/{landsdelkod}/Lan/{lankod}/Afo/Metadata
     */
    afonr: number;
  }): Observable<INGEAPIResponse<Array<AbinResultDto>>> {

    const rb = new INGEApiRequestBuilder(this.rootUrl, AbinService.AbinSammanfattningLanAfoPath, 'get');
    if (params) {
      rb.path('lankod', params.lankod, {});
      rb.path('afonr', params.afonr, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as INGEAPIResponse<Array<AbinResultDto>>;
      })
    );
  }

  /**
   * Inventeringsresultatet för Skoglig betesinventering för angivet älgförvaltningsområde.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `abinSammanfattningLanAfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  abinSammanfattningLanAfo(params: {

    /**
     * Länkod - För att se tillgängliga länkoder se /Abin/Landsdel/{landsdelkod}/Lan/Metadata
     */
    lankod: string;

    /**
     * Äfonummer - För att se tillgänglia äfonummer se /Abin/Landsdel/{landsdelkod}/Lan/{lankod}/Afo/Metadata
     */
    afonr: number;
  }): Observable<Array<AbinResultDto>> {

    return this.abinSammanfattningLanAfo$Response(params).pipe(
      map((r: INGEAPIResponse<Array<AbinResultDto>>) => r.body as Array<AbinResultDto>)
    );
  }

  /**
   * Path part for operation abinSammanfattningStratum
   */
  static readonly AbinSammanfattningStratumPath = '/v1/Abin/Lan/{lankod}/Afo/{afonr}/Stratum/{delomradesnummer}';

  /**
   * Inventeringsresultatet för Skoglig betesinventering för angivet delområde/stratum.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `abinSammanfattningStratum()` instead.
   *
   * This method doesn't expect any request body.
   */
  abinSammanfattningStratum$Response(params: {

    /**
     * Länkod - För att se tillgängliga länkoder se /Abin/Landsdel/{landsdelkod}/Lan/Metadata
     */
    lankod: string;

    /**
     * Äfonummer - För att se tillgänglia äfonummer se /Abin/Landsdel/{landsdelkod}/Lan/{lankod}/Afo/Metadata
     */
    afonr: number;

    /**
     * Delområdesnummer - För att se tillgänliga delområdesnummer se /Abin/Landsdel/{landsdelkod}/Lan/{lankod}/Afo/{afonr}/Stratum/Metadata
     */
    delomradesnummer: number;
  }): Observable<INGEAPIResponse<Array<AbinResultDto>>> {

    const rb = new INGEApiRequestBuilder(this.rootUrl, AbinService.AbinSammanfattningStratumPath, 'get');
    if (params) {
      rb.path('lankod', params.lankod, {});
      rb.path('afonr', params.afonr, {});
      rb.path('delomradesnummer', params.delomradesnummer, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as INGEAPIResponse<Array<AbinResultDto>>;
      })
    );
  }

  /**
   * Inventeringsresultatet för Skoglig betesinventering för angivet delområde/stratum.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `abinSammanfattningStratum$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  abinSammanfattningStratum(params: {

    /**
     * Länkod - För att se tillgängliga länkoder se /Abin/Landsdel/{landsdelkod}/Lan/Metadata
     */
    lankod: string;

    /**
     * Äfonummer - För att se tillgänglia äfonummer se /Abin/Landsdel/{landsdelkod}/Lan/{lankod}/Afo/Metadata
     */
    afonr: number;

    /**
     * Delområdesnummer - För att se tillgänliga delområdesnummer se /Abin/Landsdel/{landsdelkod}/Lan/{lankod}/Afo/{afonr}/Stratum/Metadata
     */
    delomradesnummer: number;
  }): Observable<Array<AbinResultDto>> {

    return this.abinSammanfattningStratum$Response(params).pipe(
      map((r: INGEAPIResponse<Array<AbinResultDto>>) => r.body as Array<AbinResultDto>)
    );
  }

  /**
   * Path part for operation abinSammanfattningHelaLandetGeometri
   */
  static readonly AbinSammanfattningHelaLandetGeometriPath = '/v1/Abin/HelaLandet/Geometri';

  /**
   * Hämtar stratumytor/delområdesytor för hela landet.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `abinSammanfattningHelaLandetGeometri()` instead.
   *
   * This method doesn't expect any request body.
   */
  abinSammanfattningHelaLandetGeometri$Response(params?: {
  }): Observable<INGEAPIResponse<AbinGeometriDto>> {

    const rb = new INGEApiRequestBuilder(this.rootUrl, AbinService.AbinSammanfattningHelaLandetGeometriPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as INGEAPIResponse<AbinGeometriDto>;
      })
    );
  }

  /**
   * Hämtar stratumytor/delområdesytor för hela landet.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `abinSammanfattningHelaLandetGeometri$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  abinSammanfattningHelaLandetGeometri(params?: {
  }): Observable<AbinGeometriDto> {

    return this.abinSammanfattningHelaLandetGeometri$Response(params).pipe(
      map((r: INGEAPIResponse<AbinGeometriDto>) => r.body as AbinGeometriDto)
    );
  }

  /**
   * Path part for operation abinSammanfattningLandsdelGeometri
   */
  static readonly AbinSammanfattningLandsdelGeometriPath = '/v1/Abin/Landsdel/{landsdelkod}/Geometri';

  /**
   * Hämtar stratumytor/delområdesytor inom en landsdel.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `abinSammanfattningLandsdelGeometri()` instead.
   *
   * This method doesn't expect any request body.
   */
  abinSammanfattningLandsdelGeometri$Response(params: {

    /**
     * Landsdelskod - För att se tillgängliga landsdelskoder se /Abin/Landsdel/Metadata
     */
    landsdelkod: number;
  }): Observable<INGEAPIResponse<AbinGeometriDto>> {

    const rb = new INGEApiRequestBuilder(this.rootUrl, AbinService.AbinSammanfattningLandsdelGeometriPath, 'get');
    if (params) {
      rb.path('landsdelkod', params.landsdelkod, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as INGEAPIResponse<AbinGeometriDto>;
      })
    );
  }

  /**
   * Hämtar stratumytor/delområdesytor inom en landsdel.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `abinSammanfattningLandsdelGeometri$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  abinSammanfattningLandsdelGeometri(params: {

    /**
     * Landsdelskod - För att se tillgängliga landsdelskoder se /Abin/Landsdel/Metadata
     */
    landsdelkod: number;
  }): Observable<AbinGeometriDto> {

    return this.abinSammanfattningLandsdelGeometri$Response(params).pipe(
      map((r: INGEAPIResponse<AbinGeometriDto>) => r.body as AbinGeometriDto)
    );
  }

  /**
   * Path part for operation abinSammanfattningLanGeometri
   */
  static readonly AbinSammanfattningLanGeometriPath = '/v1/Abin/Lan/{lankod}/Geometri';

  /**
   * Hämtar stratumytor/delområdesytor och inventeringsrutor inom ett län.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `abinSammanfattningLanGeometri()` instead.
   *
   * This method doesn't expect any request body.
   */
  abinSammanfattningLanGeometri$Response(params: {

    /**
     * Länkod - För att se tillgängliga länkoder se /Abin/Landsdel/{landsdelkod}/Lan/Metadata
     */
    lankod: string;
  }): Observable<INGEAPIResponse<AbinGeometriDto>> {

    const rb = new INGEApiRequestBuilder(this.rootUrl, AbinService.AbinSammanfattningLanGeometriPath, 'get');
    if (params) {
      rb.path('lankod', params.lankod, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as INGEAPIResponse<AbinGeometriDto>;
      })
    );
  }

  /**
   * Hämtar stratumytor/delområdesytor och inventeringsrutor inom ett län.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `abinSammanfattningLanGeometri$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  abinSammanfattningLanGeometri(params: {

    /**
     * Länkod - För att se tillgängliga länkoder se /Abin/Landsdel/{landsdelkod}/Lan/Metadata
     */
    lankod: string;
  }): Observable<AbinGeometriDto> {

    return this.abinSammanfattningLanGeometri$Response(params).pipe(
      map((r: INGEAPIResponse<AbinGeometriDto>) => r.body as AbinGeometriDto)
    );
  }

  /**
   * Path part for operation abinSammanfattningAfoGeometri
   */
  static readonly AbinSammanfattningAfoGeometriPath = '/v1/Abin/Lan/{lankod}/Afo/{afonr}/Geometri';

  /**
   * Hämtar stratumytor/delområdesytor och inventeringsrutor inom ett älgförvaltningsområde.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `abinSammanfattningAfoGeometri()` instead.
   *
   * This method doesn't expect any request body.
   */
  abinSammanfattningAfoGeometri$Response(params: {

    /**
     * Länkod - För att se tillgängliga länkoder se /Abin/Landsdel/{landsdelkod}/Lan/Metadata
     */
    lankod: string;

    /**
     * Äfonummer - För att se tillgänglia äfonummer se /Abin/Landsdel/{landsdelkod}/Lan/{lankod}/Afo/Metadata
     */
    afonr: number;
  }): Observable<INGEAPIResponse<AbinGeometriDto>> {

    const rb = new INGEApiRequestBuilder(this.rootUrl, AbinService.AbinSammanfattningAfoGeometriPath, 'get');
    if (params) {
      rb.path('lankod', params.lankod, {});
      rb.path('afonr', params.afonr, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as INGEAPIResponse<AbinGeometriDto>;
      })
    );
  }

  /**
   * Hämtar stratumytor/delområdesytor och inventeringsrutor inom ett älgförvaltningsområde.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `abinSammanfattningAfoGeometri$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  abinSammanfattningAfoGeometri(params: {

    /**
     * Länkod - För att se tillgängliga länkoder se /Abin/Landsdel/{landsdelkod}/Lan/Metadata
     */
    lankod: string;

    /**
     * Äfonummer - För att se tillgänglia äfonummer se /Abin/Landsdel/{landsdelkod}/Lan/{lankod}/Afo/Metadata
     */
    afonr: number;
  }): Observable<AbinGeometriDto> {

    return this.abinSammanfattningAfoGeometri$Response(params).pipe(
      map((r: INGEAPIResponse<AbinGeometriDto>) => r.body as AbinGeometriDto)
    );
  }

  /**
   * Path part for operation abinSammanfattningStratumGeometri
   */
  static readonly AbinSammanfattningStratumGeometriPath = '/v1/Abin/Lan/{lankod}/Afo/{afonr}/Stratum/{delomradesnummer}/Geometri';

  /**
   * Hämtar stratumytor/delområdesytor och inventeringsrutor inom ett älgförvaltningsområde.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `abinSammanfattningStratumGeometri()` instead.
   *
   * This method doesn't expect any request body.
   */
  abinSammanfattningStratumGeometri$Response(params: {

    /**
     * Länkod - För att se tillgängliga länkoder se /Abin/Landsdel/{landsdelkod}/Lan/Metadata
     */
    lankod: string;

    /**
     * Äfonummer - För att se tillgänglia äfonummer se /Abin/Landsdel/{landsdelkod}/Lan/{lankod}/Afo/Metadata
     */
    afonr: number;

    /**
     * Delområdesnummer - För att se tillgänliga delområdesnummer se /Abin/Landsdel/{landsdelkod}/Lan/{lankod}/Afo/{afonr}/Stratum/Metadata
     */
    delomradesnummer: number;
  }): Observable<INGEAPIResponse<AbinGeometriDto>> {

    const rb = new INGEApiRequestBuilder(this.rootUrl, AbinService.AbinSammanfattningStratumGeometriPath, 'get');
    if (params) {
      rb.path('lankod', params.lankod, {});
      rb.path('afonr', params.afonr, {});
      rb.path('delomradesnummer', params.delomradesnummer, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as INGEAPIResponse<AbinGeometriDto>;
      })
    );
  }

  /**
   * Hämtar stratumytor/delområdesytor och inventeringsrutor inom ett älgförvaltningsområde.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `abinSammanfattningStratumGeometri$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  abinSammanfattningStratumGeometri(params: {

    /**
     * Länkod - För att se tillgängliga länkoder se /Abin/Landsdel/{landsdelkod}/Lan/Metadata
     */
    lankod: string;

    /**
     * Äfonummer - För att se tillgänglia äfonummer se /Abin/Landsdel/{landsdelkod}/Lan/{lankod}/Afo/Metadata
     */
    afonr: number;

    /**
     * Delområdesnummer - För att se tillgänliga delområdesnummer se /Abin/Landsdel/{landsdelkod}/Lan/{lankod}/Afo/{afonr}/Stratum/Metadata
     */
    delomradesnummer: number;
  }): Observable<AbinGeometriDto> {

    return this.abinSammanfattningStratumGeometri$Response(params).pipe(
      map((r: INGEAPIResponse<AbinGeometriDto>) => r.body as AbinGeometriDto)
    );
  }

  /**
   * Path part for operation abinGetMetadataLandsdelar
   */
  static readonly AbinGetMetadataLandsdelarPath = '/v1/Abin/Landsdel/Metadata';

  /**
   * Hämtar vilka landsdelar som finns samt landsdelkod.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `abinGetMetadataLandsdelar()` instead.
   *
   * This method doesn't expect any request body.
   */
  abinGetMetadataLandsdelar$Response(params?: {
  }): Observable<INGEAPIResponse<Array<AbinMetadataDto>>> {

    const rb = new INGEApiRequestBuilder(this.rootUrl, AbinService.AbinGetMetadataLandsdelarPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as INGEAPIResponse<Array<AbinMetadataDto>>;
      })
    );
  }

  /**
   * Hämtar vilka landsdelar som finns samt landsdelkod.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `abinGetMetadataLandsdelar$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  abinGetMetadataLandsdelar(params?: {
  }): Observable<Array<AbinMetadataDto>> {

    return this.abinGetMetadataLandsdelar$Response(params).pipe(
      map((r: INGEAPIResponse<Array<AbinMetadataDto>>) => r.body as Array<AbinMetadataDto>)
    );
  }

  /**
   * Path part for operation abinGetMetadataLan
   */
  static readonly AbinGetMetadataLanPath = '/v1/Abin/Landsdel/{landsdelkod}/Lan/Metadata';

  /**
   * Hämtar vilka län som finns inom en landsdel.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `abinGetMetadataLan()` instead.
   *
   * This method doesn't expect any request body.
   */
  abinGetMetadataLan$Response(params: {

    /**
     * Landsdelskod - För att se tillgängliga landsdelskoder se /Abin/Landsdel/Metadata
     */
    landsdelkod: number;
  }): Observable<INGEAPIResponse<Array<AbinMetadataDto>>> {

    const rb = new INGEApiRequestBuilder(this.rootUrl, AbinService.AbinGetMetadataLanPath, 'get');
    if (params) {
      rb.path('landsdelkod', params.landsdelkod, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as INGEAPIResponse<Array<AbinMetadataDto>>;
      })
    );
  }

  /**
   * Hämtar vilka län som finns inom en landsdel.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `abinGetMetadataLan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  abinGetMetadataLan(params: {

    /**
     * Landsdelskod - För att se tillgängliga landsdelskoder se /Abin/Landsdel/Metadata
     */
    landsdelkod: number;
  }): Observable<Array<AbinMetadataDto>> {

    return this.abinGetMetadataLan$Response(params).pipe(
      map((r: INGEAPIResponse<Array<AbinMetadataDto>>) => r.body as Array<AbinMetadataDto>)
    );
  }

  /**
   * Path part for operation abinGetMetadataAfo
   */
  static readonly AbinGetMetadataAfoPath = '/v1/Abin/Landsdel/{landsdelkod}/Lan/{lankod}/Afo/Metadata';

  /**
   * Hämtar vilka älgförvaltningar som finns inom en län.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `abinGetMetadataAfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  abinGetMetadataAfo$Response(params: {

    /**
     * Landsdelskod - För att se tillgängliga landsdelskoder se /Abin/Landsdel/Metadata
     */
    landsdelkod: number;

    /**
     * Länkod - För att se tillgängliga länkoder se /Abin/Landsdel/{landsdelkod}/Lan/Metadata
     */
    lankod: string;
  }): Observable<INGEAPIResponse<Array<AbinMetadataDto>>> {

    const rb = new INGEApiRequestBuilder(this.rootUrl, AbinService.AbinGetMetadataAfoPath, 'get');
    if (params) {
      rb.path('landsdelkod', params.landsdelkod, {});
      rb.path('lankod', params.lankod, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as INGEAPIResponse<Array<AbinMetadataDto>>;
      })
    );
  }

  /**
   * Hämtar vilka älgförvaltningar som finns inom en län.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `abinGetMetadataAfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  abinGetMetadataAfo(params: {

    /**
     * Landsdelskod - För att se tillgängliga landsdelskoder se /Abin/Landsdel/Metadata
     */
    landsdelkod: number;

    /**
     * Länkod - För att se tillgängliga länkoder se /Abin/Landsdel/{landsdelkod}/Lan/Metadata
     */
    lankod: string;
  }): Observable<Array<AbinMetadataDto>> {

    return this.abinGetMetadataAfo$Response(params).pipe(
      map((r: INGEAPIResponse<Array<AbinMetadataDto>>) => r.body as Array<AbinMetadataDto>)
    );
  }

  /**
   * Path part for operation abinGetMetadataStratum
   */
  static readonly AbinGetMetadataStratumPath = '/v1/Abin/Landsdel/{landsdelkod}/Lan/{lankod}/Afo/{afonr}/Stratum/Metadata';

  /**
   * Hämtar vilka stratum/delområden som finns inom ett älgförvaltningsområde.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `abinGetMetadataStratum()` instead.
   *
   * This method doesn't expect any request body.
   */
  abinGetMetadataStratum$Response(params: {

    /**
     * Landsdelskod - För att se tillgängliga landsdelskoder se /Abin/Landsdel/Metadata
     */
    landsdelkod: number;

    /**
     * Länkod - För att se tillgängliga länkoder se /Abin/Landsdel/{landsdelkod}/Lan/Metadata
     */
    lankod: string;

    /**
     * Äfonummer - För att se tillgänglia äfonummer se /Abin/Landsdel/{landsdelkod}/Lan/{lankod}/Afo/Metadata
     */
    afonr: number;
  }): Observable<INGEAPIResponse<Array<AbinMetadataDto>>> {

    const rb = new INGEApiRequestBuilder(this.rootUrl, AbinService.AbinGetMetadataStratumPath, 'get');
    if (params) {
      rb.path('landsdelkod', params.landsdelkod, {});
      rb.path('lankod', params.lankod, {});
      rb.path('afonr', params.afonr, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as INGEAPIResponse<Array<AbinMetadataDto>>;
      })
    );
  }

  /**
   * Hämtar vilka stratum/delområden som finns inom ett älgförvaltningsområde.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `abinGetMetadataStratum$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  abinGetMetadataStratum(params: {

    /**
     * Landsdelskod - För att se tillgängliga landsdelskoder se /Abin/Landsdel/Metadata
     */
    landsdelkod: number;

    /**
     * Länkod - För att se tillgängliga länkoder se /Abin/Landsdel/{landsdelkod}/Lan/Metadata
     */
    lankod: string;

    /**
     * Äfonummer - För att se tillgänglia äfonummer se /Abin/Landsdel/{landsdelkod}/Lan/{lankod}/Afo/Metadata
     */
    afonr: number;
  }): Observable<Array<AbinMetadataDto>> {

    return this.abinGetMetadataStratum$Response(params).pipe(
      map((r: INGEAPIResponse<Array<AbinMetadataDto>>) => r.body as Array<AbinMetadataDto>)
    );
  }

}

import { select, Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, pipe, map, withLatestFrom } from 'rxjs';
import { AbinResultDto } from 'src/app/inge-api/client/models';
import { ButtonGroupItemData } from '../../base-components/fordjupning-button-group/button-group-item.model';
import { InGeApiState } from 'src/app/store/state-model/inge-api.model';

@Component({
    selector: 'app-arsskador-tall-overview-section',
    templateUrl: './arsskador-tall-overview-section.component.html',
    styleUrls: ['./arsskador-tall-overview-section.component.scss']
})
export class ArsskadorTallOverviewSectionComponent implements OnInit {
    private _senasteInventering = new BehaviorSubject<AbinResultDto>(null);
    senasteInventering$ = this._senasteInventering.asObservable();

    private _inventeringar = new BehaviorSubject<AbinResultDto[]>([]);
    inventeringar$ = this._inventeringar.asObservable();

    private _omradeNamn = new BehaviorSubject<string>(null);
    omradeNamn$ = this._omradeNamn.asObservable();

    lan13Chapter33WarningConfig$: Observable<{
        message: string;
        header: string;
        render: boolean;
    }>;

    public vardeVariabler: string[] = ['arsskadaTallAndel', 'medelfelArskadaTall', 'arsskadaTallAndel3InvMedel', 'medelfelArskadaTall3InvMedel'];

    constructor(private store: Store<InGeApiState>) {
        this.setupWarningConditions();
    }

    ngOnInit(): void {}

    onOmradeSelect(omrade: ButtonGroupItemData) {
        if (omrade && omrade.inventeringar && omrade.inventeringar.length > 0) {
            this._omradeNamn.next(omrade.omradeName);
            this._inventeringar.next(omrade.inventeringar);
            this._senasteInventering.next(omrade.inventeringar[0]);
        }
    }

    setupWarningConditions() {
        this.lan13Chapter33WarningConfig$ = this.omradeNamn$.pipe(
            withLatestFrom(this.store),
            select(([omrade, x]) => {
                const warningConfig = {
                    message:
                        'I ÄFO 3 Östra, Hallands län är andelen tall låg. Tall utgör 3% av de tallstammar som bedömts i årets inventering. En fördjupade analys av ÄFO 3 visar att skadorna i stor utsträckning är skador på enstaka tallar i granbestånd. Den stora årliga variationen är en effekt av det låga antalet tallar. Resultatet gällande årsskador på tall bör därför tolkas med mycket stor försiktighet.',
                    render: false,
                    header: 'Viktig information'
                };
                if (
                    x?.uppfoljningsrapport?.fordjupning?.inventeringar?.length > 0 &&
                    x?.uppfoljningsrapport?.fordjupning?.inventeringar[0]?.length > 0 &&
                    x?.uppfoljningsrapport?.fordjupning?.inventeringar[0][0].lanKod === '13'
                ) {
                    if (omrade?.includes('ÄFO 3')) {
                        return { ...warningConfig, render: true };
                    }
                }
                return { ...warningConfig, message: '' };
            })
        );
    }
}

<table class="ui-component ui-table" [ngClass]="{ selectable: selectable }">
  <caption class="ui-table-caption" *ngIf="caption">{{caption}}</caption>
  <tr>
    <th *ngFor="let columnHead of _tableConfig.columns">
      {{ columnHead.title }}
    </th>
    <th *ngIf="_tableConfig.rowActions.length > 0"></th>
  </tr>
  <tr *ngFor="let columnData of _tableContent">
    <td *ngFor="let columnHead of _tableConfig.columns">

      {{ columnData[columnHead.dataProperty] }}

    </td>
    <td *ngIf="_tableConfig.rowActions && _tableConfig.rowActions.length>0">
      <span *ngFor="let action of _tableConfig.rowActions">
        <button ui-button-round ui-button-outlined ui-button-dark *ngIf="action.showOption(columnData)"
          (click)="onRowActionClicked(action.actionId, columnData)">
          <i class="material-icons">
            {{ action.icon }}
          </i>
        </button>
      </span>
    </td>
  </tr>
</table>

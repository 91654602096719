import {
  UppfoljningRapportState,
  InventeringsNiva,
} from '../state-model/inge-api.model';
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';
import * as actions from '../actions/uppfoljningsrapport.actions';
import { cloneDeep } from 'lodash-es';

const initialState: UppfoljningRapportState = {
  inventeringsNiva: null,

  ingaendeOmraden: [],
  ingaendeOmradenError: false,
  loadingIngaendeOmraden: false,

  inventeringar: [],
  inventeringarError: false,
  loadingInventeringar: false,

  fordjupning: null,
  fordjupningError: false,
  loadingFordjupning: false,
};

const uppfoljningsRapportReducer = createReducer(
  initialState,
  on(actions.uppfoljningsrapportGetSummeringLand, (state, action) => ({
    ...state,
    loadingInventeringar: true,
    inventeringarError: false,
    inventeringar: null,
  })),
  on(actions.uppfoljningsrapportGetSummeringLandError, (state, action) => ({
    ...state,
    inventeringarError: true,
    loadingInventeringar: false,
    inventeringar: null,
  })),
  on(actions.uppfoljningsrapportGetSummeringLandSuccess, (state, action) => ({
    ...state,
    loadingInventeringar: false,
    inventeringarError: false,
    inventeringar: cloneDeep(action.result),
    inventeringsNiva: InventeringsNiva.LAND,
  })),

  on(actions.uppfoljningsrapportGetSummeringLandsdel, (state, action) => ({
    ...state,
    loadingInventeringar: true,
    inventeringarError: false,
    inventeringar: null,
  })),
  on(actions.uppfoljningsrapportGetSummeringLandsdelError, (state, action) => ({
    ...state,
    inventeringarError: true,
    loadingInventeringar: false,
    inventeringar: null,
  })),
  on(
    actions.uppfoljningsrapportGetSummeringLandsdelSuccess,
    (state, action) => ({
      ...state,
      loadingInventeringar: false,
      inventeringarError: false,
      inventeringar: cloneDeep(action.result),
      inventeringsNiva: InventeringsNiva.LANDSDEL,
    })
  ),

  on(actions.uppfoljningsrapportGetSummeringLan, (state, action) => ({
    ...state,
    loadingInventeringar: true,
    inventeringarError: false,
    inventeringar: null,
  })),
  on(actions.uppfoljningsrapportGetSummeringLanError, (state, action) => ({
    ...state,
    inventeringarError: true,
    loadingInventeringar: false,
    inventeringar: null,
  })),
  on(actions.uppfoljningsrapportGetSummeringLanSuccess, (state, action) => ({
    ...state,
    loadingInventeringar: false,
    inventeringarError: false,
    inventeringar: cloneDeep(action.result),
    inventeringsNiva: InventeringsNiva.LAN,
  })),

  on(actions.uppfoljningsrapportGetSummeringLanAfo, (state, action) => ({
    ...state,
    loadingInventeringar: true,
    inventeringarError: false,
    inventeringar: null,
  })),
  on(actions.uppfoljningsrapportGetSummeringLanAfoError, (state, action) => ({
    ...state,
    inventeringarError: true,
    loadingInventeringar: false,
    inventeringar: null,
  })),
  on(actions.uppfoljningsrapportGetSummeringLanAfoSuccess, (state, action) => ({
    ...state,
    loadingInventeringar: false,
    inventeringarError: false,
    inventeringar: cloneDeep(action.result),
    inventeringsNiva: InventeringsNiva.AFO,
  })),

  on(actions.setRapportUnderlag, (state, action) => ({
    ...state,
    rapportUnderlag: cloneDeep(action.inventering),
  })),

  on(actions.uppfoljningsrapportGetLandsdelar, (state, action) => ({
    ...state,
    loadingIngaendeOmraden: true,
    ingaendeOmraden: null,
    ingaendeOmradenError: false,
  })),
  on(actions.uppfoljningsrapportGetLandsdelarSuccess, (state, action) => ({
    ...state,
    loadingIngaendeOmraden: false,
    ingaendeOmraden: cloneDeep(action.result),
    ingaendeOmradenError: false,
  })),
  on(actions.uppfoljningsrapportGetLandsdelarError, (state, action) => ({
    ...state,
    loadingIngaendeOmraden: false,
    ingaendeOmraden: null,
    ingaendeOmradenError: true,
  })),

  on(actions.uppfoljningsrapportGetLan, (state, action) => ({
    ...state,
    loadingIngaendeOmraden: true,
    ingaendeOmraden: null,
    ingaendeOmradenError: false,
  })),
  on(actions.uppfoljningsrapportGetLanSuccess, (state, action) => ({
    ...state,
    loadingIngaendeOmraden: false,
    ingaendeOmraden: cloneDeep(action.result),
    ingaendeOmradenError: false,
  })),
  on(actions.uppfoljningsrapportGetLanError, (state, action) => ({
    ...state,
    loadingIngaendeOmraden: false,
    ingaendeOmraden: null,
    ingaendeOmradenError: true,
  })),

  on(actions.uppfoljningsrapportGetAfo, (state, action) => ({
    ...state,
    loadingIngaendeOmraden: true,
    ingaendeOmraden: null,
    ingaendeOmradenError: false,
  })),
  on(actions.uppfoljningsrapportGetAfoSuccess, (state, action) => ({
    ...state,
    loadingIngaendeOmraden: false,
    ingaendeOmraden: cloneDeep(action.result),
    ingaendeOmradenError: false,
  })),
  on(actions.uppfoljningsrapportGetAfoError, (state, action) => ({
    ...state,
    loadingIngaendeOmraden: false,
    ingaendeOmraden: null,
    ingaendeOmradenError: true,
  })),

  // Hämta alla inventeringar för samtliga landsdelar inom landet
  on(
    actions.uppfoljningsrapportGetIngaendeInventeringarLand,
    (state, action) => ({
      ...state,
      loadingFordjupning: true,
      fordjupning: null,
      fordjupningError: false,
    })
  ),
  on(
    actions.uppfoljningsrapportGetIngaendeInventeringarLandSuccess,
    (state, action) => ({
      ...state,
      loadingFordjupning: false,
      fordjupning: {
        fordjupningsNiva: InventeringsNiva.LANDSDEL,
        inventeringar: cloneDeep(action.result),
      },
      fordjupningError: false,
    })
  ),
  on(
    actions.uppfoljningsrapportGetIngaendeInventeringarLandError,
    (state, action) => ({
      ...state,
      loadingFordjupning: false,
      fordjupning: null,
      fordjupningError: true,
    })
  ),

  // Hämta alla inventeringar för samtliga län inom landsdel
  on(
    actions.uppfoljningsrapportGetIngaendeInventeringarLandsdel,
    (state, action) => ({
      ...state,
      loadingFordjupning: true,
      fordjupning: null,
      fordjupningError: false,
    })
  ),
  on(
    actions.uppfoljningsrapportGetIngaendeInventeringarLandsdelSuccess,
    (state, action) => ({
      ...state,
      loadingFordjupning: false,
      fordjupning: {
        fordjupningsNiva: InventeringsNiva.LAN,
        inventeringar: cloneDeep(action.result),
      },
      fordjupningError: false,
    })
  ),
  on(
    actions.uppfoljningsrapportGetIngaendeInventeringarLandsdelError,
    (state, action) => ({
      ...state,
      loadingFordjupning: false,
      fordjupning: null,
      fordjupningError: true,
    })
  ),
  // Hämta alla inventeringar för samtliga afo inom lan
  on(
    actions.uppfoljningsrapportGetIngaendeInventeringarLan,
    (state, action) => ({
      ...state,
      loadingFordjupning: true,
      fordjupning: null,
      fordjupningError: false,
    })
  ),
  on(
    actions.uppfoljningsrapportGetIngaendeInventeringarLanSuccess,
    (state, action) => ({
      ...state,
      loadingFordjupning: false,
      fordjupning: {
        fordjupningsNiva: InventeringsNiva.AFO,
        inventeringar: cloneDeep(action.result),
      },
      fordjupningError: false,
    })
  ),
  on(
    actions.uppfoljningsrapportGetIngaendeInventeringarLanError,
    (state, action) => ({
      ...state,
      loadingFordjupning: false,
      fordjupning: null,
      fordjupningError: true,
    })
  )
);

export function reducer(
  state: UppfoljningRapportState | undefined,
  action: Action
): UppfoljningRapportState {
  return uppfoljningsRapportReducer(state, action);
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiInfoBoxComponent } from './ui-info-box/ui-info-box.component';
import { UiInfoBoxHeaderDirective } from './directives/ui-info-box-header.directive';
import { UiInfoBoxContentDirective } from './directives/ui-info-box-content.directive';
import { UiInfoBoxDarkDirective } from './directives/ui-info-box-dark.directive';
import { UiInfoBoxAccentDirective } from './directives/ui-info-box-accent.directive';
import { UiInfoBoxLightDirective } from './directives/ui-info-box-light.directive';
import { UiInfoBoxNoBorderDirective } from './directives/ui-info-box-no-border.directive';
import { UiInfoBoxCenteredDirective } from './directives/ui-info-box-centered.directive';
import { UiInfoBoxShadowDirective } from './directives/ui-info-box-shadow.directive';
@NgModule({
  declarations: [
    UiInfoBoxComponent,
    UiInfoBoxHeaderDirective,
    UiInfoBoxContentDirective,
    UiInfoBoxDarkDirective,
    UiInfoBoxAccentDirective,
    UiInfoBoxLightDirective,
    UiInfoBoxNoBorderDirective,
    UiInfoBoxCenteredDirective,
    UiInfoBoxShadowDirective,
  ],
  imports: [CommonModule],
  exports: [
    UiInfoBoxComponent,
    UiInfoBoxContentDirective,
    UiInfoBoxHeaderDirective,
    UiInfoBoxDarkDirective,
    UiInfoBoxAccentDirective,
    UiInfoBoxLightDirective,
    UiInfoBoxNoBorderDirective,
    UiInfoBoxCenteredDirective,
    UiInfoBoxShadowDirective,
  ],
})
export class UiInfoBoxModule {}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { ArsskadorKartaService } from 'src/app/abin-resultat-rapport/rapport-components/arsskador-karta/arsskador-karta.service';
import { KartaInventeringsrutorService } from 'src/app/abin-resultat-rapport/rapport-components/karta-inventeringsrutor/karta-inventeringsrutor.service';
import { PowerPointGeneratorService } from 'src/app/export/powerpoint/pptx-generator.service';
import { ExportReportModalService } from './export-report-modal.service';

@Component({
  selector: 'app-export-report-modal',
  templateUrl: './export-report-modal.component.html',
  styleUrls: ['./export-report-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExportReportModalComponent implements OnInit {
  private visibleSource = new BehaviorSubject<boolean>(false);
  visible$ = this.visibleSource.asObservable();
  exportLoading: boolean;
  exportSuccessMessage: string;
  exportErrorMessage: string;
  reportStillLoading$: Observable<boolean>;

  constructor(
    private service: ExportReportModalService,
    private exportService: PowerPointGeneratorService,
    private inventeringsrutorKartaService: KartaInventeringsrutorService,
    private arsskadorKartaService: ArsskadorKartaService
  ) {}

  ngOnInit(): void {
    this.service.visible$.subscribe((x) => {
      this.visibleSource.next(x);
    });
    this.reportStillLoading$ = combineLatest([
      this.inventeringsrutorKartaService.loading$,
      this.arsskadorKartaService.loading$,
    ]).pipe(
      debounceTime(500),
      map(([x, y]) => x || y)
    );
  }
  close() {
    this.visibleSource.next(false);
  }

  exportReport() {
    this.exportSuccessMessage = null;
    this.exportErrorMessage = null;
    this.exportLoading = true;
    this.exportService.generateReport().subscribe(
      (fileName) => {
        this.exportLoading = false;
        this.exportSuccessMessage = `Filen ${fileName} exporterades`;
      },
      (err) => {
        console.error(err);
        this.exportLoading = false;
        this.exportErrorMessage =
          'Ett fel uppstod vid exporten, försök igen och kontakta sidansvarig om felet kvarstår.';
      }
    );
  }
}

<div class="row">
    <div class="col-xs-12">
        <div>Välj område med knapparna.</div>
    </div>

    <div class="col-xs-12">
        <app-fordjupning-button-group (selectedOmradeEvent)="onOmradeSelect($event)"></app-fordjupning-button-group>
    </div>
    <div class="col-xs-12">
        <span><b>Valt område</b>: {{ omradeNamn$ | async }}</span>
    </div>
</div>

<div class="row">
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div class="row middle-xs">
            <ng-container *ngIf="dataProdSkogsmark?.length > 0; else elseTemplate">
                <div class="row middle-xs center-xs">
                    <div class="col-xs-12">
                        <ui-figure-caption>
                            <ui-figure-text>
                                <h4>Skog högre än 5 meter, produktiv skogsmark enl. Riksskogstaxeringen.</h4>
                            </ui-figure-text>
                        </ui-figure-caption>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                        <app-google-pie-chart [data]="dataProdSkogsmark" [options]="optionsProdSkogsmark"></app-google-pie-chart>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                        <ui-legend ui-legend-vertical>
                            <ui-legend-item *ngFor="let item of legendDataProdSkogsmark; let i = index" [color]="item.color">
                                {{ item.name }}: {{ item.value | number }}%
                            </ui-legend-item>
                        </ui-legend>
                    </div>
                </div>
            </ng-container>
            <ng-template #elseTemplate>
                <div class="col-xs-6">Kunde inte ladda produktiv skogsmark för detta område</div>
            </ng-template>
        </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div class="row middle-xs">
            <ng-container *ngIf="dataUngskSammansattning?.length > 0; else elseUngskSammansattningTemplate">
                <div class="col-xs-12">
                    <ui-figure-caption>
                        <ui-figure-text>
                            <h4>Ungskogens sammansättning enl. Äbin. (Höjd 1-4 meter, produktionsstammar)</h4>
                        </ui-figure-text>
                    </ui-figure-caption>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <app-google-pie-chart [data]="dataUngskSammansattning" [options]="optionsUngskSammansattning"></app-google-pie-chart>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <ui-legend ui-legend-vertical>
                        <ui-legend-item *ngFor="let item of legendDataUngskSammansattning; let i = index" [color]="item.color">
                            {{ item.name }}: {{ item.value | number }}%
                        </ui-legend-item>
                    </ui-legend>
                </div>
            </ng-container>
            <ng-template #elseUngskSammansattningTemplate>
                <div class="col-xs-6">Kunde inte ladda ungskogens sammansättning för detta område</div>
            </ng-template>
        </div>
    </div>
</div>

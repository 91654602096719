<div class="row no-gutters">
    <div class="col-xs-12">
        <div #mapViewNode class="inventeringsrutor__esri-map">
            <div class="loading-mask" *ngIf="isLoading$ | async">
                <app-loader class="karta-loader"></app-loader>
            </div>
            <app-fordjupning-karta-popup></app-fordjupning-karta-popup>
        </div>
    </div>
    <div class="col-xs-12">
        <!--<div class="fordjupning-karta__legend">
      <div class="legend-container">
        <div class="legend-item">
          <div class="legend-item__symbol sjo-symbol"></div>
          <div class="legend-item__text">Sjö</div>
        </div>
        <div class="legend-item">
          <div class="legend-item__symbol tolerabel-symbol"></div>
          <div class="legend-item__text">Tolerabel</div>
        </div>
        <div class="legend-item">
          <div class="legend-item__symbol allvarlig-symbol"></div>
          <div class="legend-item__text">Allvarlig</div>
        </div>
        <div class="legend-item">
          <div class="legend-item__symbol svar-symbol"></div>
          <div class="legend-item__text">Svår</div>
        </div>
        <div class="legend-item">
          <div class="legend-item__symbol mkt-svar-symbol"></div>
          <div class="legend-item__text">Mycket svår</div>
        </div>
      </div>
    </div> -->
        <app-fordjupning-legends [layerConfig]="layerConfig$ | async"></app-fordjupning-legends>
    </div>
</div>

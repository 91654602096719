import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoaderComponent implements OnInit {
  _show = false;
  constructor() {}
  @Input()
  set show(value: boolean) {
    this._show = value;
  }
  ngOnInit(): void {}
}

import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[ui-info-box-no-border]',
})
export class UiInfoBoxNoBorderDirective {
  constructor() {}

  @HostBinding('class')
  elementClass = 'ui-info-box-no-border';
}

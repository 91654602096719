import { createAction, props } from '@ngrx/store';
import { AbinMetadataDto, AbinResultDto } from 'src/app/inge-api/client/models';

export const uppfoljningsrapportGetSummeringLand = createAction(
  '[UPPFOLJNINGSRAPPORT] uppfoljningsrapportGetSummeringLand'
);
export const uppfoljningsrapportGetSummeringLandSuccess = createAction(
  '[UPPFOLJNINGSRAPPORT] uppfoljningsrapportGetSummeringLandSuccess',
  props<{ result: Array<AbinResultDto> }>()
);
export const uppfoljningsrapportGetSummeringLandError = createAction(
  '[UPPFOLJNINGSRAPPORT] uppfoljningsrapportGetSummeringLandError'
);

export const uppfoljningsrapportGetSummeringLandsdel = createAction(
  '[UPPFOLJNINGSRAPPORT] uppfoljningsrapportGetSummeringLandsdel',
  props<{ landsdelKod: number; excludeLan?: string[] }>()
);
export const uppfoljningsrapportGetSummeringLandsdelSuccess = createAction(
  '[UPPFOLJNINGSRAPPORT] uppfoljningsrapportGetSummeringLandsdelSuccess',
  props<{ result: AbinResultDto[] }>()
);
export const uppfoljningsrapportGetSummeringLandsdelError = createAction(
  '[UPPFOLJNINGSRAPPORT] uppfoljningsrapportGetSummeringLandsdelError'
);

// Hämta summeringar inom samma län. Samma äfo kan förekomma flera gånger, men med olika inventeringsår
export const uppfoljningsrapportGetSummeringLan = createAction(
  '[UPPFOLJNINGSRAPPORT] uppfoljningsrapportGetSummeringLan',
  props<{ lankod: string }>()
);
export const uppfoljningsrapportGetSummeringLanSuccess = createAction(
  '[UPPFOLJNINGSRAPPORT] uppfoljningsrapportGetSummeringLanSuccess',
  props<{ result: Array<AbinResultDto> }>()
);
export const uppfoljningsrapportGetSummeringLanError = createAction(
  '[UPPFOLJNINGSRAPPORT] uppfoljningsrapportGetSummeringLanError'
);

// Hämta summeringar för specifikt äfo. Flera summeringar för samma äfo kan förekomma flera gånger, men med olika inventeringsår
export const uppfoljningsrapportGetSummeringLanAfo = createAction(
  '[UPPFOLJNINGSRAPPORT] uppfoljningsrapportGetSummeringLanAfo',
  props<{ lankod: string; afonr: number }>()
);
export const uppfoljningsrapportGetSummeringLanAfoSuccess = createAction(
  '[UPPFOLJNINGSRAPPORT] uppfoljningsrapportGetSummeringLanAfoSuccess',
  props<{ result: Array<AbinResultDto> }>()
);
export const uppfoljningsrapportGetSummeringLanAfoError = createAction(
  '[UPPFOLJNINGSRAPPORT] uppfoljningsrapportGetSummeringLanAfoError'
);

export const setRapportUnderlag = createAction(
  '[ABIN RAPPORT] setRapportUnderlag',
  props<{ inventering: AbinResultDto }>()
);

export const uppfoljningsrapportGetLandsdelar = createAction(
  '[UPPFOLJNINGSRAPPORT] uppfoljningsrapportGetLandsdelar'
);
export const uppfoljningsrapportGetLandsdelarSuccess = createAction(
  '[UPPFOLJNINGSRAPPORT] uppfoljningsrapportGetLandsdelarSuccess',
  props<{ result: Array<AbinMetadataDto> }>()
);
export const uppfoljningsrapportGetLandsdelarError = createAction(
  '[UPPFOLJNINGSRAPPORT] uppfoljningsrapportGetLandsdelarError'
);

export const uppfoljningsrapportGetLan = createAction(
  '[UPPFOLJNINGSRAPPORT] uppfoljningsrapportGetLan',
  props<{ landsdelKod: number; exclude?: string[] }>()
);
export const uppfoljningsrapportGetLanSuccess = createAction(
  '[UPPFOLJNINGSRAPPORT] uppfoljningsrapportGetLanSuccess',
  props<{ result: Array<AbinMetadataDto> }>()
);
export const uppfoljningsrapportGetLanError = createAction(
  '[UPPFOLJNINGSRAPPORT] uppfoljningsrapportGetLanError'
);

export const uppfoljningsrapportGetAfo = createAction(
  '[UPPFOLJNINGSRAPPORT] uppfoljningsrapportGetAfo',
  props<{ landsdelKod: number; lanKod: string }>()
);
export const uppfoljningsrapportGetAfoSuccess = createAction(
  '[UPPFOLJNINGSRAPPORT] uppfoljningsrapportGetAfoSuccess',
  props<{ result: Array<AbinMetadataDto> }>()
);
export const uppfoljningsrapportGetAfoError = createAction(
  '[UPPFOLJNINGSRAPPORT] uppfoljningsrapportGetAfoError'
);

// Hämta alla inventeringar för samtliga landsdelar inom landet
export const uppfoljningsrapportGetIngaendeInventeringarLand = createAction(
  '[UPPFOLJNINGSRAPPORT] uppfoljningsrapportGetIngaendeInventeringarLand'
);
export const uppfoljningsrapportGetIngaendeInventeringarLandSuccess =
  createAction(
    '[UPPFOLJNINGSRAPPORT] uppfoljningsrapportGetIngaendeInventeringarLandSuccess',
    props<{ result: Array<Array<AbinResultDto>> }>()
  );
export const uppfoljningsrapportGetIngaendeInventeringarLandError =
  createAction(
    '[UPPFOLJNINGSRAPPORT] uppfoljningsrapportGetIngaendeInventeringarLandError'
  );

// Hämta alla inventeringar för samtliga län inom landsdel
export const uppfoljningsrapportGetIngaendeInventeringarLandsdel = createAction(
  '[UPPFOLJNINGSRAPPORT] uppfoljningsrapportGetIngaendeInventeringarLandsdel',
  props<{ landsdelkod: number; excludeLan?: string[] }>()
);
export const uppfoljningsrapportGetIngaendeInventeringarLandsdelSuccess =
  createAction(
    '[UPPFOLJNINGSRAPPORT] uppfoljningsrapportGetIngaendeInventeringarLandsdelSuccess',
    props<{ result: Array<Array<AbinResultDto>> }>()
  );
export const uppfoljningsrapportGetIngaendeInventeringarLandsdelError =
  createAction(
    '[UPPFOLJNINGSRAPPORT] uppfoljningsrapportGetIngaendeInventeringarLandsdelError'
  );

// Hämta alla inventeringar för samtliga äfo inom ett län
export const uppfoljningsrapportGetIngaendeInventeringarLan = createAction(
  '[UPPFOLJNINGSRAPPORT] uppfoljningsrapportGetIngaendeInventeringarLan',
  props<{ lankod: string; landsdelkod: number }>()
);
export const uppfoljningsrapportGetIngaendeInventeringarLanSuccess =
  createAction(
    '[UPPFOLJNINGSRAPPORT] uppfoljningsrapportGetIngaendeInventeringarLanSuccess',
    props<{ result: Array<Array<AbinResultDto>> }>()
  );
export const uppfoljningsrapportGetIngaendeInventeringarLanError = createAction(
  '[UPPFOLJNINGSRAPPORT] uppfoljningsrapportGetIngaendeInventeringarLanError'
);

<app-ui-info-box ui-info-box-light ui-info-box-centered ui-info-box-no-border ui-info-box-shadow
  class="report-summary-card">
  <ui-info-box-header>
    <!-- <span class="abinicon-tree-chart"></span> -->
    <ng-content select="app-summary-card-icon"></ng-content>

    <br>

    <ng-content select="app-summary-card-header"></ng-content>


  </ui-info-box-header>
  <ui-info-box-content>

    <ng-content select="app-summary-card-content"></ng-content>


  </ui-info-box-content>
</app-ui-info-box>

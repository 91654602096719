export const environment = {
    production: true,
    kartor: {
        LMTopoWebb: 'https://portalproxy.skogsstyrelsen.se/topowebb/wms/v1',
        arsskador: 'assets/img/kriging',
        arsskadorKarta: {
            url: 'https://geodpags.skogsstyrelsen.se/arcgis/rest/services/Geodataportal/GeodataportalVisaAbinrapportheatmap_4_0/MapServer',
            sublayers: {
                land: {
                    id: 0
                },
                landsdel: {
                    id: 1
                },
                lan: {
                    id: 2
                },
                afo: {
                    id: 3
                },
                stratum: {
                    id: 4
                },

                fjallnaraGransYta: {
                    id: 5
                },
                sverigeMaskYta: {
                    id: 6
                },
                heatmap: {
                    id: 7
                }
            }
        },
        geodataportalVisaAbinrapport_3_0: {
            url: 'https://geodpags.skogsstyrelsen.se/arcgis/rest/services/Geodataportal/GeodataportalVisaAbinrapport_4_0/MapServer',
            sublayers: {
                sjoar: {
                    parendId: 0,
                    id: 1
                },
                arsskadorTallAfo: {
                    parentId: 2,
                    id: 3
                },
                arsskadorTallLan: {
                    parentId: 2,
                    id: 4
                },
                arsskadorTallLandsdel: {
                    parentId: 2,
                    id: 5
                },

                oskadadTallAfo: {
                    parentId: 6,
                    id: 7
                },
                oskadadTallLan: {
                    parentId: 6,
                    id: 8
                },
                oskadadTallLandsdel: {
                    parentId: 6,
                    id: 9
                },

                arsskadorGranAfo: {
                    parentId: 10,
                    id: 11
                },
                arsskadorGranLan: {
                    parentId: 10,
                    id: 12
                },
                arsskadorGranLandsdel: {
                    parentId: 10,
                    id: 13
                },

                konkurrensstatusRASEAfo: {
                    parentId: 14,
                    id: 15
                },
                konkurrensstatusRASELan: {
                    parentId: 14,
                    id: 16
                },
                konkurrensstatusRASELandsdel: {
                    parentId: 14,
                    id: 17
                },

                tallMagraMarkerAfo: {
                    parentId: 18,
                    id: 19
                },
                tallMagraMarkerLan: {
                    parentId: 18,
                    id: 20
                },
                tallMagraMarkerLandsdel: {
                    parentId: 18,
                    id: 21
                },

                tallMellanmarkerAfo: {
                    parentId: 22,
                    id: 23
                },
                tallMellanmarkerLan: {
                    parentId: 22,
                    id: 24
                },
                tallMellanmarkerLandsdel: {
                    parentId: 22,
                    id: 25
                }
            }
        },
        lmTopowebbSkikt: {
            url: 'https://portalproxy.skogsstyrelsen.se/topowebb-skikt/wms/v1.1',
            layers: {
                terrangskuggning: 'terrangskuggning',
                mark: 'mark',
                hydrografi_ytor: 'hydrografi_ytor',
                hydrografi: 'hydrografi',
                kurvor: 'kurvor',
                kommunikation: 'kommunikation',
                jarnvag: 'jarnvag',
                fjallinformation: 'fjallinformation',
                bebyggelse: 'bebyggelse',
                anlaggningar: 'anlaggningar',
                kraftledningar: 'kraftledningar',
                administrativ_indelning: 'administrativ_indelning',
                bestammelser: 'bestammelser',
                adresser: 'adresser',
                text: 'text',
                mark_nedtonad: 'mark_nedtonad',
                hydrografi_ytor_nedtonad: 'hydrografi_ytor_nedtonad',
                hydrografi_nedtonad: 'hydrografi_nedtonad',
                kurvor_nedtonad: 'kurvor_nedtonad',
                kommunikation_nedtonad: 'kommunikation_nedtonad',
                jarnvag_nedtonad: 'jarnvag_nedtonad',
                fjallinformation_nedtonad: 'fjallinformation_nedtonad',
                bebyggelse_nedtonad: 'bebyggelse_nedtonad',
                anlaggningar_nedtonad: 'anlaggningar_nedtonad',
                kraftledningar_nedtonad: 'kraftledningar_nedtonad',
                administrativ_indelning_nedtonad: 'administrativ_indelning_nedtonad',
                bestammelser_nedtonad: 'bestammelser_nedtonad',
                adresser_nedtonad: 'adresser_nedtonad',
                text_nedtonad: 'text_nedtonad'
            }
        }
    },
    skogsstyrelsenUrl: 'https://www.skogsstyrelsen.se/',
    oauth2: {
        authority: 'https://auth.skogsstyrelsen.se/connect/token',
        clientId: 'sks_skobi_rapport',
        clientSecret: '64d45f51-5087-890c-4307-26ca529ff406',
        scope: 'sks_api',
        grantType: 'client_credentials'
    },
    googleCharts: {
        scriptSource: 'https://www.gstatic.com/charts/loader.js'
    }
};

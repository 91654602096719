import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import * as geometryEngineAsync from '@arcgis/core/geometry/geometryEngineAsync';
import * as jsonUtils from '@arcgis/core/geometry/support/jsonUtils';
import Graphic from '@arcgis/core/Graphic';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import MapImageLayer from '@arcgis/core/layers/MapImageLayer';
import WMSLayer from '@arcgis/core/layers/WMSLayer';
import Query from '@arcgis/core/rest/support/Query';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { InGeApiState, InventeringsNiva } from 'src/app/store/state-model/inge-api.model';
import { environment } from 'src/environments/environment';
import { ResultatKartaBaseComponent } from '../../base-components/resultat-karta-base/resultat-karta-base.component';
import { ArsskadorKartaService } from './arsskador-karta.service';
import { gotlandGeometry } from './gotland-geometry';
@Component({
    selector: 'app-arsskador-karta',
    templateUrl: './arsskador-karta.component.html',
    styleUrls: ['./arsskador-karta.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ArsskadorKartaComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('mapComponent', { static: true })
    mapComponent: ResultatKartaBaseComponent;
    private subs: Subscription[];
    private inventeringsNiva$: Observable<InventeringsNiva>;
    error: boolean;
    arsskadorMapservice: MapImageLayer;
    private defaultVisibleLayerIds = [
        environment.kartor.arsskadorKarta.sublayers.sverigeMaskYta.id,
        environment.kartor.arsskadorKarta.sublayers.fjallnaraGransYta.id,
        environment.kartor.arsskadorKarta.sublayers.heatmap.id
    ];
    constructor(private route: ActivatedRoute, private store: Store<InGeApiState>, private arsskadorkartaService: ArsskadorKartaService) {
        this.inventeringsNiva$ = this.store.pipe(select((x) => x.abin.inventeringsNiva));
    }
    ngAfterViewInit(): void {
        this.subs = [];
        this.subs.push(
            combineLatest([this.route.queryParamMap, this.inventeringsNiva$]).subscribe(async ([params, inventeringsNiva]) => {
                if (!params || !inventeringsNiva) return;
                await this.setup(inventeringsNiva, params);
            })
        );
    }

    ngOnInit(): void {}

    async setup(inventeringsNiva: InventeringsNiva, params: ParamMap) {
        await this.mapComponent.view.when();
        this.disableNavigation();
        this.arsskadorkartaService.setView(this.mapComponent.view);
        await this.addArsskadorKarta();
        const layerId = this.getLayerIdForCurrentInventeringsniva(inventeringsNiva);
        const query = this.createQuery(inventeringsNiva, params);
        const maskQuery = this.createMaskQuery(inventeringsNiva, params);
        await this.addAreaMask(maskQuery, layerId, inventeringsNiva);
        const graphics = await this.queryIngaendeYtor(query, layerId);
        this.setFeatureFilter(query, layerId);
        const visibleLayerIds = this.getVisibleLayerIdsForInventeringsNiva(inventeringsNiva);
        this.setLayerVisibilityForInventeringsNiva([...visibleLayerIds]);
        this.mapComponent.lmtopowebb.visible = false;
        this.addLmTopowebbSkiktad();
        await this.zoomToGraphics(graphics);
        await this.addYtaBorder(graphics);
    }

    private disableNavigation() {
        const view = this.mapComponent.view;
        view.ui.components = [];

        view.on('key-down', (event) => {
            const prohibitedKeys = ['+', '-', 'Shift', '_', '='];
            const keyPressed = event.key;
            if (prohibitedKeys.indexOf(keyPressed) !== -1) {
                event.stopPropagation();
            }
        });

        view.on('mouse-wheel', (event) => {
            event.stopPropagation();
        });

        view.on('double-click', ['Control'], (event) => {
            event.stopPropagation();
        });
        view.on('drag', (event) => {
            event.stopPropagation();
        });
        view.on('drag', ['Shift'], (event) => {
            event.stopPropagation();
        });

        view.on('drag', ['Shift', 'Control'], (event) => {
            event.stopPropagation();
        });
    }

    async addYtaBorder(graphics: Graphic[]) {
        const union = await geometryEngineAsync.union(graphics.map((x) => x.geometry));
        const symbol = {
            type: 'simple-fill',
            color: [51, 51, 204, 0.0],
            style: 'solid',
            outline: {
                color: 'black',
                width: 1,
                style: 'solid'
            }
        };
        this.mapComponent.view.graphics.add(new Graphic({ geometry: union, symbol }));
    }

    async addAreaMask(query: Query, layerId: number, inventeringsNiva: InventeringsNiva) {
        await this.mapComponent.view.when();
        const ytor = await this.queryIngaendeYtor(query, layerId);
        const symbol = {
            type: 'simple-fill',
            color: 'white',
            style: 'solid',
            outline: {
                color: [0, 0, 0, 0],
                width: 0,
                style: 'none'
            }
        } as any;
        const areaMaskLayer = new GraphicsLayer({
            graphics: ytor.map((x) => new Graphic({ geometry: x.geometry, symbol }))
        });
        if (inventeringsNiva === InventeringsNiva.LAND || inventeringsNiva === InventeringsNiva.LANDSDEL) {
            const gotland = this.createGotlandGraphic();
            gotland.symbol = symbol;
            areaMaskLayer.add(gotland);
        }
        this.mapComponent.view.map.add(areaMaskLayer);
    }

    async addArsskadorKarta() {
        await this.mapComponent.view.when();
        const view = this.mapComponent.view;
        this.arsskadorMapservice = new MapImageLayer({
            url: environment.kartor.arsskadorKarta.url
        });
        view.map.add(this.arsskadorMapservice);
    }

    private async queryIngaendeYtor(query: Query, layerId: number) {
        await this.arsskadorMapservice.when();
        const layer = this.arsskadorMapservice.findSublayerById(layerId);
        const featureset = await layer.queryFeatures(query);
        return featureset.features;
    }

    private getLayerIdForCurrentInventeringsniva(inventeringsNiva: InventeringsNiva) {
        if (inventeringsNiva) {
            const mapserviceConfig = environment.kartor.arsskadorKarta;
            let layerId: number = null;
            if (inventeringsNiva === InventeringsNiva.LAND) {
                layerId = mapserviceConfig.sublayers.land.id;
            }
            if (inventeringsNiva === InventeringsNiva.LANDSDEL) {
                layerId = mapserviceConfig.sublayers.landsdel.id;
            }
            if (inventeringsNiva === InventeringsNiva.LAN) {
                layerId = mapserviceConfig.sublayers.lan.id;
            }
            if (inventeringsNiva === InventeringsNiva.AFO) {
                layerId = mapserviceConfig.sublayers.afo.id;
            }
            if (inventeringsNiva === InventeringsNiva.STRATUM) {
                layerId = mapserviceConfig.sublayers.stratum.id;
            }
            return layerId;
        }
    }

    private createQuery(inventeringsNiva: InventeringsNiva, params: ParamMap) {
        const landsdel = params.get('landsdel');
        const lan = params.get('lan');
        const afo = params.get('afo');
        const delomrade = params.get('delomrade');
        const landsdelkod = landsdel;
        const lankod = lan;
        let afokod: string = afo;
        let delomradekod: string = delomrade;
        if (delomrade === 'alla') {
            delomradekod = '0';
        }
        const query = new Query();
        query.outFields = ['*'];
        query.returnGeometry = true;
        if (inventeringsNiva === InventeringsNiva.LAND) {
            query.where = '1=1';
        }
        if (inventeringsNiva === InventeringsNiva.LANDSDEL) {
            query.where = `LandsdelKod=${landsdelkod}`;
        }
        if (inventeringsNiva === InventeringsNiva.LAN) {
            query.where = `LanKod='${lankod}'`;
        }
        if (inventeringsNiva === InventeringsNiva.AFO) {
            query.where = `LanKod='${lankod}' and AFONr_SKS=${afokod}`;
        }
        if (inventeringsNiva === InventeringsNiva.STRATUM) {
            query.where = `AFONr_SKS=${afokod} and DelomrNr_SKS =${delomradekod} and Lankod='${lankod}'`;
        }
        return query;
    }

    private createMaskQuery(inventeringsNiva: InventeringsNiva, params: ParamMap) {
        const landsdel = params.get('landsdel');
        const lan = params.get('lan');
        const afo = params.get('afo');
        const delomrade = params.get('delomrade');
        const landsdelkod = landsdel;
        const lankod = lan;
        let afokod: string = afo;
        let delomradekod: string = delomrade;
        if (delomrade === 'alla') {
            delomradekod = '0';
        }
        const query = new Query();
        query.outFields = ['*'];
        query.returnGeometry = true;
        if (inventeringsNiva === InventeringsNiva.LAND) {
            query.where = '1=2';
        }
        if (inventeringsNiva === InventeringsNiva.LANDSDEL) {
            query.where = `LandsdelKod<>${landsdelkod}`;
        }
        if (inventeringsNiva === InventeringsNiva.LAN) {
            query.where = `LanKod<>'${lankod}'`;
        }
        if (inventeringsNiva === InventeringsNiva.AFO) {
            query.where = `LanKod<>'${lankod}' or AFONr_SKS<>${afokod}`;
        }
        if (inventeringsNiva === InventeringsNiva.STRATUM) {
            query.where = `AFONr_SKS<>${afokod} or DelomrNr_SKS<>${delomradekod} or Lankod<>'${lankod}'`;
        }
        return query;
    }

    private setFeatureFilter(query: Query, layerId: number) {
        const layer = this.arsskadorMapservice.findSublayerById(layerId);
        layer.definitionExpression = query.where;
    }

    private setLayerVisibilityForInventeringsNiva(visibleLayerIds: number[]) {
        this.arsskadorMapservice.allSublayers.forEach((x) => {
            if (visibleLayerIds.indexOf(x.id) === -1) {
                x.visible = false;
                return;
            }
            x.visible = true;
        });
    }

    private getVisibleLayerIdsForInventeringsNiva(inventeringsNiva: InventeringsNiva): number[] {
        const heatmapConfig = environment.kartor.arsskadorKarta.sublayers;
        if (inventeringsNiva === InventeringsNiva.LAND || inventeringsNiva === InventeringsNiva.LANDSDEL) {
            return [heatmapConfig.heatmap.id, heatmapConfig.fjallnaraGransYta.id];
        } else {
            return [heatmapConfig.heatmap.id, heatmapConfig.fjallnaraGransYta.id, heatmapConfig.sverigeMaskYta.id];
        }
    }

    private async zoomToGraphics(graphics: Graphic[]) {
        const union = await geometryEngineAsync.union(graphics.map((x) => x.geometry));
        this.mapComponent.view.goTo(union.extent.expand(1.1));
    }

    private addLmTopowebbSkiktad() {
        this.mapComponent.view.map.add(
            new WMSLayer({
                url: environment.kartor.lmTopowebbSkikt.url,
                sublayers: [
                    { name: environment.kartor.lmTopowebbSkikt.layers.text },
                    {
                        name: environment.kartor.lmTopowebbSkikt.layers.administrativ_indelning_nedtonad
                    },
                    {
                        name: environment.kartor.lmTopowebbSkikt.layers.kommunikation
                    },

                    { name: environment.kartor.lmTopowebbSkikt.layers.hydrografi_ytor }
                ]
            })
        );
    }

    private createGotlandGraphic() {
        const gotlandGeom = jsonUtils.fromJSON(gotlandGeometry);
        const graphic = new Graphic({ geometry: gotlandGeom });
        return graphic;
    }

    ngOnDestroy(): void {
        this.subs.forEach((x) => x.unsubscribe());
    }
}

import { AfterContentInit, Component, ContentChild, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { InGeApiState, InventeringsNiva } from 'src/app/store/state-model/inge-api.model';
import { environment } from 'src/environments/environment';
import { FordjupningKartaPopupComponent } from '../fordjupning-karta-popup/fordjupning-karta-popup.component';
import { SublayerConfiguration } from './fordjupning-karta.model';
import {
    createGraphicsLayer,
    createMap,
    createMapImage,
    createView,
    createWMS,
    disableNavigation,
    highlightSymbol,
    ingaendeOmradenQuery,
    unionGeometries,
    within
} from './helpers';
import { KartaClickService } from './services/karta-click.service';

@Component({
    selector: 'app-fordjupning-karta-base',
    templateUrl: './fordjupning-karta-base.component.html',
    styleUrls: ['./fordjupning-karta-base.component.scss'],
    providers: [KartaClickService]
})
export class FordjupningKartaBaseComponent implements OnInit, AfterContentInit {
    inventeringsNiva$: Observable<InventeringsNiva>;

    @ViewChild('mapViewNode', { static: true }) private mapViewEl: ElementRef;
    private loading = new BehaviorSubject<boolean>(true);
    isLoading$ = this.loading.asObservable();
    private _layerConfig = new BehaviorSubject<SublayerConfiguration>(null);
    layerConfig$ = this._layerConfig.asObservable();
    view: __esri.MapView;
    abinMapService: __esri.MapImageLayer;
    private selectionLayer: __esri.GraphicsLayer;
    ingaendeOmraden: __esri.Graphic[];
    highlightSelect: __esri.Handle;

    @ContentChild(FordjupningKartaPopupComponent)
    popupComponent: FordjupningKartaPopupComponent;
    hasPopup: boolean;

    constructor(private router: ActivatedRoute, private store: Store<InGeApiState>, private clickService: KartaClickService) {
        this.inventeringsNiva$ = this.store.pipe(select((x) => x.uppfoljningsrapport.inventeringsNiva));
    }
    ngAfterContentInit(): void {
        if (this.popupComponent && this.popupComponent.popup) {
            this.hasPopup = true;
        } else {
            this.hasPopup = false;
        }
    }

    @Input()
    set layerConfig(val: SublayerConfiguration) {
        this._layerConfig.next(val);
    }

    @Output() mapClicked = new EventEmitter<__esri.Graphic>();

    ngOnInit(): void {
        this.loading.next(true);
        combineLatest([this.layerConfig$, this.router.queryParams, this.inventeringsNiva$]).subscribe(async ([layerConfig, params, invNiva]) => {
            if (layerConfig && params && invNiva) {
                await this.setup(layerConfig, params, invNiva);
            }
        });
        this.addClickListener();
    }

    async setup(layerConfig: SublayerConfiguration, params: Params, invNiva: InventeringsNiva) {
        await this.initializeMap();
        await this.setLayerVisibility(layerConfig);

        const ingaendeOmraden = await this.getIngaendeOmraden(params, layerConfig.layerId);
        if (ingaendeOmraden && ingaendeOmraden.features.length) {
            this.ingaendeOmraden = ingaendeOmraden.features;
            if (invNiva !== InventeringsNiva.LAND) {
            }
            this.zoomToIngaendeOmraden(this.ingaendeOmraden);
            this.view.ui.add(this.popupComponent.popup.nativeElement, 'top-right');
        }
        this.clickService.activate(this.view, this.ingaendeOmraden);
        this.loading.next(false);
    }

    private async initializeMap() {
        try {
            const esriMap = createMap();
            const esriView = createView(esriMap, this.mapViewEl.nativeElement);
            const abinService = createMapImage(environment.kartor.geodataportalVisaAbinrapport_3_0.url);
            const lmtopowebb = createWMS(environment.kartor.lmTopowebbSkikt.url, [
                { name: environment.kartor.lmTopowebbSkikt.layers.text },
                {
                    name: environment.kartor.lmTopowebbSkikt.layers.administrativ_indelning_nedtonad
                },
                {
                    name: environment.kartor.lmTopowebbSkikt.layers.kommunikation
                },

                { name: environment.kartor.lmTopowebbSkikt.layers.hydrografi_ytor }
            ]);
            const selectionLayer = createGraphicsLayer();

            esriMap.add(abinService);
            esriMap.add(lmtopowebb);
            esriMap.add(selectionLayer);
            disableNavigation(esriView);
            this.selectionLayer = selectionLayer;
            this.view = esriView;
            this.abinMapService = abinService;
        } catch (error) {
            console.error(error);
            this.loading.next(false);
        }
    }

    private addClickListener() {
        this.clickService.clicked$.subscribe((result) => {
            if (result && result.indexOf(true) > -1) {
                const hit = this.ingaendeOmraden[result.indexOf(true)];
                hit.symbol = highlightSymbol();
                this.selectionLayer.graphics.removeAll();
                this.selectionLayer.add(hit);

                this.mapClicked.emit(hit);
                this.popupComponent.show = true;
            } else {
                this.selectionLayer.removeAll();
                this.popupComponent.show = false;
            }
        });
        // this.view.on('click', async (clickEv: any) => {
        //     const mapPoint = clickEv.mapPoint;
        //     const result = await Promise.all(this.ingaendeOmraden.map((x) => within(mapPoint, x.geometry)));

        // });
    }

    private async zoomToIngaendeOmraden(ingaendeOmraden: __esri.Graphic[]) {
        const union = await unionGeometries(ingaendeOmraden.map((x) => x.geometry));
        const gotoExtent = union.extent.expand(1.1);
        await this.view.goTo(gotoExtent);
    }

    private async getIngaendeOmraden(params: Params, layerId: number): Promise<__esri.FeatureSet> {
        const query = ingaendeOmradenQuery(params);
        if (!query) {
            return;
        }
        await this.abinMapService.when();
        const sublayer = this.abinMapService.findSublayerById(layerId);
        sublayer.definitionExpression = query.where;
        if (!sublayer || !this.abinMapService.capabilities.operations.supportsQuery) {
            return;
        }
        return await sublayer.queryFeatures(query);
    }

    private async setLayerVisibility(layerConfig: SublayerConfiguration) {
        this.abinMapService.when(() => {
            this.abinMapService.allSublayers.forEach((x) => (x.visible = false));
            this.abinMapService.findSublayerById(layerConfig.layerId).visible = true;
            this.abinMapService.findSublayerById(layerConfig.parentId).visible = true;
            this.abinMapService.findSublayerById(environment.kartor.geodataportalVisaAbinrapport_3_0.sublayers.sjoar.id).visible = true;
            this.abinMapService.findSublayerById(environment.kartor.geodataportalVisaAbinrapport_3_0.sublayers.sjoar.parendId).visible = true;
        });
    }
}

export const gotlandGeometry = {
  spatialReference: {
    wkid: 3006,
    latestWkid: 3006,
  },
  rings: [
    [
      [690125.53899999987, 6310851.6119999997],
      [689712.78799999971, 6312647.4749999996],
      [693364.04600000009, 6320148.4269999992],
      [692927.48199999984, 6322212.182],
      [693443.42100000009, 6324633.1239999998],
      [694872.17399999965, 6325704.6889999993],
      [698166.24299999978, 6326855.6280000005],
      [699198.12000000011, 6330189.3849999998],
      [698682.18099999987, 6331102.1989999991],
      [696181.86400000006, 6327966.8809999991],
      [692927.48199999984, 6328046.2559999991],
      [693681.54600000009, 6335626.5830000006],
      [691101.85400000028, 6337015.6490000002],
      [689077.78699999955, 6341579.7200000007],
      [688641.22400000039, 6347890.0449999999],
      [685545.59300000034, 6350191.9250000007],
      [685982.15600000042, 6354359.1209999993],
      [688164.97300000023, 6356383.1870000008],
      [690030.28899999987, 6358208.8159999996],
      [689950.91399999987, 6363526.9519999996],
      [687847.47200000007, 6368686.3369999994],
      [684354.96499999985, 6368011.648],
      [683997.77699999977, 6369757.9020000007],
      [686498.09399999958, 6371821.6559999995],
      [686577.46999999974, 6375949.1640000008],
      [685228.09200000018, 6380592.6109999996],
      [685902.78100000042, 6383926.3670000006],
      [694356.23500000034, 6392459.1970000006],
      [696856.55300000031, 6396388.2670000009],
      [699912.49600000028, 6400952.3389999997],
      [703920.94199999981, 6413890.4900000002],
      [709874.07899999991, 6417105.1840000004],
      [712533.14599999972, 6417779.8729999997],
      [714438.15000000037, 6422939.2579999994],
      [720550.03799999971, 6427820.8300000001],
      [725312.54700000025, 6423415.5089999996],
      [727058.80099999998, 6427225.5170000009],
      [739004.7620000001, 6428336.7689999994],
      [741068.51599999983, 6432781.7780000009],
      [745196.02400000021, 6437068.0360000003],
      [758015.11199999973, 6435996.4719999991],
      [759086.67700000014, 6432067.4010000005],
      [755554.48300000001, 6430202.0850000009],
      [751228.53600000031, 6429170.2080000006],
      [747934.46700000018, 6427582.7050000001],
      [746307.27699999977, 6426193.6400000006],
      [748053.53000000026, 6424328.3230000008],
      [747458.21600000001, 6418097.3729999997],
      [743846.64699999988, 6413414.2390000001],
      [739877.88900000043, 6406627.6630000006],
      [737060.07100000046, 6403968.5950000007],
      [732376.93599999975, 6402381.0920000002],
      [731027.55800000019, 6399126.7100000009],
      [727654.11400000006, 6398967.9600000009],
      [727336.61400000006, 6396864.5179999992],
      [726265.04899999965, 6392935.4480000008],
      [728606.61600000039, 6391824.1960000005],
      [729162.24199999962, 6385513.8709999993],
      [726939.7379999999, 6380394.1730000004],
      [729162.24199999962, 6374599.7860000003],
      [740909.76599999983, 6375393.5380000006],
      [740751.01499999966, 6372575.7200000007],
      [736980.6950000003, 6369718.2139999997],
      [735551.94299999997, 6366979.7709999997],
      [730114.74399999995, 6364916.0170000009],
      [726820.67499999981, 6363407.8890000004],
      [724360.04499999993, 6359915.3819999993],
      [727455.67599999998, 6358089.7530000005],
      [727535.05200000014, 6354120.9949999992],
      [724955.35900000017, 6349874.4240000006],
      [724320.35800000001, 6346977.2310000006],
      [718168.78299999982, 6347175.6689999998],
      [717295.65600000042, 6346500.9800000004],
      [716819.40500000026, 6344675.3509999998],
      [712136.27099999972, 6342333.784],
      [712334.7089999998, 6340270.0299999993],
      [709651.82799999975, 6339506.0439999998],
      [709294.63999999966, 6338156.6669999994],
      [713898.39900000021, 6339426.6689999998],
      [712707.77199999988, 6336251.6630000006],
      [708818.38900000043, 6334545.0969999991],
      [705048.06900000013, 6332798.8430000003],
      [703024.00200000033, 6329901.6500000004],
      [703897.12899999972, 6328631.648],
      [703539.94099999964, 6327956.9590000007],
      [703103.37799999956, 6325258.2029999997],
      [703301.81599999964, 6324424.7640000004],
      [704135.25499999989, 6323908.8259999994],
      [705286.1950000003, 6324067.5759999994],
      [706556.19699999969, 6323194.4489999991],
      [706318.07199999969, 6321249.7579999994],
      [705286.1950000003, 6321170.3829999994],
      [703341.50299999956, 6316725.3739999998],
      [700761.80999999959, 6313590.0549999997],
      [694888.04899999965, 6310851.6119999997],
      [690125.53899999987, 6310851.6119999997],
    ],
  ],
};

import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ui-legend-item',
  templateUrl: './ui-legend-item.component.html',
  styleUrls: ['./ui-legend-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiLegendItemComponent implements OnInit {
  @Input() color: string;
  constructor() {}

  ngOnInit(): void {}
}

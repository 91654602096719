<div class="fordjupning-karta__legend">
  <div class="legend-container">
    <!-- <div class="legend-item">
      <div class="legend-item__symbol sjo-symbol"></div>
      <div class="legend-item__text">Sjö</div>
    </div> -->
    <div class="legend-item" *ngIf = "[3, 4, 5, 7, 8, 9].includes((layerConfig$ | async)?.layerId)">
      <div class="legend-item__symbol tolerabel-symbol"></div>
      <div class="legend-item__text">Tolerabel</div>
    </div>
    <div class="legend-item" *ngIf = "[3, 4, 5, 7, 8, 9].includes((layerConfig$ | async)?.layerId)">
      <div class="legend-item__symbol allvarlig-symbol"></div>
      <div class="legend-item__text">Allvarlig</div>
    </div>
    <div class="legend-item" *ngIf = "[3, 4, 5, 7, 8, 9].includes((layerConfig$ | async)?.layerId)">
      <div class="legend-item__symbol svar-symbol"></div>
      <div class="legend-item__text">Svår</div>
    </div>
    <div class="legend-item" *ngIf = "[3, 4, 5, 7, 8, 9].includes((layerConfig$ | async)?.layerId)">
      <div class="legend-item__symbol mkt-svar-symbol"></div>
      <div class="legend-item__text">Mycket svår</div>
    </div>
    <div class="legend-item" *ngIf = "[11, 12, 13, 15, 16, 17, 19, 20, 21, 23, 24, 25].includes((layerConfig$ | async)?.layerId)">
      <div class="legend-item__symbol tolerabel-symbol"></div>
      <div class="legend-item__text">Mål uppnått</div>
    </div>
    <div class="legend-item" *ngIf = "[11, 12, 13, 15, 16, 17, 19, 20, 21, 23, 24, 25].includes((layerConfig$ | async)?.layerId)">
      <div class="legend-item__symbol mkt-svar-symbol"></div>
      <div class="legend-item__text">Mål ej uppnått</div>
    </div>
  </div>
</div>

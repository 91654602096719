import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { toNumber } from 'lodash-es';
import { Observable, Subscription } from 'rxjs';
import { ChartCategory, ColorMapService } from 'src/app/common/service';
import { AbinResultDto } from 'src/app/inge-api/client/models';
import { InGeApiState } from 'src/app/store/state-model/inge-api.model';

@Component({
  selector: 'app-foder-foderprognos-chart',
  templateUrl: './foder-foderprognos-chart.component.html',
  styleUrls: ['./foder-foderprognos-chart.component.scss'],
})
export class FoderFoderprognosChartComponent implements OnInit {
  inventering$: Observable<AbinResultDto>;
  loadingInventeringar$: Observable<boolean>;
  inventeringarError$: Observable<boolean>;
  private subs: Subscription[];
  options: any;
  data: any[][];
  constructor(
    private store: Store<InGeApiState>,
    private colorMap: ColorMapService
  ) {
    this.inventering$ = this.store.pipe(
      select((x) => {
        if (
          x &&
          x.abin &&
          x.abin.inventeringar &&
          x.abin.inventeringar.length > 0
        ) {
          return x.abin.inventeringar[0];
        }
      })
    );
    this.loadingInventeringar$ = store.pipe(
      select((x) => x.abin.loadingInventeringar)
    );
    this.inventeringarError$ = store.pipe(
      select((x) => x.abin.inventeringarError)
    );
  }

  ngOnInit(): void {
    this.subs = [];

    this.subs.push(
      this.inventering$.subscribe((inventering) => {
        if (inventering) {
          this.generateChartData(inventering);
        }
      })
    );
  }

  generateChartData(inventering: AbinResultDto) {
    const data: any[][] = [['År', 'Foderprognos ha']];
    const options: any = {
      colors: [this.colorMap.getColor(ChartCategory.SKOGSMARK)],
      curveType: 'function',
      animation: {
        startup: true,
      },
      vAxis: {
        title: 'Hektar',
        titleTextStyle: {
          italic: false,
          fontSize: 16,
        },
        minValue: 0,
      },
      legend: {
        position: 'none',
      },
      height: 250,
      chartArea: { width: '80%', height: '70%' },
    };
    inventering.foderprognos.forEach((prognosDto) => {
      data.push([prognosDto.ar.toString(), toNumber(prognosDto.foderprognos)]);
    });
    this.data = data;
    this.options = options;
  }
}

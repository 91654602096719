import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { round } from 'lodash-es';
import { Subscription } from 'rxjs';
import { AndelCombochartBaseComponent } from '../../base-components';

@Component({
    selector: 'app-oskadad-tall-chart',
    templateUrl: './oskadad-tall-chart.component.html',
    styleUrls: ['./oskadad-tall-chart.component.scss']
})
export class OskadadTallChartComponent extends AndelCombochartBaseComponent implements OnInit, OnDestroy {
    @ViewChild('chartContainer') chartContainer: ElementRef;
    private subs: Subscription[];
    data: any[][];
    ngOnInit(): void {
        this.subs = [];
        this.subs.push(
            this.inventeringar$.subscribe((x) => {
                if (x && x.length > 0) {
                    const data: any[][] = [
                        [
                            'År',
                            { id: 'Arsskada_1ar', type: 'number', label: 'Årsvärde' },
                            { id: 'Medelfel_low', type: 'number', role: 'interval' },
                            { id: 'Medelfel_high', type: 'number', role: 'interval' },
                            { id: 'tool_tip_1ar', type: 'string', role: 'tooltip', p: { html: true } },
                            { id: 'Medel_3ar', type: 'number', label: 'Sammanvägning för de tre senaste inventeringarna' },
                            { id: 'Medelfel_3ar_low', type: 'number', role: 'interval' },
                            { id: 'Medelfel_3ar_high', type: 'number', role: 'interval' },
                            { id: 'tool_tip_3ar', type: 'string', role: 'tooltip', p: { html: true } }
                        ]
                    ];
                    x.forEach((inv) => {
                        if (inv.oskadadTallAndel || inv.oskadadTallAndel === 0) {
                            // Årsskada
                            const arsskadaAndel = round(inv.oskadadTallAndel * 100, 1);
                            const mfelArsskada = inv.medelfelOskadadTall * 100 * 2;
                            const mfel_Arsskada_low = arsskadaAndel > 0 ? round(arsskadaAndel - mfelArsskada, 2) : null;
                            const mfel_Arsskada_high = arsskadaAndel > 0 ? round(arsskadaAndel + mfelArsskada, 2) : null;

                            // 3års medel
                            const arsskadaAndel3InvMedel = round(inv.oskadadTallAndel3InvMedel * 100, 1);
                            const mfel3arArsskada95procent = inv.medelfelOskadadTall3InvMedel * 2 * 100;
                            const mfel3arArsskada_low = arsskadaAndel3InvMedel - mfel3arArsskada95procent;
                            const mfel3arArsskada_high = arsskadaAndel3InvMedel + mfel3arArsskada95procent;

                            data.push([
                                `${inv.invAr}`,
                                arsskadaAndel > 0 ? arsskadaAndel : null,
                                mfel_Arsskada_low > 0 ? mfel_Arsskada_low : 0,
                                mfel_Arsskada_high,
                                this.getTooltip(false, arsskadaAndel, mfelArsskada),
                                arsskadaAndel3InvMedel,
                                mfel3arArsskada_low > 0 ? mfel3arArsskada_low : 0,
                                mfel3arArsskada_high,
                                this.getTooltip(true, arsskadaAndel3InvMedel, mfel3arArsskada95procent)
                            ]);
                        }
                    });
                    this.options = {
                        ...this.options,
                        tooltip: { isHtml: true },
                        interval: {
                            Medelfel_low: { style: 'bars', lineWidth: 1 },
                            Medelfel_high: { style: 'bars', lineWidth: 1 },
                            Medelfel_3ar_low: { style: 'area', curveType: 'none', fillOpacity: 0.3 },
                            Medelfel_3ar_high: { style: 'area', curveType: 'none', fillOpacity: 0.3 }
                        },
                        height: 250,
                        vAxis: {
                            ...this.options.vAxis,
                            maxValue: this.getMaxValue(data)
                        }
                    };

                    this.data = data;
                }
            })
        );
    }

    ngOnDestroy(): void {
        this.subs.forEach((x) => x.unsubscribe());
    }
}

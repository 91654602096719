import { SpatialReference } from '@arcgis/core/geometry';
import Map from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';

export const createView = (map: Map, container: HTMLDivElement) => {
  const mapViewProperties = {
    container,
    map,
    spatialReference: new SpatialReference({ wkid: 3006 }),
    navigation: {
      mouseWheelZoomEnabled: false,
      browserTouchPanEnabled: false,
    },
    ui: {
      components: [],
    },
  };
  return new MapView(mapViewProperties);
};

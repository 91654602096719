import { SpatialReference } from '@arcgis/core/geometry';
import Geometry from '@arcgis/core/geometry/Geometry';
import * as geometryEngineAsync from '@arcgis/core/geometry/geometryEngineAsync';
import Graphic from '@arcgis/core/Graphic';

export const unionGeometries = async (
  geometries: Geometry[]
): Promise<Geometry> => {
  return await geometryEngineAsync.union(geometries);
};

export const within = async (inner: Geometry, outer: Geometry) => {
  return await geometryEngineAsync.within(inner, outer);
};

export const setSpatialRef = (geometry: Geometry, wkid: number) => {
  geometry.spatialReference = new SpatialReference({ wkid });
};

export const createGraphic = (geometry: Geometry): Graphic => {
  setSpatialRef(geometry, 3006);
  return new Graphic({ geometry });
};

export const calculateDifference = async (
  geom1: Geometry,
  geom2: Geometry
): Promise<Geometry> => {
  return await geometryEngineAsync.difference(geom1, geom2);
};

import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import MapImageLayer from '@arcgis/core/layers/MapImageLayer';
import WMSLayer from '@arcgis/core/layers/WMSLayer';

export const createWMS = (url: string, sublayers: { name: string }[]) => {
  return new WMSLayer({
    url,
    sublayers,
  });
};

export const createMapImage = (url: string) => {
  return new MapImageLayer({ url });
};

export const createGraphicsLayer = () => {
  return new GraphicsLayer({});
};

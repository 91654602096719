<div class="row">
    <div class="col-xs-12">
        <!-- <h4 class="mt-0">Historisk utveckling av andel tallstammar med årligt uppkomna viltskador</h4> -->
        <div class="subheadertext">Välj område med knapparna för att visa utvecklingen över tid och vilka år som inventerats.</div>
    </div>

    <div class="col-xs-12">
        <app-fordjupning-button-group (selectedOmradeEvent)="onOmradeSelect($event)"></app-fordjupning-button-group>
        <p><b>Valt område</b>: {{ omradeNamn$ | async }}</p>
    </div>
</div>

<div class="row">
    <div class="col-md-8 col-xs-12 col-lg-9">
        <app-arsskada-combochart
            [inventeringar]="inventeringar$ | async"
            [vardeVariabler]="vardeVariabler"
            [diagramBeskrivning]="'Historisk utveckling av andel tallstammar med årligt uppkomna viltskador'"
        ></app-arsskada-combochart>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 first-sm first-xs first-md last-lg">
        <ng-container *ngIf="senasteInventering$ | async as senasteInventering">
            <app-sammanvagning-card
                [inventering]="senasteInventering"
                vardeVariabel="arsskadaTallAndel3InvMedel"
                cardBeskrivning="Andel årskador på tall, sammanvägning för de tre senaste inventeringarna"
            >
                <ng-container sammanvagningCardIcon *ngIf="senasteInventering?.slutsatsArsskadaTallInt > 0; else elseTemplate">
                    <span class="abinicon-arsskada_tall_uppfyllt"></span>
                </ng-container>
                <ng-template sammanvagningCardIcon #elseTemplate>
                    <span class="abinicon-arsskada_tall_ej_uppfyllt"></span>
                </ng-template>
            </app-sammanvagning-card>
        </ng-container>
    </div>
</div>
<ng-container *ngIf="lan13Chapter33WarningConfig$ | async as lan13Afo3warning">
    <div class="row">
        <div class="col-md-8 col-xs-12 col-lg-8">
            <div class="col-xs-12" *ngIf="lan13Afo3warning?.render">
                <app-rapport-warning [warningConfig]="lan13Afo3warning"> </app-rapport-warning>
            </div>
        </div>
    </div>
</ng-container>

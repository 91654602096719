<ul class="rapport-table-of-content" role="directory">
    <li class="rapport-table-of-content-item">
        <a [routerLink]="[]" queryParamsHandling="merge" fragment="introduktion-section">1. Introduktion</a>
    </li>
    <li class="rapport-table-of-content-item">
        <a [routerLink]="[]" queryParamsHandling="merge" fragment="sammanfattning-section">2. Sammanfattning</a>
    </li>
    <li class="rapport-table-of-content-item">
        <a [routerLink]="[]" queryParamsHandling="merge" fragment="viltskador-section">3. Viltskador</a>
        <ul>
            <li class="rapport-table-of-content-item">
                <a [routerLink]="[]" queryParamsHandling="merge" fragment="arsskador-tall-section">3.1 Årskador Tall</a>
            </li>
            <li class="rapport-table-of-content-item">
                <a [routerLink]="[]" queryParamsHandling="merge" fragment="oskadad-tall-section">3.2 Oskadad Tall</a>
            </li>
            <li class="rapport-table-of-content-item">
                <a [routerLink]="[]" queryParamsHandling="merge" fragment="arsskador-gran-section">3.3 Årsskador Gran</a>
            </li>
            <li class="rapport-table-of-content-item">
                <a [routerLink]="[]" queryParamsHandling="merge" fragment="geografisk-fordelning-section">3.4 Geografisk fördelning</a>
            </li>
            <!-- <li class="rapport-table-of-content-item"><a [routerLink]="[]" queryParamsHandling="merge"
          fragment="nar-intraffade-skadorna-section">3.5
          När inträffade skadorna</a></li> -->
        </ul>
    </li>
    <li class="rapport-table-of-content-item">
        <a [routerLink]="[]" queryParamsHandling="merge" fragment="rase-section">4. Rönn, asp, sälg och ek (RASE)</a>
        <ul>
            <li class="rapport-table-of-content-item">
                <a [routerLink]="[]" queryParamsHandling="merge" fragment="forekomst-rase-section">4.1 Förekomst av rönn, asp, sälg och ek (RASE)</a>
            </li>
            <li class="rapport-table-of-content-item">
                <a [routerLink]="[]" queryParamsHandling="merge" fragment="konkurrensstatus-rase-section"
                    >4.2 Konkurrensstatus för rönn, asp, sälg och ek (RASE)</a
                >
            </li>
        </ul>
    </li>
    <li class="rapport-table-of-content-item">
        <a [routerLink]="[]" queryParamsHandling="merge" fragment="aterbeskogning-section">5. Återbeskogning</a>

        <ul>
            <li class="rapport-table-of-content-item">
                <a fragment="markslag-foryngrade-tradslag-section" [routerLink]="[]" queryParamsHandling="merge">5.1 Markslag och föryngrade trädslag</a>
            </li>
            <li class="rapport-table-of-content-item">
                <a [routerLink]="[]" queryParamsHandling="merge" fragment="mager-mark-section">5.2 Mager mark</a>
            </li>
            <li class="rapport-table-of-content-item">
                <a [routerLink]="[]" queryParamsHandling="merge" fragment="mellanmark-section">5.3 Mellanmark</a>
            </li>
            <li class="rapport-table-of-content-item">
                <a [routerLink]="[]" queryParamsHandling="merge" fragment="bordig-mark-section">5.4 Bördig mark</a>
            </li>
        </ul>
    </li>
    <li class="rapport-table-of-content-item">
        <a [routerLink]="[]" queryParamsHandling="merge" fragment="foder-section">6. Foder</a>

        <ul>
            <li class="rapport-table-of-content-item">
                <a [routerLink]="[]" queryParamsHandling="merge" fragment="foder-agoslagsfordelning-section">6.1 Ägoslagsfördelning</a>
            </li>
            <li class="rapport-table-of-content-item">
                <a [routerLink]="[]" queryParamsHandling="merge" fragment="foder-foderprognos-section">6.2 Foderprognos</a>
            </li>
            <li class="rapport-table-of-content-item">
                <a [routerLink]="[]" queryParamsHandling="merge" fragment="foder-skogenssammansattning-section">6.3 Skogens sammansättning</a>
            </li>
            <li class="rapport-table-of-content-item">
                <a [routerLink]="[]" queryParamsHandling="merge" fragment="foder-tradslagssammansattning-section">6.4 Trädslagssammansättning</a>
            </li>
            <li class="rapport-table-of-content-item">
                <a [routerLink]="[]" queryParamsHandling="merge" fragment="foder-ovriga-bestandsuppgifter-section">6.5 Övriga beståndsuppgifter</a>
            </li>
        </ul>
    </li>
    <!-- <li class="rapport-table-of-content-item"><a [routerLink]="" queryParamsHandling="merge"
      fragment="nyckeltal-section">7.
      Nyckeltal</a></li> -->
    <li class="rapport-table-of-content-item">
        <a [routerLink]="[]" fragment="om-skobi-section" queryParamsHandling="merge">7. Om Skoglig Betesinventering</a>
    </li>
    <li class="rapport-table-of-content-item">
        <a fragment="begrepp-forklaringar-section" [routerLink]="[]" queryParamsHandling="merge">8. Begrepp och förklaringar</a>
    </li>
    <li class="rapport-table-of-content-item">
        <a [routerLink]="[]" fragment="litteratur-section" queryParamsHandling="merge">9. Litteraturförteckning</a>
    </li>
</ul>

import pptxgen from 'pptxgenjs';
export default (
  slide: pptxgen.Slide,
  inventeringsar: number,
  omradesnamn: string
): pptxgen.Slide => {
  slide.addImage({
    path: 'assets/tallar4.jpg',
    x: 0,
    y: 0,
    h: '40%',
    w: '100%',
  });
  slide.addText('Skoglig Betesinventering', {
    x: '5%',
    y: '10%',
    // w: 10,
    fontSize: 36,
    color: 'ffffff',
  });
  slide.addText('Älgbetesinventering (Äbin) och foderprognos', {
    x: '5%',
    y: '20%',
    // w: 10,
    fontSize: 24,
    color: 'ffffff',
  });

  slide.addText(`Resultat från Äbin och foderprognoser år ${inventeringsar}`, {
    x: 0,
    y: 3.75,
    h: 0.7,
    w: '100%',
    fontSize: 36,
    bold: true,
    color: '000000',
    align: 'center',
  });

  slide.addText(omradesnamn, {
    x: 0,
    y: 4.5,
    h: 0.6,
    w: '100%',
    fontSize: 24,
    bold: true,
    color: '000000',
    align: 'center',
  });
  return slide;
};

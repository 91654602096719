import {
  AbinResultDto,
  AbinMetadataDto,
  AbinGeometriDto,
} from 'src/app/inge-api/client/models';

export interface InGeApiState {
  abin: AbinState;
  uppfoljningsrapport: UppfoljningRapportState;
}

export interface AbinState {
  inventeringar: AbinResultDto[];
  inventeringarError: boolean;
  loadingInventeringar: boolean;
  inventeringsNiva: InventeringsNiva;

  rapportUnderlag: AbinResultDto;

  landsdelar: AbinMetadataDto[];
  landsdelarError: boolean;
  loadingLandsdelar: boolean;

  lan: AbinMetadataDto[];
  lanError: boolean;
  loadingLan: boolean;

  afo: AbinMetadataDto[];
  afoError: boolean;
  loadingAfo: boolean;

  delomraden: AbinMetadataDto[];
  delomradenError: boolean;
  loadingDelomraden: boolean;

  inventeringsGeometrier: AbinGeometriDto;
  inventeringsGeometrierError: boolean;
  loadingInventeringsGeometrier: boolean;
}

export interface UppfoljningRapportState {
  inventeringsNiva: InventeringsNiva;

  ingaendeOmraden: AbinMetadataDto[];
  ingaendeOmradenError: boolean;
  loadingIngaendeOmraden: boolean;

  fordjupning: Fordjupning;
  fordjupningError: boolean;
  loadingFordjupning: boolean;

  inventeringar: AbinResultDto[];
  inventeringarError: boolean;
  loadingInventeringar: boolean;
}

export interface Fordjupning {
  fordjupningsNiva: InventeringsNiva;
  inventeringar: AbinResultDto[][];
}

export enum InventeringsNiva {
  LAND = 'HelaLandet',
  LANDSDEL = 'Landsdel',
  LAN = 'Lan',
  AFO = 'Algforvaltningsomrade',
  STRATUM = 'Delomrade',
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { round } from 'lodash-es';
import { Subscription } from 'rxjs';
import { AndelCombochartBaseComponent } from '../../base-components';

@Component({
    selector: 'app-foryngring-bordig-mark-andel-chart',
    templateUrl: './foryngring-bordig-mark-andel-chart.component.html',
    styleUrls: ['./foryngring-bordig-mark-andel-chart.component.scss']
})
export class ForyngringBordigMarkAndelChartComponent extends AndelCombochartBaseComponent implements OnInit, OnDestroy {
    private subs: Subscription[];
    data: any[][];
    public andelBordigMark: number = 0;

    ngOnInit(): void {
        this.subs = [];
        this.subs.push(
            this.inventeringar$.subscribe((x) => {
                if (x && x.length > 0) {
                    const data: any[][] = [
                        [
                            'År',
                            { id: 'Arsskada_1ar', type: 'number', label: 'Årsvärde' },
                            { id: 'tool_tip_1ar', type: 'string', role: 'tooltip', p: { html: true } },
                            { id: 'Medel_3ar', type: 'number', label: 'Sammanvägning för de tre senaste inventeringarna' },
                            { id: 'tool_tip_3ar', type: 'string', role: 'tooltip', p: { html: true } }
                        ]
                    ];

                    x.forEach((inv) => {
                        if (inv.standortsanpassningAndelBordigaMarkerMedTall || inv.standortsanpassningAndelBordigaMarkerMedTall === 0) {
                            // Uppdatera värdet för bördig mark om det finns för inventeringen
                            if (inv.andelBordigaMarker3InvMedel && inv.andelBordigaMarker3InvMedel > 0) {
                                this.andelBordigMark = round(inv.andelBordigaMarker3InvMedel * 100, 1);
                            }

                            // Årsskada
                            const arsVarde = round(inv.standortsanpassningAndelBordigaMarkerMedTall * 100, 1);

                            // 3års medel
                            const trearsMedel = round(inv.standortsanpassningAndelBordigaMarkerMedTall3InvMedel * 100, 1);

                            data.push([
                                `${inv.invAr}`,
                                arsVarde > 0 ? arsVarde : null,
                                this.getTooltipNoFelmarginal(false, arsVarde),
                                trearsMedel,
                                this.getTooltipNoFelmarginal(true, trearsMedel)
                            ]);
                        }
                    });

                    this.options = {
                        ...this.options,
                        tooltip: { isHtml: true },
                        height: 250,
                        vAxis: {
                            ...this.options.vAxis,
                            maxValue: this.getMaxValue(data)
                        }
                    };

                    this.data = data;
                }
            })
        );
    }

    ngOnDestroy(): void {
        this.subs.forEach((x) => x.unsubscribe());
    }
}

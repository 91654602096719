import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'select[ui-select]',
  templateUrl: './ui-select.component.html',
  styleUrls: ['./ui-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiSelectComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

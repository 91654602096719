import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AbinService } from '../../inge-api/client/services';
import * as apiActions from '../actions/abin.actions';
@Injectable()
export class AbinEffects {
    getAbinSummeringarSamtliga$ = createEffect(() =>
        this.actions$.pipe(
            ofType(apiActions.getAbinSummeringLand),
            mergeMap((action) =>
                this.ingeApi.abinSammanfattningHelaLandet().pipe(
                    map((result) => {
                        return apiActions.getAbinSummeringLandSuccess({
                            result
                        });
                    }),
                    catchError(() => of(apiActions.getAbinSummeringLandError()))
                )
            )
        )
    );

    getAbinSummeringLandsdelar$ = createEffect(() =>
        this.actions$.pipe(
            ofType(apiActions.getAbinSummeringLandsdel),
            mergeMap((action) =>
                this.ingeApi
                    .abinSammanfattningLandsel({
                        landsdelkod: action.landsdelKod
                    })
                    .pipe(
                        map((result) => {
                            return apiActions.getAbinSummeringLandsdelSuccess({
                                result
                            });
                        }),
                        catchError(() => of(apiActions.getAbinSummeringLandsdelError()))
                    )
            )
        )
    );

    getAbinSummeringLan$ = createEffect(() =>
        this.actions$.pipe(
            ofType(apiActions.getAbinSummeringLan),
            // delay(30000),
            mergeMap((action) =>
                this.ingeApi
                    .abinSammanfattningLan({
                        lankod: action.lankod
                    })
                    .pipe(
                        map((result) => {
                            return apiActions.getAbinSummeringLanSuccess({
                                result
                            });
                        }),
                        catchError(() => of(apiActions.getAbinSummeringLanError()))
                    )
            )
        )
    );

    getAbinSummeringAfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(apiActions.getAbinSummeringLanAfo),
            mergeMap((action) =>
                this.ingeApi
                    .abinSammanfattningLanAfo({
                        lankod: action.lankod,
                        afonr: action.afonr
                    })
                    .pipe(
                        map((result) => {
                            return apiActions.getAbinSummeringLanAfoSuccess({
                                result
                            });
                        }),
                        catchError(() => of(apiActions.getAbinSummeringLanAfoError()))
                    )
            )
        )
    );

    getAbinSummeringDelomrade$ = createEffect(() =>
        this.actions$.pipe(
            ofType(apiActions.getAbinSummeringDelomrade),
            mergeMap((action) =>
                this.ingeApi
                    .abinSammanfattningStratum({
                        lankod: action.lanKod,
                        afonr: action.afoNr,
                        delomradesnummer: action.delomradesnummer
                    })
                    .pipe(
                        map((result) => {
                            return apiActions.getAbinSummeringDelomradeSuccess({
                                result
                            });
                        }),
                        catchError(() => of(apiActions.getAbinSummeringDelomradeError()))
                    )
            )
        )
    );

    getAbinLandsdelar$ = createEffect(() =>
        this.actions$.pipe(
            ofType(apiActions.getAbinLandsdelar),
            mergeMap((action) =>
                this.ingeApi.abinGetMetadataLandsdelar().pipe(
                    map((result) => {
                        return apiActions.getAbinLandsdelarSuccess({
                            result
                        });
                        return apiActions.getAbinLandsdelarError();
                    }),
                    catchError(() => of(apiActions.getAbinLandsdelarError()))
                )
            )
        )
    );

    getAbinLan$ = createEffect(() =>
        this.actions$.pipe(
            ofType(apiActions.getAbinLan),
            mergeMap((action) =>
                this.ingeApi.abinGetMetadataLan({ landsdelkod: action.landsdelKod }).pipe(
                    map((res) => {
                        let sorted = res.sort((a, b) => (a.namn > b.namn ? 1 : b.namn > a.namn ? -1 : 0));

                        if (action.exclude && action.exclude.length && action.exclude.length > 0) {
                            sorted = sorted.filter((x) => {
                                if (!action.exclude.includes(x.kod)) {
                                    return x;
                                }
                            });
                        }
                        return sorted;
                    }),
                    map((result) => {
                        return apiActions.getAbinLanSuccess({
                            result
                        });
                    }),
                    catchError(() => of(apiActions.getAbinLanError()))
                )
            )
        )
    );
    getAbinAfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(apiActions.getAbinAfo),
            mergeMap((action) =>
                this.ingeApi
                    .abinGetMetadataAfo({
                        landsdelkod: action.landsdelKod,
                        lankod: action.lanKod
                    })
                    .pipe(
                        map((result) => {
                            return apiActions.getAbinAfoSuccess({
                                result
                            });
                        }),
                        catchError(() => of(apiActions.getAbinAfoError()))
                    )
            )
        )
    );

    getAbinDelomraden$ = createEffect(() =>
        this.actions$.pipe(
            ofType(apiActions.getAbinDelomraden),
            mergeMap((action) =>
                this.ingeApi
                    .abinGetMetadataStratum({
                        landsdelkod: action.landsdelkod,
                        lankod: action.lankod,
                        afonr: action.afonr
                    })
                    .pipe(
                        map((result) => {
                            return apiActions.getAbinDelomradenSuccess({
                                result
                            });
                        }),
                        catchError(() => of(apiActions.getAbinDelomradenError()))
                    )
            )
        )
    );

    getAbinHelaLandetGeometri$ = createEffect(() =>
        this.actions$.pipe(
            ofType(apiActions.getAbinHelaLandetGeometri),
            mergeMap((action) =>
                this.ingeApi.abinSammanfattningHelaLandetGeometri({}).pipe(
                    map((result) => {
                        return apiActions.getAbinHelaLandetGeometriSuccess({
                            result
                        });
                    }),
                    catchError(() => of(apiActions.getAbinHelaLandetGeometriError()))
                )
            )
        )
    );

    getAbinLandsdelGeometri$ = createEffect(() =>
        this.actions$.pipe(
            ofType(apiActions.getAbinLandsdelGeometri),
            mergeMap((action) =>
                this.ingeApi
                    .abinSammanfattningLandsdelGeometri({
                        landsdelkod: action.landsdelkod
                    })
                    .pipe(
                        map((result) => {
                            return apiActions.getAbinLandsdelGeometriSuccess({
                                result
                            });
                        }),
                        catchError(() => of(apiActions.getAbinLandsdelGeometriError()))
                    )
            )
        )
    );

    getAbinLanGeometri$ = createEffect(() =>
        this.actions$.pipe(
            ofType(apiActions.getAbinLanGeometri),
            mergeMap((action) =>
                this.ingeApi
                    .abinSammanfattningLanGeometri({
                        lankod: action.lankod
                    })
                    .pipe(
                        map((result) => {
                            return apiActions.getAbinLanGeometriSuccess({
                                result
                            });
                        }),
                        catchError(() => of(apiActions.getAbinLanGeometriError()))
                    )
            )
        )
    );

    getAbinAfoGeometri$ = createEffect(() =>
        this.actions$.pipe(
            ofType(apiActions.getAbinAfoGeometri),
            mergeMap((action) =>
                this.ingeApi
                    .abinSammanfattningAfoGeometri({
                        lankod: action.lankod,
                        afonr: action.afonr
                    })
                    .pipe(
                        map((result) => {
                            return apiActions.getAbinAfoGeometriSuccess({
                                result
                            });
                        }),
                        catchError(() => of(apiActions.getAbinAfoGeometriError()))
                    )
            )
        )
    );

    getAbinDelomradeGeometri$ = createEffect(() =>
        this.actions$.pipe(
            ofType(apiActions.getAbinDelomradeGeometri),
            mergeMap((action) =>
                this.ingeApi
                    .abinSammanfattningStratumGeometri({
                        lankod: action.lankod,
                        afonr: action.afonr,
                        delomradesnummer: action.delomradesnummer
                    })
                    .pipe(
                        map((result) => {
                            return apiActions.getAbinDelomradeGeometriSuccess({
                                result
                            });
                        }),
                        catchError(() => of(apiActions.getAbinDelomradeGeometriError()))
                    )
            )
        )
    );

    constructor(private actions$: Actions, private ingeApi: AbinService) {}
}

// function removeDuplicates(myArr: any[], prop: string): AbinResultDto[] {
//   return myArr.filter((obj, pos, arr) => {
//     return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
//   });
// }

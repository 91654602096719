<app-fordjupning-karta-base
  [layerConfig]="layerConfig$ | async"
  (mapClicked)="onMapClicked($event)"
>
  <app-fordjupning-karta-popup
    [isLoading]="isLoadingPopupData"
    [popupContent]="popupData$ | async"
  >
    <ng-container
      *ngIf="(popupData$ | async)?.thumbsUp; else elseTemplate"
      popupIcon
    >
      <span class="abinicon-tall_mager_mark_uppfyllt"></span>
    </ng-container>
    <ng-template #elseTemplate popupIcon>
      <span class="abinicon-tall_mager_mark_ej_uppfyllt"></span>
    </ng-template>
  </app-fordjupning-karta-popup>
</app-fordjupning-karta-base>

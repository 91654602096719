<!-- <app-ui-info-box ui-info-box-light ui-info-box-centered>
  <ui-info-box-header>Andel årsskador på gran, sammanvägning för de tre senaste
    inventeringarna</ui-info-box-header>
  <ui-info-box-content>
    <app-loader *ngIf="loadingInventeringar$ | async; else rapportComponent"></app-loader>
    <ng-template #rapportComponent>
      <h3>{{(inventeringar$|async)?.arsskadaGranAndel3InvMedel|percent:'1.0-1':'sv'}}</h3>
    </ng-template>

  </ui-info-box-content>
</app-ui-info-box> -->
<app-summary-card>
  <app-summary-card-icon>
    <ng-container *ngIf="(inventering$ | async)?.slutsatsArsskadaGranInt>0; else elseTemplate3">
      <span class="abinicon-arsskada_gran_uppfyllt">
        <span class="path1"></span>
        <span class="path2"></span>
      </span>
    </ng-container>
    <ng-template #elseTemplate3>
      <span class="abinicon-arsskada_gran_ej_uppfyllt">
        <span class="path1"></span>
        <span class="path2"></span>
      </span>
    </ng-template>
  </app-summary-card-icon>
  <app-summary-card-header>
    {{(inventering$|async)?.arsskadaGranAndel3InvMedel|percent:'1.1-1':'sv'}}
  </app-summary-card-header>
  <app-summary-card-content>
    Andel årsskador på gran, sammanvägning för de tre senaste
    inventeringarna
  </app-summary-card-content>
</app-summary-card>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ChartCategory } from 'src/app/common/service';
import { AbinResultDto } from 'src/app/inge-api/client/models';
import { FordelningPiechartBaseComponent } from '../../base-components';

@Component({
    selector: 'app-agoslagsfordelning-chart',
    templateUrl: './agoslagsfordelning-chart.component.html',
    styleUrls: ['./agoslagsfordelning-chart.component.scss']
})
export class AgoslagsfordelningChartComponent extends FordelningPiechartBaseComponent implements OnInit, OnDestroy {
    private subs: Subscription[];

    data: any[][];
    legendData: any[];

    ngOnInit(): void {
        this.subs = [];
        this.subs.push(
            this.inventering$.subscribe((senasteInventering) => {
                if (senasteInventering) {
                    this.generateChartData(senasteInventering);
                }
            })
        );
    }

    generateChartData(senasteInventering: AbinResultDto) {
        const total = senasteInventering.totalArealHa;
        const data: any[][] = [['Ägoslag', 'Procent']];
        const colors = [];
        const legendData = [];
        if (senasteInventering.arealSkogsmarkHa) {
            const name = 'Skogsmark';
            const value = Math.round((senasteInventering.arealSkogsmarkHa / total) * 100);
            const color = this.colormapService.getColor(ChartCategory.SKOGSMARK);
            data.push([name, value]);
            colors.push(color);
            legendData.push({ name, value, color });
        }
        if (senasteInventering.myrmark) {
            const name = 'Myrmark';
            const value = Math.round((senasteInventering.myrmark / total) * 100);
            const color = this.colormapService.getColor(ChartCategory.MYRMARK);
            data.push([name, value]);
            colors.push(color);
            legendData.push({ name, value, color });
        }

        if (senasteInventering.ovrigOppenMark) {
            const name = 'Övrig öppen mark';
            const value = Math.round((senasteInventering.ovrigOppenMark / total) * 100);
            const color = this.colormapService.getColor(ChartCategory.INGENATGARD);
            data.push([name, value]);
            colors.push(color);
            legendData.push({ name, value, color });
        }
        if (senasteInventering.arealVattenHa) {
            const name = 'Vatten';
            const value = Math.round((senasteInventering.arealVattenHa / total) * 100);
            const color = this.colormapService.getColor(ChartCategory.VATTEN);
            data.push([name, value]);
            colors.push(color);
            legendData.push({ name, value, color });
        }
        if (senasteInventering.tatortsmark) {
            const name = 'Tätort';
            const value = Math.round((senasteInventering.tatortsmark / total) * 100);
            const color = this.colormapService.getColor(ChartCategory.TATORTSMARK);
            data.push([name, value]);
            colors.push(color);
            legendData.push({ name, value, color });
        }
        this.data = data;
        this.options = { ...this.options, colors };
        this.legendData = legendData;
    }

    ngOnDestroy(): void {
        this.subs.forEach((x) => x.unsubscribe());
    }
}

import { GoogleChartType } from './google-charts-type';
import { GoogleChartsConfig } from './google-charts-config';

const ChartTypesToPackages = {
  [GoogleChartType.AnnotationChart]: 'annotationchart',
  [GoogleChartType.AreaChart]: 'corechart',
  [GoogleChartType.Bar]: 'bar',
  [GoogleChartType.BarChart]: 'corechart',
  [GoogleChartType.BubbleChart]: 'corechart',
  [GoogleChartType.Calendar]: 'calendar',
  [GoogleChartType.CandlestickChart]: 'corechart',
  [GoogleChartType.ColumnChart]: 'corechart',
  [GoogleChartType.ComboChart]: 'corechart',
  [GoogleChartType.PieChart]: 'corechart',
  [GoogleChartType.Gantt]: 'gantt',
  [GoogleChartType.Gauge]: 'gauge',
  [GoogleChartType.GeoChart]: 'geochart',
  [GoogleChartType.Histogram]: 'corechart',
  [GoogleChartType.Line]: 'line',
  [GoogleChartType.LineChart]: 'corechart',
  [GoogleChartType.Map]: 'map',
  [GoogleChartType.OrgChart]: 'orgchart',
  [GoogleChartType.Sankey]: 'sankey',
  [GoogleChartType.Scatter]: 'scatter',
  [GoogleChartType.ScatterChart]: 'corechart',
  [GoogleChartType.SteppedAreaChart]: 'corechart',
  [GoogleChartType.Table]: 'table',
  [GoogleChartType.Timeline]: 'timeline',
  [GoogleChartType.TreeMap]: 'treemap',
  [GoogleChartType.WordTree]: 'wordtree',
};

export function getPackageForChart(type: GoogleChartType): string {
  return ChartTypesToPackages[type];
}

export function getDefaultConfig(): GoogleChartsConfig {
  return {
    version: 'current',
    safeMode: false,
  };
}

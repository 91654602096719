import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-summary-card',
  templateUrl: './summary-card.component.html',
  styleUrls: ['./summary-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SummaryCardComponent implements OnInit {
  @Input() header: number | string;
  @Input() content: number | string;
  constructor() {}

  ngOnInit(): void {}
}

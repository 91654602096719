<div role="main">
    <app-export-report-modal></app-export-report-modal>
    <h2 class="rapport-top-header">Resultat från Äbin och foderprognoser</h2>
    <h3 class="rapport-sub-header">{{ inventeringsNamn$ | async }}</h3>
    <div class="row center-xs center-sm start-md start-lg">
        <div class="col-xs-12">
            <div class="rapport-top-toolbar">
                <div class="button-link-container">
                    <span class="material-icons"> cached </span>
                    <a class="button-link" [routerLink]="'/'">Sök nytt område</a>
                </div>
                <button class="rapport-top-toolbar__button" (click)="exportReport()">
                    <span class="material-icons-outlined"> file_download </span>Ladda ned rapport
                </button>
            </div>
        </div>
    </div>
    <section>
        <div class="row" id="rapport-toc-section" data-scroll="true">
            <ng-container *ngIf="lan1Chapter1WarningConfig$ | async as lan1warning">
                <div class="col-xs-12" *ngIf="lan1warning?.render">
                    <app-rapport-warning [warningConfig]="lan1warning"></app-rapport-warning>
                </div>
            </ng-container>

            <ng-container *ngIf="lan9Chapter1WarningConfig$ | async as lan9warning">
                <div class="col-xs-12" *ngIf="lan9warning?.render">
                    <app-rapport-warning [warningConfig]="lan9warning">
                        <ng-container warningContent>
                            På Gotland finns inte älg och inventeringen har därför anpassats för att fånga in skador från rådjur istället. Det innebär att
                            bestånd mellan 0,1 till 1,5 m i medelhöjd har inventerats (istället för 1 - 4 m).
                            <br />
                            Rapportmallen är dock oförändrad från den som används för fastlandet och detta måste tas i beaktande. Följande skillnader är
                            viktiga:
                            <ul>
                                <li>Kap 3. Viltskador - registreras från 0,1 dm istället för 0,3 dm</li>
                                <li>Kap 3.4 Geografisk fördelning – Detta är andra året som Gotland inventeras och kartan är baserad på två års data.</li>
                                <li>
                                    Kap 6.3. Skogens sammansättning – Uppgifter om skogenssammansättning avser bestånd mellan 0,1 till 1,5 m i medelhöjd
                                    (istället för 1 - 4 m). Uppgifter från Riksskogstaxeringen är fortfarande för bestånd > 5m och därför saknas uppgifter om
                                    skog med medelhöjd mellan 1,5 m och 4 m.
                                </li>
                                <li>Kap 7. Om Skoglig betesinventering. Texten är anpassad främst efter älgförvaltning.</li>
                            </ul>
                        </ng-container>
                    </app-rapport-warning>
                </div>
            </ng-container>

            <!-- <ng-container *ngIf="lan13Chapter31WarningConfig$ | async as lan13warning">
                <div class="col-xs-12" *ngIf="lan13warning?.render">
                    <app-rapport-warning [warningConfig]="lan13warning"> </app-rapport-warning>
                </div>
            </ng-container> -->

            <div class="col-xs-12">
                <app-ui-section-header>
                    <h2 ui-section-header-title>Innehåll</h2>
                    <p ui-section-header-text>
                        Här ser du vilka delar rapporten innehåller. Skrolla ner för att läsa rapporten eller klicka på det avsnitt du är intresserad av.
                    </p>
                </app-ui-section-header>
                <app-rapport-toc></app-rapport-toc>
            </div>
        </div>
    </section>

    <section aria-labelledby="introductionHeader" id="introduktion-section" data-scroll="true">
        <div class="row">
            <div class="col-xs-12">
                <app-ui-section-header>
                    <h2 ui-section-header-title id="introductionHeader">1. Introduktion</h2>
                    <p ui-section-header-text>
                        Utgångspunkten i den svenska förvaltningen av hjortdjur är att den ska vila på bra beslutsunderlag så att tillräcklig hänsyn kan tas
                        till de oönskade effekter som närvaron av hjortdjur för med sig. När det gäller den skogliga statistiken, ansvarar Skogsstyrelsen för
                        metodfrågor, resultatberäkningar, slutsatser och rapportering. Insamling av fältdata i Älgbetesinventeringen görs av entreprenörer och
                        koordineras av Skogforsk. Skogsbruket finansierar fältarbetet. Denna rapport består av underlag från olika datakällor (Äbin,
                        foderprognoser, Riksskogstaxeringen med flera) som tillsammans syftar till att ge en sammanhållen beskrivning av tillståndet i skogen
                        för de som förvaltar hjortdjur eller intresserar sig för viltanpassad skogsskötsel.
                    </p>
                </app-ui-section-header>
            </div>
        </div>
        <br />

        <div class="row center-xs">
            <div class="col-xs-12">
                <app-karta-inventeringsrutor></app-karta-inventeringsrutor>
            </div>
        </div>
        <br />
        <br />
        <app-rapport-rendering-filter>
            <div class="row print-no-break">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <app-omrade-table></app-omrade-table>
                </div>
            </div>
            <div class="row print-no-break">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                    <app-agoslagsfordelning-chart></app-agoslagsfordelning-chart>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                    <app-foder-unskogenssammansattning-chart></app-foder-unskogenssammansattning-chart>
                </div>
            </div>
            <app-rapport-footer></app-rapport-footer>
        </app-rapport-rendering-filter>
    </section>

    <section aria-labelledby="sammanfattningHeader">
        <div class="row" id="sammanfattning-section" data-scroll="true">
            <div class="col-xs-12">
                <app-ui-section-header>
                    <h2 ui-section-header-title id="sammanfattningHeader">2. Sammanfattning</h2>
                    <p ui-section-header-text>
                        I sammanfattningen redovisas resultat utifrån den målsättning som är antagen. De redovisade resultaten är en sammanvägning av de tre
                        senaste inventeringarna.
                    </p>
                </app-ui-section-header>
            </div>
        </div>
        <app-rapport-rendering-filter>
            <div class="row">
                <div class="col-xs-12">
                    <app-slutsatser-lista></app-slutsatser-lista>
                </div>
            </div>
            <app-rapport-footer></app-rapport-footer>
        </app-rapport-rendering-filter>
    </section>

    <section aria-labelledby="viltskadorHeader">
        <div class="row" id="viltskador-section" data-scroll="true">
            <div class="col-xs-12">
                <app-ui-section-header>
                    <h2 ui-section-header-title id="viltskadorHeader">3. Viltskador</h2>
                    <p ui-section-header-text>
                        Med viltskada menar vi en skada på trädets stamaxel (toppskottsbete, toppbrott, barkgnag och fejning) som sitter högre än 3 decimeter
                        ovan mark och som orsakats av ett hjortdjur (älg, rådjur, kron-, eller dovhjort). Toppskottsbete är den dominerande skadetypen och älg
                        är den dominerande skadegöraren. Vid inventeringen bedöms även när skadan uppstod. På så sätt kan vi skilja skador som inträffade under
                        det senaste året från äldre skador.
                    </p>
                </app-ui-section-header>
            </div>
            <div class="col-xs-12"></div>
        </div>

        <div class="row" id="arsskador-tall-section" data-scroll="true">
            <div class="col-xs-12">
                <h3>3.1 Årsskador tall</h3>
            </div>
        </div>
        <app-rapport-rendering-filter>
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="section-text two-columns">
                        <ng-container *ngIf="senasteInventering$ | async as senasteInventering">
                            En årsskada är en viltskada som tillkommit under perioden mellan föregående vegetationsperiods start fram till vinterns
                            avslutning.<br /><br />
                            Medelvärdet för andelen tallar med en årsskada är
                            {{ senasteInventering?.arsskadaTallAndel3InvMedel * 100 | number : '1.0-0' : 'sv' }} procent
                            <ng-container *ngIf="senasteInventering?.medelfelArskadaTall3InvMedel">
                                (felmarginal +/- {{ senasteInventering?.medelfelArskadaTall3InvMedel * 2 * 100 | number : '1.0-0' : 'sv' }} procent)
                            </ng-container>
                            för de tre senaste inventeringarna. Vid den senaste inventeringen ({{ senasteInventering?.invAr }}) var andelen tallar med en
                            årsskada {{ senasteInventering?.arsskadaTallAndel * 100 | number : '1.0-0' : 'sv' }} procent
                            <ng-container *ngIf="senasteInventering?.medelfelArskadaTall">
                                (felmarginal +/- {{ senasteInventering?.medelfelArskadaTall * 2 * 100 | number : '1.0-0' : 'sv' }} procent) </ng-container
                            >.

                            {{ (senasteInventering$ | async)?.slutsatsArsskadaTallText }}

                            <ng-container *ngIf="senasteInventering?.standortsindexTallMarker > 18; else elseTemplate">
                                Målet är att andel tall med årsskador max är 5 procent.
                            </ng-container>
                            <ng-template #elseTemplate> Målet är att andel tall med årsskador max är 2 procent. </ng-template>
                            För bedömning av måluppfyllelse har hänsyn tagits till att genomsnittligt ståndortsindex uppgår till
                            {{ senasteInventering?.standortsindexTallMarker }} meter enligt Riksskogstaxeringens inventering. <br /><br />
                            Medelvärdet för de tre senaste inventeringarna har beräknats genom en sammanvägning av grunddata och kan skilja sig något från ett
                            medelvärde beräknat utifrån varje enskilt år som redovisas i diagrammet.
                        </ng-container>
                    </div>
                    <br />
                    <!-- <app-rapport-warning [warningConfig]="lan13Chapter31WarningConfig$ | async"> </app-rapport-warning> -->
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <app-arsskador-tall-overview-section />
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <h4>Kartan visar grad av måluppfyllelse för de områden som ingår.</h4>
                    <div class="subheadertext">Klicka i kartan för att se aktuellt värde för ett enskilt område.</div>
                    <app-uppfolj-arsskador-tall-karta></app-uppfolj-arsskador-tall-karta>
                </div>
            </div>
            <app-rapport-footer></app-rapport-footer>
        </app-rapport-rendering-filter>

        <hr class="rapport-divider-line" />

        <div class="row" id="oskadad-tall-section" data-scroll="true">
            <div class="col-xs-12">
                <h3 #section32>3.2 Oskadad tall</h3>
            </div>
        </div>
        <app-rapport-rendering-filter>
            <div class="row">
                <div class="col-xs-12">
                    <app-rapport-warning [warningConfig]="landsdel2Lan21Afo3Chapter32WarningConfig$ | async"> </app-rapport-warning>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="section-text two-columns">
                        <ng-container *ngIf="senasteInventering$ | async as senasteInventering">
                            Oskadad tall är tallstammar som vare sig har en viltskada från det senaste året eller en viltskada från tidigare år.
                            <br /><br />

                            Medelvärdet för andelen oskadade tallar är
                            {{ senasteInventering?.oskadadTallAndel3InvMedel * 100 | number : '1.0-0' : 'sv' }} procent
                            <ng-container *ngIf="senasteInventering?.medelfelOskadadTall3InvMedel">
                                (felmarginal +/- {{ senasteInventering?.medelfelOskadadTall3InvMedel * 2 * 100 | number : '1.0-0' : 'sv' }} procent)
                            </ng-container>
                            för de tre senaste inventeringarna. Vid den senaste inventeringen ({{ senasteInventering?.invAr }}) var andelen oskadade tallar
                            {{ senasteInventering?.oskadadTallAndel * 100 | number : '1.0-0' : 'sv' }} procent
                            <ng-container *ngIf="senasteInventering?.medelfelOskadadTall">
                                (felmarginal +/- {{ senasteInventering?.medelfelOskadadTall * 2 * 100 | number : '1.0-0' : 'sv' }} procent)</ng-container
                            >. Viltskador har ackumulerats under flera år. <br /><br />

                            Det övergripande målet är att 7 av 10 tallstammar ska vara oskadade när ungskogen uppnår 5 meter i medelhöjd och toppskotten inte
                            längre är inom räckhåll för djuren. De ungskogar som ingår i inventeringen är dock mellan 1 och 4 meter. Hänsyn måste därför tas
                            till att tallarna riskerar få viltskador ytterligare några år. Målet är därför att minst 85 procent av Äbininventerade tallstammar
                            ska vara oskadade av vilt.
                            <br /><br />

                            Medelvärdet för de tre senaste inventeringarna har beräknats genom en sammanvägning av grunddata och kan skilja sig något från ett
                            medelvärde beräknat utifrån varje enskilt år som redovisas i diagrammet.
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <app-uppfolj-oskadad-tall-fordjupning />
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <h4>Kartan visar grad av måluppfyllelse för de områden som ingår.</h4>
                    <div class="subheadertext">Klicka i kartan för att se aktuellt värde för ett enskilt område.</div>
                    <app-uppfolj-oskadad-tall-karta></app-uppfolj-oskadad-tall-karta>
                </div>
            </div>
            <br />
            <app-rapport-footer></app-rapport-footer>
        </app-rapport-rendering-filter>

        <hr class="rapport-divider-line" />

        <div class="row" id="arsskador-gran-section" data-scroll="true">
            <div class="col-xs-12">
                <h3>3.3 Årsskador gran</h3>
            </div>
        </div>
        <app-rapport-rendering-filter>
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="section-text two-columns">
                        <ng-container *ngIf="senasteInventering$ | async as senasteInventering">
                            En årsskada är en viltskada som tillkommit under perioden mellan föregående vegetationsperiods start fram till vinterns avslutning.
                            <br /><br />

                            Medelvärdet för andelen granar med en årsskada är
                            {{ senasteInventering?.arsskadaGranAndel3InvMedel * 100 | number : '1.1-1' : 'sv' }} procent
                            <ng-container *ngIf="senasteInventering?.medelfelArskadaGran3InvMedel">
                                (felmarginal +/- {{ senasteInventering?.medelfelArskadaGran3InvMedel * 2 * 100 | number : '1.1-1' : 'sv' }} procent)
                            </ng-container>
                            för de tre senaste inventeringarna. Vid den senaste inventeringen ({{ senasteInventering?.invAr }}) var andelen granar med en
                            årsskada {{ senasteInventering?.arsskadaGranAndel * 100 | number : '1.1-1' : 'sv' }} procent
                            <ng-container *ngIf="senasteInventering?.medelfelArskadaGran">
                                (felmarginal +/- {{ senasteInventering?.medelfelArskadaGran * 2 * 100 | number : '1.1-1' : 'sv' }} procent)</ng-container
                            >. <br /><br />

                            Bortsett från nyplanteringar är granskott ett foder som normalt inte utnyttjas av djuren. Om viltskador på gran är högt kan det bero
                            på en obalans mellan hjortdjur och fodertillgång. Var gränsen för obalans exakt går är dock oklart. En indikation om att obalans
                            råder i området inträder när andelen gran med årligt uppkomna viltskador överskrider 1 %.<br /><br />

                            Medelvärdet för de tre senaste inventeringarna har beräknats genom en sammanvägning av grunddata och kan skilja sig något från ett
                            medelvärde beräknat utifrån varje enskilt år som redovisas i diagrammet.
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <app-uppfolj-arsskada-gran-fordjupning />
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <h4>Kartan visar grad av måluppfyllelse för de områden som ingår.</h4>
                    <div class="subheadertext">Klicka i kartan för att se aktuellt värde för ett enskilt område.</div>
                    <app-uppfolj-arsskada-gran-karta />
                </div>
            </div>
            <app-rapport-footer></app-rapport-footer>
        </app-rapport-rendering-filter>

        <hr class="rapport-divider-line" />

        <div class="row" id="geografisk-fordelning-section" data-scroll="true">
            <div class="col-xs-12">
                <h3>3.4 Geografisk fördelning</h3>
            </div>
        </div>
        <app-rapport-rendering-filter>
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    De värden som presenteras i rapporten över viltskador gäller för området som helhet. I verkligheten fördelar sig skadorna oftast ojämnt över
                    landskapet så att vissa bestånd, vissa trädgrupper och vissa träd drabbas hårdare. Detta kan innebära att även vid låga genomsnittliga
                    skadenivåer inom ett ÄFO kan vissa områden lokalt drabbas hårt. Kartan visar den lokala variationen över årsskadad tall i området. För att
                    ta fram kartan har de tre senaste inventeringarna använts. Med stöd av denna karta kan så kallade "hot spots" identifieras. Mörkare färg
                    innebär att en högre andel av tallstammarna är skadade än i de områden på kartan som har ljusare nyans.
                    <br /><br />
                    Kartan ger endast vägledning, faktorer som andelen tall i landskapet och antalet provpunkter påverkar med vilken säkerhet kartan speglar den
                    faktiska skadebilden. Enstaka punkter och fläckar med höga eller låga värden bör tolkas med försiktighet.
                    <br />
                    <br />
                    <ui-figure-caption>
                        <ui-figure-text ui-figure-text-bold
                            >Kartan visar hur årsskadorna på tall i ungskog varierar inom området och baseras på de tre senaste inventeringarna. Mörkare färg
                            innebär att en högre andel av tallstammarna registrerats som årsskadad än i de områden på kartan som har ljusare nyans.
                        </ui-figure-text>
                    </ui-figure-caption>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8">
                    <app-arsskador-karta></app-arsskador-karta>
                </div>
            </div>
            <br />
            <div class="print-no-break">
                <div class="row center-xs"></div>
            </div>
            <app-rapport-footer></app-rapport-footer>
        </app-rapport-rendering-filter>
    </section>

    <br />

    <section aria-labelledby="raseHeader">
        <div class="row" id="rase-section" data-scroll="true">
            <div class="col-xs-12">
                <app-ui-section-header>
                    <h2 ui-section-header-title id="raseHeader">4. Rönn, asp, sälg och ek (RASE)</h2>
                    <p ui-section-header-text>
                        För att värna biologisk mångfald och foder för det vilda djurlivet ska trädslag som naturligt förekommer i landskapet ha möjlighet att
                        på lämpliga ståndorter etablera sig och växa upp till vuxna träd. Rönn, asp, sälg och ek är fyra olika lövträd som tillhör de mest
                        omtyckta av hjortdjuren. De är därför ofta hårt betade i ungskogen. Ett hårt bete bidrar till att de hamnar på efterkälken när övriga
                        beståndet växer. Möjligheten för RASE-träden att bli vuxna försvåras därmed. För att framtida skogar ska innehålla dessa ur biologisk
                        synvinkel viktiga trädslag behöver såväl viltförvaltning som skogsskötseln ta hänsyn till och bereda plats för dem.
                    </p>
                </app-ui-section-header>
            </div>
        </div>
        <div class="row" id="forekomst-rase-section" data-scroll="true">
            <div class="col-xs-12">
                <h3>4.1 Förekomst av rönn, asp, sälg och ek (RASE)</h3>
            </div>
        </div>
        <app-rapport-rendering-filter>
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-9">
                    <div class="section-text two-columns">
                        <ng-container *ngIf="senasteInventering$ | async as senasteInventering">
                            Medelvärdet för andelen inventerade provytor där minst en individ inom gruppen RASE som är högre än 3 decimeter påträffades är
                            {{ senasteInventering?.raseAndelForekomst3InvMedel * 100 | number : '1.0-1' : 'sv' }} procent för de tre senaste inventeringarna.
                            Vid den senaste inventeringen ({{ senasteInventering?.invAr }}) var andelen
                            {{ senasteInventering?.raseAndelForekomst * 100 | number : '1.0-1' : 'sv' }} procent. <br /><br />
                            Förekomst av RASE i ungskogen påverkas av flera faktorer såsom marktyp och viltbete, men också var i landet vi befinner oss.
                            Förekomst påverkas givetvis också av skogsskötsel. Eftersom kvist och blad från RASE är ett mycket eftertraktat foder för
                            hjortdjuren speglar de förändringar i betestryck över tid. Förekomst av RASE kan därmed fungera som en kompletterande indikator över
                            viltskadornas utveckling. <br /><br />
                            Medelvärdet för de tre senaste inventeringarna har beräknats genom en sammanvägning av grunddata och kan skilja sig något från ett
                            medelvärde beräknat utifrån varje enskilt år som redovisas i diagrammet. Utveckling av beräkningar pågår och för närvarande
                            presenteras inga uppgifter för felmarginal för denna variabel.
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <app-uppfolj-forekomst-rase-fordjupning></app-uppfolj-forekomst-rase-fordjupning>
                </div>
            </div>
            <app-rapport-footer></app-rapport-footer>
        </app-rapport-rendering-filter>

        <hr class="rapport-divider-line" />

        <div class="row" id="konkurrensstatus-rase-section" data-scroll="true">
            <div class="col-xs-12">
                <h3>4.2 Konkurrensstatus för rönn, asp, sälg och ek (RASE)</h3>
            </div>
        </div>
        <app-rapport-rendering-filter>
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="section-text two-columns">
                        <ng-container *ngIf="senasteInventering$ | async as senasteInventering">
                            Eftersom RASE ofta är hårt betade i ungskogen hamnar de på efterkälken jämfört med trädslag som björk, tall och gran. Efterhand
                            riskerar de att konkurreras ut av omgivande träd. En individ inom gruppen RASE har gynnsam konkurrensstatus om den är högre än
                            medelhöjden för de två högsta barrstammarna. Denna individ bedöms då fortfarande kunna konkurrera med övriga träd på provytan.<br /><br />

                            Medelvärdet för andelen inventerade provytorna där RASE har gynnsam status är
                            {{ senasteInventering?.raseAndelGynnsam3InvMedel * 100 | number : '1.0-1' : 'sv' }} procent för de tre senaste inventeringarna. Vid
                            den senaste inventeringen ({{ senasteInventering?.invAr }}) var andelen
                            {{ senasteInventering?.raseAndelGynnsam * 100 | number : '1.0-1' : 'sv' }} procent. Målet är att RASE ska ha en gynnsam status på 10
                            procent av de inventerade ytorna. Om andelen mager mark i området är stor (>40%) så accepteras att RASE har gynnsam status på 5
                            procent av ytorna. I detta område är andelen mager mark
                            {{ senasteInventering?.andelMagraMarker3InvMedel * 100 | number : '1.0-1' : 'sv' }}%. <br /><br />

                            Medelvärdet för de tre senaste inventeringarna har beräknats genom en sammanvägning av grunddata och kan skilja sig något från ett
                            medelvärde beräknat utifrån varje enskilt år som redovisas i diagrammet. Utveckling av beräkningar pågår och för närvarande
                            presenteras inga uppgifter för felmarginal för denna variabel.
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <app-uppfolj-konkurrens-rase-fordjupning></app-uppfolj-konkurrens-rase-fordjupning>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <h4>Kartan visar grad av måluppfyllelse för de områden som ingår.</h4>
                    <div class="subheadertext">Klicka i kartan för att se aktuellt värde för ett enskilt område.</div>
                    <app-uppfolj-konkurrens-rase-karta></app-uppfolj-konkurrens-rase-karta>
                </div>
            </div>
            <app-rapport-footer></app-rapport-footer>
        </app-rapport-rendering-filter>
    </section>

    <br />

    <section aria-labelledby="aterbeskogningHeader">
        <div class="row" id="aterbeskogning-section" data-scroll="true">
            <div class="col-xs-12">
                <app-ui-section-header>
                    <h2 ui-section-header-title id="aterbeskogningHeader">5. Återbeskogning</h2>
                    <p ui-section-header-text>
                        Viltskadornas omfattning påverkar hur markägare föryngrar sin skog och här måste markägare och jägare samverka för att skapa
                        förutsättningar för den skog vi vill se i framtiden. Markägare ska ha goda möjligheter att välja trädslag som passar för ståndorten utan
                        att för den skull riskera orimliga ekonomiska kostnader till följd av viltskador. De värden som presenteras i Äbin visar hur skogen
                        föryngrades för 5 till 10 år sedan.
                    </p>
                </app-ui-section-header>
            </div>
        </div>
        <br />

        <app-rapport-warning [warningConfig]="lan9Chapter5WarningConfig$ | async"></app-rapport-warning>

        <br />

        <div class="row print-no-break" id="markslag-foryngrade-tradslag-section" data-scroll="true">
            <div class="col-xs-12">
                <h3>5.1 Markslag och föryngrade trädslag</h3>
            </div>
        </div>
        <app-rapport-rendering-filter>
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <ng-container *ngIf="senasteInventering$ | async as senasteInventering">
                        Vid senaste inventering ({{ senasteInventering?.invAr }}) var andelen mager mark
                        {{ senasteInventering?.andelMargraMarker * 100 | number : '1.0-0' : 'sv' }}
                        procent, andelen bördig mark
                        {{ senasteInventering?.andelBordigaMarker * 100 | number : '1.0-0' : 'sv' }}
                        procent och mellanmarkerna utgjorde
                        {{ senasteInventering?.andelMellanMarker * 100 | number : '1.0-0' : 'sv' }}
                        procent. Av ungskogen i området är
                        {{ senasteInventering?.foryngratTradslagTall | number : '1.0-0' : 'sv' }}
                        hektar föryngrat med tall medan
                        {{ senasteInventering?.foryngratTradslagGran | number : '1.0-0' : 'sv' }}
                        hektar är föryngrat med gran.
                    </ng-container>
                </div>
            </div>

            <app-kombo-markslagsfordelning />

            <app-rapport-footer></app-rapport-footer>
        </app-rapport-rendering-filter>

        <hr class="rapport-divider-line" />

        <div class="row print-no-break" id="mager-mark-section" data-scroll="true">
            <div class="col-xs-12">
                <h3>5.2 Mager mark</h3>
            </div>
        </div>
        <app-rapport-rendering-filter>
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="section-text two-columns">
                        <ng-container *ngIf="senasteInventering$ | async as senasteInventering">
                            Av den magra marken i området har tall föryngrats på
                            {{ senasteInventering?.standortsanpassningAndelMagraMarkerMedTall3InvMedel * 100 | number : '1.0-0' : 'sv' }}
                            procent av arealen, visar de tre senaste inventeringarna. Vid senaste inventering ({{ senasteInventering?.invAr }}) var andelen
                            {{ senasteInventering?.standortsanpassningAndelMagraMarkerMedTall * 100 | number : '1.0-0' : 'sv' }} procent. Målet är att 80
                            procent av de magra markerna ska vara föryngrad med tall.
                            <br />
                            <br />

                            På magra marker växer tallen bättre än gran och är därför ett lämpligare trädslag ur produktionssynpunkt. Skogsstyrelsen anser
                            därför att utpräglade tallmarker inte ska föryngras med gran även om det innebär en mindre risk för att drabbas av viltskador. Gran
                            på magra marker löper dessutom större risk att drabbas av rotröta, men även av torkstress som är en inkörsport för till exempel
                            granbarkborre. Resultatet av de tre senaste inventeringarna visar att
                            {{ senasteInventering?.standortsanpassningAndelMagraMarkerMedGran3InvMedel * 100 | number : '1.0-0' : 'sv' }} procent av den magra
                            marken i området är föryngrad med gran. Vid den senaste inventeringen ({{ senasteInventering?.invAr }}) var andelen föryngrad med
                            gran {{ senasteInventering?.standortsanpassningAndelMagraMarkerMedGran * 100 | number : '1.0-0' : 'sv' }} procent.
                            <br />
                            <br />

                            Medelvärdet för de tre senaste inventeringarna har beräknats genom en sammanslagning av grunddata och kan skilja sig något från ett
                            medelvärde beräknat utifrån varje enskilt år som redovisas i diagrammet. Utveckling av beräkningar pågår och för närvarande
                            presenteras inga uppgifter för felmarginal för denna variabel.
                        </ng-container>
                    </div>
                </div>
            </div>
            <br />

            <app-kombo-magermark />

            <div class="row">
                <div class="col-xs-12">
                    <h4>Kartan visar grad av måluppfyllelse för de områden som ingår.</h4>
                    <div class="subheadertext">Klicka i kartan för att se aktuellt värde för ett enskilt område.</div>
                </div>
                <div class="col-xs-12">
                    <app-uppfolj-tall-mager-mark-karta></app-uppfolj-tall-mager-mark-karta>
                </div>
            </div>

            <app-rapport-footer></app-rapport-footer>
        </app-rapport-rendering-filter>

        <hr class="rapport-divider-line" />

        <div class="row" id="mellanmark-section" data-scroll="true">
            <div class="col-xs-12">
                <h3>5.3 Mellanmarker</h3>
            </div>
        </div>
        <app-rapport-rendering-filter>
            <div class="row">
                <div class="col-xs-12">
                    <app-rapport-warning [warningConfig]="lan22Chapter53WarningConfig$ | async"> </app-rapport-warning>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-9">
                    <div class="section-text two-columns">
                        <ng-container *ngIf="senasteInventering$ | async as senasteInventering">
                            Av mellanmarkerna föryngrade med tall var medelvärdet
                            {{ senasteInventering?.standortsanpassningAndelMellanMarkerMedTall3InvMedel * 100 | number : '1.0-0' : 'sv' }}
                            procent för de tre senaste inventeringarna. Vid senaste inventering ({{ senasteInventering?.invAr }}) var andelen
                            {{ senasteInventering?.standortsanpassningAndelMellanMarkerMedTall * 100 | number : '1.0-0' : 'sv' }}
                            procent. På mellanmarker växer tall och gran ungefär lika bra. Det finns flera skäl till att undvika att gran helt dominerar på
                            dessa marker. Målet är därför att minst en fjärdedel av mellanmarkerna ska vara föryngrad med tall.
                            <br /><br />
                            Medelvärdet för de tre senaste inventeringarna har beräknats genom en sammanslagning av grunddata och kan skilja sig något från ett
                            medelvärde beräknat utifrån varje enskilt år som redovisas i diagrammet. Utveckling av beräkningar pågår och för närvarande
                            presenteras inga uppgifter för felmarginal för denna variabel.
                        </ng-container>
                    </div>
                </div>
            </div>
            <br />

            <app-kombo-mellanmark />

            <div class="row">
                <div class="col-xs-12">
                    <h4>Kartan visar grad av måluppfyllelse för de områden som ingår.</h4>
                    <div class="subheadertext">Klicka i kartan för att se aktuellt värde för ett enskilt område.</div>
                </div>
                <div class="col-xs-12">
                    <app-uppfolj-tall-mellan-mark-karta></app-uppfolj-tall-mellan-mark-karta>
                </div>
            </div>

            <app-rapport-footer></app-rapport-footer>
        </app-rapport-rendering-filter>

        <hr class="rapport-divider-line" />

        <div class="row" id="bordig-mark-section" data-scroll="true">
            <div class="col-xs-12">
                <h3>5.4 Bördig mark</h3>
            </div>
        </div>
        <app-rapport-rendering-filter>
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-9">
                    <div class="section-text two-columns">
                        <ng-container *ngIf="senasteInventering$ | async as senasteInventering">
                            De tre senaste inventeringarna visar att
                            {{ (senasteInventering?.standortsanpassningAndelBordigaMarkerMedTall3InvMedel * 100 | number : '1.0-0' : 'sv') || 0 }}
                            procent av de bördiga markerna har föryngrats med tall. Vid senaste inventering ({{ senasteInventering?.invAr }}) var andelen
                            {{ senasteInventering?.standortsanpassningAndelBordigaMarkerMedTall * 100 | number : '1.0-0' : 'sv' }} procent. <br /><br />

                            På bördiga marker är gran mer konkurrenskraftig än tall. Det finns därför inget skogligt mål som innebär att begränsa mängden gran
                            på dessa marker.
                            <br /><br />
                            Medelvärdet för de tre senaste inventeringarna har beräknats genom en sammanslagning av grunddata och kan skilja sig något från ett
                            medelvärde beräknat utifrån varje enskilt år som redovisas i diagrammet. Utveckling av beräkningar pågår och för närvarande
                            presenteras inga uppgifter för felmarginal för denna variabel.
                        </ng-container>
                    </div>
                </div>
            </div>
            <br />

            <app-kombo-bordigmark />

            <app-rapport-footer></app-rapport-footer>
        </app-rapport-rendering-filter>
    </section>

    <br />

    <section aria-labelledby="foderHeader">
        <div class="row" id="foder-section" data-scroll="true">
            <div class="col-xs-12">
                <app-ui-section-header>
                    <h2 ui-section-header-title id="foderHeader">6. Foder</h2>
                    <p ui-section-header-text>
                        Antal hjortdjur ska vara i balans med landskapets foderresurser. Detta är nödvändigt både för att skadorna ska vara rimliga, men även
                        för att få hjortdjursstammar av god kvalitet. Älg och rådjur är anpassade till skogslandskapet och dieten består huvudsakligen av örter,
                        ris samt blad och kvistar från plantor, träd och buskar. Tall utgör en stapelföda för älg. Dov- och kronhjort äter gräs, men även örter
                        samt blad och kvistar från träd.
                        <br />
                        <br />
                        Älgarna äter cirka fem gånger mer foder per hektar i ungskog än i övrig skog eftersom tillgången till foder där är högre. Men även
                        övriga skogen är en viktig foderresurs för älg och andra hjortdjur. I den äldre skogen äter djuren i första hand blåbärsris, lingonris
                        och ljung. Kronhjort och älg äter ibland också bark från äldre träd. Det är bekymmersamt för skogsägaren eftersom trädet då riskerar
                        drabbas av röta och kvalitetsförluster.
                    </p>
                </app-ui-section-header>
            </div>
        </div>

        <div class="row" id="foder-agoslagsfordelning-section" data-scroll="true">
            <div class="col-xs-12">
                <h3>6.1 Ägoslagsfördelning</h3>
            </div>
            <div class="col-xs-12">
                <div class="section-text two-columns" style="margin-bottom: 1rem">
                    <ng-container *ngIf="senasteInventering$ | async as si">
                        Ägoslagsfördelningen anger i stort vilka förutsättningar som finns för hjortdjurens totala födoresurs. Inom området finns
                        {{ si.arealSkogsmarkHa | number : '' : 'sv' }}
                        hektar skogsmark och
                        {{
                            si.tallskogHaABIN3InvMedel + si.granskogHaABIN3InvMedel + si.bblandskogHaABIN3InvMedel + si.ovrigskogHaABIN3InvMedel
                                | number : '' : 'sv'
                        }}
                        hektar av denna areal utgörs av ungskog i 1-4 meters höjd. Myrmark utgör
                        {{ si.myrmark | number : '' : 'sv' }}
                        hektar inom området medan övrig öppen mark uppgår till
                        {{ si.ovrigOppenMark | number : '' : 'sv' }}. Area bebyggd mark uppgår inom området till
                        {{ si.tatortsmark | number : '' : 'sv' }}
                        hektar.
                    </ng-container>
                </div>
            </div>
        </div>
        <app-rapport-rendering-filter>
            <div class="row print-no-break">
                <div class="col-xs-12">
                    <app-kombo-agoslagsfordelning />
                </div>
            </div>
            <app-rapport-footer></app-rapport-footer>
        </app-rapport-rendering-filter>

        <hr class="rapport-divider-line" />

        <div class="row" id="foder-foderprognos-section" data-scroll="true">
            <div class="col-xs-12">
                <h3>6.2 Utveckling av foderproducerande ungskog</h3>
            </div>
        </div>
        <div class="row print-no-break">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="section-text" style="margin-bottom: 1rem">
                    {{ (senasteInventering$ | async)?.foderprognosAndring }} Ungskogen är av särskild betydelse för förändring av fodertillgången i landskapet.
                    Det är därför viktigt att följa utvecklingen. Prognosen utgörs av ungskog mellan 1 och 6 meter i medelhöjd. Den är framtagen av
                    Skogsstyrelsen och baseras på uppgifter om avverkade arealer från satellitbildsanalys samt höjdtillväxtdata från Riksskogstaxeringen.
                </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <app-kombo-foderprognos />
            </div>
            <app-rapport-footer></app-rapport-footer>
        </div>

        <hr class="rapport-divider-line" />

        <div class="row" id="foder-skogenssammansattning-section" data-scroll="true">
            <div class="col-xs-12">
                <h3>6.3 Skogens sammansättning</h3>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8">
                <div class="section-text two-columns" style="margin-bottom: 1rem">
                    <ng-container *ngIf="senasteInventering$ | async as senasteInventering">
                        Enligt Äbin består ungskog i 1-4 meters höjd av
                        {{ senasteInventering?.tallskogHaABIN3InvMedel | number : '' : 'sv' }}
                        hektar tallungskog,
                        {{ senasteInventering?.granskogHaABIN3InvMedel | number : '' : 'sv' }}
                        hektar granungskog,
                        {{ senasteInventering?.bblandskogHaABIN3InvMedel | number : '' : 'sv' }}
                        hektar barrblandskog. Kategorin ”övrig ungskog” uppgår till
                        {{ senasteInventering?.ovrigskogHaABIN3InvMedel | number : '' : 'sv' }} hektar. Detta kan jämföras med den resterande skog (över 5 m)
                        som utgörs av {{ senasteInventering?.tallAldreskogHaRT | number : '' : 'sv' }} hektar tallskog,
                        {{ senasteInventering?.granAldreskogHaRT | number : '' : 'sv' }} hektar granskog,
                        {{ senasteInventering?.barrblandAldreskogHaRT | number : '' : 'sv' }} hektar barrblandskog och
                        {{ senasteInventering?.ovrigtAldreskogHaRT | number : '' : 'sv' }} hektar övrig skog enligt uppgifter från Riksskogstaxeringen.
                        <br /><br />
                        Mängden tillgängligt foder i skogen beror bland annat på vilka skogstyper som dominerar området. Tallskog, barrblandskog och övrig skog
                        är ofta mer öppna och ger därför bättre förutsättningar för vegetation på marken som bl.a. kan nyttjas som foder.
                    </ng-container>
                </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                <app-foder-tradslagssammansatning-info-ruta></app-foder-tradslagssammansatning-info-ruta>
            </div>
        </div>
        <app-rapport-rendering-filter>
            <app-kombo-skogenssammansattning />
            <app-rapport-footer></app-rapport-footer>
        </app-rapport-rendering-filter>

        <hr class="rapport-divider-line" />

        <div class="print-no-break">
            <div class="row" id="foder-tradslagssammansattning-section" data-scroll="true">
                <div class="col-xs-12">
                    <h3>6.4 Trädslagens sammansättning</h3>
                </div>
                <div class="col-xs-12">
                    <div class="section-text two-columns" style="margin-bottom: 1rem">
                        Skogens trädslagssammansättning skiljer sig drastiskt åt mellan olika bestånd. En mycket generaliserad bild får vi fram genom att
                        beräkna alla stammar i området per hektar i ungskog. Men detta ska inte tolkas som att det är sammansättningen i ett genomsnittligt
                        bestånd, utan det ger snarare en bild av trädslagssammansättningen i ungskogarna i det specifika i området.
                        <br />
                        <br />
                        De mest begärliga trädslagen för älg och andra hjortdjur utgörs av gruppen RASE som innefattar trädslagen rönn, asp, sälg och ek.
                    </div>
                </div>
            </div>
            <app-rapport-rendering-filter>
                <app-kombo-tradslagsammansattning />
                <app-rapport-footer></app-rapport-footer>
            </app-rapport-rendering-filter>
        </div>

        <hr class="rapport-divider-line" />

        <div class="row" id="foder-ovriga-bestandsuppgifter-section" data-scroll="true">
            <div class="col-xs-12">
                <h3>6.5 Övriga Beståndsuppgifter</h3>
            </div>
        </div>
        <app-rapport-rendering-filter>
            <app-kombo-ovrigabestandsuppg />
            <app-rapport-footer></app-rapport-footer>
        </app-rapport-rendering-filter>
    </section>

    <br />

    <section aria-labelledby="omSkobiHeader">
        <div class="row" id="om-skobi-section" data-scroll="true">
            <div class="col-xs-12">
                <app-ui-section-header>
                    <h2 ui-section-header-title id="omSkobiHeader">7. Skoglig betesinventering (SKOBI)</h2>
                    <p ui-section-header-text>
                        Skoglig betesinventering består av underlag från olika datakällor (Äbin, foderprognoser, Riksskogstaxeringen med flera) som tillsammans
                        syftar till att ge en sammanhållen beskrivning av tillståndet i skogen för de som förvaltar hjortdjur eller intresserar sig för
                        viltanpassad skogsskötsel.
                    </p>
                </app-ui-section-header>
            </div>
            <br />
            <br />
            <div class="col-xs-12">
                <div class="section-text two-columns" style="margin: 2rem 0">
                    Älgbetesinventeringen (Äbin) är en basmetod som används inom älgförvaltningen. I Äbin tas uppgifter fram som visar skogstillståndet med
                    fokus på skador från hjortdjur, i ungskog som är mellan 1 och 4 meter i medelhöjd. Inventeringen baseras på välbeprövade statistiska metoder
                    som beräknar hur många av träden inom ett inventeringsområde som skadats av hjortdjur. Äbin tar även fram uppgifter om trädslag som är
                    särskilt begärliga för viltet; rönn, asp, sälg och ek. Dessutom ger inventeringen information om vilka trädslag som använts då ungskogen
                    anlades. Uppgifterna från Äbin säger alltså inte bara något om de skogsskador som orsakas av älg och andra hjortdjur, utan här finns även
                    relevant information om tillgång till och utnyttjande av kvistfoder samt ungskogens sammansättning av trädslag.
                    <br /><br />
                    Fältarbetet utförs på våren av professionella entreprenörer. Skogsstyrelsen ansvarar för metoden, analyserar och sammanställer
                    inventeringsresultaten samt levererar resultat som framför allt används av älgförvaltningsgrupperna. Insamling av fältdata görs av
                    entreprenörer och koordineras av Skogforsk. Skogsbruket finansierar fältarbetet. Trots namnet skiljer inte Äbin på skador som orsakats av
                    andra vilda hjortdjur än älg.
                    <br /><br />
                    Foderprognoser visar hur mycket ungskog som finns inom ett specifikt åldersintervall. Skogsstyrelsen beräknar årligen den foderproducerande
                    ungskogsarealen utifrån en satellitbildsanalys. Därefter används en matematisk modell för att beräkna inom vilka åldersintervall som
                    ungskogen befinner sig i ett höjdintervall som ger stor tillgång till tillgänglig kvist för i första hand älg. Sådan skog har en medelhöjd
                    mellan 1 och 6 meter. <br /><br />
                    Riksskogstaxeringens statistik är en del av Sveriges officiella statistik. Som underlag för statistikproduktionen utför Riksskogstaxeringen
                    en årlig stickprovsinventering i fält av Sveriges landareal, exklusive kalfjäll och bebyggd mark. Riksskogstaxeringen drivs av Institutionen
                    för skoglig resurshushållning vid Sveriges lantbruksuniversitet (SLU) i Umeå. När uppgifter från Riksskogstaxering används i denna rapport
                    anges Riksskogstaxeringen som källa.
                </div>
            </div>
        </div>
        <app-rapport-footer></app-rapport-footer>
    </section>

    <section aria-labelledby="begreppOchForklaringarHeader">
        <div class="row" id="begrepp-forklaringar-section" data-scroll="true">
            <div class="col-xs-12">
                <app-ui-section-header>
                    <h2 ui-section-header-title id="begreppOchForklaringarHeader">8. Begrepp och förklaringar</h2>
                </app-ui-section-header>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <p>
                    För beskrivning av de begrepp som används i denna rapport hänvisas till den senaste versionen av fältinstruktionen som finns tillgängligt
                    på:
                    <a href="https://www.skogstyrelsen.se/abin">www.skogstyrelsen.se/abin</a>
                </p>
                <p>Följande begrepp används i rapporten men tas inte upp i ovan nämnd källa.</p>
            </div>
            <div class="col-xs-12">
                <app-begrepp-forklaringar-table></app-begrepp-forklaringar-table>
            </div>
        </div>
        <app-rapport-footer></app-rapport-footer>
    </section>
    <br />
    <section aria-labelledby="litteratureHeader">
        <div class="row" id="litteratur-section" data-scroll="true">
            <div class="col-xs-12">
                <app-ui-section-header>
                    <h2 ui-section-header-title id="litteratureHeader">9. Mer information</h2>
                </app-ui-section-header>
            </div>
            <div class="col-xs-12">
                <p>
                    Mer information om Äbin kan hittas på:
                    <a href="https://www.skogstyrelsen.se/abin">www.skogstyrelsen.se/abin</a>
                </p>
                <p>Här kan du bland annat ta del av:</p>
                <ul>
                    <li>Fältinstruktionen för Äbin</li>
                    <li>Instruktioner för både lottning och planläggning inom Äbin</li>
                    <li>Information om varför det finns variation i Äbin resultat</li>
                    <li>Frågor och svar om Äbin</li>
                    <li>Film om Äbin</li>
                    <li>Länk till Äbin-skolan (utbildningsprogram för inventerare, men öppna för alla)</li>
                </ul>
            </div>
        </div>
        <app-rapport-footer></app-rapport-footer>
    </section>
</div>

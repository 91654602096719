import pptxgen from 'pptxgenjs';
import { AbinResultDto } from '../../inge-api/client/models/abin-result-dto';
import * as uiColors from 'src/app/ui-components/colors';

export const getComboChartOptions = (title: string, maxValue: number) => {
    return {
        x: 1,
        y: 2,
        w: 8,
        h: 4,
        chartArea: { fill: { color: 'FFFFFF' } },
        barDir: 'col',
        barGrouping: 'stacked',
        //
        catAxisLabelFontFace: 'Arial',
        catAxisLabelFontSize: 12,
        catAxisOrientation: 'minMax',
        //
        dataLabelFormatCode: '0',
        showLegend: true,
        legendPos: 'b',
        //
        showTitle: true,
        titleFontFace: 'Calibri Light',
        titleFontSize: 18,
        title: title,
        //
        valAxes: [
            {
                showValAxisTitle: true,
                valAxisTitle: 'Procent (%)',
                valAxisMinVal: 0,
                valAxisMaxVal: maxValue,
                valAxisTitleColor: '000000',
                valAxisLabelColor: '000000'
            },
            {
                showValAxisTitle: false,
                valAxisTitle: '',
                valAxisMinVal: 0,
                valAxisMaxVal: maxValue,
                valAxisTitleColor: 'FFFFFF',
                valAxisLabelColor: 'FFFFFF',
                valGridLine: { style: 'none' }
            }
        ],
        //
        catAxes: [{ catAxisTitle: 'År' }, { catAxisHidden: true }]
    };
};

export const getComboTypes = (
    inventeringar: AbinResultDto[],
    variabelnamnAr: string,
    variabelnamnTreAr: string
): { type: pptxgen.charts; data: { name: string; labels: any[]; values: any[] }[]; options: {} }[] => {
    return [
        {
            type: 'bar' as pptxgen.charts.BAR,
            data: getArsskadorChartData(inventeringar, variabelnamnAr),
            options: { chartColors: [uiColors.veryLightOliveGreen] }
        },
        {
            type: 'line' as pptxgen.charts.LINE,
            data: getTreArsskadorChartData(inventeringar, variabelnamnTreAr),
            options: { chartColors: [uiColors.darkBlue], secondaryValAxis: true, secondaryCatAxis: true }
        }
    ];
};

const getArsskadorChartData = (inventeringar: AbinResultDto[], variabelnamn: string) => {
    const inv = structuredClone(inventeringar).sort((a, b) => a.invAr - b.invAr);
    const data = { name: 'Årsvärde', labels: [], values: [] };
    inv.forEach((x) => {
        data.values.push(x[variabelnamn] * 100);
        data.labels.push(x.invAr);
    });
    return [data];
};

const getTreArsskadorChartData = (inventeringar: AbinResultDto[], variabelnamn) => {
    const inv = structuredClone(inventeringar).sort((a, b) => a.invAr - b.invAr);
    const data = { name: 'Sammanvägning för de tre senaste inventeringarna', labels: [], values: [] };
    inv.forEach((x) => {
        data.values.push(x[variabelnamn] * 100);
        data.labels.push(x.invAr);
    });
    return [data];
};

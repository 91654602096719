<div class="row">
    <div class="col-xs-12">
        <div>Välj område med knapparna för att visa utvecklingen över tid och vilka år som inventerats.</div>
    </div>

    <div class="col-xs-12">
        <app-fordjupning-button-group (selectedOmradeEvent)="onOmradeSelect($event)"></app-fordjupning-button-group>
    </div>
    <div class="col-xs-12">
        <span><b>Valt område</b>: {{ omradeNamn$ | async }}</span>
    </div>
</div>

<div class="row">
    <div class="col-xs-12">
        <div #rapportComponent>
            <ui-figure-caption>
                <ui-figure-text>
                    <h4 class="mb-0">Utveckling av foderproducerande ungskogsareal över tid</h4>
                </ui-figure-text>
            </ui-figure-caption>
            <app-google-line-chart [data]="data" [options]="options"></app-google-line-chart>
        </div>
    </div>
</div>

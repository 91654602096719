import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ChartCategory } from 'src/app/common/service';
import { AbinResultDto } from 'src/app/inge-api/client/models';
import { FordelningPiechartBaseComponent } from '../../base-components';

@Component({
    selector: 'app-foder-rojning-rojt-ejrojt-chart',
    templateUrl: './foder-rojning-rojt-ejrojt-chart.component.html',
    styleUrls: ['./foder-rojning-rojt-ejrojt-chart.component.scss']
})
export class FoderRojningRojtEjrojtChartComponent extends FordelningPiechartBaseComponent implements OnInit, OnDestroy {
    private subs: Subscription[];
    inventeringsAr: number;
    data: any[][];
    legendData: any[];
    ngOnInit(): void {
        this.subs = [];
        this.subs.push(
            this.inventering$.subscribe((x) => {
                if (x) {
                    this.generateChartData(x);
                }
            })
        );
    }
    generateChartData(senasteInventering: AbinResultDto) {
        const data: any[][] = [['Träslag', 'Procent']];
        const colors = [];
        const legendData = [];
        this.inventeringsAr = senasteInventering.invAr;
        if (senasteInventering.andelRojt3InvMedel) {
            const rojdaName = 'Röjda';
            const rojdaValue = Math.round(senasteInventering.andelRojt3InvMedel * 100);
            const rojdaColor = this.colormapService.getColor(ChartCategory.TALL);
            data.push([rojdaName, rojdaValue]);
            colors.push(rojdaColor);
            legendData.push({
                name: rojdaName,
                value: rojdaValue,
                color: rojdaColor
            });

            const inteRojdaName = 'Inte röjda';
            const inteRojdaValue = Math.round(100 - senasteInventering.andelRojt3InvMedel * 100);
            const inteRojdaColor = this.colormapService.getColor(ChartCategory.INGENATGARD);
            data.push([inteRojdaName, inteRojdaValue]);
            colors.push(inteRojdaColor);
            legendData.push({
                name: inteRojdaName,
                value: inteRojdaValue,
                color: inteRojdaColor
            });
        }
        this.data = data;
        this.options = { ...this.options, colors };
        this.legendData = legendData;
    }

    ngOnDestroy(): void {
        this.subs.forEach((x) => x.unsubscribe());
    }
}

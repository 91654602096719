import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ToggleBtnService {
  private activeToggleSource = new BehaviorSubject<{
    id: string;
    value: any;
  }>(null);
  public activeToggle = this.activeToggleSource.asObservable();
  constructor() {}

  setActive(val: { id: string; value: any }): void {
    this.activeToggleSource.next(val);
  }
}

export const camouflageGreen = '#465c0f';
export const sapGreenTwo = '#628114';
export const sapGreen = '#688816';
export const lightOliveGreen = '#94b754';
export const veryLightOliveGreen = '#d4e2ba';
export const lightForestGreen = '#407c62';
export const blueGreen = '#007880';
export const petrol = '#006066';
export const greyishBrown = '#575756';
export const greyishBrownRGBA = [87, 87, 86];
export const veryLightGrey = '#e0e0e0';
export const white = '#fff';
export const brick = '#b51f1f';
export const brickRGBA = [181, 31, 31];
export const brownishOrange = '#cb621a';
export const black = '#000';
export const milkChocolate = '#722624';
export const green = '#13a538';
export const spruce = '#0c6c24';
export const winde = '#3f51b5';
export const birchYellow = '#f8dfa1';
export const oceanBlue = '#d4f1f9';
export const darkBlue = '#001ec5';

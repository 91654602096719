import {
  Component,
  OnInit,
  ViewEncapsulation,
  HostBinding,
} from '@angular/core';

@Component({
  selector: 'ui-legend',
  templateUrl: './ui-legend.component.html',
  styleUrls: ['./ui-legend.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiLegendComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
  @HostBinding('class') elClass = 'ui-legend';
}

import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  InGeApiState,
  InventeringsNiva,
} from '../../store/state-model/inge-api.model';
import * as abinActions from '../../store/actions/abin.actions';
import { toInteger } from 'lodash-es';
@Injectable({ providedIn: 'root' })
export class AbinRapportDataResolver  {
  constructor(private store: Store<InGeApiState>) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    const landsdel = route.queryParamMap.get('landsdel');
    const lan = route.queryParamMap.get('lan');
    const afo = route.queryParamMap.get('afo');
    const delomrade = route.queryParamMap.get('delomrade');

    if (landsdel === 'alla') {
      this.getLandsData();
    } else if (
      this.isNumeric(landsdel) &&
      this.isNumeric(lan) &&
      this.isNumeric(afo) &&
      this.isNumeric(delomrade)
    ) {
      this.getStratumData(route);
    } else if (
      this.isNumeric(landsdel) &&
      this.isNumeric(lan) &&
      this.isNumeric(afo)
    ) {
      this.getAfoData(route);
    } else if (this.isNumeric(landsdel) && this.isNumeric(lan)) {
      this.getLanData(route);
    } else if (this.isNumeric(landsdel)) {
      this.getLandsdelData(route);
    }
  }

  getLandsData(): void {
    this.store.dispatch(abinActions.getAbinSummeringLand());
    this.store.dispatch(abinActions.getAbinHelaLandetGeometri());
  }

  getLandsdelData(route: ActivatedRouteSnapshot): void {
    let landsdelKod: string | number = route.queryParamMap.get('landsdel');
    if (landsdelKod) {
      landsdelKod = toInteger(landsdelKod);
      this.store.dispatch(
        abinActions.getAbinSummeringLandsdel({ landsdelKod })
      );
      this.store.dispatch(
        abinActions.getAbinLandsdelGeometri({ landsdelkod: landsdelKod })
      );
      // this.store.dispatch(abinActions.getAbinArskadaGeometrier());
    }
  }

  getLanData(route: ActivatedRouteSnapshot): void {
    const lankod = route.queryParamMap.get('lan');
    if (lankod) {
      this.store.dispatch(abinActions.getAbinSummeringLan({ lankod }));
      this.store.dispatch(abinActions.getAbinLanGeometri({ lankod }));
      // this.store.dispatch(abinActions.getAbinArskadaGeometrier());
    }
  }

  getAfoData(route: ActivatedRouteSnapshot): void {
    const lankod = route.queryParamMap.get('lan');
    const afonr = this.paramToNumber(route.queryParamMap.get('afo'));
    if (lankod && afonr) {
      this.store.dispatch(
        abinActions.getAbinSummeringLanAfo({ lankod, afonr })
      );
      this.store.dispatch(abinActions.getAbinAfoGeometri({ lankod, afonr }));
      // this.store.dispatch(abinActions.getAbinArskadaGeometrier());
    }
  }

  getStratumData(route: ActivatedRouteSnapshot): void {
    const lanKod = route.queryParamMap.get('lan');
    const afoNr = this.paramToNumber(route.queryParamMap.get('afo'));
    const delomradesnummer = this.paramToNumber(
      route.queryParamMap.get('delomrade')
    );
    if (lanKod && afoNr && delomradesnummer) {
      this.store.dispatch(
        abinActions.getAbinSummeringDelomrade({
          lanKod,
          afoNr,
          delomradesnummer,
        })
      );
      this.store.dispatch(
        abinActions.getAbinDelomradeGeometri({
          lankod: lanKod,
          afonr: afoNr,
          delomradesnummer,
        })
      );
    }
  }

  private paramToNumber(param: string): number {
    if (param) {
      return toInteger(param);
    }
  }

  private isNumeric(value: any): boolean {
    return !isNaN(value - parseFloat(value));
  }
}

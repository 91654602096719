import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ABINROUTES } from '../route/abin-routes';
import { isString } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class EmptyParamGuard  {
  constructor(private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.hasEmpty(state)) {
      const params = this.removeEmpty(state);
      this.router.navigate([ABINROUTES.RAPPORT_PAGE], {
        queryParams: { ...params },
      });
    }

    return true;
  }

  hasEmpty(state: RouterStateSnapshot): boolean {
    let hasEmpty = false;
    const keys = ['landsdel', 'lan', 'afo', 'delomrade'];
    keys.forEach((key) => {
      const param = state.root.queryParamMap.get(key);
      if (param === '' || param === null) {
        hasEmpty = true;
      }
    });
    return hasEmpty;
  }

  removeEmpty(state: RouterStateSnapshot): any {
    const landsdel = state.root.queryParamMap.get('landsdel');
    const lan = state.root.queryParamMap.get('lan');
    const afo = state.root.queryParamMap.get('afo');
    const delomrade = state.root.queryParamMap.get('delomrade');
    let params = {};
    if (landsdel === '' || landsdel === null) {
      params = { ...state.root.queryParams, landsdel: 'alla' };
    }
    if (lan === '' || lan === null) {
      params = { ...state.root.queryParams, lan: 'alla' };
    }
    if (afo === '' || afo === null) {
      params = { ...state.root.queryParams, afo: 'alla' };
    }
    if (delomrade === '' || delomrade === null) {
      params = { ...state.root.queryParams, delomrade: 'alla' };
    }
    return { ...state.root.queryParams, ...params };
  }
}

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { combineLatest } from 'rxjs';
import { getPackageForChart } from '../../google-charts-helper';
import { GoogleChartType } from '../../google-charts-type';
import { GoogleChartBaseComponent } from '../chart-base.component';
declare const google: any;

@Component({
    selector: 'app-google-combochart',
    templateUrl: './google-combochart.component.html',
    styleUrl: './google-combochart.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GoogleCombochartComponent extends GoogleChartBaseComponent implements OnInit {
    @ViewChild('chartContainer', { read: ElementRef })
    private containerEl: ElementRef<HTMLElement>;
    chartType = GoogleChartType.ComboChart;

    @Input()
    set data(val: Array<Array<any>>) {
        if (val && val.length > 0) {
            this.chartData.next(val);
        }
    }

    @Input()
    set options(val: Record<string, any>) {
        if (val) {
            this.chartOptions.next(val);
        }
    }

    ngOnInit(): void {
        const chartPackage = getPackageForChart(this.chartType);

        combineLatest([this.chartData$, this.chartOptions$, this.loadChartPackage(chartPackage)]).subscribe(([data, options, _null]) => {
            if (data && options) {
                this.chart = new google.visualization.ComboChart(this.containerEl.nativeElement);
                this.drawChart(data, options);
            }
        });
    }
}

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { toNumber } from 'lodash-es';
import { AbinResultDto } from '../../../../inge-api/client/models';
import { ButtonGroupItemData } from '../../../../shared-report-components/base-components/fordjupning-button-group/button-group-item.model';
import { ChartCategory, ColorMapService } from '../../../../common/service/color-mapper.service';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-kombo-foderprognos',
    templateUrl: './kombo-foderprognos.component.html',
    styleUrl: './kombo-foderprognos.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class KomboFoderprognosComponent implements OnInit {
    private _omradeNamn = new BehaviorSubject<string>(null);
    omradeNamn$ = this._omradeNamn.asObservable();

    options: any;
    data: any[][];

    constructor(public colormapService: ColorMapService) {}

    ngOnInit(): void {}

    onOmradeSelect(omrade: ButtonGroupItemData) {
        if (omrade && omrade.inventeringar && omrade.inventeringar.length > 0) {
            this._omradeNamn.next(omrade.omradeName);
            this.generateChartData(omrade.inventeringar[0]);
        }
    }

    generateChartData(inventering: AbinResultDto) {
        const data: any[][] = [['År', 'Foderprognos ha']];
        const options: any = {
            colors: [this.colormapService.getColor(ChartCategory.SKOGSMARK)],
            curveType: 'function',
            animation: {
                startup: true
            },
            vAxis: {
                title: 'Hektar',
                titleTextStyle: {
                    italic: false,
                    fontSize: 16
                },
                minValue: 0
            },
            legend: {
                position: 'none'
            },
            height: 250,
            chartArea: { width: '80%', height: '70%' }
        };
        inventering.foderprognos.forEach((prognosDto) => {
            data.push([prognosDto.ar.toString(), toNumber(prognosDto.foderprognos)]);
        });
        this.data = data;
        this.options = options;
    }
}

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { combineLatest } from 'rxjs';
import { getPackageForChart } from '../../google-charts-helper';
import { GoogleChartType } from '../../google-charts-type';
import { GoogleChartBaseComponent } from '../chart-base.component';
declare const google: any;
@Component({
  selector: 'app-google-line-chart',
  templateUrl: './google-line-chart.component.html',
  styleUrls: ['./google-line-chart.component.scss'],
})
export class GoogleLineChartBaseComponent
  extends GoogleChartBaseComponent
  implements OnInit
{
  @ViewChild('chartContainer', { read: ElementRef })
  private containerEl: ElementRef<HTMLElement>;
  chartType = GoogleChartType.LineChart;

  @Input()
  set data(val: Array<Array<any>>) {
    if (val && val.length > 0) {
      this.chartData.next(val);
    }
  }

  @Input()
  set options(val: Record<string, any>) {
    if (val) {
      this.chartOptions.next(val);
    }
  }

  ngOnInit(): void {
    const chartPackage = getPackageForChart(this.chartType);

    combineLatest([
      this.chartData$,
      this.chartOptions$,
      this.loadChartPackage(chartPackage),
    ]).subscribe(([data, options, _null]) => {
      if (data && options) {
        this.chart = new google.visualization.LineChart(
          this.containerEl.nativeElement
        );
        this.drawChart(data, options);
      }
    });
  }
}

<div class="row">
    <div class="col-xs-12">
        <div>Välj område med knapparna.</div>
    </div>

    <div class="col-xs-12">
        <app-fordjupning-button-group (selectedOmradeEvent)="onOmradeSelect($event)"></app-fordjupning-button-group>
    </div>
    <div class="col-xs-12">
        <span><b>Valt område</b>: {{ omradeNamn$ | async }}</span>
    </div>
</div>

<div class="row">
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div class="row middle-xs">
            <ng-container *ngIf="dataMarkslag?.length > 0; else elseTemplate">
                <div class="col-xs-12">
                    <ui-figure-caption>
                        <ui-figure-text>
                            <h4>Markslagsfördelning för de tre senaste inventeringarna</h4>
                        </ui-figure-text>
                    </ui-figure-caption>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <app-google-pie-chart [data]="dataMarkslag" [options]="optionsMarkslag"></app-google-pie-chart>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <ui-legend ui-legend-vertical>
                        <ui-legend-item *ngFor="let item of legendDataMarkslag; let i = index" [color]="item.color">
                            {{ item.name }}: {{ item.value | number }}%
                        </ui-legend-item>
                    </ui-legend>
                </div>
            </ng-container>
            <ng-template #elseTemplate>
                <div class="col-xs-6">Kunde inte ladda ägoslag för detta område</div>
            </ng-template>
        </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div class="row middle-xs">
            <ng-container *ngIf="dataForyngrTradslag?.length > 0; else elseTradslagTemplate">
                <div class="col-xs-12">
                    <ui-figure-caption>
                        <ui-figure-text>
                            <h4>Föryngrade trädslag för de tre senaste inventeringarna</h4>
                        </ui-figure-text>
                    </ui-figure-caption>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <app-google-pie-chart [data]="dataForyngrTradslag" [options]="optionsForyngrTradslag"></app-google-pie-chart>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <ui-legend ui-legend-vertical>
                        <ui-legend-item *ngFor="let item of legendDataForyngrTradslag; let i = index" [color]="item.color">
                            {{ item.name }}: {{ item.value | number }}%
                        </ui-legend-item>
                    </ui-legend>
                </div>
            </ng-container>
            <ng-template #elseTradslagTemplate>
                <div class="col-xs-6">Kunde inte ladda föryngrade trädslag för detta område</div>
            </ng-template>
        </div>
    </div>
</div>

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription, zip } from 'rxjs';
import { AbinResultDto } from 'src/app/inge-api/client/models';
import {
  InventeringsNiva,
  InGeApiState,
} from 'src/app/store/state-model/inge-api.model';
import { UiTableConfig } from 'src/app/ui-components/ui-table/ui-table/models/tableConfig';
import { Store, select } from '@ngrx/store';
import { formatNumber } from '@angular/common';

@Component({
  selector: 'app-foder-tradslagssammansattning-table',
  templateUrl: './foder-tradslagssammansattning-table.component.html',
  styleUrls: ['./foder-tradslagssammansattning-table.component.scss'],
})
export class FoderTradslagssammansattningTableComponent
  implements OnInit, OnDestroy {
  inventeringar$: Observable<AbinResultDto[]>;
  inventeringarLoading$: Observable<boolean>;
  inventeringarError$: Observable<boolean>;
  tableConfig: UiTableConfig = {
    columns: [
      { title: 'Trädslag', dataProperty: 'tradslag' },
      { title: 'Stammar per ha', dataProperty: 'stammarPerHa' },
      { title: 'Senaste tre inventeringar', dataProperty: 'senasteTreInv' },
    ],
    rowActions: [],
  };

  tableCaption = '';

  tableContent = [];
  private subs: Subscription[] = [];
  constructor(private store: Store<InGeApiState>) {
    this.inventeringar$ = this.store.pipe(select((x) => x.abin.inventeringar));
    this.inventeringarLoading$ = store.pipe(
      select((x) => x.abin.loadingInventeringar)
    );
    this.inventeringarError$ = store.pipe(
      select((x) => x.abin.inventeringarError)
    );
  }

  ngOnInit(): void {
    this.subs.push(
      this.inventeringar$.subscribe((x) => {
        if (x && x.length > 0) {
          const senasteInv = x[0];
          this.tableConfig = {
            columns: [
              { title: 'Trädslag', dataProperty: 'tradslag' },
              { title: `${senasteInv.invAr}`, dataProperty: 'stammarPerHa' },
              {
                title: 'Senaste tre inventeringar',
                dataProperty: 'senasteTreInv',
              },
            ],
            rowActions: [],
          };
          this.tableContent = [
            {
              tradslag: 'Tall',
              stammarPerHa: formatNumber(senasteInv.antalTallarHa, 'sv'),
              senasteTreInv: formatNumber(
                senasteInv.antalTallarHa3InvAVG,
                'sv'
              ),
            },
            {
              tradslag: 'Gran',
              stammarPerHa: formatNumber(senasteInv.antalGranarHa, 'sv'),
              senasteTreInv: formatNumber(
                senasteInv.antalGranarHa3InvAVG,
                'sv'
              ),
            },

            {
              tradslag: 'Björk',
              stammarPerHa: formatNumber(senasteInv.antalBjorkarHa, 'sv'),
              senasteTreInv: formatNumber(
                senasteInv.antalBjorkarHa3InvAVG,
                'sv'
              ),
            },
            {
              tradslag: 'Övriga produktionsträdslag',
              stammarPerHa: formatNumber(senasteInv.antalOvrigtHa, 'sv'),
              senasteTreInv: formatNumber(
                senasteInv.antalOvrigtHa3InvAVG,
                'sv'
              ),
            },
            {
              tradslag: 'RASE',
              stammarPerHa: formatNumber(senasteInv.antalRASEHa, 'sv'),
              senasteTreInv: formatNumber(senasteInv.antalRASEHa3InvAVG, 'sv'),
            },
            {
              tradslag: 'Totalt',
              stammarPerHa: formatNumber(
                this.getTotalStammarHa(senasteInv),
                'sv'
              ),
              senasteTreInv: formatNumber(
                this.getTotalStammarHa3Inv(senasteInv),
                'sv'
              ),
            },
          ];
        }
      })
    );
  }

  getTotalStammarHa(inv: AbinResultDto): number {
    return Math.round(
      (inv.antalTallarHa || 0) +
        (inv.antalGranarHa || 0) +
        (inv.antalBjorkarHa || 0) +
        (inv.antalOvrigtHa || 0) +
        (inv.antalRASEHa || 0)
    );
  }

  getTotalStammarHa3Inv(inv: AbinResultDto): number {
    return Math.round(
      (inv.antalTallarHa3InvAVG || 0) +
        (inv.antalGranarHa3InvAVG || 0) +
        (inv.antalBjorkarHa3InvAVG || 0) +
        (inv.antalOvrigtHa3InvAVG || 0) +
        (inv.antalRASEHa3InvAVG || 0)
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((x) => x.unsubscribe());
  }
}

<div class="row">
    <div class="col-xs-12">
        <ui-figure-caption>
            <ui-figure-text>
                <h4>Andel ungskog på bördig mark som är föryngrad med tall</h4>
            </ui-figure-text>
            <ui-figure-sub-text> Andel bördig mark: {{ andelBordigMark }}% för de tre senaste inventeringarna </ui-figure-sub-text>
        </ui-figure-caption>

        <app-google-barchart [data]="data" [options]="options"></app-google-barchart>
    </div>
</div>

import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { Observable, Subscription } from 'rxjs';
import { AbinResultDto } from 'src/app/inge-api/client/models';
import { InGeApiState } from 'src/app/store/state-model/inge-api.model';

@Component({
  selector: 'app-slutsatser-lista',
  templateUrl: './slutsatser-lista.component.html',
  styleUrls: ['./slutsatser-lista.component.scss'],
})
export class SlutsatserListaComponent implements OnInit {
  inventering$: Observable<AbinResultDto>;
  private subs: Subscription[];
  constructor(private store: Store<InGeApiState>) {
    this.inventering$ = this.store.pipe(
      select((x) => {
        if (x && x.abin?.inventeringar?.length > 0) {
          return cloneDeep(x.abin.inventeringar).sort(
            (a, b) => b.invAr - a.invAr
          )[0];
        }
      })
    );
  }

  ngOnInit(): void {}
}

<div class="row">
    <div class="row">
        <div class="col-xs-12">
            <h1>Välj område</h1>
        </div>
    </div>
    <div class="col-xs-12">
        <div class="section-text two-columns">
            <p>
                På denna sida hittar du underlag och fakta från Skogsstyrelsens Älgbetesinventering (Äbin) och foderprognoser samt relevanta uppgifter från
                Riksskogstaxeringen. Du kan titta på siffror från lokala delområden, älgförvaltningsområden, län, landsdel och för landet som helhet.
                Statistiken är ett underlag för i första hand de Älgförvaltningsgrupper som regelbundet tar fram förvaltningsplaner för sina respektive områden.
                Underlaget är även relevant för övriga aktörer inom viltförvaltningen.
            </p>
        </div>
    </div>
</div>
<br />
<hr />
<div class="row" *ngIf="requestError$ | async">
    <div class="col-xs-12">
        <app-error-message></app-error-message>
    </div>
</div>

<!-- <div class="row">
    <div class="col-xs-12">
        <h3 class="valjrapport">Välj rapporttyp att visa:</h3>
        <button
            ui-button-outlined
            ui-button-round
            (click)="toggleDisplayMainReportDiv('resultat')"
            title="Detaljerad rapport för ett specifikt område."
            [ngClass]="{ activeChoice: reportToShow == 'resultat' }"
        >
            Resultatrapport
        </button>
        <button
            ui-button-outlined
            ui-button-round
            (click)="toggleDisplayMainReportDiv('mal')"
            title="Överblick för hela Sverige, landsdel eller län med möjlighet att granska underliggande områden."
            [ngClass]="{ activeChoice: reportToShow == 'mal' }"
        >
            Måluppföljning
        </button>
    </div>
</div> -->
<div>
    <app-resultat-rapport-select></app-resultat-rapport-select>
    <!-- <app-resultat-rapport-select *ngIf="reportToShow == 'resultat'"></app-resultat-rapport-select> -->
    <!-- <app-uppfolj-rapport-select *ngIf="reportToShow == 'mal'"></app-uppfolj-rapport-select> -->
</div>

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AbinResultDto } from '../../../../inge-api/client/models';
import { ButtonGroupItemData } from '../../../../shared-report-components/base-components/fordjupning-button-group/button-group-item.model';
import { ChartCategory } from '../../../../common/service/color-mapper.service';
import { BehaviorSubject } from 'rxjs';
import round from 'lodash-es/round';
import { AndelCombochartBaseComponent } from '../../../../abin-resultat-rapport/base-components/andel-combochart-base/andel-combochart-base.component';

@Component({
    selector: 'app-kombo-bordigmark',
    templateUrl: './kombo-bordigmark.component.html',
    styleUrl: './kombo-bordigmark.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class KomboBordigmarkComponent extends AndelCombochartBaseComponent implements OnInit {
    // Magra marker diagram
    public dataBarchart: any[][];
    public andelBordigMark: number = 0;
    public inventeringsAr: number;

    // Föryngrade trädslag chart
    private chartEntriesTradslag = [
        {
            name: 'Tall',
            key: 'standortsanpassningAndelBordigaMarkerMedTall3InvMedel',
            color: ChartCategory.TALL
        },
        {
            name: 'Gran',
            key: 'standortsanpassningAndelBordigaMarkerMedGran3InvMedel',
            color: ChartCategory.GRAN
        },
        {
            name: 'Björk',
            key: 'standortsanpassningAndelBordigaMarkerMedBjork3InvMedel',
            color: ChartCategory.BJORK
        },
        {
            name: 'Lärk',
            key: 'standortsanpassningAndelBordigaMarkerMedLark3InvMedel',
            color: ChartCategory.LARK
        },
        {
            name: 'Contorta',
            key: 'standortsanpassningAndelBordigaMarkerMedContorta3InvMedel',
            color: ChartCategory.CONTORTA
        },
        {
            name: 'Övrigt',
            key: 'standortsanpassningAndelBordigaMarkerMedOvrigt3InvMedel',
            color: ChartCategory.OVRIG
        },
        {
            name: 'Uppgift saknas',
            key: 'standortsanpassningAndelBordigaMarkerMedIngenAtgard3InvMedel',
            color: ChartCategory.INGENATGARD
        }
    ];
    optionsTradslag: any = {
        chartArea: { width: '90%', height: '90%' },
        height: 230,
        pieSliceText: 'none',
        tooltip: {
            trigger: 'none'
        },
        legend: {
            position: 'none'
        }
    };
    dataTradslag: any[][];
    legendDataTradslag: any[];

    private _omradeNamn = new BehaviorSubject<string>(null);
    omradeNamn$ = this._omradeNamn.asObservable();
    private _senasteInventering = new BehaviorSubject<AbinResultDto>(null);
    senasteInventering$ = this._senasteInventering.asObservable();

    ngOnInit(): void {}

    onOmradeSelect(omrade: ButtonGroupItemData) {
        if (omrade && omrade.inventeringar && omrade.inventeringar.length > 0) {
            const inventeringarSorted = omrade.inventeringar.map((a) => a).sort((a, b) => a.invAr - b.invAr);
            const senasteInventering = omrade.inventeringar[0];
            this._omradeNamn.next(omrade.omradeName);
            this.generateChartData(inventeringarSorted);
            this.generateChartDataTradslag(senasteInventering);
            this._senasteInventering.next(senasteInventering);

            // Uppdatera värdet för bördig mark om det finns för inventeringen
            if (senasteInventering.andelBordigaMarker3InvMedel && senasteInventering.andelBordigaMarker3InvMedel > 0) {
                this.andelBordigMark = round(senasteInventering.andelBordigaMarker3InvMedel * 100, 1);
            }
        }
    }

    generateChartData(inventeringar: AbinResultDto[]) {
        const data: any[][] = [
            [
                'År',
                { id: 'Arsskada_1ar', type: 'number', label: 'Årsvärde' },
                { id: 'tool_tip_1ar', type: 'string', role: 'tooltip', p: { html: true } },
                { id: 'Medel_3ar', type: 'number', label: 'Sammanvägning för de tre senaste inventeringarna' },
                { id: 'tool_tip_3ar', type: 'string', role: 'tooltip', p: { html: true } }
            ]
        ];

        inventeringar.forEach((inv) => {
            if (inv.standortsanpassningAndelBordigaMarkerMedTall || inv.standortsanpassningAndelBordigaMarkerMedTall === 0) {
                // Årsskada
                const arsVarde = round(inv.standortsanpassningAndelBordigaMarkerMedTall * 100, 1);

                // 3års medel
                const trearsMedel = round(inv.standortsanpassningAndelBordigaMarkerMedTall3InvMedel * 100, 1);

                data.push([
                    `${inv.invAr}`,
                    arsVarde > 0 ? arsVarde : null,
                    this.getTooltipNoFelmarginal(false, arsVarde),
                    trearsMedel,
                    this.getTooltipNoFelmarginal(true, trearsMedel)
                ]);
            }
        });

        this.options = {
            ...this.options,
            tooltip: { isHtml: true },
            height: 315,
            chartArea: { width: '100%', height: '100%', top: 25, left: 50, right: 0, bottom: 50 },
            vAxis: {
                ...this.options.vAxis,
                maxValue: this.getMaxValue(data)
            }
        };

        this.dataBarchart = data;
    }

    generateChartDataTradslag(senasteInventering: AbinResultDto) {
        const data: any[][] = [['Markslag', 'Procent']];
        const colors = [];
        const legendData = [];
        this.inventeringsAr = senasteInventering.invAr;
        for (const entry of this.chartEntriesTradslag) {
            if (senasteInventering[entry.key]) {
                const name = entry.name;
                const value = Math.round(senasteInventering[entry.key] * 100);
                const color = this.colormapService.getColor(entry.color);
                data.push([name, value]);
                colors.push(color);
                legendData.push({ name, value, color });
            }
        }

        this.dataTradslag = data;
        this.optionsTradslag = { ...this.optionsTradslag, colors };
        this.legendDataTradslag = legendData;
    }
}

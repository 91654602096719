<div class="row">
    <div class="col-xs-12">
        <!-- <h4 class="mt-0">Historisk utveckling av andel tallstammar oskadade av vilt</h4> -->
        <div class="subheadertext">Välj område med knapparna för att visa utvecklingen över tid och vilka år som inventerats.</div>
    </div>

    <div class="col-xs-12">
        <app-fordjupning-button-group (selectedOmradeEvent)="onOmradeSelect($event)"></app-fordjupning-button-group>
        <p><b>Valt område</b>: {{ omradeNamn$ | async }}</p>
    </div>
</div>

<div class="row">
    <div class="col-md-8 col-xs-12 col-lg-9">
        <app-arsskada-combochart
            [inventeringar]="inventeringar$ | async"
            [vardeVariabler]="vardeVariabler"
            [diagramBeskrivning]="'Historisk utveckling av andel tallstammar oskadade av vilt'"
        ></app-arsskada-combochart>
    </div>

    <div class="col-md-4 col-xs-12 col-lg-3">
        <ng-container *ngIf="senasteInventering$ | async as senasteInventering">
            <app-sammanvagning-card
                [inventering]="senasteInventering"
                vardeVariabel="oskadadTallAndel3InvMedel"
                cardBeskrivning="Andel oskadad tall, sammanvägning för de tre senaste inventeringarna"
            >
                <ng-container sammanvagningCardIcon *ngIf="senasteInventering?.slutsatsOskadadTallInt > 0; else elseTemplate">
                    <span class="abinicon-oskadad_tall_uppfyllt"></span>
                </ng-container>
                <ng-template sammanvagningCardIcon #elseTemplate>
                    <span class="abinicon-oskadad_tall_ej_uppfyllt"></span>
                </ng-template>
            </app-sammanvagning-card>
        </ng-container>
    </div>
</div>

import {
  Component,
  OnInit,
  ViewEncapsulation,
  HostBinding,
} from '@angular/core';

@Component({
  selector:
    'button[ui-button], button[ui-button-round], button[ui-button-outlined]',
  templateUrl: './ui-button.component.html',
  styleUrls: ['./ui-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiButtonComponent implements OnInit {
  constructor() {}
  @HostBinding('class') elementClass = 'ui-button';
  ngOnInit(): void {}
}

import { formatPercent } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { flatten } from 'lodash-es';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  InventeringsNiva,
  Fordjupning,
  InGeApiState,
} from 'src/app/store/state-model/inge-api.model';
import { environment } from 'src/environments/environment';
import { SublayerConfiguration } from '../../base-components/fordjupning-karta-base/fordjupning-karta.model';
import { FordjupningKartaPopupContent } from '../../base-components/fordjupning-karta-popup/fordjupning-karta-popup.model';

@Component({
  selector: 'app-uppfolj-arsskada-gran-karta',
  templateUrl: './uppfolj-arsskada-gran-karta.component.html',
  styleUrls: ['./uppfolj-arsskada-gran-karta.component.scss'],
})
export class UppfoljArsskadaGranKartaComponent implements OnInit {
  private _layerConfig = new BehaviorSubject<SublayerConfiguration>(null);
  layerConfig$ = this._layerConfig.asObservable();
  inventeringsNiva$: Observable<InventeringsNiva>;
  inventeringsNiva: InventeringsNiva;
  fordjupningsData$: Observable<Fordjupning>;
  fordjupningsData: Fordjupning;
  private popupData = new BehaviorSubject<FordjupningKartaPopupContent>(null);
  popupData$ = this.popupData.asObservable();
  isLoadingPopupData: boolean;

  constructor(private store: Store<InGeApiState>) {
    this.inventeringsNiva$ = this.store.pipe(
      select((x) => x.uppfoljningsrapport.inventeringsNiva)
    );
    this.store
      .pipe(select((x) => x.uppfoljningsrapport.fordjupning))
      .subscribe((x) => (this.fordjupningsData = x));
  }
  ngOnDestroy(): void {}
  ngOnInit(): void {
    this.inventeringsNiva$.subscribe((x) => {
      if (x) {
        this.inventeringsNiva = x;
        const mapserviceConfig =
          environment.kartor.geodataportalVisaAbinrapport_3_0;
        if (x === InventeringsNiva.LAND) {
          this._layerConfig.next({
            layerId: mapserviceConfig.sublayers.arsskadorGranLandsdel.id,
            parentId: mapserviceConfig.sublayers.arsskadorGranLandsdel.parentId,
          });
        }
        if (x === InventeringsNiva.LANDSDEL) {
          this._layerConfig.next({
            layerId: mapserviceConfig.sublayers.arsskadorGranLan.id,
            parentId: mapserviceConfig.sublayers.arsskadorGranLan.parentId,
          });
        }
        if (x === InventeringsNiva.LAN) {
          this._layerConfig.next({
            layerId: mapserviceConfig.sublayers.arsskadorGranAfo.id,
            parentId: mapserviceConfig.sublayers.arsskadorGranAfo.parentId,
          });
        }
      }
    });
  }

  onMapClicked(graphic: __esri.Graphic) {
    this.isLoadingPopupData = true;
    if (
      !graphic ||
      !this.fordjupningsData ||
      !this.fordjupningsData.inventeringar
    ) {
      return;
    }
    const inventeringar = flatten(this.fordjupningsData.inventeringar);
    let omradeNamn: string;
    let omradeVardePercent: string;
    const contentText = 'Sammanvägning för de tre senaste inventeringarna';
    let thumbsUp = false;
    if (this.fordjupningsData.fordjupningsNiva === InventeringsNiva.LANDSDEL) {
      const landsdelKod = graphic.attributes.LandsdelKod.toString();
      const inventering = inventeringar.filter(
        (x) => x.landsdelKod.toString() === landsdelKod
      )[0];
      omradeNamn = inventering.landsdelNamn;
      omradeVardePercent = formatPercent(
        inventering.arsskadaGranAndel3InvMedel,
        'sv',
        '1.1-1'
      );
      thumbsUp = inventering.slutsatsArsskadaGranInt > 0;
    }
    if (this.fordjupningsData.fordjupningsNiva === InventeringsNiva.LAN) {
      const lanKod = graphic.attributes.LanKod.toString();
      const inventering = inventeringar.filter((x) => x.lanKod === lanKod)[0];
      omradeNamn = `${inventering.landsdelNamn} - ${inventering.lanNamn}`;
      omradeVardePercent = formatPercent(
        inventering.arsskadaGranAndel3InvMedel,
        'sv',
        '1.1-1'
      );
      thumbsUp = inventering.slutsatsArsskadaGranInt > 0;
    }
    if (this.fordjupningsData.fordjupningsNiva === InventeringsNiva.AFO) {
      const AFONr = graphic.attributes.AFONr.toString();
      const inventering = inventeringar.filter(
        (x) => x.afoNr.toString() === AFONr
      )[0];
      omradeNamn = `${inventering.lanNamn} - ${inventering.afoNamn} (ÄFO nr ${inventering.afoNr})`;
      omradeVardePercent = formatPercent(
        inventering.arsskadaGranAndel3InvMedel,
        'sv',
        '1.1-1'
      );
      thumbsUp = inventering.slutsatsArsskadaGranInt > 0;
    }

    this.popupData.next({
      omradeNamn,
      omradeVarde: `Årsskadad gran, ${omradeVardePercent}`,
      contentText,
      thumbsUp,
    });
    this.isLoadingPopupData = false;
  }
}

import { formatPercent } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AbinResultDto } from 'src/app/inge-api/client/models';

@Component({
  selector: 'app-sammanvagning-card',
  templateUrl: './sammanvagning-card.component.html',
  styleUrls: ['./sammanvagning-card.component.scss'],
})
export class SammanvagningCardComponent implements OnInit {
  private _inventering = new BehaviorSubject<AbinResultDto>(null);
  inventering$ = this._inventering.asObservable();

  private _vardeVariabel = new BehaviorSubject<string>(null);
  vardeVariabel$ = this._vardeVariabel.asObservable();

  private _cardBeskrivning = new BehaviorSubject<string>(null);
  cardBeskrivning$ = this._cardBeskrivning.asObservable();

  percentValue$: Observable<string>;

  private subs: Subscription[];
  private _decimals: number;

  constructor() {}

  @Input()
  set inventering(val: AbinResultDto) {
    if (val) this._inventering.next(val);
  }

  @Input()
  set vardeVariabel(val: string) {
    if (val) this._vardeVariabel.next(val);
  }

  @Input()
  set cardBeskrivning(val: string) {
    if (val) this._cardBeskrivning.next(val);
  }

  @Input()
  set decimals(val: number) {
    this._decimals = val;
  }

  ngOnInit(): void {
    this.percentValue$ = combineLatest([
      this.inventering$,
      this.vardeVariabel$,
    ]).pipe(
      map(([inventering, vardeVariabel]) => {
        if (inventering && vardeVariabel) {
          if (!inventering.hasOwnProperty(vardeVariabel)) {
            console.trace(
              `Felaktig värdevarabel ${vardeVariabel}. Kunde inte skapa sammanvägning`
            );
            return;
          }
          const decimals = this._decimals || 0;
          return formatPercent(
            inventering[vardeVariabel],
            'sv',
            `1.${decimals}`
          );
        }
      })
    );
  }
}

import { formatPercent } from '@angular/common';
import pptxgen from 'pptxgenjs';
import { AbinResultDto } from 'src/app/inge-api/client/models';
import { _andelBarchartOptions } from '../slide-components/_andelBarchart';
import addBanner from '../slide-components/_banner';
import { sammanvagningCardOptions } from '../slide-components/_sammanvagningCard';
import { getComboChartOptions, getComboTypes } from '../pptx-chart-utils';
import { getYAxisMaxValueFromList } from '../../../google-charts/google-chart-utils';

export default (slide: pptxgen.Slide, inventeringar: AbinResultDto[]) => {
    addBanner(slide, {
        header: 'Konkurrensstatus för rönn, asp, sälg och ek (RASE)',
        text: 'Målet är att RASE ska ha en gynnsam status på minst 10 procent av de inventerade ytorna. Om andelen mager mark i området är stor (>40%) så accepteras att RASE har gynnsam status på 5 procent av ytorna.'
    });
    const senasteInventering = inventeringar[0];

    const comboTypes = getComboTypes(inventeringar, 'raseAndelGynnsam', 'raseAndelGynnsam3InvMedel');
    const maxValue = getYAxisMaxValueFromList([...comboTypes[0].data[0].values, ...comboTypes[1].data[0].values]);
    const comboOptions = getComboChartOptions('Andel av inventerade ytor i ungskog där RASE har gynnsam status', maxValue);

    slide
        .addChart(comboTypes, comboOptions as any)
        .addText('Felmarginaler finns enbart i onlinerapporten', {
            w: 8,
            x: 1,
            h: 0.81,
            y: 6,
            fontSize: 14,
            bold: false,
            align: 'center',
            valign: 'top'
        })
        .addShape('rect', sammanvagningCardOptions())
        .addImage({
            path:
                senasteInventering.slutsatsRASEGynInt > 0
                    ? 'assets/abin-icons-png/Rase_gynnsam_status_uppfylld-2022.svg'
                    : 'assets/abin-icons-png/Rase_gynnsam_status_ej_uppfylld-2022.svg',
            sizing: { type: 'cover', w: 1.13, h: 1.18 },
            x: 10.69,
            y: 2.39
        })
        .addText(formatPercent(senasteInventering.raseAndelGynnsam3InvMedel, 'sv-se', '1.0-1'), {
            h: 0.91,
            w: 2.83,
            x: 9.77,
            y: 3.47,
            fontSize: 48,
            bold: true,
            align: 'center'
        })
        .addText('Andel ytor med gynnsam konkurrensstatus för RASE, sammanvägning för de tre senaste inventeringarna', {
            w: 2.83,
            x: 9.77,
            h: 0.81,
            y: 4.38,
            fontSize: 14,
            bold: false,
            align: 'center',
            valign: 'top'
        });
};

<header class="site-header">
  <a [href]="skogsstyrelsenUrl"
    ><img
      src="assets\Skogsstyrelsen_logo_1rad.png"
      alt="Skogsstyrelsen Skobi logga"
  /></a>
  <div class="site-header__vertical-divider"></div>
  <span class="site-header__title">SKOGLIG BETESINVENTERING</span>
  <div class="site-header-spacer"></div>
  <span class="version-info">Version: {{ appVersion }}</span>
</header>

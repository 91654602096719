import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rapport-toc',
  templateUrl: './rapport-toc.component.html',
  styleUrls: ['./rapport-toc.component.scss']
})
export class RapportTocComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<div #chartContainer>
    <ui-figure-caption>
        <ui-figure-text>
            <h4>{{ diagramBeskrivning$ | async }}</h4>
        </ui-figure-text>
    </ui-figure-caption>
    <br />
    <app-google-combochart [data]="data" [options]="options"></app-google-combochart>
    <ui-error-margin *ngIf="showFelmarginalText" />
</div>

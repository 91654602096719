import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ABINROUTES } from 'src/app/common/route/abin-routes';
import { AbinMetadataDto } from 'src/app/inge-api/client/models';
import * as abinActions from 'src/app/store/actions/abin.actions';
import { InGeApiState } from 'src/app/store/state-model/inge-api.model';

@Component({
    selector: 'app-resultat-rapport-select',
    templateUrl: './resultat-rapport-select.component.html',
    styleUrls: ['./resultat-rapport-select.component.scss']
})
export class ResultatRapportSelectComponent implements OnInit, OnDestroy {
    selectForm: UntypedFormGroup;
    subs: Subscription[] = [];

    delomraden$: Observable<AbinMetadataDto[]>;
    loadingDelomraden$: Observable<boolean>;
    afo$: Observable<AbinMetadataDto[]>;
    loadingAfo$: Observable<boolean>;
    lan$: Observable<AbinMetadataDto[]>;
    loadingLan$: Observable<boolean>;
    landsdelar$: Observable<AbinMetadataDto[]>;
    loadingLandsdelar$: Observable<boolean>;
    requestError$: Observable<boolean>;
    constructor(private fb: UntypedFormBuilder, private store: Store<InGeApiState>) {
        this.delomraden$ = this.store.pipe(select((x) => x.abin.delomraden));
        this.loadingDelomraden$ = this.store.pipe(select((x) => x.abin.loadingDelomraden));
        this.afo$ = this.store.pipe(
            select((x) => x.abin.afo),
            map((abinMetadataDtoer) => cloneDeep(abinMetadataDtoer)?.sort((a, b) => parseInt(a.kod) - parseInt(b.kod)))
        );
        this.loadingAfo$ = this.store.pipe(select((x) => x.abin.loadingAfo));
        this.lan$ = this.store.pipe(select((x) => x.abin.lan));
        this.loadingLan$ = this.store.pipe(select((x) => x.abin.loadingLan));
        this.landsdelar$ = this.store.pipe(select((x) => x.abin.landsdelar));
        this.loadingLandsdelar$ = this.store.pipe(select((x) => x.abin.loadingLandsdelar));
    }

    ngOnInit(): void {
        this.selectForm = this.fb.group({
            landsdel: new UntypedFormControl({
                value: { namn: 'Alla', kod: 'alla' },
                disabled: false
            }),
            lan: new UntypedFormControl({
                value: { namn: 'Alla', kod: 'alla' },
                disabled: true
            }),
            afo: new UntypedFormControl({
                value: { namn: 'Alla', kod: 'alla' },
                disabled: true
            }),
            delomrade: new UntypedFormControl({
                value: { namn: 'Alla', kod: 'alla' },
                disabled: true
            })
        });
        this.store.dispatch(abinActions.getAbinLandsdelar());

        this.subs.push(
            this.selectForm.controls.landsdel.valueChanges.subscribe((x) => {
                if (x.kod !== 'alla') {
                    this.store.dispatch(abinActions.getAbinLan({ landsdelKod: x.kod }));
                    this.selectForm.controls.lan.enable();
                    this.selectForm.controls.lan.setValue({ namn: 'Alla', kod: 'alla' }, { emitEvent: false });
                    this.selectForm.controls.afo.setValue({ namn: 'Alla', kod: 'alla' }, { emitEvent: false });
                    this.selectForm.controls.afo.disable();
                    this.selectForm.controls.delomrade.setValue({ namn: 'Alla', kod: 'alla' }, { emitEvent: false });
                    this.selectForm.controls.delomrade.disable();
                    return;
                }

                this.selectForm.controls.lan.setValue({ namn: 'Alla', kod: 'alla' }, { emitEvent: false });
                this.selectForm.controls.lan.disable();
                this.selectForm.controls.afo.setValue({ namn: 'Alla', kod: 'alla' }, { emitEvent: false });
                this.selectForm.controls.afo.disable();
                this.selectForm.controls.delomrade.setValue({ namn: 'Alla', kod: 'alla' }, { emitEvent: false });
                this.selectForm.controls.delomrade.disable();
            })
        );
        this.subs.push(
            this.selectForm.controls.lan.valueChanges.subscribe((x) => {
                if (x.kod !== 'alla') {
                    const landsdelKod = this.selectForm.controls.landsdel.value?.kod;
                    this.store.dispatch(abinActions.getAbinAfo({ landsdelKod, lanKod: x.kod }));
                    // Specialfall för Gotland som bara har ett Äfo
                    if (x.kod == '09') {
                        this.selectForm.controls.afo.setValue({ namn: 'ÄFO 1 Gotland', kod: '1' }, { emitEvent: false });
                    } else {
                        this.selectForm.controls.afo.setValue({ namn: 'Alla', kod: 'alla' }, { emitEvent: false });
                    }
                    this.selectForm.controls.afo.enable();
                    this.selectForm.controls.delomrade.setValue({ namn: 'Alla', kod: 'alla' }, { emitEvent: false });
                    this.selectForm.controls.delomrade.disable();
                    return;
                }

                this.selectForm.controls.afo.setValue({ namn: 'Alla', kod: 'alla' }, { emitEvent: false });
                this.selectForm.controls.afo.disable();
                this.selectForm.controls.delomrade.setValue({ namn: 'Alla', kod: 'alla' }, { emitEvent: false });
                this.selectForm.controls.delomrade.disable();
            })
        );
        this.subs.push(
            this.selectForm.controls.afo.valueChanges.subscribe((x) => {
                if (x.kod !== 'alla') {
                    const landsdelkod = this.selectForm.controls.landsdel.value?.kod;
                    const lankod = this.selectForm.controls.lan.value?.kod;
                    this.store.dispatch(abinActions.getAbinDelomraden({ landsdelkod, lankod, afonr: x.kod }));
                    this.selectForm.controls.delomrade.setValue({ namn: 'Alla', kod: 'alla' }, { emitEvent: false });
                    this.selectForm.controls.delomrade.enable();
                    return;
                }
                this.selectForm.controls.delomrade.setValue({ namn: 'Alla', kod: 'alla' }, { emitEvent: false });
                this.selectForm.controls.delomrade.disable();
            })
        );
    }

    ngOnDestroy(): void {
        if (this.subs) {
            this.subs.forEach((x) => x.unsubscribe());
        }
    }

    laddaRapport(evt: any): void {
        evt.preventDefault();
        const { landsdel, lan, afo, delomrade } = this.selectForm.value;

        // this.router.navigate(['/abin-rapport'], {
        //   queryParams: {
        //     landsdel: landsdel?.kod || 'alla',
        //     lan: lan?.kod || 'alla',
        //     afo: afo?.kod || 'alla',
        //     delomrade: delomrade?.kod || 'alla',
        //   },
        // });

        const queryParams = {
            landsdel: landsdel?.kod || 'alla',
            lan: lan?.kod || 'alla',
            afo: afo?.kod || 'alla',
            delomrade: delomrade?.kod || 'alla'
        };
        const baseUrl = window.location.href;
        const newUrl = baseUrl.replace(ABINROUTES.SELECT_DATA_PAGE, ABINROUTES.RAPPORT_PAGE);

        // Specialhantering för Gotland - välj alltid Äfo 1
        if (queryParams.lan == '09') {
            queryParams.afo = '1';
        }

        const params = `landsdel=${queryParams.landsdel}&lan=${queryParams.lan}&afo=${queryParams.afo}&delomrade=${queryParams.delomrade}`;
        const url = `${newUrl}?${params}`;
        window.open(url, '_blank');
    }

    compareFn(c1: any, c2: any): boolean {
        return c1 && c2 ? c1.kod === c2.kod : c1 === c2;
    }
}

import {
  Component,
  OnInit,
  HostBinding,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { ToggleBtnService } from '../toggle-btn.service';
@Component({
  selector: 'app-ui-toggle-button',
  templateUrl: './ui-toggle-button.component.html',
  styleUrls: ['./ui-toggle-button.component.scss'],
})
export class UiToggleButtonComponent implements OnInit {
  active = false;
  @Input() value: any;
  @Input()
  set isActive(val: boolean) {
    this.active = val;
    if (val) {
      this.setActive();
    }
  }
  _id: string;
  constructor(private service: ToggleBtnService) {
    this._id = this.generateId();
  }
  ngOnInit(): void {}

  setActive(): void {
    this.service.setActive({ id: this._id, value: this.value });
  }

  generateId() {
    var S4 = function () {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (
      S4() +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      S4() +
      S4()
    );
  }
}

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, map } from 'rxjs';
import { AbinResultDto } from '../../inge-api/client/models';
import { ExportReportModalService } from '../../shared-components/export-report-modal/export-report-modal.service';
import { InventeringsNiva, InGeApiState, AbinState } from '../../store/state-model/inge-api.model';

@Component({
    selector: 'app-rapport-kombo-main',
    templateUrl: './rapport-kombo-main.component.html',
    styleUrl: './rapport-kombo-main.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RapportKomboMainComponent implements OnInit {
    loadingInventeringar$: Observable<boolean>;
    inventeringarError$: Observable<boolean>;
    inventeringsNamn$: Observable<string>;
    senasteInventering$: Observable<AbinResultDto>;
    inventeringsNiva$: Observable<InventeringsNiva>;

    lan9Chapter5WarningConfig$: Observable<{
        message: string;
        header: string;
        render: boolean;
    }>;
    lan9Chapter1WarningConfig$: Observable<{
        message: string;
        header: string;
        render: boolean;
    }>;

    landsdel2Lan21Afo3Chapter32WarningConfig$: Observable<{
        message: string;
        header: string;
        render: boolean;
    }>;

    lan1Chapter1WarningConfig$: Observable<{
        message: string;
        header: string;
        render: boolean;
    }>;

    lan22Chapter53WarningConfig$: Observable<{
        message: string;
        header: string;
        render: boolean;
    }>;

    // lan13Chapter31WarningConfig$: Observable<{
    //     message: string;
    //     header: string;
    //     render: boolean;
    // }>;

    constructor(private store: Store<InGeApiState>, private exportReportModalService: ExportReportModalService) {
        this.loadingInventeringar$ = this.store.pipe(select((x) => x.abin.loadingInventeringar));
        this.inventeringarError$ = this.store.pipe(select((x) => x.abin.inventeringarError));
        this.inventeringsNamn$ = this.store.pipe(
            select((x) => {
                if (x && x.abin?.inventeringar && x.abin.inventeringar.length > 0) {
                    const senaste = x.abin.inventeringar[0];
                    const niva = x.abin.inventeringsNiva;
                    switch (niva) {
                        case InventeringsNiva.LAND:
                            return 'Område: Hela landet';
                        case InventeringsNiva.LANDSDEL:
                            return `Område: ${senaste.landsdelNamn}`;
                        case InventeringsNiva.LAN:
                            return `Område: ${senaste.lanNamn}`;
                        case InventeringsNiva.AFO:
                            return `Område: ${senaste.afoNamn}, ${senaste.lanNamn} `;
                        case InventeringsNiva.STRATUM:
                            return `Område: Delområde ${senaste.delomrNamn} i ${senaste.afoNamn}, ${senaste.lanNamn}`;

                        default:
                            return '';
                    }
                }
            })
        );

        this.senasteInventering$ = this.store.pipe(
            select((x) => {
                if (x && x.abin && x.abin.inventeringar && x.abin.inventeringar.length > 0) {
                    return x.abin.inventeringar[0];
                }
            })
        );

        this.inventeringsNiva$ = this.store.pipe(select((x) => x.abin.inventeringsNiva));
    }
    ngOnInit(): void {
        this.setupWarningConditions();
    }

    setupWarningConditions() {
        this.landsdel2Lan21Afo3Chapter32WarningConfig$ = this.store.pipe(
            select((x) => {
                const warningConfig = {
                    message:
                        'Problem under insamlingen medför att data för andel oskadade tallar för året 2022 inte kunde sammanställas. Resultatet för 2022 har uteslutits från figuren nedan samt i beräkningen av sammanvägning för de tre senaste inventeringarna.',
                    header: 'Viktig information',
                    render: false
                };
                if (x?.abin?.inventeringsNiva) {
                    if ([InventeringsNiva.AFO].includes(x.abin.inventeringsNiva) || [InventeringsNiva.AFO].includes(x.abin.inventeringsNiva)) {
                        if (x.abin.inventeringar && x.abin.inventeringar.length > 0) {
                            const senaste = x.abin.inventeringar[0];
                            const lanKod = senaste.lanKod;
                            const afoKod = senaste.afoNr;
                            if (['21'].includes(lanKod) && [3].includes(afoKod)) {
                                return { ...warningConfig, render: true };
                            }
                        }
                    }
                }
                return { ...warningConfig, message: '' };
            })
        );

        this.lan1Chapter1WarningConfig$ = this.store.pipe(
            select((x) => {
                const warningConfig = {
                    message:
                        'Vanligtvis inventeras varje ÄFO i landet minst vartannat år och resultat på länsnivån beräknas utifrån två års resultat, till exempel beräknas länsresultat för 2022 från ÄFO-underlag från 2021 och 2022. På grund av olika faktorer har inte ”vartannat års inventering” genomförts för alla ÄFO i Stockholms län och resultat för länet som helhet bör därför tolkas med försiktighet.',
                    header: 'Viktig information',
                    render: false
                };
                if (x?.abin?.inventeringsNiva) {
                    if ([InventeringsNiva.LAN].includes(x.abin.inventeringsNiva)) {
                        if (x.abin.inventeringar && x.abin.inventeringar.length > 0) {
                            const senaste = x.abin.inventeringar[0];
                            const lanKod = senaste.lanKod;
                            if (['01'].includes(lanKod)) {
                                return { ...warningConfig, render: true };
                            }
                        }
                    }
                }
                return { ...warningConfig, message: '' };
            })
        );

        this.lan9Chapter1WarningConfig$ = this.store.pipe(
            select((x) => {
                const warningConfig = {
                    message: '',
                    render: false,
                    header: 'Viktig information'
                };
                if (x?.abin?.inventeringsNiva) {
                    if ([InventeringsNiva.LAN].includes(x.abin.inventeringsNiva) || [InventeringsNiva.AFO].includes(x.abin.inventeringsNiva)) {
                        if (x.abin.inventeringar && x.abin.inventeringar.length > 0) {
                            const senaste = x.abin.inventeringar[0];
                            const lanKod = senaste.lanKod;
                            if (['09'].includes(lanKod)) {
                                return { ...warningConfig, render: true };
                            }
                        }
                    }
                }
                return { ...warningConfig, message: '' };
            })
        );

        this.lan9Chapter5WarningConfig$ = this.store.pipe(
            select((x) => x?.abin),
            map((abin: AbinState) => {
                const warningConfig = {
                    message:
                        'Metoden för klassning av marktyper som har utvecklas för fastlandet har visat sig svårtillämpad på Gotland och siffrorna vad gäller ståndortsanpassning bör behandlas med stor försiktighet.',
                    render: false,
                    header: 'Viktig information'
                };
                if (abin?.inventeringsNiva) {
                    if ([InventeringsNiva.LAN].includes(abin.inventeringsNiva) || [InventeringsNiva.AFO].includes(abin.inventeringsNiva)) {
                        if (abin.inventeringar && abin.inventeringar.length > 0) {
                            const senaste = abin.inventeringar[0];
                            const lanKod = senaste.lanKod;
                            if (['09'].includes(lanKod)) {
                                return { ...warningConfig, render: true };
                            }
                        }
                    }
                }
                return { ...warningConfig, message: '' };
            })
        );

        this.lan22Chapter53WarningConfig$ = this.store.pipe(
            select((x) => {
                const warningConfig = {
                    message:
                        'Problem under insamlingen medför att data för andel av mellanmarkerna föryngrade med tall för året 2022 inte kunde sammanställas. Resultatet för 2022 har uteslutits från figuren nedan samt i sammanvägningen för de tre senaste inventeringarna.',
                    render: false,
                    header: 'Viktig information'
                };
                if (x?.abin?.inventeringsNiva) {
                    if ([InventeringsNiva.AFO].includes(x.abin.inventeringsNiva)) {
                        if (x.abin.inventeringar && x.abin.inventeringar.length > 0) {
                            const senaste = x.abin.inventeringar[0];
                            const lanKod = senaste.lanKod;
                            const afoKod = senaste.afoNr;
                            if (['22'].includes(lanKod) && [2].includes(afoKod)) {
                                return { ...warningConfig, render: true };
                            }
                        }
                    }
                }
                return { ...warningConfig, message: '' };
            })
        );

        // this.lan13Chapter31WarningConfig$ = this.store.pipe(
        //     select((x) => {
        //         const warningConfig = {
        //             message:
        //                 'I ÄFO 3 Östra, Hallands län utgör tall endast 3% av de stammar som bedömts i årets inventering av ungskogar i höjdintervallet 1-4 meter. En fördjupade analys av ÄFO 3 visar att skadorna i stor utsträckning är skador på enstaka tallar i granbestånd. Den stora årliga variationen är en effekt av det låga antalet tallar. Resultatet gällande årsskador på tall bör därför tolkas med mycket stor försiktighet.',
        //             render: false,
        //             header: 'Viktig information'
        //         };
        //         if (x?.abin?.inventeringsNiva) {
        //             if ([InventeringsNiva.AFO].includes(x.abin.inventeringsNiva)) {
        //                 if (x.abin.inventeringar && x.abin.inventeringar.length > 0) {
        //                     const senaste = x.abin.inventeringar[0];
        //                     const lanKod = senaste.lanKod;
        //                     const afoKod = senaste.afoNr;
        //                     if (['13'].includes(lanKod) && [3].includes(afoKod)) {
        //                         return { ...warningConfig, render: true };
        //                     }
        //                 }
        //             }
        //         }
        //         return { ...warningConfig, message: '' };
        //     })
        // );
    }

    exportReport() {
        this.exportReportModalService.setVisible(true);
    }
}

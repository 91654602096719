import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiButtonModule } from '../ui-button/ui-button.module';
import { UiModalComponent } from './ui-modal/ui-modal.component';

@NgModule({
  declarations: [UiModalComponent],
  imports: [CommonModule, UiButtonModule],
  exports: [UiModalComponent],
})
export class UiModalModule {}

<div class="row">
  <div class="col-xs-12">
    <ui-figure-caption>
      <ui-figure-text>
        <h4>Ungskogens trädslagssammansättning, stammar/ha</h4>
      </ui-figure-text>
    </ui-figure-caption>
    <app-ui-table border-top right-aligned [tableConfig]="tableConfig" [tableContent]="tableContent"
      [caption]="tableCaption">
    </app-ui-table>
  </div>
</div>

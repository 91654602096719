import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiComponentsModule } from '../ui-components/ui-components.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RapportMainComponent } from './rapport-main/rapport-main.component';
import { AppRoutingModule } from '../app-routing.module';
import { RapportTocComponent } from './rapport-toc/rapport-toc.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OmradeTableComponent } from './rapport-components/omrade-table/omrade-table.component';
import { AgoslagsfordelningChartComponent } from './rapport-components/agoslagsfordelning-chart/agoslagsfordelning-chart.component';
import { ArsskadorTallSammanvagningComponent } from './rapport-components/arsskador-tall-sammanvagning/arsskador-tall-sammanvagning.component';
import { ArsskadorTallChartComponent } from './rapport-components/arsskador-tall-chart/arsskador-tall-chart.component';
import { OskadadTallSammanvagningComponent } from './rapport-components/oskadad-tall-sammanvagning/oskadad-tall-sammanvagning.component';
import { OskadadTallChartComponent } from './rapport-components/oskadad-tall-chart/oskadad-tall-chart.component';
import { ArsskadorGranSammanvagningComponent } from './rapport-components/arsskador-gran-sammanvagning/arsskador-gran-sammanvagning.component';
import { ArsskadorGranChartComponent } from './rapport-components/arsskador-gran-chart/arsskador-gran-chart.component';
import { WarningIconComponent } from './rapport-components/warning-icon/warning-icon.component';
import { RaseForekomstMedelvardeComponent } from './rapport-components/rase-forekomst-medelvarde/rase-forekomst-medelvarde.component';
import { RaseForekomstChartComponent } from './rapport-components/rase-forekomst-chart/rase-forekomst-chart.component';
import { RaseKonkurrensMedelvardeComponent } from './rapport-components/rase-konkurrens-medelvarde/rase-konkurrens-medelvarde.component';
import { RaseKonkurrensChartComponent } from './rapport-components/rase-konkurrens-chart/rase-konkurrens-chart.component';
import { ForyngringMagermarkSammanvagningComponent } from './rapport-components/foryngring-magermark-sammanvagning/foryngring-magermark-sammanvagning.component';
import { ForyngringMagermarkAndelTallChartComponent } from './rapport-components/foryngring-magermark-andel-tall-chart/foryngring-magermark-andel-tall-chart.component';
import { ForyngringMagermarkTraslagChartComponent } from './rapport-components/foryngring-magermark-traslag-chart/foryngring-magermark-traslag-chart.component';
import { ForyngringMellanmarkSammanvagningComponent } from './rapport-components/foryngring-mellanmark-sammanvagning/foryngring-mellanmark-sammanvagning.component';
import { ForyngringMellanmarkAndelTallChartComponent } from './rapport-components/foryngring-mellanmark-andel-tall-chart/foryngring-mellanmark-andel-tall-chart.component';
import { ForyngringMellanmarkTraslagChartComponent } from './rapport-components/foryngring-mellanmark-traslag-chart/foryngring-mellanmark-traslag-chart.component';
import { ForyngringBordigMarkSammanvagningComponent } from './rapport-components/foryngring-bordig-mark-sammanvagning/foryngring-bordig-mark-sammanvagning.component';
import { ForyngringBordigMarkAndelChartComponent } from './rapport-components/foryngring-bordig-mark-andel-chart/foryngring-bordig-mark-andel-chart.component';
import { ForyngringBordigMarkTraslagChartComponent } from './rapport-components/foryngring-bordig-mark-traslag-chart/foryngring-bordig-mark-traslag-chart.component';
import { FoderAgoslagsfordelningChartComponent } from './rapport-components/foder-agoslagsfordelning-chart/foder-agoslagsfordelning-chart.component';
import { FoderFoderprognosChartComponent } from './rapport-components/foder-foderprognos-chart/foder-foderprognos-chart.component';
import { FoderTradslagssammansattningTableComponent } from './rapport-components/foder-tradslagssammansattning-table/foder-tradslagssammansattning-table.component';
import { FoderTradslagssammansattningChartComponent } from './rapport-components/foder-tradslagssammansattning-chart/foder-tradslagssammansattning-chart.component';
import { FoderRojningTableComponent } from './rapport-components/foder-rojning-table/foder-rojning-table.component';
import { FoderRojningRojtEjrojtChartComponent } from './rapport-components/foder-rojning-rojt-ejrojt-chart/foder-rojning-rojt-ejrojt-chart.component';
import { SlutsatserListaComponent } from './rapport-components/slutsatser-lista/slutsatser-lista.component';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { FoderProduktivSkogsmarkChartComponent } from './rapport-components/foder-produktiv-skogsmark-chart/foder-produktiv-skogsmark-chart.component';
import { FoderUnskogenssammansattningChartComponent } from './rapport-components/foder-unskogenssammansattning-chart/foder-unskogenssammansattning-chart.component';
import { KartaInventeringsrutorComponent } from './rapport-components/karta-inventeringsrutor/karta-inventeringsrutor.component';
import { SommarVinterSkadorTableComponent } from './rapport-components/sommar-vinter-skador-table/sommar-vinter-skador-table.component';
import { AndelMarkslagChartComponent } from './rapport-components/andel-markslag-chart/andel-markslag-chart.component';
import { ForyngradeTraslagChartComponent } from './rapport-components/foryngrade-traslag-chart/foryngrade-traslag-chart.component';
import { ArsskadorKartaComponent } from './rapport-components/arsskador-karta/arsskador-karta.component';
import { FoderTradslagssammansatningInfoRutaComponent } from './rapport-components/foder-tradslagssammansatning-info-ruta/foder-tradslagssammansatning-info-ruta.component';
import { RapportFooterComponent } from './rapport-components/rapport-footer/rapport-footer.component';

import { GoogleChartsBaseModule } from '../google-charts/google-charts-base-components/google-charts-base.module';
import { AndelBarchartBaseComponent } from './base-components/andel-barchart-base/andel-barchart-base.component';
import { FordelningPiechartBaseComponent } from './base-components/fordelning-piechart-base/fordelning-piechart-base.component';
import { ResultatKartaBaseComponent } from './base-components/resultat-karta-base/resultat-karta-base.component';
import { AndelCombochartBaseComponent } from './base-components';
import { AbinUppfoljRapportModule } from '../abin-uppfolj-rapport/abin-uppfolj-rapport.module';
import { SharedReportComponentsModule } from '../shared-report-components/shared-report-components.module';
@NgModule({
    declarations: [
        RapportMainComponent,
        RapportTocComponent,
        OmradeTableComponent,
        AgoslagsfordelningChartComponent,
        ArsskadorTallSammanvagningComponent,
        ArsskadorTallChartComponent,
        OskadadTallSammanvagningComponent,
        OskadadTallChartComponent,
        ArsskadorGranSammanvagningComponent,
        ArsskadorGranChartComponent,
        WarningIconComponent,
        RaseForekomstMedelvardeComponent,
        RaseForekomstChartComponent,
        RaseKonkurrensMedelvardeComponent,
        RaseKonkurrensChartComponent,
        ForyngringMagermarkSammanvagningComponent,
        ForyngringMagermarkAndelTallChartComponent,
        ForyngringMagermarkTraslagChartComponent,
        ForyngringMellanmarkSammanvagningComponent,
        ForyngringMellanmarkAndelTallChartComponent,
        ForyngringMellanmarkTraslagChartComponent,
        ForyngringBordigMarkSammanvagningComponent,
        ForyngringBordigMarkAndelChartComponent,
        ForyngringBordigMarkTraslagChartComponent,
        FoderAgoslagsfordelningChartComponent,
        FoderFoderprognosChartComponent,
        FoderTradslagssammansattningTableComponent,
        FoderTradslagssammansattningChartComponent,
        FoderRojningTableComponent,
        FoderRojningRojtEjrojtChartComponent,
        SlutsatserListaComponent,
        FoderProduktivSkogsmarkChartComponent,
        FoderUnskogenssammansattningChartComponent,
        KartaInventeringsrutorComponent,
        SommarVinterSkadorTableComponent,
        AndelMarkslagChartComponent,
        ForyngradeTraslagChartComponent,
        ArsskadorKartaComponent,
        FoderTradslagssammansatningInfoRutaComponent,
        RapportFooterComponent,
        AndelBarchartBaseComponent,
        AndelCombochartBaseComponent,
        FordelningPiechartBaseComponent,
        ResultatKartaBaseComponent
    ],
    imports: [
        CommonModule,
        UiComponentsModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        SharedComponentsModule,
        GoogleChartsBaseModule,
        AbinUppfoljRapportModule,
        SharedReportComponentsModule
    ],
    exports: [
        RapportMainComponent,
        RapportTocComponent,
        OmradeTableComponent,
        AgoslagsfordelningChartComponent,
        ArsskadorTallSammanvagningComponent,
        ArsskadorTallChartComponent,
        OskadadTallSammanvagningComponent,
        OskadadTallChartComponent,
        ArsskadorGranSammanvagningComponent,
        ArsskadorGranChartComponent,
        WarningIconComponent,
        RaseForekomstMedelvardeComponent,
        RaseForekomstChartComponent,
        RaseKonkurrensMedelvardeComponent,
        RaseKonkurrensChartComponent,
        ForyngringMagermarkSammanvagningComponent,
        ForyngringMagermarkAndelTallChartComponent,
        ForyngringMagermarkTraslagChartComponent,
        ForyngringMellanmarkSammanvagningComponent,
        ForyngringMellanmarkAndelTallChartComponent,
        ForyngringMellanmarkTraslagChartComponent,
        ForyngringBordigMarkSammanvagningComponent,
        ForyngringBordigMarkAndelChartComponent,
        ForyngringBordigMarkTraslagChartComponent,
        FoderAgoslagsfordelningChartComponent,
        FoderFoderprognosChartComponent,
        FoderTradslagssammansattningTableComponent,
        FoderTradslagssammansattningChartComponent,
        FoderRojningTableComponent,
        FoderRojningRojtEjrojtChartComponent,
        SlutsatserListaComponent,
        FoderProduktivSkogsmarkChartComponent,
        FoderUnskogenssammansattningChartComponent,
        KartaInventeringsrutorComponent,
        SommarVinterSkadorTableComponent,
        AndelMarkslagChartComponent,
        ForyngradeTraslagChartComponent,
        ArsskadorKartaComponent,
        FoderTradslagssammansatningInfoRutaComponent,
        RapportFooterComponent,
        AndelBarchartBaseComponent,
        AndelCombochartBaseComponent,
        FordelningPiechartBaseComponent,
        ResultatKartaBaseComponent
    ]
})
export class AbinResultatRapportModule {}

import { formatPercent } from '@angular/common';
import pptxgen from 'pptxgenjs';
import { AbinResultDto } from 'src/app/inge-api/client/models';
import { _andelBarchartOptions } from '../slide-components/_andelBarchart';
import addBanner from '../slide-components/_banner';
import { sammanvagningCardOptions } from '../slide-components/_sammanvagningCard';
import { getYAxisMaxValueFromList } from '../../../google-charts/google-chart-utils';
import { getComboChartOptions, getComboTypes } from '../pptx-chart-utils';

export default (slide: pptxgen.Slide, inventeringar: AbinResultDto[]) => {
    addBanner(slide, {
        header: 'Årsskador tall',
        text: 'Målet är maximalt fem procent årliga skador på tallungskog'
    });

    const senasteInventering = inventeringar[0];
    const comboTypes = getComboTypes(inventeringar, 'arsskadaTallAndel', 'arsskadaTallAndel3InvMedel');
    const maxValue = getYAxisMaxValueFromList([...comboTypes[0].data[0].values, ...comboTypes[1].data[0].values]);
    const comboOptions = getComboChartOptions('Andel tallstammar i ungskog som årligen skadas av vilt', maxValue);

    slide
        .addChart(comboTypes, comboOptions as any)
        .addText('Felmarginaler finns enbart i onlinerapporten', {
            w: 8,
            x: 1,
            h: 0.81,
            y: 6,
            fontSize: 14,
            bold: false,
            align: 'center',
            valign: 'top'
        })
        .addShape('rect', sammanvagningCardOptions())
        .addImage({
            path:
                senasteInventering.slutsatsArsskadaTallInt > 0
                    ? 'assets/abin-icons-png/Arsskada_tall_uppfyllt-2022.svg'
                    : 'assets/abin-icons-png/Arsskada_tall_ej_uppfyllt-2022.svg',
            sizing: { type: 'contain', w: 1.1, h: 1.18 },
            x: 10.74,
            y: 2.39
        })
        .addText(formatPercent(senasteInventering.arsskadaTallAndel3InvMedel, 'sv-se', '1.0'), {
            h: 0.91,
            w: 2.83,
            x: 9.77,
            y: 3.47,
            fontSize: 48,
            bold: true,
            align: 'center'
        })
        .addText('Andel årskador på tall, sammanvägning för de tre senaste inventeringarna', {
            w: 2.83,
            x: 9.77,
            h: 0.81,
            y: 4.38,
            fontSize: 14,
            bold: false,
            align: 'center',
            valign: 'top'
        });
};

import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { round } from 'lodash-es';
import { Subscription } from 'rxjs';

import { AndelCombochartBaseComponent } from '../../base-components';

@Component({
    selector: 'app-foryngring-magermark-andel-tall-chart',
    templateUrl: './foryngring-magermark-andel-tall-chart.component.html',
    styleUrls: ['./foryngring-magermark-andel-tall-chart.component.scss']
})
export class ForyngringMagermarkAndelTallChartComponent extends AndelCombochartBaseComponent implements OnInit, OnDestroy {
    @ViewChild('chartContainer') chartContainer: ElementRef;

    public data: any[][];
    public andelMagraMarker: number = 0;

    private subs: Subscription[];

    ngOnInit(): void {
        this.subs = [];
        this.subs.push(
            this.inventeringar$.subscribe((x) => {
                if (x && x.length > 0) {
                    const data: any[][] = [
                        [
                            'År',
                            { id: 'Arsskada_1ar', type: 'number', label: 'Årsvärde' },
                            { id: 'tool_tip_1ar', type: 'string', role: 'tooltip', p: { html: true } },
                            { id: 'Medel_3ar', type: 'number', label: 'Sammanvägning för de tre senaste inventeringarna' },
                            { id: 'tool_tip_3ar', type: 'string', role: 'tooltip', p: { html: true } }
                        ]
                    ];

                    x.forEach((inv) => {
                        if (inv.standortsanpassningAndelMagraMarkerMedTall || inv.standortsanpassningAndelMagraMarkerMedTall === 0) {
                            // Uppdatera värdet för mager mark om det finns för inventeringen
                            if (inv.andelMagraMarker3InvMedel && inv.andelMagraMarker3InvMedel > 0) {
                                this.andelMagraMarker = round(inv.andelMagraMarker3InvMedel * 100, 1);
                            }

                            // Årsskada
                            const arsVarde = round(inv.standortsanpassningAndelMagraMarkerMedTall * 100, 1);

                            // 3års medel
                            const trearsMedel = round(inv.standortsanpassningAndelMagraMarkerMedTall3InvMedel * 100, 1);

                            data.push([
                                `${inv.invAr}`,
                                arsVarde > 0 ? arsVarde : null,
                                this.getTooltipNoFelmarginal(false, arsVarde),
                                trearsMedel,
                                this.getTooltipNoFelmarginal(true, trearsMedel)
                            ]);
                        }
                    });

                    this.options = {
                        ...this.options,
                        tooltip: { isHtml: true },
                        height: 250,
                        vAxis: {
                            ...this.options.vAxis,
                            maxValue: this.getMaxValue(data)
                        }
                    };

                    this.data = data;
                }
            })
        );
    }

    ngOnDestroy(): void {
        this.subs.forEach((x) => x.unsubscribe());
    }
}

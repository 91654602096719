import {
  Component,
  OnInit,
  ViewEncapsulation,
  HostBinding,
} from '@angular/core';

@Component({
  selector: 'app-ui-info-box',
  templateUrl: './ui-info-box.component.html',
  styleUrls: ['./ui-info-box.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiInfoBoxComponent implements OnInit {
  @HostBinding('class')
  elementClass = 'ui-info-box';

  constructor() {}

  ngOnInit(): void {}
}

<div class="row">
    <div class="col-xs-12">
        <div class="subheadertext">Välj område med knapparna för att visa utvecklingen över tid och vilka år som inventerats.</div>
    </div>

    <div class="col-xs-12">
        <app-fordjupning-button-group (selectedOmradeEvent)="onOmradeSelect($event)"></app-fordjupning-button-group>
        <p><b>Valt område</b>: {{ omradeNamn$ | async }}</p>
    </div>
</div>

<div class="row">
    <div class="col-md-8 col-xs-12 col-lg-9">
        <app-arsskada-combochart
            [inventeringar]="inventeringar$ | async"
            [vardeVariabler]="vardeVariabler"
            [diagramBeskrivning]="'Historisk utveckling av andel ytor i ungskog med förekomst av RASE'"
        ></app-arsskada-combochart>
    </div>

    <div class="col-md-4 col-xs-12 col-lg-3">
        <ng-container *ngIf="senasteInventering$ | async as senasteInventering">
            <app-summary-card>
                <app-summary-card-icon>
                    <span class="abinicon-tree-chart"></span>
                </app-summary-card-icon>
                <app-summary-card-header>
                    {{ senasteInventering?.raseAndelForekomst3InvMedel | percent : '1.0' : 'sv' }}
                </app-summary-card-header>
                <app-summary-card-content> Andel ytor med förekomst av RASE, sammanvägning för de tre senaste inventeringarna </app-summary-card-content>
            </app-summary-card>
        </ng-container>
    </div>
</div>

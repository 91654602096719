import { SublayerConfiguration } from './../fordjupning-karta-base/fordjupning-karta.model';
import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-fordjupning-legends',
  templateUrl: './fordjupning-legends.component.html',
  styleUrls: ['./fordjupning-legends.component.scss']
})
export class FordjupningLegendsComponent implements OnInit {

  private _layerConfig = new BehaviorSubject<SublayerConfiguration>(null);
  layerConfig$ = this._layerConfig.asObservable();

  constructor() { }

  ngOnInit(): void {
    this.layerConfig$.subscribe((layerconfig) => {});

  }

  @Input()
  set layerConfig(val: SublayerConfiguration) {
    this._layerConfig.next(val);
  }

}

import { toNumber } from 'lodash-es';
import pptxgen from 'pptxgenjs';
import { AbinResultDto } from 'src/app/inge-api/client/models';
import * as uiColors from 'src/app/ui-components/colors';
import addBanner from '../slide-components/_banner';
export default (slide: pptxgen.Slide, inventeringar: AbinResultDto[]) => {
  addBanner(slide, {
    header: 'Utveckling av foderproducerande ungskog',
    text: 'Utveckling av foderproducerande ungskogsareal över tid',
  });
  const senasteInventering = inventeringar[0];
  const fodeprognosData = getFoderprognosChartData(senasteInventering);
  slide
    .addText(
      'Att följa utvecklingen av foderproducerande ungskog som utgörs av skog som är mellan 1 och 6 meter i medelhöjd är viktigt. På det sättet kan viltförvaltningen möta förändringar i fodertillgång genom ett adaptivt förhållningssätt.',
      {
        x: 1,
        y: 1.58,
        h: 0.91,
        w: 11.43,
        fontSize: 16,
      }
    )
    .addChart('line', fodeprognosData, {
      x: 1,
      y: 2.78,
      w: 11.4,
      h: 3.22,
      chartColors: [uiColors.spruce],
      showValAxisTitle: true,
      valAxisTitle: 'Hektar',
      valAxisTitleFontSize: 16,
      valGridLine: { color: 'e0e0e0' },
      valAxisMinVal: 0,
      title: 'Utveckling av foderproducerande ungskogsareal över tid',
      showTitle: true,
    });
  return slide;
};

const getFoderprognosChartData = (senasteInventering: AbinResultDto) => {
  const data = { name: '', labels: [], values: [] };
  senasteInventering.foderprognos.forEach((prognosDto) => {
    data.values.push(toNumber(prognosDto.foderprognos));
    data.labels.push(prognosDto.ar);
  });
  return [data];
};

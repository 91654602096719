import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AbinResultDto } from '../../../../inge-api/client/models';
import { ButtonGroupItemData } from '../../../../shared-report-components/base-components/fordjupning-button-group/button-group-item.model';
import { ChartCategory, ColorMapService } from '../../../../common/service/color-mapper.service';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-kombo-markslagsfordelning',
    templateUrl: './kombo-markslagsfordelning.component.html',
    styleUrl: './kombo-markslagsfordelning.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class KomboMarkslagsfordelningComponent implements OnInit {
    // Markslag chart
    dataMarkslag: any[][];
    legendDataMarkslag: any[];
    optionsMarkslag: any = {
        chartArea: { width: '90%', height: '90%' },
        height: 230,
        pieSliceText: 'none',
        tooltip: {
            trigger: 'none'
        },
        legend: {
            position: 'none'
        }
    };

    // Föryngrade trädslag chart
    dataForyngrTradslag: any[][];
    legendDataForyngrTradslag: any[];
    optionsForyngrTradslag: any = {
        chartArea: { width: '90%', height: '90%' },
        height: 230,
        pieSliceText: 'none',
        tooltip: {
            trigger: 'none'
        },
        legend: {
            position: 'none'
        }
    };

    private _omradeNamn = new BehaviorSubject<string>(null);
    omradeNamn$ = this._omradeNamn.asObservable();

    public vardeVariabler: string[] = ['arsskadaTallAndel', 'medelfelArskadaTall', 'arsskadaTallAndel3InvMedel', 'medelfelArskadaTall3InvMedel'];

    constructor(public colormapService: ColorMapService) {}

    ngOnInit(): void {}

    onOmradeSelect(omrade: ButtonGroupItemData) {
        if (omrade && omrade.inventeringar && omrade.inventeringar.length > 0) {
            this._omradeNamn.next(omrade.omradeName);
            this.generateChartDataMarkslag(omrade.inventeringar[0]);
            this.generateChartDataForyngrTradslag(omrade.inventeringar[0]);
        }
    }

    generateChartDataMarkslag(senasteInventering: AbinResultDto) {
        const data: any[][] = [['Markslag', 'Procent']];
        const colors = [];
        const legendData = [];
        if (senasteInventering.andelMagraMarker3InvMedel) {
            const name = 'Magra marker';
            const value = Math.round(senasteInventering.andelMagraMarker3InvMedel * 100);
            const color = this.colormapService.getColor(ChartCategory.MAGRAMARKER);
            data.push([name, value]);
            colors.push(color);
            legendData.push({ name, value, color });
        }
        if (senasteInventering.andelMellanMarker3InvMedel) {
            const name = 'Mellanmarker';
            const value = Math.round(senasteInventering.andelMellanMarker3InvMedel * 100);
            const color = this.colormapService.getColor(ChartCategory.MELLANMARKER);
            data.push([name, value]);
            colors.push(color);
            legendData.push({ name, value, color });
        }
        if (senasteInventering.andelBordigaMarker3InvMedel) {
            const name = 'Bördiga marker';
            const value = Math.round(senasteInventering.andelBordigaMarker3InvMedel * 100);
            const color = this.colormapService.getColor(ChartCategory.BORDIGAMARKER);
            data.push([name, value]);
            colors.push(color);
            legendData.push({ name, value, color });
        }

        this.dataMarkslag = data;
        this.optionsMarkslag = { ...this.optionsMarkslag, colors };
        this.legendDataMarkslag = legendData;
    }

    generateChartDataForyngrTradslag(senasteInventering: AbinResultDto) {
        const total = this.getTotal(senasteInventering);
        const data: any[][] = [['Träslag', 'Procent']];
        const colors = [];
        const legendData = [];
        if (senasteInventering.foryngTallHa3InvMedel) {
            const name = 'Tall';
            const value = Math.round((senasteInventering.foryngTallHa3InvMedel / total) * 100);
            const color = this.colormapService.getColor(ChartCategory.TALL);
            data.push([name, value]);
            colors.push(color);
            legendData.push({ name, value, color });
        }

        if (senasteInventering.foryngGranHa3InvMedel) {
            const name = 'Gran';
            const value = Math.round((senasteInventering.foryngGranHa3InvMedel / total) * 100);
            const color = this.colormapService.getColor(ChartCategory.GRAN);
            data.push([name, value]);
            colors.push(color);
            legendData.push({ name, value, color });
        }

        if (senasteInventering.foryngBjorkHa3InvMedel) {
            const name = 'Björk';
            const value = Math.round((senasteInventering.foryngBjorkHa3InvMedel / total) * 100);
            const color = this.colormapService.getColor(ChartCategory.BJORK);
            data.push([name, value]);
            colors.push(color);
            legendData.push({ name, value, color });
        }
        if (senasteInventering.foryngContortaHa3InvMedel) {
            const name = 'Contorta';
            const value = Math.round((senasteInventering.foryngContortaHa3InvMedel / total) * 100);
            const color = this.colormapService.getColor(ChartCategory.CONTORTA);
            data.push([name, value]);
            colors.push(color);
            legendData.push({ name, value, color });
        }
        if (senasteInventering.foryngLarkHa3InvMedel) {
            const name = 'Lärk';
            const value = Math.round((senasteInventering.foryngLarkHa3InvMedel / total) * 100);
            const color = this.colormapService.getColor(ChartCategory.LARK);
            data.push([name, value]);
            colors.push(color);
            legendData.push({ name, value, color });
        }
        if (senasteInventering.foryngOvrigtHa3InvMedel) {
            const name = 'Övrigt';
            const value = Math.round((senasteInventering.foryngOvrigtHa3InvMedel / total) * 100);
            const color = this.colormapService.getColor(ChartCategory.OVRIG);
            data.push([name, value]);
            colors.push(color);
            legendData.push({ name, value, color });
        }
        if (senasteInventering.foryngIngenHa3InvMedel) {
            const name = 'Okänt';
            const value = Math.round((senasteInventering.foryngIngenHa3InvMedel / total) * 100);
            const color = this.colormapService.getColor(ChartCategory.INGENATGARD);
            data.push([name, value]);
            colors.push(color);
            legendData.push({ name, value, color });
        }
        this.dataForyngrTradslag = data;
        this.optionsForyngrTradslag = { ...this.optionsForyngrTradslag, colors };
        this.legendDataForyngrTradslag = legendData;
    }

    getTotal(inv: AbinResultDto): number {
        return (
            (inv.foryngTallHa3InvMedel || 0) +
            (inv.foryngBjorkHa3InvMedel || 0) +
            (inv.foryngContortaHa3InvMedel || 0) +
            (inv.foryngGranHa3InvMedel || 0) +
            (inv.foryngIngenHa3InvMedel || 0) +
            (inv.foryngLarkHa3InvMedel || 0) +
            (inv.foryngOvrigtHa3InvMedel || 0)
        );
    }
}

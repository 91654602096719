import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RapportSelectComponent } from './abin-rapport-select/rapport-select/rapport-select.component';
import { AbinRapportDataResolver } from './common/resolver/abin-rapport.resolver';
import { EmptyParamGuard } from './common/guard/empty-param.guard';
import { ABINROUTES } from './common/route/abin-routes';
import { AbinUppfoljningsRapportResolver } from './common/resolver/abin-uppfoljningsrapport.resolver';
import { RapportContainerComponent } from './abin-rapport-select/container/rapport-container/rapport-container.component';

const routes: Routes = [
    {
        path: ABINROUTES.RAPPORT_PAGE,
        component: RapportContainerComponent,
        resolve: { state: AbinRapportDataResolver, state2: AbinUppfoljningsRapportResolver },
        canActivate: [EmptyParamGuard]
    },
    { path: ABINROUTES.SELECT_DATA_PAGE, component: RapportSelectComponent },
    { path: '', redirectTo: ABINROUTES.SELECT_DATA_PAGE, pathMatch: 'full' },
    { path: '**', redirectTo: ABINROUTES.SELECT_DATA_PAGE }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            anchorScrolling: 'enabled',
            scrollPositionRestoration: 'enabled',
            onSameUrlNavigation: 'reload',
            enableTracing: false,
            useHash: true
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}

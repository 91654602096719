<div class="row sammanfattning-lista">
    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
        <app-ui-info-box ui-info-box-light ui-info-box-centered ui-info-box-no-border ui-info-box-shadow>
            <ui-info-box-header>
                <ng-container *ngIf="(inventering$ | async)?.slutsatsArsskadaTallInt > 0; else elseTemplate1">
                    <span class="abinicon-arsskada_tall_uppfyllt"></span>
                </ng-container>
                <ng-template #elseTemplate1>
                    <span class="abinicon-arsskada_tall_ej_uppfyllt"></span>
                </ng-template>
            </ui-info-box-header>
            <ui-info-box-content>
                <p class="content-header">
                    Årsskadad tall,
                    {{ (inventering$ | async)?.arsskadaTallAndel3InvMedel | percent : '1.0' : 'sv' }}
                </p>
                <p>{{ (inventering$ | async)?.slutsatsArsskadaTallText }}</p>
            </ui-info-box-content>
        </app-ui-info-box>
    </div>
    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
        <app-ui-info-box ui-info-box-light ui-info-box-centered ui-info-box-no-border ui-info-box-shadow>
            <ui-info-box-header>
                <ng-container *ngIf="(inventering$ | async)?.slutsatsOskadadTallInt > 0; else elseTemplate2">
                    <span class="abinicon-oskadad_tall_uppfyllt"></span>
                </ng-container>
                <ng-template #elseTemplate2>
                    <span class="abinicon-oskadad_tall_ej_uppfyllt"></span>
                </ng-template>
            </ui-info-box-header>
            <ui-info-box-content>
                <p class="content-header">
                    Oskadad tall,
                    {{ (inventering$ | async)?.oskadadTallAndel3InvMedel | percent : '1.0' : 'sv' }}
                </p>
                <p>{{ (inventering$ | async)?.slutsatsOskadadTallText }}</p>
            </ui-info-box-content>
        </app-ui-info-box>
    </div>
    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
        <app-ui-info-box ui-info-box-light ui-info-box-centered ui-info-box-no-border ui-info-box-shadow>
            <ui-info-box-header>
                <ng-container *ngIf="(inventering$ | async)?.slutsatsArsskadaGranInt > 0; else elseTemplate3">
                    <span class="abinicon-arsskada_gran_uppfyllt">
                        <span class="path1"></span>
                        <span class="path2"></span>
                    </span>
                </ng-container>
                <ng-template #elseTemplate3>
                    <span class="abinicon-arsskada_gran_ej_uppfyllt">
                        <span class="path1"></span>
                        <span class="path2"></span>
                    </span>
                </ng-template>
            </ui-info-box-header>
            <ui-info-box-content>
                <p class="content-header">
                    Årsskadad gran,
                    {{ (inventering$ | async)?.arsskadaGranAndel3InvMedel | percent : '1.1-1' : 'sv' }}
                </p>
                <p>{{ (inventering$ | async)?.slutsatsArsskadaGranText }}</p>
            </ui-info-box-content>
        </app-ui-info-box>
    </div>
    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
        <app-ui-info-box ui-info-box-light ui-info-box-centered ui-info-box-no-border ui-info-box-shadow>
            <ui-info-box-header>
                <ng-container *ngIf="(inventering$ | async)?.slutsatsTallpaMagraMarkerInt > 0; else elseTemplate4">
                    <span class="abinicon-tall_mager_mark_uppfyllt"></span>
                </ng-container>
                <ng-template #elseTemplate4>
                    <span class="abinicon-tall_mager_mark_ej_uppfyllt"></span>
                </ng-template>
            </ui-info-box-header>
            <ui-info-box-content>
                <p class="content-header">
                    Tall på magra marker,
                    {{ (inventering$ | async)?.standortsanpassningAndelMagraMarkerMedTall3InvMedel | percent : '1.0' : 'sv' }}
                </p>
                <p>{{ (inventering$ | async)?.slutsatsTallpaMagraMarkerText }}</p>
            </ui-info-box-content>
        </app-ui-info-box>
    </div>
    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
        <app-ui-info-box ui-info-box-light ui-info-box-centered ui-info-box-no-border ui-info-box-shadow>
            <ui-info-box-header>
                <ng-container *ngIf="(inventering$ | async)?.slutsatsTallpaMellanMarkerInt > 0; else elseTemplate5">
                    <span class="abinicon-tall_mellanmark_uppfyllt"></span>
                </ng-container>
                <ng-template #elseTemplate5>
                    <span class="abinicon-tall_mellanmark_ej_uppfyllt"></span>
                </ng-template>
            </ui-info-box-header>
            <ui-info-box-content>
                <p class="content-header">
                    Tall på mellanmarker,
                    {{ (inventering$ | async)?.standortsanpassningAndelMellanMarkerMedTall3InvMedel | percent : '1.0' : 'sv' }}
                </p>
                <p>{{ (inventering$ | async)?.slutsatsTallpaMellanMarkerText }}</p>
            </ui-info-box-content>
        </app-ui-info-box>
    </div>
    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
        <app-ui-info-box ui-info-box-light ui-info-box-centered ui-info-box-no-border ui-info-box-shadow>
            <ui-info-box-header>
                <ng-container *ngIf="(inventering$ | async)?.slutsatsRASEGynInt > 0; else elseTemplate6">
                    <span class="abinicon-rase_gynnsam_status_uppfylld"></span>
                </ng-container>
                <ng-template #elseTemplate6>
                    <span class="abinicon-rase_gynnsam_status_ej_uppfylld"></span>
                </ng-template>
            </ui-info-box-header>
            <ui-info-box-content>
                <p class="content-header">
                    Konkurrensstatus RASE,
                    {{ (inventering$ | async)?.raseAndelGynnsam3InvMedel | percent : '1.0-1' : 'sv' }}
                </p>
                <p>
                    {{ (inventering$ | async)?.slutsatsRASEGynText }}
                </p>
            </ui-info-box-content>
        </app-ui-info-box>
    </div>
</div>

<!--
<div class="text-box-container">
  <div class="icon-box">
    <ng-container *ngIf="arsskadaTallOk; else elseTemplate">
      <span class="material-icons">
        done
      </span>
    </ng-container>
    <ng-template #elseTemplate>
      <span class="material-icons">
        priority_high
      </span>
    </ng-template>

  </div>
  <div class="text-box">
    {{ (inventering$ | async)?.slutsatsArsskadaTallText}}
  </div>
</div>
<div class="text-box-container">
  <div class="icon-box">
    <ng-container *ngIf="oskadadTallOk; else elseTemplate">
      <span class="material-icons">
        done
      </span>
    </ng-container>
    <ng-template #elseTemplate>
      <span class="material-icons">
        priority_high
      </span>
    </ng-template>
  </div>
  <div class="text-box">
    {{ (inventering$ | async)?.slutsatsOskadadTallText}}
  </div>
</div>
<div class="text-box-container" *ngIf="(inventering$ | async)?.slutsatsArsskadaGranText">
  <div class="icon-box">
    <ng-container *ngIf="arsskadorGranOk; else elseTemplate">
      <span class="material-icons">
        done
      </span>
    </ng-container>
    <ng-template #elseTemplate>
      <span class="material-icons">
        priority_high
      </span>
    </ng-template>
  </div>
  <div class="text-box">
    {{ (inventering$ | async)?.slutsatsArsskadaGranText}}
  </div>
</div>
<div class="text-box-container">
  <div class="icon-box">
    <ng-container *ngIf="magerMarkOk; else elseTemplate">
      <span class="material-icons">
        done
      </span>
    </ng-container>
    <ng-template #elseTemplate>
      <span class="material-icons">
        priority_high
      </span>
    </ng-template>
  </div>
  <div class="text-box">
    {{ (inventering$ | async)?.slutsatsTallpaMagraMarkerText}}
  </div>
</div>
<div class="text-box-container">
  <div class="icon-box">
    <ng-container *ngIf="mellanMarkOk; else elseTemplate">
      <span class="material-icons">
        done
      </span>
    </ng-container>
    <ng-template #elseTemplate>
      <span class="material-icons">
        priority_high
      </span>
    </ng-template>
  </div>
  <div class="text-box">
    {{ (inventering$ | async)?.slutsatsTallpaMellanMarkerText}}
  </div>
</div>
<div class="text-box-container">
  <div class="icon-box">
    <ng-container *ngIf="konkurrensStatusRaseOk; else elseTemplate">
      <span class="material-icons">
        done
      </span>
    </ng-container>
    <ng-template #elseTemplate>
      <span class="material-icons">
        priority_high
      </span>
    </ng-template>
  </div>
  <div class="text-box">
    {{ (inventering$ | async)?.slutsatsRASEGynText}}
  </div>
</div> -->

<!-- <ui-list class="sammanfattning-lista">
  <ui-list-item *ngIf="(inventering$ | async)?.slutsatsArsskadaTall">
    <p class="slutsats-text">{{ (inventering$ | async).slutsatsArsskadaTallText}}</p>
  </ui-list-item>
  <ui-list-item *ngIf="(inventering$ | async)?.slutsatsOskadadTall">
    <p class="slutsats-text">{{ (inventering$ | async).slutsatsOskadadTallText}}</p>
  </ui-list-item>
  <ui-list-item *ngIf="(inventering$ | async)?.slutsatsTallpaMagraMarker">
    <p class="slutsats-text">{{ (inventering$ | async).slutsatsTallpaMagraMarkerText}}</p>
  </ui-list-item>
  <ui-list-item *ngIf="(inventering$ | async)?.slutsatsTallpaMellanMarker">
    <p class="slutsats-text">{{ (inventering$ | async).slutsatsTallpaMellanMarkerText}}</p>
  </ui-list-item>
</ui-list> -->

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AbinResultDto } from '../../../../inge-api/client/models';
import { ButtonGroupItemData } from '../../../../shared-report-components/base-components/fordjupning-button-group/button-group-item.model';
import { ChartCategory, ColorMapService } from '../../../../common/service/color-mapper.service';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-kombo-agoslagsfordelning',
    templateUrl: './kombo-agoslagsfordelning.component.html',
    styleUrl: './kombo-agoslagsfordelning.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class KomboAgoslagsfordelningComponent implements OnInit {
    options: any = {
        chartArea: { width: '90%', height: '90%' },
        height: 230,
        pieSliceText: 'none',
        tooltip: {
            trigger: 'none'
        },
        legend: {
            position: 'none'
        }
    };

    private chartEntries = [
        // {
        //     name: 'Ungskog',
        //     key: 'tallskogHaABIN3InvMedel',
        //     color: ChartCategory.MELLANMARKER
        // },
        {
            name: 'Tätort',
            key: 'tatortsmark',
            color: ChartCategory.TATORTSMARK
        },
        {
            name: 'Myrmark',
            key: 'myrmark',
            color: ChartCategory.MYRMARK
        },
        {
            name: 'Övrig öppen mark',
            key: 'ovrigOppenMark',
            color: ChartCategory.OVRIG
        }
    ];

    private _omradeNamn = new BehaviorSubject<string>(null);
    omradeNamn$ = this._omradeNamn.asObservable();

    inventeringsAr: number;
    data: any[][];
    legendData: any[];

    constructor(public colormapService: ColorMapService) {}

    ngOnInit(): void {}

    onOmradeSelect(omrade: ButtonGroupItemData) {
        if (omrade && omrade.inventeringar && omrade.inventeringar.length > 0) {
            this._omradeNamn.next(omrade.omradeName);
            this.generateChartData(omrade.inventeringar[0]);
        }
    }

    generateChartData(senasteInventering: AbinResultDto) {
        const data: any[][] = [['Träslag', 'Procent']];
        const colors = [];
        const legendData = [];
        this.inventeringsAr = senasteInventering.invAr;
        const totAreal = senasteInventering.arealLandHa;

        if (senasteInventering.arealSkogsmarkHa) {
            const name = 'Ungskog';
            const skogstypHa =
                (senasteInventering.tallskogHaABIN3InvMedel ?? 0) +
                (senasteInventering.granskogHaABIN3InvMedel ?? 0) +
                (senasteInventering.bblandskogHaABIN3InvMedel ?? 0) +
                (senasteInventering.ovrigskogHaABIN3InvMedel ?? 0);
            const value = Math.round((skogstypHa / totAreal) * 100);
            const color = this.colormapService.getColor(ChartCategory.MELLANMARKER);
            data.push([name, value]);
            colors.push(color);
            legendData.push({ name, value, color });
        }

        if (totAreal && totAreal > 0) {
            for (const entry of this.chartEntries) {
                if (senasteInventering[entry.key]) {
                    const name = entry.name;
                    const value = Math.round((senasteInventering[entry.key] / totAreal) * 100);
                    const color = this.colormapService.getColor(entry.color);
                    data.push([name, value]);
                    colors.push(color);
                    legendData.push({ name, value, color });
                }
            }
        }

        if (senasteInventering.arealSkogsmarkHa && senasteInventering.foderprognosHa) {
            const name = 'Äldre skog & hyggen';
            const value = Math.round(
                ((senasteInventering.arealSkogsmarkHa -
                    ((senasteInventering.tallskogHaABIN3InvMedel ?? 0) +
                        (senasteInventering.granskogHaABIN3InvMedel ?? 0) +
                        (senasteInventering.bblandskogHaABIN3InvMedel ?? 0) +
                        (senasteInventering.ovrigskogHaABIN3InvMedel ?? 0))) /
                    totAreal) *
                    100
            );
            const color = this.colormapService.getColor(ChartCategory.SKOGSMARK);
            data.push([name, value]);
            colors.push(color);
            legendData.push({ name, value, color });
        }

        this.data = data;
        this.options = { ...this.options, colors };
        this.legendData = legendData;
    }
}

import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[uiToastSuccess]',
})
export class UiToastSuccessDirective {
  constructor() {}

  @HostBinding('class') elClass = 'success';
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';

interface VersionInfo {
  GitCommit: string;
  GitBranch: string;
  TfsReleaseName: string;
  ReleaseUrl: string;
  TfsReleaseUrl: string;
  ReleaseName: string;
  BuildNumber: string;
}
@Injectable({
  providedIn: 'root',
})
export class VersionService {
  constructor(private httpClient: HttpClient) {}

  getVersionInfo(): Observable<VersionInfo> {
    return this.httpClient.get<VersionInfo>('/AbinRapport/VersionInfo.json');
  }
}

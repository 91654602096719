<div class="row">
    <div class="col-xs-12">
        <div>Välj område med knapparna.</div>
    </div>

    <div class="col-xs-12">
        <app-fordjupning-button-group (selectedOmradeEvent)="onOmradeSelect($event)"></app-fordjupning-button-group>
    </div>
    <div class="col-xs-12">
        <span><b>Valt område</b>: {{ omradeNamn$ | async }}</span>
    </div>
</div>

<div class="row">
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <ng-container *ngIf="tableContent?.length > 0; else elseTemplate">
            <div class="row">
                <div class="col-xs-12">
                    <ui-figure-caption>
                        <ui-figure-text>
                            <h4>Ungskogens trädslagssammansättning, stammar/ha</h4>
                        </ui-figure-text>
                    </ui-figure-caption>
                    <app-ui-table border-top right-aligned [tableConfig]="tableConfig" [tableContent]="tableContent" [caption]="tableCaption"> </app-ui-table>
                </div>
            </div>
        </ng-container>
        <ng-template #elseTemplate>
            <div class="col-xs-6">Kunde inte ladda trädslagens sammansättning för detta område</div>
        </ng-template>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div class="row middle-xs">
            <ng-container *ngIf="data?.length > 0; else elseTradslagSammansattningTemplate">
                <div class="col-xs-12">
                    <ui-figure-caption>
                        <ui-figure-text>
                            <h4>Andel trädslag i ungskog, medelvärde för de tre senaste inventeringarna</h4>
                        </ui-figure-text>
                    </ui-figure-caption>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <app-google-pie-chart [data]="data" [options]="options"></app-google-pie-chart>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <ui-legend ui-legend-vertical>
                        <ui-legend-item *ngFor="let item of legendData; let i = index" [color]="item.color">
                            {{ item.name }}: {{ item.value | number }}%
                        </ui-legend-item>
                    </ui-legend>
                </div>
            </ng-container>
            <ng-template #elseTradslagSammansattningTemplate>
                <div class="col-xs-6">Kunde inte ladda trädslagens sammansättning för detta område</div>
            </ng-template>
        </div>
    </div>
</div>

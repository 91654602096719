import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  InGeApiState,
  InventeringsNiva,
} from 'src/app/store/state-model/inge-api.model';

@Component({
  selector: 'app-rapport-warning',
  templateUrl: './rapport-warning.component.html',
  styleUrls: ['./rapport-warning.component.scss'],
})
export class RapportWarningComponent implements OnInit {
  _config: {
    message: string;
    render: boolean;
    header: string;
  };

  @Input()
  set warningConfig(val: { message: string; render: boolean; header: string }) {
    this._config = val;
  }

  constructor() {}

  ngOnInit(): void {}
}

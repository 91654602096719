import { createAction, props } from '@ngrx/store';
// import { Landsdel, Afo, Lan } from '../state-model/inge-api.model';
import {
  AbinGeometriDto,
  AbinMetadataDto,
  AbinResultDto,
} from 'src/app/inge-api/client/models';

export const getAbinSummeringLand = createAction(
  '[ABIN API] getAbinSummeringLand'
);
export const getAbinSummeringLandSuccess = createAction(
  '[ABIN API] getAbinSummeringLandSuccess',
  props<{ result: Array<AbinResultDto> }>()
);
export const getAbinSummeringLandError = createAction(
  '[ABIN API] getAbinSummeringLandError'
);

export const getAbinSummeringLandsdel = createAction(
  '[ABIN API] getAbinSummeringLandsdel',
  props<{ landsdelKod: number }>()
);
export const getAbinSummeringLandsdelSuccess = createAction(
  '[ABIN API] getAbinSummeringLandsdelSuccess',
  props<{ result: AbinResultDto[] }>()
);
export const getAbinSummeringLandsdelError = createAction(
  '[ABIN API] getAbinSummeringLandsdelError'
);

// Hämta summeringar inom samma län. Samma äfo kan förekomma flera gånger, men med olika inventeringsår
export const getAbinSummeringLan = createAction(
  '[ABIN API] getAbinSummeringLan',
  props<{ lankod: string }>()
);
export const getAbinSummeringLanSuccess = createAction(
  '[ABIN API] getAbinSummeringLanSuccess',
  props<{ result: Array<AbinResultDto> }>()
);
export const getAbinSummeringLanError = createAction(
  '[ABIN API] getAbinSummeringLanError'
);

// Hämta summeringar för specifikt äfo. Flera summeringar för samma äfo kan förekomma flera gånger, men med olika inventeringsår
export const getAbinSummeringLanAfo = createAction(
  '[ABIN API] getAbinSummeringLanAfo',
  props<{ lankod: string; afonr: number }>()
);
export const getAbinSummeringLanAfoSuccess = createAction(
  '[ABIN API] getAbinSummeringLanAfoSuccess',
  props<{ result: Array<AbinResultDto> }>()
);
export const getAbinSummeringLanAfoError = createAction(
  '[ABIN API] getAbinSummeringLanAfoError'
);

// Hämta summeringar för specifikt äfo, län och inventeringsår.
export const getAbinSummeringDelomrade = createAction(
  '[ABIN API] getAbinSummeringDelomrade',
  props<{ lanKod: string; afoNr: number; delomradesnummer: number }>()
);
export const getAbinSummeringDelomradeSuccess = createAction(
  '[ABIN API] getAbinSummeringDelomradeSuccess',
  props<{ result: AbinResultDto[] }>()
);
export const getAbinSummeringDelomradeError = createAction(
  '[ABIN API] getAbinSummeringDelomradeError'
);

export const setRapportUnderlag = createAction(
  '[ABIN RAPPORT] setRapportUnderlag',
  props<{ inventering: AbinResultDto }>()
);

export const getAbinLandsdelar = createAction('[ABIN API] getAbinLandsdelar');
export const getAbinLandsdelarSuccess = createAction(
  '[ABIN API] getAbinLandsdelarSuccess',
  props<{ result: Array<AbinMetadataDto> }>()
);
export const getAbinLandsdelarError = createAction(
  '[ABIN API] getAbinLandsdelarError'
);

export const getAbinLan = createAction(
  '[ABIN API] getAbinLan',
  props<{ landsdelKod: number; exclude?: string[] }>()
);
export const getAbinLanSuccess = createAction(
  '[ABIN API] getAbinLanSuccess',
  props<{ result: Array<AbinMetadataDto> }>()
);
export const getAbinLanError = createAction('[ABIN API] getAbinLanError');

export const getAbinAfo = createAction(
  '[ABIN API] getAbinAfo',
  props<{ landsdelKod: number; lanKod: string }>()
);
export const getAbinAfoSuccess = createAction(
  '[ABIN API] getAbinAfoSuccess',
  props<{ result: Array<AbinMetadataDto> }>()
);
export const getAbinAfoError = createAction('[ABIN API] getAbinAfoError');

export const getAbinDelomraden = createAction(
  '[ABIN API] getAbinDelomraden',
  props<{ landsdelkod: number; lankod: string; afonr: number }>()
);
export const getAbinDelomradenSuccess = createAction(
  '[ABIN API] getAbinDelomradenSuccess',
  props<{ result: Array<AbinMetadataDto> }>()
);
export const getAbinDelomradenError = createAction(
  '[ABIN API] getAbinDelomradenError'
);

export const getAbinHelaLandetGeometri = createAction(
  '[ABIN API] getAbinHelaLandetGeometri'
);
export const getAbinHelaLandetGeometriSuccess = createAction(
  '[ABIN API] getAbinHelaLandetGeometriSuccess',
  props<{ result: AbinGeometriDto }>()
);
export const getAbinHelaLandetGeometriError = createAction(
  '[ABIN API] getAbinHelaLandetGeometriError'
);

export const getAbinLandsdelGeometri = createAction(
  '[ABIN API] getAbinLandsdelGeometri',
  props<{ landsdelkod: number }>()
);
export const getAbinLandsdelGeometriSuccess = createAction(
  '[ABIN API] getAbinLandsdelGeometriSuccess',
  props<{ result: AbinGeometriDto }>()
);
export const getAbinLandsdelGeometriError = createAction(
  '[ABIN API] getAbinLandsdelGeometriError'
);

export const getAbinLanGeometri = createAction(
  '[ABIN API] getAbinLanGeometri',
  props<{ lankod: string }>()
);
export const getAbinLanGeometriSuccess = createAction(
  '[ABIN API] getAbinLanGeometriSuccess',
  props<{ result: AbinGeometriDto }>()
);
export const getAbinLanGeometriError = createAction(
  '[ABIN API] getAbinLanGeometriError'
);

export const getAbinAfoGeometri = createAction(
  '[ABIN API] getAbinAfoGeometri',
  props<{ lankod: string; afonr: number }>()
);
export const getAbinAfoGeometriSuccess = createAction(
  '[ABIN API] getAbinAfoGeometriSuccess',
  props<{ result: AbinGeometriDto }>()
);
export const getAbinAfoGeometriError = createAction(
  '[ABIN API] getAbinAfoGeometriError'
);

export const getAbinDelomradeGeometri = createAction(
  '[ABIN API] getAbinDelomradeGeometri',
  props<{ lankod: string; afonr: number; delomradesnummer: number }>()
);
export const getAbinDelomradeGeometriSuccess = createAction(
  '[ABIN API] getAbinDelomradeGeometriSuccess',
  props<{ result: AbinGeometriDto }>()
);
export const getAbinDelomradeGeometriError = createAction(
  '[ABIN API] getAbinDelomradeGeometriError'
);

// export const getAbinArskadaGeometrier = createAction(
//   '[ABIN API] getAbinArskadaGeometrier'
// );
// export const getAbinArskadaGeometrierSuccess = createAction(
//   '[ABIN API] getAbinArskadaGeometrierSuccess',
//   props<{ result: AbinInventeringsRutorHeatmapDto[] }>()
// );
// export const getAbinArskadaGeometrierError = createAction(
//   '[ABIN API] getAbinArskadaGeometrierError'
// );

<app-fordjupning-karta-base
  [layerConfig]="layerConfig$ | async"
  (mapClicked)="onMapClicked($event)"
>
  <app-fordjupning-karta-popup
    [isLoading]="isLoadingPopupData"
    [popupContent]="popupData$ | async"
  >
    <ng-container
      *ngIf="(popupData$ | async)?.thumbsUp; else elseTemplate"
      popupIcon
    >
      <span class="abinicon-oskadad_tall_uppfyllt"></span>
    </ng-container>
    <ng-template #elseTemplate popupIcon>
      <span class="abinicon-oskadad_tall_ej_uppfyllt"></span>
    </ng-template>
  </app-fordjupning-karta-popup>
</app-fordjupning-karta-base>
<br />
<ui-figure-caption>
  <ui-figure-text ui-figure-text-bold>
    Skalan i kartan visa graden av måluppfyllelse. Gränserna är 85 % för
    Tolerabel, 70-85 % för Allvarlig, 50-70 % för Svår och mindre än 50 % för
    Mycket svår.
  </ui-figure-text>
</ui-figure-caption>

<!-- <app-ui-info-box ui-info-box-light ui-info-box-centered>
  <ui-info-box-header>Andel av mellanmarkerna som föryngras med tall, sammanvägning för de tre senaste inventeringarna.
  </ui-info-box-header>
  <ui-info-box-content>
    <h3>{{(inventeringar$|async)?.standortsanpassningAndelMellanMarkerMedTall3InvMedel
      |percent:'1.0':'sv'}}</h3>
  </ui-info-box-content>
</app-ui-info-box> -->

<app-summary-card>
    <app-summary-card-icon>
        <ng-container *ngIf="(inventering$ | async)?.slutsatsTallpaMellanMarkerInt > 0; else elseTemplate5">
            <span class="abinicon-tall_mellanmark_uppfyllt"></span>
        </ng-container>
        <ng-template #elseTemplate5>
            <span class="abinicon-tall_mellanmark_ej_uppfyllt"></span>
        </ng-template>
    </app-summary-card-icon>
    <app-summary-card-header>
        {{ (inventering$ | async)?.standortsanpassningAndelMellanMarkerMedTall3InvMedel | percent : '1.0' : 'sv' }}
    </app-summary-card-header>
    <app-summary-card-content> Andel av mellanmarkerna som föryngras med tall, sammanvägning för de tre senaste inventeringarna. </app-summary-card-content>
</app-summary-card>

import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import * as geometryEngine from '@arcgis/core/geometry/geometryEngine';
import * as geometryJsonUtils from '@arcgis/core/geometry/support/jsonUtils';
import Graphic from '@arcgis/core/Graphic';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { GeometryTransformService } from 'src/app/common/service';
import { AbinGeometriDto } from 'src/app/inge-api/client/models';
import {
  InGeApiState,
  InventeringsNiva,
} from 'src/app/store/state-model/inge-api.model';
import * as colors from '../../../ui-components/colors';
import { ResultatKartaBaseComponent } from '../../base-components/resultat-karta-base/resultat-karta-base.component';
import { KartaInventeringsrutorService } from './karta-inventeringsrutor.service';
@Component({
  selector: 'app-karta-inventeringsrutor',
  templateUrl: './karta-inventeringsrutor.component.html',
  styleUrls: ['./karta-inventeringsrutor.component.scss'],
})
export class KartaInventeringsrutorComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('mapComponent', { static: true })
  private mapComponent: ResultatKartaBaseComponent;
  inventeringsGeometrier$: Observable<AbinGeometriDto>;
  inventeringsNiva$: Observable<InventeringsNiva>;
  private subs: Subscription[] = [];
  constructor(
    private store: Store<InGeApiState>,
    private parserService: GeometryTransformService,
    private inventeringsrutorService: KartaInventeringsrutorService
  ) {
    this.inventeringsGeometrier$ = this.store.pipe(
      select((x) => x.abin.inventeringsGeometrier)
    );
    this.inventeringsNiva$ = this.store.pipe(
      select((x) => x.abin.inventeringsNiva)
    );
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.mapComponent.view.when().then((view) => {
      this.inventeringsrutorService.setView(view);
      this.subs.push(
        combineLatest([
          this.inventeringsGeometrier$,
          this.inventeringsNiva$,
        ]).subscribe(([geometrier, inventeringsNiva]) => {
          if (geometrier) {
            if (geometrier.senasteInventeringsrutor) {
              const inventeringsrutorSenaste =
                geometrier.senasteInventeringsrutor.map((x) => x.wkt);
              const color = [...colors.brickRGBA, 0.5];
              this.addInventeringsYtor(inventeringsrutorSenaste, color);
            }
            if (geometrier.foregaendeInventeringsrutor) {
              const inventeringsrutorForegaende =
                geometrier.foregaendeInventeringsrutor.map((x) => x.wkt);
              const color = [...colors.greyishBrownRGBA, 0.5];
              this.addInventeringsYtor(inventeringsrutorForegaende, color);
            }
            if (geometrier.stratumytor) {
              let stratumYtor: string[];
              if (
                inventeringsNiva === InventeringsNiva.LANDSDEL ||
                inventeringsNiva === InventeringsNiva.LAND
              ) {
                // Filtrera bort Gotland på landsdelnivå
                stratumYtor = geometrier.stratumytor
                  .filter((x) => x.stratumId !== '09-001-0')
                  .map((x) => x.wkt);
              } else {
                stratumYtor = geometrier.stratumytor.map((x) => x.wkt);
              }

              this.addStratumYtor(stratumYtor);
            }
          }
        })
      );
    });
  }

  addStratumYtor(wktList: string[]): void {
    const view = this.mapComponent.view;
    const esriJsonList = this.parserService.wktListToEsri(wktList, 3006);
    const symbol = {
      type: 'simple-line', // autocasts as new SimpleLineSymbol()
      color: 'black',
      width: '2px',
      style: 'solid',
    };
    const ytor = esriJsonList.map(
      (geom) =>
        new Graphic({ geometry: geometryJsonUtils.fromJSON(geom), symbol })
    );
    view.graphics.addMany(ytor);
    view.goTo(
      geometryEngine.union(ytor.map((x) => x.geometry)).extent.expand(1.2)
    );
  }

  addInventeringsYtor(wktList: string[], color: number[]): void {
    const esriJsonList = this.parserService.wktListToEsri(wktList, 3006);
    const symbol = {
      type: 'simple-fill',
      color,
      style: 'solid',
      outline: {
        color,
        width: 1,
      },
    };
    const ytor = esriJsonList.map(
      (geom) =>
        new Graphic({ geometry: geometryJsonUtils.fromJSON(geom), symbol })
    );
    this.mapComponent.view.graphics.addMany(ytor);
  }

  ngOnDestroy(): void {
    this.subs.forEach((x) => x.unsubscribe());
  }
}

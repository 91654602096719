import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AbinResultDto } from '../../../../inge-api/client/models';
import { ButtonGroupItemData } from '../../../../shared-report-components/base-components/fordjupning-button-group/button-group-item.model';
import { ChartCategory, ColorMapService } from '../../../../common/service/color-mapper.service';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-kombo-skogenssammansattning',
    templateUrl: './kombo-skogenssammansattning.component.html',
    styleUrl: './kombo-skogenssammansattning.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class KomboSkogensSammansattningComponent implements OnInit {
    // Produktiv skogsmark chart
    private chartEntriesProdSkogsmark = [
        {
            name: 'Tallskog',
            key: 'tallAldreskogHaRT',
            color: ChartCategory.TALL
        },
        {
            name: 'Granskog',
            key: 'granAldreskogHaRT',
            color: ChartCategory.GRAN
        },
        {
            name: 'Barrblandskog',
            key: 'barrblandAldreskogHaRT',
            color: ChartCategory.BARRBLAND
        },
        {
            name: 'Övrig skog',
            key: 'ovrigtAldreskogHaRT',
            color: ChartCategory.OVRIG
        }
    ];

    optionsProdSkogsmark: any = {
        chartArea: { width: '90%', height: '90%' },
        height: 230,
        pieSliceText: 'none',
        tooltip: {
            trigger: 'none'
        },
        legend: {
            position: 'none'
        }
    };

    inventeringsAr: number;
    dataProdSkogsmark: any[][];
    legendDataProdSkogsmark: any[];

    // Ungskogens sammansättning
    private chartEntriesUngskSammansattning = [
        {
            name: 'Tallskog',
            key: 'tallskogHaABIN3InvMedel',
            color: ChartCategory.TALL
        },
        {
            name: 'Granskog',
            key: 'granskogHaABIN3InvMedel',
            color: ChartCategory.GRAN
        },
        {
            name: 'Barrblandskog',
            key: 'bblandskogHaABIN3InvMedel',
            color: ChartCategory.BARRBLAND
        },
        {
            name: 'Övrig skog',
            key: 'ovrigskogHaABIN3InvMedel',
            color: ChartCategory.OVRIG
        }
    ];

    optionsUngskSammansattning: any = {
        chartArea: { width: '90%', height: '90%' },
        height: 230,
        pieSliceText: 'none',
        tooltip: {
            trigger: 'none'
        },
        legend: {
            position: 'none'
        }
    };
    inventeringsArUngskSammansattning: number;
    dataUngskSammansattning: any[][];
    legendDataUngskSammansattning: any[];

    private _omradeNamn = new BehaviorSubject<string>(null);
    omradeNamn$ = this._omradeNamn.asObservable();

    constructor(public colormapService: ColorMapService) {}

    ngOnInit(): void {}

    onOmradeSelect(omrade: ButtonGroupItemData) {
        if (omrade && omrade.inventeringar && omrade.inventeringar.length > 0) {
            this._omradeNamn.next(omrade.omradeName);
            this.generateChartDataProdSkogsmark(omrade.inventeringar[0]);
            this.generateChartDataUngskSammansattning(omrade.inventeringar[0]);
        }
    }

    generateChartDataProdSkogsmark(senasteInventering: AbinResultDto) {
        const data: any[][] = [['Träslag', 'Procent']];
        const colors = [];
        const legendData = [];
        this.inventeringsAr = senasteInventering.invAr;
        const total = this.getTotalSkogHaRT(senasteInventering);
        for (const entry of this.chartEntriesProdSkogsmark) {
            if (senasteInventering[entry.key]) {
                const name = entry.name;
                const value = Math.round((senasteInventering[entry.key] / total) * 100);
                const color = this.colormapService.getColor(entry.color);
                data.push([name, value]);
                colors.push(color);
                legendData.push({ name, value, color });
            }
        }
        this.dataProdSkogsmark = data;
        this.optionsProdSkogsmark = { ...this.optionsProdSkogsmark, colors };
        this.legendDataProdSkogsmark = legendData;
    }

    getTotalSkogHaRT(inv: AbinResultDto): number {
        return Math.round((inv.barrblandAldreskogHaRT || 0) + (inv.granAldreskogHaRT || 0) + (inv.ovrigtAldreskogHaRT || 0) + (inv.tallAldreskogHaRT || 0));
    }

    generateChartDataUngskSammansattning(senasteInventering: AbinResultDto) {
        const data: any[][] = [['Träslag', 'Procent']];
        const colors = [];
        const legendData = [];
        this.inventeringsAr = senasteInventering.invAr;
        const total = this.getTotalSkogHaAbin(senasteInventering);
        for (const entry of this.chartEntriesUngskSammansattning) {
            if (senasteInventering[entry.key]) {
                const name = entry.name;
                const value = Math.round((senasteInventering[entry.key] / total) * 100);
                const color = this.colormapService.getColor(entry.color);
                data.push([name, value]);
                colors.push(color);
                legendData.push({ name, value, color });
            }
        }
        this.dataUngskSammansattning = data;
        this.optionsUngskSammansattning = { ...this.optionsUngskSammansattning, colors };
        this.legendDataUngskSammansattning = legendData;
    }

    getTotalSkogHaAbin(inv: AbinResultDto): number {
        let sumTotal = 0;
        for (const entry of this.chartEntriesUngskSammansattning) {
            const value = inv[entry.key];
            sumTotal += value || 0;
        }
        return Math.round(sumTotal);
    }
}

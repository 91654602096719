import { CommonModule } from '@angular/common';
import { UiErrorMarginComponent } from './ui-error-margin.component';
import { NgModule } from '@angular/core';

@NgModule({
    declarations: [UiErrorMarginComponent],
    imports: [CommonModule],
    exports: [UiErrorMarginComponent]
})
export class UiErrorMarginModule {}

<div class="row">
    <div class="col-xs-12">
        <!-- <h4 class="mt-0">Historisk utveckling av andel ytor där RASE har gynnsam konkurrensstatus</h4> -->
        <div class="subheadertext">Välj område med knapparna för att visa utvecklingen över tid och vilka år som inventerats.</div>
    </div>

    <div class="col-xs-12">
        <app-fordjupning-button-group (selectedOmradeEvent)="onOmradeSelect($event)"></app-fordjupning-button-group>
        <p><b>Valt område</b>: {{ omradeNamn$ | async }}</p>
    </div>
</div>

<div class="row">
    <div class="col-md-8 col-xs-12 col-lg-9">
        <app-arsskada-combochart
            [inventeringar]="inventeringar$ | async"
            [vardeVariabler]="vardeVariabler"
            [diagramBeskrivning]="'Historisk utveckling av andel ytor där RASE har gynnsam konkurrensstatus'"
        ></app-arsskada-combochart>
    </div>

    <div class="col-md-4 col-xs-12 col-lg-3">
        <ng-container *ngIf="senasteInventering$ | async as senasteInventering">
            <app-sammanvagning-card
                [inventering]="senasteInventering"
                vardeVariabel="raseAndelGynnsam3InvMedel"
                cardBeskrivning="Andel ytor med gynnsam konkurrensstatus för RASE, sammanvägning för de tre senaste inventeringarna"
            >
                <ng-container sammanvagningCardIcon *ngIf="senasteInventering?.slutsatsRASEGynInt > 0; else elseTemplate">
                    <span class="abinicon-rase_gynnsam_status_uppfylld"></span>
                </ng-container>
                <ng-template sammanvagningCardIcon #elseTemplate>
                    <span class="abinicon-rase_gynnsam_status_ej_uppfylld"></span>
                </ng-template>
            </app-sammanvagning-card>
        </ng-container>
    </div>
</div>

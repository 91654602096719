import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { versionInfo } from '../../../appVersion/versionInfo';
import { VersionService } from '../../common/service/version.service';
@Component({
  selector: 'app-site-header',
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.scss'],
})
export class SiteHeaderComponent implements OnInit {
  appVersion: string;
  skogsstyrelsenUrl: string;
  constructor(
    private versionService: VersionService,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.skogsstyrelsenUrl = environment.skogsstyrelsenUrl;
    this.titleService.setTitle('Abin Rapport');
    this.versionService.getVersionInfo().subscribe(
      (res) => {
        this.appVersion = res.BuildNumber;
      },
      (err) => {
        this.appVersion = versionInfo.BuildNumber;
        console.info('Kunde inte hämta app version, visar default version');
      }
    );
  }
}

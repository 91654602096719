import { Injectable } from '@angular/core';
import MapView from '@arcgis/core/views/MapView';
import { BehaviorSubject, from } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ArsskadorKartaService {
  view: MapView;
  private loading = new BehaviorSubject<boolean>(true);
  public loading$ = this.loading.asObservable();
  constructor() {}

  setView(view: MapView) {
    if (view) {
      this.view = view;
      this.setLoadingObserver();
    }
  }

  private setLoadingObserver = () => {
    this.view.watch('updating', (status) => {
      this.loading.next(status);
    });
  };

  getScreenshot(width: number, height: number) {
    return from(this.view.takeScreenshot({ width, height }));
  }
}

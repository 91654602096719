<app-fordjupning-karta-base [layerConfig]="layerConfig$ | async" (mapClicked)="onMapClicked($event)">
    <app-fordjupning-karta-popup [isLoading]="isLoadingPopupData" [popupContent]="popupData$ | async">
        <ng-container *ngIf="(popupData$ | async)?.thumbsUp; else elseTemplate" popupIcon>
            <span class="abinicon-arsskada_tall_uppfyllt"></span>
        </ng-container>
        <ng-template #elseTemplate popupIcon>
            <span class="abinicon-arsskada_tall_ej_uppfyllt"></span>
        </ng-template>
    </app-fordjupning-karta-popup>
</app-fordjupning-karta-base>
<br />
<ui-figure-caption>
    <ui-figure-text ui-figure-text-bold>
        Skalan i kartan visar graden av måluppfyllelse. För de flesta områden är gränserna 5 % för Tolerabel, 5-10 % för Allvarlig, 10-20 % för Svår och högre
        än 20 % för Mycket svår. På svagare marker är gränserna dock lägre med 2 % för Tolerabel, 2-5 % för Allvarlig, 5-10 % för Svår och högre än 10 % för
        Mycket svår.
    </ui-figure-text>
</ui-figure-caption>

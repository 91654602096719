import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import jwt_decode from 'jwt-decode';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export interface Credentials {
  access_token: string;
  expires_in: number;
  scope: string;
  token_type: string;
}

interface Token {
  aud: string;
  client_id: string;
  exp: number;
  iss: string;
  nbf: number;
  scope: string[];
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  credentials: Credentials;
  tokenDecoded: Token;
  tokenEncoded: string;
  constructor(private http: HttpClient) {}

  private getHeaders(): HttpHeaders {
    const secret = btoa(
      `${environment.oauth2.clientId}:${environment.oauth2.clientSecret}`
    );
    const contentType = 'application/x-www-form-urlencoded';
    return new HttpHeaders({
      'Content-Type': contentType,
      authorization: `Basic ${secret}`,
      // authority: 'auth.skogsstyrelsen.se',
    });
  }

  private getBody(): HttpParams {
    return new HttpParams()
      .set(`scope`, `${environment.oauth2.scope}`)
      .set(`grant_type`, `${environment.oauth2.grantType}`);
  }

  private decodeToken(token: string): Token {
    return jwt_decode(token);
  }

  private tokenExpired(token: Token): boolean {
    return token && token.exp < new Date().getTime() / 1000;
  }

  public login(): Observable<string> {
    if (!this.tokenDecoded || this.tokenExpired(this.tokenDecoded)) {
      const headers = this.getHeaders();
      const body = this.getBody();
      return this.http
        .post<Credentials>(`${environment.oauth2.authority}`, body, {
          headers,
        })
        .pipe(
          map((res) => {
            const decoded = this.decodeToken(res.access_token);
            this.tokenDecoded = Object.assign(
              {},
              { ...decoded, scope: [...decoded.scope] }
            );
            this.tokenEncoded = res.access_token;
            return this.tokenEncoded;
          }),
          catchError((err) => {
            console.error(err);
            return of('Kunde inte hämta token');
          })
        );
    } else {
      return of(this.tokenEncoded);
    }
  }
}

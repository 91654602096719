import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AbinResultDto } from '../../../../inge-api/client/models';
import { ButtonGroupItemData } from '../../../../shared-report-components/base-components/fordjupning-button-group/button-group-item.model';
import { ChartCategory, ColorMapService } from '../../../../common/service/color-mapper.service';
import { BehaviorSubject } from 'rxjs';
import { UiTableConfig } from '../../../../ui-components/ui-table/ui-table/models/tableConfig';
import { formatNumber } from '@angular/common';
import { round } from 'lodash-es';

@Component({
    selector: 'app-kombo-tradslagsammansattning',
    templateUrl: './kombo-tradslagsammansattning.component.html',
    styleUrl: './kombo-tradslagsammansattning.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class KomboTradslagensSammansattningComponent implements OnInit {
    tableConfig: UiTableConfig = {
        columns: [
            { title: 'Trädslag', dataProperty: 'tradslag' },
            { title: 'Stammar per ha', dataProperty: 'stammarPerHa' },
            { title: 'Senaste tre inventeringar', dataProperty: 'senasteTreInv' }
        ],
        rowActions: []
    };

    tableCaption = '';
    tableContent = [];

    private chartEntries = [
        {
            name: 'Tall',
            key: 'antalTallarHa3InvAVG',
            color: ChartCategory.TALL
        },
        {
            name: 'Gran',
            key: 'antalGranarHa3InvAVG',
            color: ChartCategory.GRAN
        },
        {
            name: 'Björk',
            key: 'antalBjorkarHa3InvAVG',
            color: ChartCategory.BJORK
        },
        {
            name: 'Övrigt',
            key: 'antalOvrigtHa3InvAVG',
            color: ChartCategory.OVRIG
        },
        {
            name: 'RASE',
            key: 'antalRASEHa3InvAVG',
            color: ChartCategory.RASE
        }
    ];

    options: any = {
        chartArea: { width: '90%', height: '90%' },
        height: 230,
        pieSliceText: 'none',
        tooltip: {
            trigger: 'none'
        },
        legend: {
            position: 'none'
        }
    };
    inventeringsAr: number;
    data: any[][];
    legendData: any[];

    private _omradeNamn = new BehaviorSubject<string>(null);
    omradeNamn$ = this._omradeNamn.asObservable();

    constructor(public colormapService: ColorMapService) {}

    ngOnInit(): void {}

    onOmradeSelect(omrade: ButtonGroupItemData) {
        if (omrade && omrade.inventeringar && omrade.inventeringar.length > 0) {
            this._omradeNamn.next(omrade.omradeName);
            this.generateTableData(omrade.inventeringar[0]);
            this.generateChartData(omrade.inventeringar[0]);
        }
    }

    generateTableData(senasteInv: AbinResultDto) {
        this.tableConfig = {
            columns: [
                { title: 'Trädslag', dataProperty: 'tradslag' },
                { title: `${senasteInv.invAr}`, dataProperty: 'stammarPerHa' },
                {
                    title: 'Senaste tre inventeringar',
                    dataProperty: 'senasteTreInv'
                }
            ],
            rowActions: []
        };
        this.tableContent = [
            {
                tradslag: 'Tall',
                stammarPerHa: formatNumber(senasteInv.antalTallarHa, 'sv'),
                senasteTreInv: formatNumber(senasteInv.antalTallarHa3InvAVG, 'sv')
            },
            {
                tradslag: 'Gran',
                stammarPerHa: formatNumber(senasteInv.antalGranarHa, 'sv'),
                senasteTreInv: formatNumber(senasteInv.antalGranarHa3InvAVG, 'sv')
            },

            {
                tradslag: 'Björk',
                stammarPerHa: formatNumber(senasteInv.antalBjorkarHa, 'sv'),
                senasteTreInv: formatNumber(senasteInv.antalBjorkarHa3InvAVG, 'sv')
            },
            {
                tradslag: 'Övriga produktionsträdslag',
                stammarPerHa: formatNumber(senasteInv.antalOvrigtHa, 'sv'),
                senasteTreInv: formatNumber(senasteInv.antalOvrigtHa3InvAVG, 'sv')
            },
            {
                tradslag: 'RASE',
                stammarPerHa: formatNumber(senasteInv.antalRASEHa, 'sv'),
                senasteTreInv: formatNumber(senasteInv.antalRASEHa3InvAVG, 'sv')
            },
            {
                tradslag: 'Totalt',
                stammarPerHa: formatNumber(this.getTotalStammarHaArsvarde(senasteInv), 'sv'),
                senasteTreInv: formatNumber(this.getTotalStammarHa3InvMedel(senasteInv), 'sv')
            }
        ];
    }

    getTotalStammarHaArsvarde(inv: AbinResultDto): number {
        return Math.round((inv.antalTallarHa || 0) + (inv.antalGranarHa || 0) + (inv.antalBjorkarHa || 0) + (inv.antalOvrigtHa || 0) + (inv.antalRASEHa || 0));
    }

    generateChartData(senasteInventering: AbinResultDto) {
        const data: any[][] = [['Träslag', 'Procent']];
        const colors = [];
        const legendData = [];
        this.inventeringsAr = senasteInventering.invAr;
        const total = this.getTotalStammarHa3InvMedel(senasteInventering);
        for (const entry of this.chartEntries) {
            if (senasteInventering[entry.key]) {
                const name = entry.name;
                const value = round((senasteInventering[entry.key] / total) * 100, 0);
                const color = this.colormapService.getColor(entry.color);
                data.push([name, value]);
                colors.push(color);
                legendData.push({ name, value, color });
            }
        }
        this.data = data;
        this.options = { ...this.options, colors };
        this.legendData = legendData;
    }

    getTotalStammarHa3InvMedel(inv: AbinResultDto): number {
        let sumTotal = 0;
        for (const entry of this.chartEntries) {
            const value = inv[entry.key];
            sumTotal += value || 0;
        }
        return Math.round(sumTotal);
    }
}

import { Component, HostListener, OnInit } from '@angular/core';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GoogleChartsLoaderService } from '../google-charts-loader.service';
declare const google: any;
@Component({
    selector: 'chart-base',
    template: ``
})
export class GoogleChartBaseComponent implements OnInit {
    chart: any;
    googleChartsLoadError: boolean;

    public chartOptions = new BehaviorSubject<Record<string, any>>([]);
    public chartOptions$ = this.chartOptions.asObservable();
    public chartData = new BehaviorSubject<any[][]>([]);
    public chartData$ = this.chartData.asObservable();

    @HostListener('window:resize', ['$event'])
    onResize(evt) {
        this.drawChart(this.chartData.getValue(), this.chartOptions.getValue());
    }

    constructor(public loaderService: GoogleChartsLoaderService) {}

    ngOnInit() {}

    loadChartPackage(chartPackage: string) {
        return this.loaderService.loadChartPackages(chartPackage).pipe(
            catchError(() => {
                this.googleChartsLoadError = true;
                return throwError(() => new Error('Google Charts could not be loaded'));
            })
        );
    }

    drawChart(data: any[][], options: Record<string, any>) {
        if (this.chart && data && data.length && options) {
            this.chart.draw(google.visualization.arrayToDataTable(data), options);
        }
    }

    getImageURI() {
        return this.chart.getImageURI();
    }
}

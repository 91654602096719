import { NgModule } from '@angular/core';
import { ArsskadaBarchartComponent } from './base-components/arsskada-barchart/arsskada-barchart.component';
import { ArsskadaCombochartComponent } from './base-components/arsskada-combochart/arsskada-combochart.component';
import { FordjupningButtonGroupComponent } from './base-components/fordjupning-button-group/fordjupning-button-group.component';
import { FordjupningDropDownComponent } from './base-components/fordjupning-drop-down/fordjupning-drop-down.component';
import { FordjupningKartaBaseComponent } from './base-components/fordjupning-karta-base/fordjupning-karta-base.component';
import { FordjupningKartaIconDirective } from './base-components/fordjupning-karta-popup/fordjupning-karta-icon.directive';
import { FordjupningKartaPopupComponent } from './base-components/fordjupning-karta-popup/fordjupning-karta-popup.component';
import { FordjupningLegendsComponent } from './base-components/fordjupning-legends/fordjupning-legends.component';
import { IngaendeOmradenBarchartComponent } from './base-components/ingaende-omraden-barchart/ingaende-omraden-barchart.component';
import { SammanvagningCardIconDirective } from './base-components/sammanvagning-card/sammanvagning-card-icon.directive';
import { SammanvagningCardComponent } from './base-components/sammanvagning-card/sammanvagning-card.component';
import { UppfoljBarchartBaseComponent } from './base-components/uppfolj-barchart-base/uppfolj-barchart-base.component';
import { UppfoljRenderingFilterComponent } from './base-components/uppfolj-rendering-filter/uppfolj-rendering-filter.component';
import { UppfoljArsskadaGranFordjupningComponent } from './uppfolj-arsskada-gran/uppfolj-arsskada-gran-fordjupning/uppfolj-arsskada-gran-fordjupning.component';
import { UppfoljArsskadaGranKartaComponent } from './uppfolj-arsskada-gran/uppfolj-arsskada-gran-karta/uppfolj-arsskada-gran-karta.component';
import { ArsskadorTallOverviewSectionComponent } from './uppfolj-arsskador-tall/arsskador-tall-overview-section/arsskador-tall-overview-section.component';
import { UppfoljArsskadorTallKartaComponent } from './uppfolj-arsskador-tall/uppfolj-arsskador-tall-karta/uppfolj-arsskador-tall-karta.component';
import { UppfoljFoderFoderprognosChartComponent } from './uppfolj-foder-foderprognos-chart/uppfolj-foder-foderprognos-chart.component';
import { UppfoljFoderprognosChartComponent } from './uppfolj-foderprognos/uppfolj-foderprognos-chart/uppfolj-foderprognos-chart.component';
import { UppfoljFoderprognosFordjupningComponent } from './uppfolj-foderprognos/uppfolj-foderprognos-fordjupning/uppfolj-foderprognos-fordjupning.component';
import { UppfoljForekomstRaseFordjupningComponent } from './uppfolj-forekomst-rase/uppfolj-forekomst-rase-fordjupning/uppfolj-forekomst-rase-fordjupning.component';
import { UppfoljKonkurrensRaseFordjupningComponent } from './uppfolj-konkurrens-rase/uppfolj-konkurrens-rase-fordjupning/uppfolj-konkurrens-rase-fordjupning.component';
import { UppfoljKonkurrensRaseKartaComponent } from './uppfolj-konkurrens-rase/uppfolj-konkurrens-rase-karta/uppfolj-konkurrens-rase-karta.component';
import { UppfoljOskadadTallFordjupningComponent } from './uppfolj-oskadad-tall/uppfolj-oskadad-tall-fordjupning/uppfolj-oskadad-tall-fordjupning.component';
import { UppfoljOskadadTallKartaComponent } from './uppfolj-oskadad-tall/uppfolj-oskadad-tall-karta/uppfolj-oskadad-tall-karta.component';
import { UppfoljRapportFooterComponent } from './uppfolj-rapport-footer/uppfolj-rapport-footer.component';
import { UppfoljRapportTocComponent } from './uppfolj-rapport-toc/uppfolj-rapport-toc.component';
import { UppfoljSlutsatserListaComponent } from './uppfolj-slutsatser-lista/uppfolj-slutsatser-lista.component';
import { UppfoljTallMagerMarkFordjupningComponent } from './uppfolj-tall-mager-mark/uppfolj-tall-mager-mark-fordjupning/uppfolj-tall-mager-mark-fordjupning.component';
import { UppfoljTallMagerMarkKartaComponent } from './uppfolj-tall-mager-mark/uppfolj-tall-mager-mark-karta/uppfolj-tall-mager-mark-karta.component';
import { UppfoljTallMellanMarkFordjupningComponent } from './uppfolj-tall-mellan-mark/uppfolj-tall-mellan-mark-fordjupning/uppfolj-tall-mellan-mark-fordjupning.component';
import { UppfoljTallMellanMarkKartaComponent } from './uppfolj-tall-mellan-mark/uppfolj-tall-mellan-mark-karta/uppfolj-tall-mellan-mark-karta.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from '../app-routing.module';
import { GoogleChartsBaseModule } from '../google-charts/google-charts-base-components/google-charts-base.module';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { UiComponentsModule } from '../ui-components/ui-components.module';

@NgModule({
    declarations: [
        UppfoljRapportTocComponent,
        ArsskadorTallOverviewSectionComponent,
        FordjupningDropDownComponent,
        UppfoljSlutsatserListaComponent,
        UppfoljBarchartBaseComponent,
        UppfoljFoderFoderprognosChartComponent,
        UppfoljRapportFooterComponent,

        FordjupningButtonGroupComponent,
        FordjupningKartaPopupComponent,
        FordjupningKartaIconDirective,
        FordjupningKartaBaseComponent,
        ArsskadaBarchartComponent,
        ArsskadaCombochartComponent,
        IngaendeOmradenBarchartComponent,
        SammanvagningCardComponent,
        SammanvagningCardIconDirective,
        UppfoljOskadadTallFordjupningComponent,
        UppfoljOskadadTallKartaComponent,
        UppfoljArsskadaGranKartaComponent,
        UppfoljArsskadaGranFordjupningComponent,
        UppfoljTallMagerMarkKartaComponent,
        UppfoljTallMagerMarkFordjupningComponent,
        UppfoljTallMellanMarkFordjupningComponent,
        UppfoljTallMellanMarkKartaComponent,
        UppfoljForekomstRaseFordjupningComponent,
        UppfoljKonkurrensRaseFordjupningComponent,
        UppfoljKonkurrensRaseKartaComponent,
        UppfoljArsskadorTallKartaComponent,
        FordjupningLegendsComponent,
        UppfoljRenderingFilterComponent,
        UppfoljFoderprognosFordjupningComponent,
        UppfoljFoderprognosChartComponent
    ],
    exports: [
        UppfoljRapportTocComponent,
        ArsskadorTallOverviewSectionComponent,
        FordjupningDropDownComponent,
        UppfoljSlutsatserListaComponent,
        UppfoljBarchartBaseComponent,
        UppfoljFoderFoderprognosChartComponent,
        UppfoljRapportFooterComponent,

        FordjupningButtonGroupComponent,
        FordjupningKartaPopupComponent,
        FordjupningKartaIconDirective,
        FordjupningKartaBaseComponent,
        ArsskadaBarchartComponent,
        ArsskadaCombochartComponent,
        IngaendeOmradenBarchartComponent,
        SammanvagningCardComponent,
        SammanvagningCardIconDirective,
        UppfoljOskadadTallFordjupningComponent,
        UppfoljOskadadTallKartaComponent,
        UppfoljArsskadaGranKartaComponent,
        UppfoljArsskadaGranFordjupningComponent,
        UppfoljTallMagerMarkKartaComponent,
        UppfoljTallMagerMarkFordjupningComponent,
        UppfoljTallMellanMarkFordjupningComponent,
        UppfoljTallMellanMarkKartaComponent,
        UppfoljForekomstRaseFordjupningComponent,
        UppfoljKonkurrensRaseFordjupningComponent,
        UppfoljKonkurrensRaseKartaComponent,
        UppfoljArsskadorTallKartaComponent,
        FordjupningLegendsComponent,
        UppfoljRenderingFilterComponent,
        UppfoljFoderprognosFordjupningComponent,
        UppfoljFoderprognosChartComponent
    ],
    imports: [
        CommonModule,
        UiComponentsModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        SharedComponentsModule,
        GoogleChartsBaseModule
    ]
})
export class SharedReportComponentsModule {}

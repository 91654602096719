import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[ui-info-box-centered]',
})
export class UiInfoBoxCenteredDirective {
  constructor() {}

  @HostBinding('class')
  elementClass = 'ui-info-box-centered';
}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-foder-tradslagssammansatning-info-ruta',
  templateUrl: './foder-tradslagssammansatning-info-ruta.component.html',
  styleUrls: ['./foder-tradslagssammansatning-info-ruta.component.scss'],
})
export class FoderTradslagssammansatningInfoRutaComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

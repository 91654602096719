import { formatNumber } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChartCategory, ColorMapService } from 'src/app/common/service';
import { getYAxisMaxValue } from 'src/app/google-charts/google-chart-utils';
import { AbinResultDto } from 'src/app/inge-api/client/models';
import { InGeApiState } from 'src/app/store/state-model/inge-api.model';

@Component({
    selector: 'app-andel-combochart-base',
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AndelCombochartBaseComponent implements OnInit {
    options: any = {
        chartArea: { width: '80%', height: '75%', top: 10 },
        // tooltip: {
        //     trigger: 'none'
        // },
        hAxis: {
            maxAlternation: 6,
            formatOptions: { decimalSymbol: ',', groupingSymbol: '.' }
        },
        vAxis: {
            title: 'Procent %',
            minValue: 0,
            baseline: 0,
            baselineColor: '#ccc',
            // ticks: [5, 10, 15, 20, 25],
            gridlines: { multiple: 1 },
            minorGridlines: { count: 0 },
            titleTextStyle: {
                italic: false,
                fontSize: 16
            },
            formatOptions: { decimalSymbol: ',', groupingSymbol: '.' }
        },
        seriesType: 'bars',
        series: { 1: { type: 'line' } },
        intervals: { style: 'bars' },
        annotations: {
            alwaysOutside: true,
            textStyle: {
                color: 'black'
            },
            style: 'point',
            stem: {
                length: 2
            }
        },
        legend: { position: 'bottom' },
        fontName: 'Roboto',
        bar: { groupWidth: '40%' },
        animation: {
            startup: true,
            duration: 100
        },
        colors: []
    };
    inventeringar$: Observable<AbinResultDto[]>;
    constructor(private store: Store<InGeApiState>, protected colormapService: ColorMapService) {
        this.options.colors = [this.colormapService.getColor(ChartCategory.BARCHARTDEFAULT), this.colormapService.getColor(ChartCategory.MEDEL3AR)];
        this.inventeringar$ = this.store.pipe(
            select((x) => x.abin.inventeringar),
            map((x) => x && cloneDeep(x).sort((a, b) => a.invAr - b.invAr))
        );
    }

    getMaxValue(data: any[][]) {
        if (!data || !data.length) return;
        return getYAxisMaxValue(data);
    }

    protected getTooltip(isTreArsMedel: boolean, skadaAndel: number, konfidensProcent: number, decimalFormat: string = '1.0-0'): string {
        return (
            '<div class="tooltip_layout">' +
            '<table>' +
            '<tr>' +
            '<td>' +
            (isTreArsMedel ? 'Förekomst senaste 3 inv' : 'Förekomst 1 år') +
            '</td>' +
            '</tr>' +
            '<tr>' +
            '<td><b>' +
            formatNumber(skadaAndel, 'sv-SE', decimalFormat) +
            '</b>% (Felmarginal +/- ' +
            formatNumber(konfidensProcent, 'sv-SE', decimalFormat) +
            '%)</td>' +
            '</tr>' +
            '</table>' +
            '</div>'
        );
    }

    protected getTooltipNoFelmarginal(
        isTreArsMedel: boolean,
        skadaAndel: number,
        text1Ar: string = 'Förekomst 1 år',
        text3Ar: string = 'Förekomst senaste 3 inv',
        decimalFormat: string = '1.0-0'
    ): string {
        return (
            '<div class="tooltip_layout">' +
            '<table>' +
            '<tr>' +
            '<td>' +
            (isTreArsMedel ? text3Ar : text1Ar) +
            '</td>' +
            // '</tr>' +
            // '<tr>' +
            '<td> <b>' +
            formatNumber(skadaAndel, 'sv-SE', decimalFormat) +
            '</b>%</td>' +
            '</tr>' +
            '</table>' +
            '</div>'
        );
    }

    ngOnInit(): void {}
}

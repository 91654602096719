import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ui-figure-caption',
  templateUrl: './ui-figure-caption.component.html',
  styleUrls: ['./ui-figure-caption.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiFigureCaptionComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

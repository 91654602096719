import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiSelectDirective } from './directives/ui-select.directive';
import { UiSelectComponent } from './ui-select/ui-select.component';

@NgModule({
  declarations: [UiSelectDirective, UiSelectComponent],
  imports: [CommonModule],
  exports: [UiSelectComponent, UiSelectDirective],
})
export class UiSelectModule {}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AbinResultDto } from 'src/app/inge-api/client/models';
import { UiTableConfig } from 'src/app/ui-components/ui-table/ui-table/models/tableConfig';
import { Store, select } from '@ngrx/store';
import { InGeApiState } from 'src/app/store/state-model/inge-api.model';
import { round } from 'lodash-es';
import { formatNumber, formatPercent } from '@angular/common';

@Component({
  selector: 'app-foder-rojning-table',
  templateUrl: './foder-rojning-table.component.html',
  styleUrls: ['./foder-rojning-table.component.scss'],
})
export class FoderRojningTableComponent implements OnInit, OnDestroy {
  inventeringar$: Observable<AbinResultDto[]>;
  loadingInventeringar$: Observable<boolean>;
  inventeringarError$: Observable<boolean>;
  tableConfig: UiTableConfig = {
    columns: [],
    rowActions: [],
  };

  tableCaption = '';

  tableContent = [];
  private subs: Subscription[] = [];
  constructor(private store: Store<InGeApiState>) {
    this.inventeringar$ = this.store.pipe(select((x) => x.abin.inventeringar));
    this.loadingInventeringar$ = store.pipe(
      select((x) => x.abin.loadingInventeringar)
    );
    this.inventeringarError$ = store.pipe(
      select((x) => x.abin.inventeringarError)
    );
  }

  ngOnInit(): void {
    this.subs.push(
      this.inventeringar$.subscribe((x) => {
        if (x && x.length > 0) {
          const senasteInv = x[0];
          this.tableConfig = {
            columns: [
              { title: 'Beståndsuppgift', dataProperty: 'titel' },
              { title: `${senasteInv.invAr}`, dataProperty: 'innevarandeAr' },
              {
                title: 'Senaste tre inventeringar',
                dataProperty: 'senasteTreInv',
              },
            ],
            rowActions: [],
          };

          this.tableContent = [
            {
              titel: 'Beståndsmedelhöjd',
              innevarandeAr:
                formatNumber(senasteInv.medelbestandshojd / 10, 'sv', '1.0-1') +
                ' m',
              senasteTreInv:
                formatNumber(
                  senasteInv.medelbestandshojd3InvMedel / 10,
                  'sv',
                  '1.0-1'
                ) + ' m',
            },
            {
              titel: 'Andel provytor som röjts',
              innevarandeAr: formatPercent(senasteInv.andelRojt, 'sv', '1.0-0'),
              senasteTreInv: formatPercent(
                senasteInv.andelRojt3InvMedel,
                'sv',
                '1.0-0'
              ),
            },
          ];
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((x) => x.unsubscribe());
  }
}

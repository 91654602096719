<div class="abin-body">
  <app-site-header></app-site-header>
  <div class="abin-container">
    <div class="row">
      <div class="col-xs-12">
        <div class="page-title">
          <h1>Skoglig Betesinventering</h1>
          <h2>Älgbetesinventering (Äbin) och foderprognos</h2>
        </div>
      </div>
    </div>
    <router-outlet></router-outlet>
  </div>
</div>

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { toInteger } from 'lodash-es';
import { Observable } from 'rxjs';
import * as actions from '../../store/actions/uppfoljningsrapport.actions';
import { InGeApiState } from '../../store/state-model/inge-api.model';
@Injectable({ providedIn: 'root' })
export class AbinUppfoljningsRapportResolver  {
  constructor(private store: Store<InGeApiState>) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    const landsdel = route.queryParamMap.get('landsdel');
    const lan = route.queryParamMap.get('lan');

    if (landsdel === 'alla') {
      this.getLandsData();
    } else if (this.isNumeric(landsdel) && this.isNumeric(lan)) {
      this.getLanData(route);
    } else if (this.isNumeric(landsdel)) {
      this.getLandsdelData(route);
    }
  }

  getLandsData(): void {
    this.store.dispatch(actions.uppfoljningsrapportGetSummeringLand());
    this.store.dispatch(actions.uppfoljningsrapportGetLandsdelar());
    this.store.dispatch(
      actions.uppfoljningsrapportGetIngaendeInventeringarLand()
    );
  }

  getLandsdelData(route: ActivatedRouteSnapshot): void {
    let landsdelKod: string | number = route.queryParamMap.get('landsdel');
    if (landsdelKod) {
      landsdelKod = toInteger(landsdelKod);
      this.store.dispatch(
        actions.uppfoljningsrapportGetSummeringLandsdel({
          landsdelKod,
          excludeLan: ['09'],
        })
      );
      this.store.dispatch(
        actions.uppfoljningsrapportGetLan({ landsdelKod, exclude: ['09'] })
      );
      this.store.dispatch(
        actions.uppfoljningsrapportGetIngaendeInventeringarLandsdel({
          landsdelkod: landsdelKod,
          excludeLan: ['09'],
        })
      );
    }
  }

  getLanData(route: ActivatedRouteSnapshot): void {
    const lankod = route.queryParamMap.get('lan');
    let landsdelKod: string | number = route.queryParamMap.get('landsdel');
    if (lankod && landsdelKod) {
      landsdelKod = toInteger(landsdelKod);
      this.store.dispatch(
        actions.uppfoljningsrapportGetSummeringLan({ lankod })
      );
      this.store.dispatch(
        actions.uppfoljningsrapportGetAfo({ lanKod: lankod, landsdelKod })
      );
      this.store.dispatch(
        actions.uppfoljningsrapportGetIngaendeInventeringarLan({
          lankod: lankod,
          landsdelkod: landsdelKod,
        })
      );
    }
  }

  private isNumeric(value: any): boolean {
    return !isNaN(value - parseFloat(value));
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { round } from 'lodash-es';
import { Subscription } from 'rxjs';
import { ChartCategory } from 'src/app/common/service';
import { AbinResultDto } from 'src/app/inge-api/client/models';
import { FordelningPiechartBaseComponent } from '../../base-components';

@Component({
    selector: 'app-foder-tradslagssammansattning-chart',
    templateUrl: './foder-tradslagssammansattning-chart.component.html',
    styleUrls: ['./foder-tradslagssammansattning-chart.component.scss']
})
export class FoderTradslagssammansattningChartComponent extends FordelningPiechartBaseComponent implements OnInit, OnDestroy {
    private subs: Subscription[];

    private chartEntries = [
        {
            name: 'Tall',
            key: 'antalTallarHa3InvAVG',
            color: ChartCategory.TALL
        },
        {
            name: 'Gran',
            key: 'antalGranarHa3InvAVG',
            color: ChartCategory.GRAN
        },
        {
            name: 'Björk',
            key: 'antalBjorkarHa3InvAVG',
            color: ChartCategory.BJORK
        },
        {
            name: 'Övrigt',
            key: 'antalOvrigtHa3InvAVG',
            color: ChartCategory.OVRIG
        },
        {
            name: 'RASE',
            key: 'antalRASEHa3InvAVG',
            color: ChartCategory.RASE
        }
    ];
    inventeringsAr: number;
    data: any[][];
    legendData: any[];

    ngOnInit(): void {
        this.subs = [];
        this.subs.push(
            this.inventering$.subscribe((x) => {
                if (x) {
                    this.generateChartData(x);
                }
            })
        );
    }

    generateChartData(senasteInventering: AbinResultDto) {
        const data: any[][] = [['Träslag', 'Procent']];
        const colors = [];
        const legendData = [];
        this.inventeringsAr = senasteInventering.invAr;
        const total = this.getTotalStammarHa(senasteInventering);
        for (const entry of this.chartEntries) {
            if (senasteInventering[entry.key]) {
                const name = entry.name;
                const value = round((senasteInventering[entry.key] / total) * 100, 0);
                const color = this.colormapService.getColor(entry.color);
                data.push([name, value]);
                colors.push(color);
                legendData.push({ name, value, color });
            }
        }
        this.data = data;
        this.options = { ...this.options, colors };
        this.legendData = legendData;
    }

    getTotalStammarHa(inv: AbinResultDto): number {
        let sumTotal = 0;
        for (const entry of this.chartEntries) {
            const value = inv[entry.key];
            sumTotal += value || 0;
        }
        return Math.round(sumTotal);
    }

    ngOnDestroy(): void {
        this.subs.forEach((x) => x.unsubscribe());
    }
}

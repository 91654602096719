import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AbinResultDto } from 'src/app/inge-api/client/models';
import { ButtonGroupItemData } from '../../base-components/fordjupning-button-group/button-group-item.model';

@Component({
    selector: 'app-uppfolj-arsskada-gran-fordjupning',
    templateUrl: './uppfolj-arsskada-gran-fordjupning.component.html',
    styleUrls: ['./uppfolj-arsskada-gran-fordjupning.component.scss']
})
export class UppfoljArsskadaGranFordjupningComponent implements OnInit {
    private _senasteInventering = new BehaviorSubject<AbinResultDto>(null);
    senasteInventering$ = this._senasteInventering.asObservable();
    private _inventeringar = new BehaviorSubject<AbinResultDto[]>(null);
    inventeringar$ = this._inventeringar.asObservable();
    private _omradeNamn = new BehaviorSubject<string>(null);
    omradeNamn$ = this._omradeNamn.asObservable();

    public vardeVariabler: string[] = ['arsskadaGranAndel', 'medelfelArskadaGran', 'arsskadaGranAndel3InvMedel', 'medelfelArskadaGran3InvMedel'];

    constructor() {}

    ngOnInit(): void {}

    onOmradeSelect(omrade: ButtonGroupItemData) {
        if (omrade && omrade.inventeringar && omrade.inventeringar.length > 0) {
            this._omradeNamn.next(omrade.omradeName);
            this._inventeringar.next(omrade.inventeringar);
            this._senasteInventering.next(omrade.inventeringar[0]);
        }
    }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { toInteger } from 'lodash-es';
import { forkJoin, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AbinMetadataDto, AbinResultDto } from 'src/app/inge-api/client/models';
import { AbinService } from '../../inge-api/client/services';
import * as apiActions from '../actions/uppfoljningsrapport.actions';
@Injectable()
export class UppfoljniningsRapportEffects {
    uppfoljningsrapportGetSummeringarSamtliga$ = createEffect(() =>
        this.actions$.pipe(
            ofType(apiActions.uppfoljningsrapportGetSummeringLand),
            mergeMap((action) =>
                this.ingeApi.abinSammanfattningHelaLandet().pipe(
                    map((result) => {
                        return apiActions.uppfoljningsrapportGetSummeringLandSuccess({
                            result
                        });
                    }),
                    catchError(() => of(apiActions.uppfoljningsrapportGetSummeringLandError()))
                )
            )
        )
    );

    uppfoljningsrapportGetSummeringLandsdelar$ = createEffect(() =>
        this.actions$.pipe(
            ofType(apiActions.uppfoljningsrapportGetSummeringLandsdel),
            mergeMap((action) =>
                this.ingeApi
                    .abinSammanfattningLandsel({
                        landsdelkod: action.landsdelKod
                    })
                    .pipe(
                        map((x) => {
                            let filtered: AbinResultDto[] = [];
                            if (action.excludeLan && action.excludeLan.length && action.excludeLan.length > 0) {
                                filtered = x.filter((xx) => !action.excludeLan.includes(xx.lanKod));
                            } else {
                                filtered = x;
                            }
                            return filtered;
                        }),
                        map((result) => {
                            return apiActions.uppfoljningsrapportGetSummeringLandsdelSuccess({
                                result
                            });
                        }),
                        catchError(() => of(apiActions.uppfoljningsrapportGetSummeringLandsdelError()))
                    )
            )
        )
    );

    uppfoljningsrapportGetSummeringLan$ = createEffect(() =>
        this.actions$.pipe(
            ofType(apiActions.uppfoljningsrapportGetSummeringLan),
            // delay(30000),
            mergeMap((action) =>
                this.ingeApi
                    .abinSammanfattningLan({
                        lankod: action.lankod
                    })
                    .pipe(
                        // delay(50000),
                        map((result) => {
                            return apiActions.uppfoljningsrapportGetSummeringLanSuccess({
                                result
                            });
                        }),
                        catchError(() => of(apiActions.uppfoljningsrapportGetSummeringLanError()))
                    )
            )
        )
    );

    uppfoljningsrapportGetSummeringAfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(apiActions.uppfoljningsrapportGetSummeringLanAfo),
            mergeMap((action) =>
                this.ingeApi
                    .abinSammanfattningLanAfo({
                        lankod: action.lankod,
                        afonr: action.afonr
                    })
                    .pipe(
                        map((result) => {
                            return apiActions.uppfoljningsrapportGetSummeringLanAfoSuccess({
                                result
                            });
                        }),
                        catchError(() => of(apiActions.uppfoljningsrapportGetSummeringLanAfoError()))
                    )
            )
        )
    );

    uppfoljningsrapportGetLandsdelar$ = createEffect(() =>
        this.actions$.pipe(
            ofType(apiActions.uppfoljningsrapportGetLandsdelar),
            mergeMap((action) =>
                this.ingeApi.abinGetMetadataLandsdelar().pipe(
                    map((result) => {
                        return apiActions.uppfoljningsrapportGetLandsdelarSuccess({
                            result
                        });
                    }),
                    catchError(() => of(apiActions.uppfoljningsrapportGetLandsdelarError()))
                )
            )
        )
    );

    uppfoljningsrapportGetLan$ = createEffect(() =>
        this.actions$.pipe(
            ofType(apiActions.uppfoljningsrapportGetLan),
            mergeMap((action) =>
                this.ingeApi.abinGetMetadataLan({ landsdelkod: action.landsdelKod }).pipe(
                    map((res) => {
                        let sorted = res.sort((a, b) => (a.namn > b.namn ? 1 : b.namn > a.namn ? -1 : 0));
                        if (action.exclude && action.exclude.length && action.exclude.length > 0) {
                            sorted = sorted.filter((x) => {
                                if (!action.exclude.includes(x.kod)) {
                                    return x;
                                }
                            });
                        }
                        return sorted;
                    }),
                    map((result) => {
                        return apiActions.uppfoljningsrapportGetLanSuccess({
                            result
                        });
                    }),
                    catchError(() => of(apiActions.uppfoljningsrapportGetLanError()))
                )
            )
        )
    );
    uppfoljningsrapportGetAfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(apiActions.uppfoljningsrapportGetAfo),
            mergeMap((action) =>
                this.ingeApi
                    .abinGetMetadataAfo({
                        landsdelkod: action.landsdelKod,
                        lankod: action.lanKod
                    })
                    .pipe(
                        map((result) => {
                            return apiActions.uppfoljningsrapportGetAfoSuccess({
                                result
                            });
                        }),
                        catchError(() => of(apiActions.uppfoljningsrapportGetAfoError()))
                    )
            )
        )
    );
    // Hämta alla inventeringar för samtliga landsdelar inom landet
    uppfoljningsrapportGetIngaendeInventeringarLand$ = createEffect(() =>
        this.actions$.pipe(
            ofType(apiActions.uppfoljningsrapportGetIngaendeInventeringarLand),
            mergeMap((action) =>
                this.ingeApi.abinGetMetadataLandsdelar().pipe(
                    mergeMap((result) => {
                        const invReq = result.map((x) =>
                            this.ingeApi.abinSammanfattningLandsel({
                                landsdelkod: toInteger(x.kod)
                            })
                        );
                        return forkJoin(invReq).pipe(
                            map((result) => {
                                return apiActions.uppfoljningsrapportGetIngaendeInventeringarLandSuccess({ result });
                            })
                        );
                    }),
                    catchError(() => of(apiActions.uppfoljningsrapportGetIngaendeInventeringarLandError()))
                )
            )
        )
    );
    // Hämta alla inventeringar för samtliga län inom landsdel
    uppfoljningsrapportGetIngaendeInventeringarLandsdel$ = createEffect(() =>
        this.actions$.pipe(
            ofType(apiActions.uppfoljningsrapportGetIngaendeInventeringarLandsdel),
            mergeMap((action) =>
                this.ingeApi.abinGetMetadataLan({ landsdelkod: action.landsdelkod }).pipe(
                    map((res) => {
                        let filtered: AbinMetadataDto[] = [];
                        if (action.excludeLan && action.excludeLan.length && action.excludeLan.length > 0) {
                            filtered = res.filter((x) => !action.excludeLan.includes(x.kod));
                        } else {
                            filtered = res;
                        }
                        return filtered;
                    }),
                    mergeMap((result) => {
                        const invReq = result.map((x) =>
                            this.ingeApi.abinSammanfattningLan({
                                lankod: x.kod
                            })
                        );
                        return forkJoin(invReq).pipe(
                            map((result) => {
                                return apiActions.uppfoljningsrapportGetIngaendeInventeringarLandsdelSuccess({ result });
                            })
                        );
                    }),
                    catchError(() => of(apiActions.uppfoljningsrapportGetIngaendeInventeringarLandsdelError()))
                )
            )
        )
    );
    // Hämta alla inventeringar för samtliga äfo inom ett län
    uppfoljningsrapportGetIngaendeInventeringarLan$ = createEffect(() =>
        this.actions$.pipe(
            ofType(apiActions.uppfoljningsrapportGetIngaendeInventeringarLan),
            mergeMap((action) =>
                this.ingeApi
                    .abinGetMetadataAfo({
                        landsdelkod: action.landsdelkod,
                        lankod: action.lankod
                    })
                    .pipe(
                        mergeMap((result) => {
                            const invReq = result.map((x) =>
                                this.ingeApi.abinSammanfattningLanAfo({
                                    afonr: toInteger(x.kod),
                                    lankod: action.lankod
                                })
                            );
                            return forkJoin(invReq).pipe(
                                map((result) => {
                                    return apiActions.uppfoljningsrapportGetIngaendeInventeringarLanSuccess({ result });
                                })
                            );
                        }),
                        catchError(() => of(apiActions.uppfoljningsrapportGetIngaendeInventeringarLanError()))
                    )
            )
        )
    );

    constructor(private actions$: Actions, private ingeApi: AbinService) {}
}

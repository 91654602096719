import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: 'ui-info-box-content',
})
export class UiInfoBoxContentDirective {
  constructor() {}

  @HostBinding('class')
  elementClass = 'ui-info-box-content';
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ChartCategory } from 'src/app/common/service';
import { AbinResultDto } from 'src/app/inge-api/client/models';
import { FordelningPiechartBaseComponent } from '../../base-components';

@Component({
    selector: 'app-foder-agoslagsfordelning-chart',
    templateUrl: './foder-agoslagsfordelning-chart.component.html',
    styleUrls: ['./foder-agoslagsfordelning-chart.component.scss']
})
export class FoderAgoslagsfordelningChartComponent extends FordelningPiechartBaseComponent implements OnInit, OnDestroy {
    private subs: Subscription[];

    private chartEntries = [
        // {
        //     name: 'Ungskog',
        //     key: 'foderprognosHa',
        //     color: ChartCategory.MELLANMARKER
        // },
        {
            name: 'Tätort',
            key: 'tatortsmark',
            color: ChartCategory.TATORTSMARK
        },
        {
            name: 'Myrmark',
            key: 'myrmark',
            color: ChartCategory.MYRMARK
        },
        {
            name: 'Övrig öppen mark',
            key: 'ovrigOppenMark',
            color: ChartCategory.OVRIG
        }
    ];
    inventeringsAr: number;
    data: any[][];
    legendData: any[];

    ngOnInit(): void {
        this.subs = [];
        this.subs.push(
            this.inventering$.subscribe((x) => {
                if (x) {
                    this.generateChartData(x);
                }
            })
        );
    }

    generateChartData(senasteInventering: AbinResultDto) {
        const data: any[][] = [['Träslag', 'Procent']];
        const colors = [];
        const legendData = [];
        this.inventeringsAr = senasteInventering.invAr;
        const totAreal = senasteInventering.arealLandHa;

        if (senasteInventering.arealSkogsmarkHa) {
            const name = 'Ungskog';
            const skogstypHa =
                (senasteInventering.tallskogHaABIN3InvMedel ?? 0) +
                (senasteInventering.granskogHaABIN3InvMedel ?? 0) +
                (senasteInventering.bblandskogHaABIN3InvMedel ?? 0) +
                (senasteInventering.ovrigskogHaABIN3InvMedel ?? 0);
            const value = Math.round((skogstypHa / totAreal) * 100);
            const color = this.colormapService.getColor(ChartCategory.MELLANMARKER);
            data.push([name, value]);
            colors.push(color);
            legendData.push({ name, value, color });
        }

        if (totAreal && totAreal > 0) {
            for (const entry of this.chartEntries) {
                if (senasteInventering[entry.key]) {
                    const name = entry.name;
                    const value = Math.round((senasteInventering[entry.key] / totAreal) * 100);
                    const color = this.colormapService.getColor(entry.color);
                    data.push([name, value]);
                    colors.push(color);
                    legendData.push({ name, value, color });
                }
            }
        }
        if (senasteInventering.arealSkogsmarkHa && senasteInventering.foderprognosHa) {
            const name = 'Äldre skog & hyggen';
            const value = Math.round(
                ((senasteInventering.arealSkogsmarkHa -
                    ((senasteInventering.tallskogHaABIN3InvMedel ?? 0) +
                        (senasteInventering.granskogHaABIN3InvMedel ?? 0) +
                        (senasteInventering.bblandskogHaABIN3InvMedel ?? 0) +
                        (senasteInventering.ovrigskogHaABIN3InvMedel ?? 0))) /
                    totAreal) *
                    100
            );
            const color = this.colormapService.getColor(ChartCategory.SKOGSMARK);
            data.push([name, value]);
            colors.push(color);
            legendData.push({ name, value, color });
        }

        this.data = data;
        this.options = { ...this.options, colors };
        this.legendData = legendData;
    }

    ngOnDestroy(): void {
        this.subs.forEach((x) => x.unsubscribe());
    }
}

import { Action, createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import * as actions from '../actions/abin.actions';
import { AbinState, InventeringsNiva } from '../state-model/inge-api.model';

const initialState: AbinState = {
    inventeringar: null,
    inventeringarError: false,
    loadingInventeringar: false,
    rapportUnderlag: null,
    inventeringsNiva: null,

    landsdelar: null,
    landsdelarError: false,
    loadingLandsdelar: false,

    lan: null,
    lanError: false,
    loadingLan: false,

    afo: null,
    afoError: false,
    loadingAfo: false,

    delomraden: null,
    delomradenError: false,
    loadingDelomraden: false,

    inventeringsGeometrier: null,
    inventeringsGeometrierError: false,
    loadingInventeringsGeometrier: false
};

const abinReducer = createReducer(
    initialState,
    on(actions.getAbinSummeringLand, (state, action) => ({
        ...state,
        loadingInventeringar: true,
        inventeringarError: false,
        inventeringar: null
    })),
    on(actions.getAbinSummeringLandError, (state, action) => ({
        ...state,
        inventeringarError: true,
        loadingInventeringar: false,
        inventeringar: null
    })),
    on(actions.getAbinSummeringLandSuccess, (state, action) => ({
        ...state,
        loadingInventeringar: false,
        inventeringarError: false,
        inventeringar: cloneDeep(action.result).sort((a, b) => b.invAr - a.invAr),
        inventeringsNiva: InventeringsNiva.LAND
    })),

    on(actions.getAbinSummeringLandsdel, (state, action) => ({
        ...state,
        loadingInventeringar: true,
        inventeringarError: false,
        inventeringar: null
    })),
    on(actions.getAbinSummeringLandsdelError, (state, action) => ({
        ...state,
        inventeringarError: true,
        loadingInventeringar: false,
        inventeringar: null
    })),
    on(actions.getAbinSummeringLandsdelSuccess, (state, action) => ({
        ...state,
        loadingInventeringar: false,
        inventeringarError: false,
        inventeringar: cloneDeep(action.result).sort((a, b) => b.invAr - a.invAr),
        inventeringsNiva: InventeringsNiva.LANDSDEL
    })),

    on(actions.getAbinSummeringLan, (state, action) => ({
        ...state,
        loadingInventeringar: true,
        inventeringarError: false,
        inventeringar: null
    })),
    on(actions.getAbinSummeringLanError, (state, action) => ({
        ...state,
        inventeringarError: true,
        loadingInventeringar: false,
        inventeringar: null
    })),
    on(actions.getAbinSummeringLanSuccess, (state, action) => ({
        ...state,
        loadingInventeringar: false,
        inventeringarError: false,
        inventeringar: cloneDeep(action.result).sort((a, b) => b.invAr - a.invAr),
        inventeringsNiva: InventeringsNiva.LAN
    })),

    on(actions.getAbinSummeringLanAfo, (state, action) => ({
        ...state,
        loadingInventeringar: true,
        inventeringarError: false,
        inventeringar: null
    })),
    on(actions.getAbinSummeringLanAfoError, (state, action) => ({
        ...state,
        inventeringarError: true,
        loadingInventeringar: false,
        inventeringar: null
    })),
    on(actions.getAbinSummeringLanAfoSuccess, (state, action) => ({
        ...state,
        loadingInventeringar: false,
        inventeringarError: false,
        inventeringar: cloneDeep(action.result).sort((a, b) => b.invAr - a.invAr),
        inventeringsNiva: InventeringsNiva.AFO
    })),

    on(actions.getAbinSummeringDelomrade, (state, action) => ({
        ...state,
        loadingInventeringar: true,
        inventeringarError: false,
        inventeringar: null
    })),
    on(actions.getAbinSummeringDelomradeError, (state, action) => ({
        ...state,
        inventeringarError: true,
        loadingInventeringar: false,
        inventeringar: null
    })),
    on(actions.getAbinSummeringDelomradeSuccess, (state, action) => ({
        ...state,
        loadingInventeringar: false,
        inventeringarError: false,
        inventeringar: cloneDeep(action.result).sort((a, b) => b.invAr - a.invAr),
        inventeringsNiva: InventeringsNiva.STRATUM
    })),

    on(actions.setRapportUnderlag, (state, action) => ({
        ...state,
        rapportUnderlag: cloneDeep(action.inventering)
    })),

    on(actions.getAbinLandsdelar, (state, action) => ({
        ...state,
        loadingLandsdelar: true,
        landsdelar: null,
        landsdelarError: false
    })),
    on(actions.getAbinLandsdelarSuccess, (state, action) => ({
        ...state,
        loadingLandsdelar: false,
        landsdelar: cloneDeep(action.result)?.sort((x, y) => +x.kod - +y.kod),
        landsdelarError: false
    })),
    on(actions.getAbinLandsdelarError, (state, action) => ({
        ...state,
        loadingLandsdelar: false,
        landsdelar: null,
        landsdelarError: true
    })),

    on(actions.getAbinLan, (state, action) => ({
        ...state,
        loadingLan: true,
        lan: null,
        lanError: false
    })),
    on(actions.getAbinLanSuccess, (state, action) => ({
        ...state,
        loadingLan: false,
        lan: cloneDeep(action.result),
        lanError: null
    })),
    on(actions.getAbinLanError, (state, action) => ({
        ...state,
        loadingLan: false,
        lan: null,
        lanError: true
    })),

    on(actions.getAbinAfo, (state, action) => ({
        ...state,
        loadingAfo: true,
        afo: null,
        afoError: false
    })),
    on(actions.getAbinAfoSuccess, (state, action) => ({
        ...state,
        loadingAfo: false,
        afo: cloneDeep(action.result),
        afoError: false
    })),
    on(actions.getAbinAfoError, (state, action) => ({
        ...state,
        loadingAfo: false,
        afo: null,
        afoError: true
    })),

    on(actions.getAbinDelomraden, (state, action) => ({
        ...state,
        loadingDelomraden: true,
        delomraden: null,
        delomradenError: false
    })),
    on(actions.getAbinDelomradenSuccess, (state, action) => ({
        ...state,
        loadingDelomraden: false,
        delomraden: cloneDeep(action.result),
        delomradenError: false
    })),
    on(actions.getAbinDelomradenError, (state, action) => ({
        ...state,
        loadingDelomraden: false,
        delomraden: null,
        delomradenError: true
    })),

    on(actions.getAbinHelaLandetGeometri, (state, action) => ({
        ...state,
        loadingInventeringsGeometrier: true,
        inventeringsGeometrier: null,
        inventeringsGeometrierError: false
    })),
    on(actions.getAbinHelaLandetGeometriSuccess, (state, action) => ({
        ...state,
        loadingInventeringsGeometrier: false,
        inventeringsGeometrier: action.result,
        inventeringsGeometrierError: null
    })),
    on(actions.getAbinHelaLandetGeometriError, (state, action) => ({
        ...state,
        loadingInventeringsGeometrier: false,
        inventeringsGeometrier: null,
        inventeringsGeometrierError: true
    })),

    on(actions.getAbinLandsdelGeometri, (state, action) => ({
        ...state,
        loadingInventeringsGeometrier: true,
        inventeringsGeometrier: null,
        inventeringsGeometrierError: false
    })),
    on(actions.getAbinLandsdelGeometriSuccess, (state, action) => ({
        ...state,
        loadingInventeringsGeometrier: false,
        inventeringsGeometrier: action.result,
        inventeringsGeometrierError: null
    })),
    on(actions.getAbinLandsdelGeometriError, (state, action) => ({
        ...state,
        loadingInventeringsGeometrier: false,
        inventeringsGeometrier: null,
        inventeringsGeometrierError: true
    })),

    on(actions.getAbinLanGeometri, (state, action) => ({
        ...state,
        loadingInventeringsGeometrier: true,
        inventeringsGeometrier: null,
        inventeringsGeometrierError: false
    })),
    on(actions.getAbinLanGeometriSuccess, (state, action) => ({
        ...state,
        loadingInventeringsGeometrier: false,
        inventeringsGeometrier: action.result,
        inventeringsGeometrierError: null
    })),
    on(actions.getAbinLanGeometriError, (state, action) => ({
        ...state,
        loadingInventeringsGeometrier: false,
        inventeringsGeometrier: null,
        inventeringsGeometrierError: true
    })),

    on(actions.getAbinAfoGeometri, (state, action) => ({
        ...state,
        loadingInventeringsGeometrier: true,
        inventeringsGeometrier: null,
        inventeringsGeometrierError: false
    })),
    on(actions.getAbinAfoGeometriSuccess, (state, action) => ({
        ...state,
        loadingInventeringsGeometrier: false,
        inventeringsGeometrier: action.result,
        inventeringsGeometrierError: null
    })),
    on(actions.getAbinAfoGeometriError, (state, action) => ({
        ...state,
        loadingInventeringsGeometrier: false,
        inventeringsGeometrier: null,
        inventeringsGeometrierError: true
    })),

    on(actions.getAbinDelomradeGeometri, (state, action) => ({
        ...state,
        loadingInventeringsGeometrier: true,
        inventeringsGeometrier: null,
        inventeringsGeometrierError: false
    })),
    on(actions.getAbinDelomradeGeometriSuccess, (state, action) => ({
        ...state,
        loadingInventeringsGeometrier: false,
        inventeringsGeometrier: action.result,
        inventeringsGeometrierError: null
    })),
    on(actions.getAbinDelomradeGeometriError, (state, action) => ({
        ...state,
        loadingInventeringsGeometrier: false,
        inventeringsGeometrier: null,
        inventeringsGeometrierError: true
    }))
);

export function reducer(state: AbinState | undefined, action: Action): AbinState {
    return abinReducer(state, action);
}

import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[ui-info-box-shadow]',
})
export class UiInfoBoxShadowDirective {
  constructor() {}

  @HostBinding('class')
  elementClass = 'ui-info-box-shadow';
}
